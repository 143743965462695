
const texts = {

text_1og2: "I: Hadde de nokon spesielle ritual før de begynte timane om morgonen og slik, når de kom på skulen?\n" +
    "1: Me song. Det er heilt greitt. Akkurat kva me song om morgonen, det veit eg ikkje, men @på\n" +
    "ettermiddag var det no omtrent fast denne her «Fager kveldsol smiler». Den hadde me.\n" +
    "2: Det var berre av og til me hadde song om morgonen. Sjeldan, men så hadde me som regel denne\n" +
    "her «Du mettar liten fugl» @når du skulle maten @då.\n" +
    "1: Ja, eg trur me, me bad sikkert «Fadervår». Det er eg nesten sikker @på altså at me gjorde.\n" +
    "I: Når de skulle heim att då?\n" +
    "2: Sprang nesten ned ¤døra @når klokka ringde.\n" +
    "1: Å, nei.\n" +
    "2: Som regel [latter].\n" +
    "I: Sånn som nett før jul og slik, var det noko fast tradisjon at då gjorde skulen eit eller anna?\n" +
    "2: Me hadde i vert fall sånn gjekk rundt juletreet @på Trudvang. Eg veit ikkje om akkurat det var siste\n" +
    "skuledagen, men me gjekk (i hvert fall) rundt juletreet og song no, gjekk og heldt i ~hendene og.\n" +
    "1: Ja, eg kan *hugsa at det var, at det var juletre i, i …\n" +
    "2: gymsalen\n" +
    "1: … i gamle gymsalen, ja at ein var oppe der klassevis av og til og\n" +
    "2: Ja.\n" +
    "1: og gjekk rundt juletre. Det kan eg *hugsa.\n" +
    "……………………………………….\n" +
    "2: Altså skulesekker. Eg meiner slik som, eg tykte me hadde tunge sekker, men dei som dei @går med\n" +
    "no, eg tykkjer det er heilt forferdeleg. Dei er så tunge desse ~skulesekkene at dei @må no\n" +
    "*øydeleggja aldeles ~ryggane @på ~ungane.\n" +
    "1: Eg kan ikkje *hugsa at me tenkte noko spesielt over dette der eg, om det var tungt eller ikkje\n" +
    "tungt. For me fekk sånn §trillebag òg me. Det var ei sånn sykje som ein hadde @då i, sikkert i slutten\n" +
    "av #barneskulen. Det var det som var in.\n" +
    "2: Ja, @då @når eg kom i sjette, @då var det skulderveske som var in.\n" +
    "1: Å, ja. Ja.\n" +
    "2: Du var jo, du var jo aldeles skeive.\n" +
    "1: Ja, nei, det var ikkje nokon som gjekk med.\n" +
    "2: For det var jo så tungt.\n" +
    "1: Ja.\n" +
    "I: Mm.\n" +
    "\n" +
    "2: Men du, du lærte deg jo kunsten å *leggja att bøker @på skulen. Det gjorde jo ein.\n" +
    "1: Dreiv me aldri med.\n" +
    "2: Hadde alt med?\n" +
    "1: Ja, eller me hadde det no ikkje @på skulen. Var no ikkje nokon plass å ha det.\n" +
    "2: Nei. Under pulten!\n" +
    "1: Nei.\n" +
    "2: Gamle pultar som du sat med oppunder ~armane.\n" +
    "1: Nei, hadde ikkje noko der.\n" +
    "I: Når snøen kom då, hadde de nokon plass de kunne vera ute og\n" +
    "2: Hadde sånt halvtak, leskur eller kva det heiter\n" +
    "1: Ja.\n" +
    "2: §imellom Trudvang og gamleskulen. Den var vel ein ja to-tre meter brei og …\n" +
    "1: Var nokre meter lang.\n" +
    "2: Kor lang han var @då?\n" +
    "1: Var nokre meter lang. Eg hugsar det at …\n" +
    "2: Ti, femten meter kanskje lang.\n" +
    "1: Ja, hugsar det at me …\n" +
    "2: Det var vel der du kunne @stå turt.\n" +
    "1: Der kunne du @stå turt. Der hugsar eg ein hoppa strikk inne og, og …\n" +
    "2: og §balla.\n" +
    "1: … og §balla som me seier @når, @når det var, @når det regna. Men elles, eg kan ikkje *hugsa at\n" +
    "me eigentleg hadde så fælt mykje for oss @på vinteren. No var me ein del oppe, veit du det var ikkje\n" +
    "utbygt oppi Rutlinslid eller noko. Det var jo ikkje eit hus der.\n" +
    "I: Nei.\n" +
    "1: Så der kan eg *hugsa at me var av garde og nokre gymtimar, gjekk @på ski, eller siglde rettare\n" +
    "sagt. Det var no bratta, så for mykje @gåing var det no vel ikkje,\n" +
    "I: Mm.\n" +
    "1: men siglde vel noko @på ski der, men elles så var det ikkje så …\n" +
    "………………………………………………………\n" +
    "2: Samla @på egg ei stund, og dei gitt vekk og nei det var …\n" +
    "1: Me hadde ei slik glansbildesykje me, men det var i #barneskulen altså. Og @då var det, @då var\n" +
    "det sjeldne og utanlandske og gamaldagse. Det hugsar eg.\n" +
    "\n" +
    "2: Åh.\n" +
    "1: Guri.\n" +
    "2: englar!\n" +
    "1: Så ho fekk jo tak i slike utanlandske og sjeldne\n" +
    "2: [Latter]\n" +
    "1: Det veit du var altså, det var svære greier viss me fekk tak, for @då @måtte me liksom *betala\n" +
    "med #gjerne fire stykk berre for å @få eitt …\n" +
    "I: Ja.\n" +
    "1: … @få eitt slikt eit. Så hadde me slike samlemapper @då som det var nedi. Desse, dei. Eg er sikker\n" +
    "@på at dei eksisterer ikkje lenger altså\n" +
    "2: Ja, opna …\n" +
    "1: Ja, eg er sikker @på dei hadde lommedukar eller noko liknande i til vanleg, ~mødrene @våre. Så\n" +
    "kva dei §kallar dei for no?\n" +
    "2: Arkivmapper?\n" +
    "1: Ja, meir som ei arkiv, men det var just ikkje arkiv denne her ho, nei.\n" +
    "2: Nei, det var vel meir som ei bok som du kunne *opna?\n" +
    "1: Det var god lukt av ho, for mor mi hadde hatt eitkvart parfyme og litt slikt @småtteri oppi. Me fekk\n" +
    "denne der, eg kjenner den ¤lukta der også heldt @på lenge med, valdsam sortering.\n" +
    "……………………………………………\n" +
    "I: Foreldra då om kveldane, var det noko spesielt dei masa om eller?\n" +
    "2: Eg @åt vel middag, og så gjorde eg lekser med ein gong, fekk det unna.\n" +
    "1: Ja.\n" +
    "2: Elles var det no alltid du @måtte vel som regel *koma deg heim att du skulle ditt og datt og i seng\n" +
    "og.\n" +
    "1: Ja, det var no legging.\n" +
    "2: Eg tykkjer dei er mykje meir ute no enn me fekk *vera.",

text_1pog2p: "I: Ja, ja, har de hatt noko kontakt desse her tjue åra då eller er det?\n" +
    "1: Ser no kvarandre som han sa slik.\n" +
    "2: Kanskje det var meir og kanskje gjennom #barnehagen.\n" +
    "1: Mm.\n" +
    "…………………………………………….\n" +
    "I: Det kjem vel noko nytt her borte òg.\n" +
    "1: Ja.\n" +
    "2: ¤Brua.\n" +
    "1: ¤Brua, ja.\n" +
    "2: ¤Loftesnesbrua.\n" +
    "I: Kva meiner de om den då?\n" +
    "1: Er det slik at det skal *vera plass til både køyrande og @gåande i begge retningar som han sa?\n" +
    "2: Det skal *vera plass, det er tofeltsveg.\n" +
    "1: Ja.\n" +
    "2: Og så blir det gang-sykkelveg @attåt …\n" +
    "1: men berre @på ei …\n" +
    "2: @på den sida mot Barsnes.\n" +
    "1: Men berre @på ei side?\n" +
    "2: Det er @på ei side.\n" +
    "1: Ja, @då skal det liksom, @då skal du *kunna *sykla att og fram hadde eg så nær sagt, @når du\n" +
    "syklar?\n" +
    "2: Ja @då, det skal … Eg veit ikkje kor mange meter, men ein gang-sykkelveg skal *vera tre meter.\n" +
    "1: Eg tykkjer ikkje det ser så fælt stort ut eg @då, veit du. Eg har køyrt forbi der oppe.\n" +
    "2: Kor brei var det ho var @då? §Elleve eitkvart.\n" +
    "1. Ho er, ho er sikkert bra, som han sa, det er berre …\n" +
    "2: Mm.\n" +
    "1: … det at du følte huff er det plass til alt dette her som dei, altså det er jo nøydd å dimensjonerast\n" +
    "for å *tola ein del no @då eller. §Elleve meter totalt?\n" +
    "………………………………………………….\n" +
    "2: Men eg trur mange studentar kjem berre for å alt du kan *oppleva her.\n" +
    "\n" +
    "1: Ja.\n" +
    "2: Og til og med folk som skal *søkja seg jobbar.\n" +
    "1: Ja\n" +
    "2: Dei òg …\n" +
    "1: Ja, ja, ja.\n" +
    "2: … er ute etter meint å *seia fjord og §fjell.\n" +
    "1: Me les no både i avisa og forskjellig slik eitkvart slag frami noko pudderstyr og @då er det nokon\n" +
    "av desse ~tilflyttarane som har etablert seg i jobbar her, og dette her med at dei har @gått @oppå\n" +
    "Togga og kome seg ned att. Og alt dette her har dei vunne før dei begynner @på jobb ho er @åtte.\n" +
    "I: Ja, mm.\n" +
    "2: Eg likar meir å *koma @oppå ¤Fagereggi, liten bakke opp og så @gå @på flata, *finna seg ein\n" +
    "hyttevegg, *kosa seg og ned att sånn.\n" +
    "1: Mm.\n" +
    "2: Eg er meir der.\n" +
    "1: At det er jo så grueleg mange her no, sant? Det er jo noko med det òg altså før så var det no ikkje\n" +
    "anna enn denne her lærarskulen her omtrent, sant?\n" +
    "I: Mm.\n" +
    "1: Og så var det no lite vette @på DH som det heitte den gongen <humrar> før?\n" +
    "I: Mm.\n" +
    "2: Men eg ser @på jobben, der er det mange unge folk som òg fer og sprett oppi §¤fjella\n" +
    "1: Ja, ja, ja.\n" +
    "2: både seint og tidleg.\n" +
    "1: Det er jo valdsamt med denne dette ~toppturgreiene @på, det har følelsen av at det er sånn kreti\n" +
    "og pleti. Berre tenk @på dette her, her nede.\n" +
    "2: Byggjefeltet her ja.\n" +
    "1: Ja, byggjefeltet her nede. Det er jo heilt valdsamt. Dukkar opp slike namn veit du som aldri høyrt\n" +
    "om før.\n" +
    "I: <latter>\n" +
    "1: Jammen, det er sant. Det er jo omtrent som §alle vil …\n" +
    "2: Svigerfar har høyrt dei.\n" +
    "1: Ja, han har høyrt dei, men kan du *tenkja deg at det var sikkert fordi at der hadde dei ~kalvane\n" +
    "@gåande @då, eller der var der dei slo først eller sist eller eitkvart slik.\n" +
    "I: Kva trur de vil endra seg mest no på dei neste tjue åra då?\n" +
    "2: @Då satsar no eg @på at eg skal @få #barne#barn òg.\n" +
    "\n" +
    "1: Ja, @då lyt …, men kva som kjem til å skje liksom sånn i ¤bygda du tenkjer @på? Det med å @sjå\n" +
    "for seg ei utvikling det tykkjer eg er sånn … Ja, kva meir er det som dei kan @få til nede i denne\n" +
    "¤gropa her nede …\n" +
    "I: <latter>\n" +
    "1: no liksom? Her er jo …\n" +
    "I: Ja, ja\n" +
    "1: Jammen her er jo så minimalt med plass.\n" +
    "I: Ja.\n" +
    "2: Nei, no vil dei ikkje *skifta logoen heller?\n" +
    "1: Nei.\n" +
    "2: Det kostar for mykje.\n" +
    "1: Ja, det er no greitt. Det gjer det no òg det. Det er no @rådyrt det.\n" +
    "2: Men, det må jo du ta i betraktning viss du skal @slå saman nokon, og det skal ha eit nytt namn,\n" +
    "1: Ja\n" +
    "2: så kan ikkje du *behalda det gamle, for det er billegast.\n" +
    "1: Mm.\n" +
    "I: <latter>\n" +
    "1: Så har du denne @¤kommunesamanslåinga @då, men eg ser ikkje heilt for meg at kva det skal gje\n" +
    "slags …\n" +
    "I: Nei.\n" +
    "1: valdsame utslag for ¤bygda her.\n" +
    "2: Berre ikkje dei bestemmer at §alle skal *skriva @på @bokmål,\n" +
    "I: Ja.\n" +
    "1: Ja, ja.\n" +
    "2: at me skal *slutta med nynorsken\n" +
    "1: Ja, ja, mm.\n" +
    "I: Mm.\n" +
    "2: som @skriftspråk @då.\n" +
    "1: Ja.\n" +
    "2: For @då er det klart at skal @då du *begynna å *skriva «jeg» heile ¤tida og …\n" +
    "1: Ja.\n" +
    "2: ikkje det. Eg, eg er betre å *skriva bokmål enn nynorsk for det @då tenkjer du @bokmål, men\n" +
    "@når du skulle *skriva nynorsk @når du var ung, så skulle du plutseleg *skriva sånn som du snakkar.\n" +
    "\n" +
    "Og så @då hadde du plutseleg nokre ord §innimellom som sikkert var @bokmål. Men no har jo du\n" +
    "lov å *skriva nesten hummar og kanari, for no er det mykje som er lov. Men det veit du fekk du det\n" +
    "inn gjennom #barne-tv.\n" +
    "I: Mm.\n" +
    "2: Det var alt meir eller mindre var vel … Men det er jo fordelar med å ha fleire @språk. Dei seier at\n" +
    "du blir flinkare @på skulen.\n" +
    "I: Mm.\n" +
    "2: Du lærer meir @språkøyre eller.",

text_3og4 :   "3: Eg hugsar første skuledagen\n" +
    "I: Ja.\n" +
    "3: ser han for meg når@ du snakkar om han korleis eg skal sitja* mellom§ to store.\n" +
    "4: [latter]\n" +
    "3: Eg hugsar det godt. Eg var berre\n" +
    "4: Ja.\n" +
    "3: seks år@ eg begynte.\n" +
    "4: Å, ja. Du begynte så tidleg du?\n" +
    "3: Ja, det hadde vel litt med at søster mi gjekk no i skulen,\n" +
    "4: Ja.\n" +
    "3: og hadde ho lyst å ha einkvan med seg då@.\n" +
    "4: Ja.\n" +
    "3: Og eg gjekk no der i dørene~ og slengde så.\n" +
    "4: Jaha.\n" +
    "3: Så eg vart no med, men eg hugsar det godt. Å, sidan så, heile året@ for så vidt, men eg hugsar\n" +
    "godt skuledagen. Eg veit kva pult eg sat på@, kvar han stod og alt mogleg slag.\n" +
    "4: [humrar] Jaha.\n" +
    "3: Så det, det\n" +
    "I: Var det store klassar?\n" +
    "3: Nei, me hadde, det var ikkje delt skule når@ eg gjekk.\n" +
    "I: Nei.\n" +
    "3: Det var mindre ungar då@ så. Me var vel ein sju-åtte@ ungar.\n" +
    "4: Var ikkje det delt skule då@?\n" +
    "3: Ikkje, nei.\n" +
    "4: Åh.\n" +
    "I: Kor ofte var de på skulen i di tid?\n" +
    "4: Då@ var det annankvar dag. Her var todelt skule og kalla§ det småskulen@ og storskulen, gjekk tre\n" +
    "år@ i småskulen@ og fire i storskulen. Så var det, ja så begynte me no mykje seinare då@. Me\n" +
    "begynte ikkje før rundt den første oktober, og så var omtrent alltid ferdig til den søttande mai. Ja.\n" +
    "I: Kva gjorde de på i friminuttane då?\n" +
    "\n" +
    "3: Å, det var mykje rart du.\n" +
    "4: [humrar]\n" +
    "I: Ja, må fortelja om det.\n" +
    "3: Slo ball§ som det heitte no. Eg veit ikkje om de veit kva det er.\n" +
    "I: Ja.\n" +
    "3: Me hadde ikkje gummiball§. Det var ein som var sydd av larkar 1 .\n" +
    "4: [humrar]\n" +
    "3: For det var no så mykje urer der at skulle, me kunne ikkje få@ han så langt vekk, for då@ fann\n" +
    "ikkje me han att.\n" +
    "I: Nei.\n" +
    "3: Om vinteren så hadde me no ski. Me gjekk vel på ski til skulen og heim att omtrent kvar einaste\n" +
    "dag. Det gjorde vel de òg?\n" +
    "4: Ja.\n" +
    "3: For vegar var det litt mindre med, og dei brøytte no med hest.\n" +
    "4: Frikvartera¤ då var det no til leika* med skiene~ og hoppa* og.\n" +
    "3: Ja.\n" +
    "4: Det var no det som var in då@ i dei dagar.\n" +
    "3: Ja. Og\n" +
    "I: Mm.\n" +
    "4: Og så var det forskjellige øvelsar, sigla* på ei ski og slikt noko.\n" +
    "3: Ja.\n" +
    "I: Åh!\n" +
    "3: Du veit eg hadde det som heiter middagsstund.\n" +
    "4: Jaha.\n" +
    "3: Læraren budde no i skulen då@ i eit rom attåt@ skulestova, så skulle ho laga* seg middag.\n" +
    "4: Jadå@.\n" +
    "3: Og då@ gjekk det av timen, veit du, så då@ hadde me mykje moro.\n" +
    "4: Ja.\n" +
    "1 Larkar = tøyfiller",

text_3pog30p : "I: Men korleis var det dalingar og fjører kom i saman då? Kvar var det de treftest hen?\n" +
    "3: Ja, me @måtte nedom som regel. Me køyrde med hest …\n" +
    "I: Ja.\n" +
    "3: … eller gjekk. Men @når det blei litt betre tid, så me kunne @få *sykla, så kom det seg.\n" +
    "I: Ja.\n" +
    "3: Men eg hugsar godt dette der. Det hende det at dalingar … dei var #gjerne til kyrkje og\n" +
    "køyrde til kyrkje, og så sette dei hesten attmed og gav han litt mat og så breidde @på ryggen,\n" +
    "og så høyrde dei @på ¤preika.\n" +
    "I: Ja.\n" +
    "3: Men no er det ikkje nokon som er til kyrkje @på preik lenger omtrent.\n" +
    "I: Nei. Då møttest de etterpå då, eller?\n" +
    "3: Ja, møttest no litt, hadde nokre slektningar nedi Fjøra.\n" +
    "I: Ja då. Men korleis kom de i kontakt med dalingane då? Hugsar du noko om det, eller?\n" +
    "30: Nei, det var no sjeldan det at me var framover her. Slik at det var no helst dei ~gongene\n" +
    "@når dei kom nedover.\n" +
    "I: Ja.\n" +
    "3: Ja.\n" +
    "30: Ja, det blei det.\n" +
    "3: Ja.\n" +
    "30: Det var no viss så ein var framme @på vatnet og fiska eller noko at ein kunne *treffa @på\n" +
    "nokon og …\n" +
    "3: Ja.\n" +
    "30: … *prata med nokon slikt, men elles …\n" +
    "……………………\n" +
    "I: Hadde de nokon faste timar, faste <stammar>?\n" +
    "30: Ja, ~rutinane var, var mykje det same @hjå oss óg @når me var der nede. Det var …\n" +
    "begynte, begynte som du seier med kristendomskunnskap til å *begynna med, og så blei det\n" +
    "@då kvart fag utover.\n" +
    "I: Ja. Gjorde de noko sånn når de starta første time om morgonen, eller?\n" +
    "30: Første me gjorde me begynte, det var til å *syngja.\n" +
    "I: Ja.\n" +
    "3: Ja. Alltid song.\n" +
    "30: Alltid.\n" +
    "I: Ja.\n" +
    "30: Ja.\n" +
    "…………………………..\n" +
    "I: Men det kjem masse studentar hertil òg …\n" +
    "30: Ja.\n" +
    "I: Kva er det trur de som trekkjer dei då?\n" +
    "3: <nøler>\n" +
    "30: Puddersnø og §fotball og …\n" +
    "\n" +
    "3: Og @gå @på skule her (selvfølgeleg) òg.\n" +
    "I: Ja.\n" +
    "30: Ja, skulen.\n" +
    "I: Men dei trivst valdsamt godt her, seier dei, så.\n" +
    "30: Ja.\n" +
    "I: Det må vera noko anna òg.\n" +
    "3: Her er så mykje å *vera med @på.\n" +
    "I: Ja.\n" +
    "3: Og det er no ofte byfolk <uforståeleg>, så trivst dei i Sogndal, fordi at her er så mangt å\n" +
    "*vera med @på her. Her kan dei @sjå @på §fotball. Her kan dei @gå @på ski og *reisa til\n" +
    "fjells og …\n" +
    "I: Ja.\n" +
    "30: Nei, så eg trur det at det er ein, det er ein, attraktiv plass å *vera @på.\n" +
    "3: Ja, det er det nok, men no begynner det å bli trongt.\n" +
    "30: Ja, no utvidar det seg att no. Det er mykje bygging.\n" +
    "I: Ja. Kvar kan neste byggjeplass koma, nesten sagt? Kvar er det plass?\n" +
    "30: Eg tykkjer det at det begynner å bli bra eg no @når dei har fylt ut @på #Kjørnes, og dei\n" +
    "har @fått fylt ut nedi Nedrehagen og …\n" +
    "3: Ja.\n" +
    "30: Dei har @fått fylt opp over alt oppe @på Ulvehaugen og innover att i ¤Hovsmarka.\n" +
    "I: Ja.\n" +
    "30: Det, det, det er mykje bygging her.\n" +
    "3: Ja, så har ikkje dei plass …\n" +
    "30: Det er no i §alle fall fleire …\n" +
    "3: … har ein ikkje plass på aldersheimen til oss gamle.\n" +
    "30: Kjem vel @på neste byggetrinn ein del.\n" +
    "3: Lyt *sitja heime me og …\n" +
    "……………………………………….\n" +
    "30: Dei hadde no travløp her @nedpå òg, veit du.\n" +
    "3: Ja, dei hadde det ein gong.\n" +
    "I: Å ja. Kan du hugsa det?\n" +
    "30: Ja.\n" +
    "I: Fortel litt om det då. Det har ikkje eg kjennskap til.\n" +
    "3: <hostar> Ja, det er ikkje så mykje me veit om det. Dei skulle ha eit travløp @nedpå her, og\n" +
    "eg veit ikkje kvar dei kom @ifrå eg, desse ~hestane.\n" +
    "30: Nei, det var mange plassar det.\n" +
    "I: Ja.\n" +
    "3: Ja.\n" +
    "30: Hugsar eit, eit renn som dei hadde her nede, eit travløp. Det var han Olav Hofslundsengen\n" +
    "som hadde, …\n" +
    "3: Ja.\n" +
    "30: … hadde ein der @då som … Først så var han så sta, han ville ikkje @gå i det heile teke.\n" +
    "Men så gjekk han noko valdsamt ein, ein runde eller to @då. Og så @når han kom hit att for\n" +
    "\n" +
    "@mål, så var det plutseleg stopp. Og så prøvde dei @få han til att ein gong til. Nei, der skar\n" +
    "han ut og så opp §imellom ~bilane. Sulkyen gjekk over fleire biltak oppover.\n" +
    "3: Ja.\n" +
    "30: Og så for han heimover her, fantastisk. Han gjekk @på tan, <uforståeleg>, utan sulky,\n" +
    "eller utan … Og så @når han kom heim i, … eg veit ikkje kor langt heim han kom, så sleit\n" +
    "han av eine tindla.\n" +
    "3: Ja.\n" +
    "30: Og dermed så sleit han vekk sulkyen og så bar det heimover og heilt fram i <stammar>,\n" +
    "ned i Hildeskår. Og der gjekk han opp ein sideveg, og der blei han @ståande å *pusta.\n" +
    "I: <humrar>\n" +
    "30: Der fekk dei fat i han att nede.\n" +
    "3: Ja.\n" +
    "30: Men han øydelagde … Eg stod på hi sida med kikkert og @såg @på det, eg.",

text_5og6 : "I: Hugsar de den dagen de begynte i skulen, kanskje?\n" +
    "6: Det var einaste eg som gjekk med kjole. Alle§ dei andre gjekk med bukse.\n" +
    "5: [latter]. Det var mykje dilldall på begynnelsen. Eg hadde ikkje lyst å begynna* på skule. For det\n" +
    "første så, så hadde eg ikkje lyst å reisa* heimanfrå@ då@. Eg hadde meir lyst å gå@ i barnehage#\n" +
    "sånn i. Me var vel sju stykke. Var det det me var?\n" +
    "6: Ja, sju stykke i heile klassen, så det var ganske koseleg.\n" +
    "I: Gjekk de nedi Trudvang?\n" +
    "6: Nei, me gjekk på Notsete.\n" +
    "5: Så det var litt spesielt.\n" +
    "6: Kjende alle# alle# og, ja sju stykke i klassen.\n" +
    "I: Ja.\n" +
    "6: Så var me tredve på heile skulen.\n" +
    "I: Kva de gjorde på i friminutta då?\n" +
    "5: Nei, då@\n" +
    "6: Då@ leikte alle# med alle# og leikte\n" +
    "5: Var heilt\n" +
    "6: alle# var med og ingen som stod utanfor og sånn. Det var litt forskjell då@ når@ me kom ned på@\n" +
    "Trudvang, så\n" +
    "5: Då@ følte me oss utanfor med ein gong.\n" +
    "6: Ja, for det var, då@ meinte det dei leikene~ som me hadde på Notsete me hadde så masse sånn,\n" +
    "leikte hest og\n" +
    "5: Ja, me hadde berre slike\n" +
    "6: siglde akebrett og masse sånn.\n" +
    "5: Og så gifte me oss då@ vel å merka*. Hadde laga til, og så når@ det var snø då@ så laga me til\n" +
    "slike borger og så hadde me bryllaup§ inni dei og alt mogleg løge og laga til valdsamt. Det var alltid\n" +
    "litt spesielt, så når@ me kom ned på@ Trudvang, då@ følte du med ein gong valdsamt utanfor, på@\n" +
    "grunn av der var det sparka* fotball§ og gå@ og prata* og hoppa* tau og slikt, noko slik som me aldri\n" +
    "gjorde der framme eigentleg, heilt andre aktivitetar.\n" +
    "I: Kva var de alltid rolege på skulen eller?\n" +
    "6: Fann eigentleg aldri på noko tull.\n" +
    "5: Me torde ikkje.\n" +
    "6: Nei, me kjende lærarane~ så godt at me gjorde eigentleg ikkje noko gale.\n" +
    "\n" +
    "5: Nei.\n" +
    "6: Det var einaste viss me var langt oppi bakkane~ og siglde på@ akebrett og sånn då@, var ikkje\n" +
    "alltid me hugsa på@ å koma* ned att til timen. [latter] Det var liksom det einaste.\n" +
    "5: Ja, det var det einaste, for elles torde ikkje me noko anna.\n" +
    "6: Så hadde me papir inn i ringeklokka, for dei stod på trappa¤ og ringde med sånn klokke då@ sånn,\n" +
    "I: Ja.\n" +
    "6: sauebjølle§, hadde me papir inni den då [latter].\n" +
    "I: [latter]\n" +
    "6: Respekt,\n" +
    "5: Ja.\n" +
    "6: for lærarane~.\n" +
    "I: Kva hadde de nokon faste sånne morgonritual før de, før de begynte timane?\n" +
    "6: Ja, me song den der\n" +
    "5: Me song. Me hadde ein\n" +
    "6: Fager kveldsol smiler song me vel\n" +
    "5: Ja, den hadde me alltid, og så når@ me var heim att, når@ me skulle heim att så var det Alltid\n" +
    "freidig når du går. Det var\n" +
    "6: Så bad me for, song for maten.\n" +
    "5: Ja, song for maten før me åt@ som han sa då@.\n" +
    "……………………………………………………………………………………………………\n" +
    "I: Har de vore på noka lang køyring eller noko?\n" +
    "5: Ja, var det lengste trur eg har køyrt, er vel til Førde. Det var på@ opp, det var på@ sånn\n" +
    "køyregreier. Elles er det ganske mykje køyring i Fjøra. Det er det.\n" +
    "I: Kan du fortelja om ein tur de har vore på eller?\n" +
    "5: Det er ikkje så fæle langt. Eg torer no ikkje å reisa* så langt eg, eg har ein slik gammal bil som nett\n" +
    "så vidt går@.\n" +
    "6: Ja, køyrer mest moped eg då@ sjølv. Eg har eit sånt gamalt vrak av ein moped som går@ sånn,\n" +
    "går@ av og til og så hostar han litt, og så lyset det verkar berre når@ det er lyst ute. Når@ det er\n" +
    "mørkt, då@ koplar det ut med ein gong og fer no og raggar med den då@ [latter]\n" +
    "I: Ja.\n" +
    "6: Kjem no fram med han. Skal no bli godt å bli atten òg.\n" +
    "……………………………………………………………………………………………………\n" +
    "I: Korleis såg jenterommet dykkar ut før?\n" +
    "\n" +
    "5: Det\n" +
    "6: Mitt var rosa.\n" +
    "5: Mitt var raudt, hugsar eg, og så hadde eg grøne gardiner og så berre plakatar og teikningar, berre\n" +
    "slik småe@ ting, berre slikt drit.\n" +
    "I: Kvar du hadde dei hen då?\n" +
    "5: Dei hang rundt om alle§ plassar. Eg hadde\n" +
    "6: Oppetter alle§ vegger. Eg har nesten tapetsert veggene~ med plakatar av\n" +
    "5: Ja, det var heilt.\n" +
    "6: slik popidol og filmstjerner, og hadde masse hesteplakatar òg eg. Og så masse sånn Barbie-\n" +
    "plakatar.\n" +
    "5: Og romsterte på@ dette rommet omtrent heile tida¤. Ein gong i veka så snudde senga¤ eller,\n" +
    "eitkvart. Det måtte@, måtte@ skje eitkvart heile tida¤.\n" +
    "I: Mm.\n" +
    "5: Eg er valdsamt sånn rastlaus type då@. Så klarer ikkje sjå@ sånne, er i ro heile tida¤. Det må@ skje\n" +
    "eitkvart heile tida¤ liksom.\n" +
    "6: No slepp eg no å ta frå@ gardinene~. Det berre heng att heile tida¤ [latter].\n" +
    "5: Ja, heile tida¤ og det med glaset ope og så berre bykser du i senga¤ og søv nokre timar og så er det\n" +
    "opp att og på@ skulen og så etterpå@ gjera* noko arbeid og så kjem du att seint og så. Det går@ i\n" +
    "ein slik fast gang heile tida¤.\n" +
    "I: Kva var det foreldra masa mest om når de var småe då?\n" +
    "5: Kva dei masa med, å skulle gjera* lekser. Eg gløymde for det meste å gjera* lekser då@. Ikkje før\n" +
    "eg kom og skulle leggja* meg sånn i ti-tida¤.\n" +
    "I: Ja.\n" +
    "5: Då¤ kom eg på at eg gjerne# hadde lekser eg òg. Då@ måtte@ eg til med det.\n" +
    "………………………………………………………………………………………\n" +
    "5: Grendebasaren var jo det store.\n" +
    "6: Det er, det er sånn misjonsbasar, og så er det grendelaget som har basar. Så er det fire hå, me har\n" +
    "basar og så. Det er litt.\n" +
    "5: Det er lite vetta sånn aktivitetar der no enno.\n" +
    "I: Mm.\n" +
    "6: Eg var alltid med og selde årer@ for besta så var. Ho stod og skreik opp nummer og sånn då@ som\n" +
    "me vann på@. Og så måtte@ eg alltid vera* med å selja* årer@, måtte@ vera* med å dela* ut mat\n" +
    "og sånn.\n" +
    "5: Ja.\n" +
    "6: Alle§ hadde jo med seg mat då@, så me delte ut\n" +
    "\n" +
    "I: Ja.\n" +
    "6: mat og kaffi og så saft til ungane~. Og så kaker og slikt, og så måtte@ eg vera* med å rydda* opp\n" +
    "att.\n" +
    "5: Fekk no vera* litt lenger opp om kvelden.\n" +
    "6: Ja, vart ikkje ferdig før i ti-elleve§- tida.\n" +
    "5: Nei, då@ fekk du vera* litt lenger oppe, så det. Så å vera* med å rydda* opp att, det var jo det\n" +
    "store det.\n" +
    "I: Mm.\n" +
    "5: Kunne, du, du var ikkje med og rydda* opp, men\n" +
    "I: Ja, ja.\n" +
    "5: du var der liksom, du fekk vera* lenger oppe, det, det var liksom det store.",

text_5pog6p : "I: Jammen hen skal dei få ny viss dei skal utvida det då?\n" +
    "6: Dei blir ikkje einige.\n" +
    "5: Nei, det, det beste hadde vel vore nedpå@ Flugheim, trur eg.\n" +
    "6: Ja, Bjelde gard er jo, har jo\n" +
    "5: Ja.\n" +
    "6: forslag om. Det hadde jo vore flott, men dei vil jo ta fruktgarden til han på@, til han Rutlin. Og det,\n" +
    "det er det jo mange som er ueinige i då@ sjølvsagt. Men då@ tenkjer eg at då@ må@ me sånn som\n" +
    "når@ det står@ tredve stykke på venteliste no for å koma* i barnehage#, så seier jo det litt om at me\n" +
    "heng ikkje heilt med.\n" +
    "5: Men eg tenkjer som så at folk er i bil uansett. Så om det er på@ Fluga eller om det er på@ Rutlin,\n" +
    "det må@ no\n" +
    "6: Å koma* seg gjennom Fjøra no då@ når@ det ofte er så mykje folk bort på Kjørnes# for å henta*\n" +
    "ungar i barnehagen#, det kan vera* litt sånn problematisk, og så skal du over brua¤ som no driv og\n" +
    "byggjer ny, sant? Og der er det kaos. Førre veke når@ det kom snø, så var det ein time ventetid i\n" +
    "Fjøra.\n" +
    "5: Ja, det var på@ grunn av at ein trailer ikkje kom seg over gamlebrua¤\n" +
    "6: Ja.\n" +
    "5: fordi at dei ikkje hadde brøytt. Då@ hadde desse luringane~ gløymt og vore ute og brøytt.\n" +
    "6: Men alle§ desse vogntoga¤ lagar jo mykje trøbbel.\n" +
    "5: Å, fy.\n" +
    "…………………………………………………………..\n" +
    "6: Me var så mykje meir sosiale\n" +
    "5: Ja.\n" +
    "6: sant før du fekk data og telefon\n" +
    "5: Ja.\n" +
    "6: og alt sånt, så var me, me var jo sosiale heile tida¤.\n" +
    "5: Og så var hjå@ kvarandre, valdsamt flinke med det. Og slik var me når@ me fekk førarkort òg, for\n" +
    "det at då@ var det liksom, eller sånn som helgane~ då@, så var det då@ den. Det var alltid ein av oss\n" +
    "som køyrde.\n" +
    "I: Mm.\n" +
    "5: Stort sett. Og då@ kom me oss heim att. Det var òg litt sånn altså tykte mammaa og pappaen var\n" +
    "så greitt, for at då@ var det alltid, alltid einkvan som køyrde.\n" +
    "I: Men de snakkar om samhaldet her i Dalen. Det er spesielt altså?\n" +
    "6: Eg tykkjer det, og her framme så var det ofte nokon som berre banka på og kom inn utan at du\n" +
    "hadde verken avtalt det eller noko, og så sette du deg ned og så tok du deg ein kaffikopp.\n" +
    "5: Ja.\n" +
    "6: Men no føler eg at det er mykje meir avtalt og at dei må@, det er så store forventningar på ein\n" +
    "måte@. Det er ikkje denne her stikka* innom kulturen lenger. Men det er kanskje alle§ plassar, men\n" +
    "eg tykte at når@ eg budde i Dalen, så var det oftare eg fekk sånn her stakk innom besøk.\n" +
    "5: Ja.\n" +
    "6: Det saknar eg.\n" +
    "5: Ja, men det var det mykje meir før. No er det meir sånn, som du seier, dei står ute på trappa¤, så\n" +
    "redde for å bry kvarandre.\n" +
    "6: No er det vorte så kort veg i Fjøra. Folk er jo nede i Fjøra to gonger for dagen, tre gjerne# òg, sant.\n" +
    "5: [humrar]\n" +
    "6: Mens me var, når@ me kom heim frå@ skulen, så var me heime. Var me gjerne# nedpå@ Notsete.\n" +
    "Det var ingen som køyrde ekstra tur i Fjøra for å verken handla*. Butikkane~ var no ikkje opne heller,\n" +
    "sant. Så der har det skjedd mykje òg.\n" +
    "5: Ja, det er ikkje att\n" +
    "6: Butikkane~ stengde klokka halv fem, var ikkje noko å renna* ned der etter.\n" +
    "5: Og så hadde me jo sirka ein kveld i veka som me møttest og spelte fotball§ bortpå@ Notsete.\n" +
    "6: Ja, og det var vaksne og ungar og\n" +
    "5: Rubbel og bit\n" +
    "6: alt mogleg.\n" +
    "\n" +
    "5: Og me fekk vera* med dei. Me delte oss inn i lag og spelte fotball§.\n" +
    "6: Det som er att no då@, det er fire hå. Dei held ut. Og så er det grendelaget som har to aktivitetar i\n" +
    "året@, og det er basar om hausten og så er det grendefest om våren@. Det er vanskeleg å halda* liv\n" +
    "i slike ting.\n" +
    "5: Nei, alt skjer nedafor.\n" +
    "6: Ja, og så er det noko med dugnadsinnsatsen òg. Folk har det så travelt. Det har det skjedd mykje\n" +
    "med då@ at folk har det så travelt at det er ikkje nokon som har tid til noko.\n" +
    "I: Nei, kva dei gjer då?\n" +
    "6: Nei, går rundt seg sjølv, trur eg, og seier dei har det travelt.\n" +
    "5: Ja, det trur eg\n" +
    "6: [latter]\n" +
    "5: faktisk at\n" +
    "6: At det er litt in i tida¤ no og seia* at dei har det. Viss ein ikkje har det travelt, så er det noko gale\n" +
    "med deg.\n" +
    "5: Nei, då@ har du møtt veggen, så då@.\n" +
    "I: [latter]\n" +
    "6: [latter]\n" +
    "5: Jammen det er anten eller det. Då@, då@ må@ du ta det med ro.\n" +
    "6: Ja.\n" +
    "5: Så då@ kan du i vert fall ikkje ut. [nøler] Det er ser eg. Det er det nye at dei har rett og slett, det er\n" +
    "vorte for mykje så dei har møtt veggen, og det innrømmer dei òg. Og då@ ser du dei knapt.\n" +
    "6: Eg trur ikkje det at dei hadde det mindre travelt. Det var berre det at dei tenkte ikkje på@ det på@\n" +
    "den måten.\n" +
    "…………………………………………………………\n" +
    "I: No er jo denne her studentveka i gang. Er det noko som bygdefolket er med på òg eller?\n" +
    "6: Det er litt konsertar som er for alle§ då@.\n" +
    "5: Hadde ikkje dei vore på Trudvang no og laga til eitkvart slags arrangement då@?\n" +
    "6: Jau, graffiti.\n" +
    "5: Graffiti greier der.\n" +
    "6: Og så var dei på sjukeheimen og song til dei gamle og hatt med seg dei på kino,\n" +
    "5: Seier du det ja.\n" +
    "6: så dei prøver no å gjera* litt ut, ut frå bygda¤, eller ut ifrå@ huset òg. Eg trur det er viktig, for det\n" +
    "at viss Sogndal berre skal vera* sånn turistplass om sommaren heldt eg på@ å seia* eller berre\n" +
    "fotball§, det er ikkje, det er ikkje nok. Det er så mange andre som har så mykje, så er flott at me\n" +
    "\n" +
    "5: Mm.\n" +
    "6: kan ha litt vinterstid òg.\n" +
    "5: Ja, så er det noko med det at dei, når@ folk har funne fjelltoppane~ om vinteren på snø sant,\n" +
    "når@ dei har bykst utover skrentane~,\n" +
    "6: Ja.\n" +
    "5: så kjem dei att om sommaren og ser.\n" +
    "…………………………………………………………..\n" +
    "5: Det er det og veit du, berre tenkjer berre tenk på gymnaset, handelsskulen. Det var liksom det me\n" +
    "hadde når@ me gjekk på@ vidaregåande@.\n" +
    "6: Mm., ja.\n" +
    "5: Og no kjem du opp der, så eg må@ berre seia* at eg veit ikkje å kjenna* meg att.",

text_7og8 : "7: Det eg tykkjer er mest moro, det var om @våren. Du veit at @då var det hestebrøyting, og så @då\n" +
    "begynte å *@tåna 1 etter ~stabbesteinane. Og me sprang rundt dei. @Då tykte me det var @vår, og\n" +
    "@då var det moro. Det er eit minne som sit i meg, altså.\n" +
    "8: Dreiv og sprang rundt ~steinane <humrar>. Det kan ikkje *vera så moro.\n" +
    "7: Jau, det var interessant for meg og gild det blei @tåflekker.\n" +
    "………………………………………………………………………..\n" +
    "I: Kva gjorde de då før de gjekk heim att om dagen?\n" +
    "7: @Då vart det lese @Fadervår, stilte oss opp @då stod attmed pulten. Og så song me Fager\n" +
    "kveldssol smiler. Det var vanleg. Ja.\n" +
    "8: For det meste den.\n" +
    "7: Ja @då. Me hadde i §alle fall valdsam respekt for læraren i dei dagar.\n" +
    "I: Ja. Fann de aldri på noko tull?\n" +
    "8: Jau.\n" +
    "7: Å jau @då, det gjorde me, men @då laut dei *sitja att @når hine gjekk heim.\n" +
    "8: Det var strengt det, veit du.\n" +
    "7: Ja.\n" +
    "I: Straffa var det?\n" +
    "7: Ja, laut *sitja inne.\n" +
    "8: Ja, laut anten *sitja inne i friminuttet, *sitja i @¤kråa eller *sitja @attepå. *Sitja @attepå det var jo\n" +
    "ei stor skam. @Då kunne ein *risikera å @få juling @når me kom heim att.\n" +
    "I: Kva gjorde de når de kom heim att om dagane då?\n" +
    "8: @Då var det som regel å *jobba.\n" +
    "7: Men me hadde ikkje kjøpeleiker slik som dei har i dag. @Då var det til å *reisa opp i ¤marka, og så\n" +
    "var det til å *leggja §heller, og så steikte me §hellekaker av lauvblad, kokte av sand og drit, kan eg\n" +
    "*seia.\n" +
    "I: Kven som såg etter dykk når de var småe då?\n" +
    "7: <latter> Var ikkje nokon.\n" +
    "8: Mm. Det var no den eldste @måtte *passa den yngste. Eg for min del så var eg på §stølen med ho\n" +
    "mor heile sommaren. @Då første ~somrane så var ho der med sju @småe ungar. Så rann det ei elv\n" +
    "nett forbi selet @vårt. Så var det for oss eldste til å *sitja å *passa @på dei yngste, hadde no atten\n" +
    "mjølkekyr og yste og # 2 kinna/kjerna. Og @då laut me eldste *vera #barnejenter. Det var ikkje snakk\n" +
    "om noko anna.\n" +
    "1 Å tåna vil seia at snøen smeltar om våren slik at det vert snøbert, og jorda vert telefri.\n" +
    "2 Halda mjølk i rørsle slik at feittet skil seg ut som smør\n" +
    "\n" +
    "7: Det var heimelaga mestedelen.\n" +
    "8: Mm. Me hadde #korn òg i dei dagar, veit du. Så hadde dei mølle i Sogndal. Så var det til og ned\n" +
    "@på mølla å *mala mjøl og …\n" +
    "7: Så kokte dei graut ut av dette mjølet. Det vart slik som ei, eg veit ikkje om det er §kalla for\n" +
    "sammale, slik at @~#kornsåene dei kom i lag med i mjølet. Så kokte graut utav. @Når me hatt graut,\n" +
    "så kjende me berre kor det raspa nedetter halsen <humrar> av desse @~#kornsåene eller det skalet\n" +
    "på #kornet kan du *seia.\n" +
    "I: Ja.\n" +
    "7: Det var kokt graut ein kveld og så var det @påhita att kvelden etter.\n" +
    "I: Hadde de høner òg?\n" +
    "7: Ja.\n" +
    "8: Ja, det var all slags husdyr; høner og geiter, sauer og kyr.\n" +
    "7: Dei gjekk vilt og så varp dei rundt §alle marker. Så. Det var arbeid til å *leita opp att reiret til\n" +
    "~hønene og *finna ¤egga. Det var ikkje sikkert me …\n" +
    "I: Ja.\n" +
    "8: Nei, egg det hadde ikkje me @råd til å *bruka.\n" +
    "7: Hadde ikkje så mykje høner at me hadde @råd å *eta egg.\n" +
    "8: Nei, nei. Det hadde ikkje @råd til å *eta. Det var om å *gjera å @få *selja.\n" +
    "………………………………………………….\n" +
    "I: Korleis var juletradisjonane då? Kva gjorde de kring jul?\n" +
    "8: Nei, det var ikkje så mykje julepresangar.\n" +
    "7: Eg hugsar så godt @hjå oss, som me var mange. Ho mor la ned julepakkar med, så gøymde\n" +
    "lyspæreboksar, og så hadde ho nedi visst mykje godt i dei. Og me var lukkelege for å @få det og. Og\n" +
    "#gjerne kvar si teiknebok og. Men eg trur det at me var @endå meir gilde av det me fekk den gongen\n" +
    "enn det dei er i dag kor dyre presangar. Det er ikkje det som tel. Men og så juledag @då gjekk det,\n" +
    "@då gjekk det rutebil som me seier eller.\n" +
    "8: Nei.\n" +
    "7: Gjekk det kyrkjebuss, jau.\n" +
    "8: Ja, ja @når du var ung #gjerne. Eg er mykje eldre eg. <latter>.\n" +
    "7: Ja, @då køyrde dei med hest @då men.\n" +
    "8: Ja.\n" +
    "7: @Då var det til å *møta opp og …\n" +
    "8: @Då var det flott. @Då, @då skulle du ha vore her, her framme i Dalen, altså. På den ¤tida.\n" +
    "I: Ja.\n" +
    "\n" +
    "8: Køyrde med hestar. Og @då, og så hadde me @då ~§dombjøllene. ~Ungane i dag dei kjenner bilar,\n" +
    "men me kjende ~hestane. Me kjende ikkje berre ~hestane, men me kjende §bjølla til kvar enkelt.\n" +
    "<nøler>\n" +
    "7: Bonde frami.\n" +
    "8: Bonde frami. @Då var det høgtidssamt altså @når ~dalingane fór nedkøyrande til kyrkje juledag.\n" +
    "Somme ~tidene @såg ein gneistrande kaldt at du høyrde berre kor det kvein under slede,\n" +
    "~sledemeiane.\n" +
    "I: De kunne høyra korleis hest som var på veg?\n" +
    "8: Ja, me kjende dei på ~§bjøllene eller @på ~§dombjøllene.",

text_9og10:  "I: Kva gjorde de på i friminuttane då?\n" +
    "9: Nei, me har, me siglde valdsamt mykje @på kjelke nedover mot ¤Haukereidbrua,\n" +
    "10: Mm.\n" +
    "9: og det var i grunnen ein viss fare med og ei viss straff for, for me hadde ikkje lov\n" +
    "10: Nei.\n" +
    "9: forbi Notsetesvingen.\n" +
    "10: Neivel.\n" +
    "9: Og så opp @på Øynahaugen\n" +
    "10: Ja, ja @då.\n" +
    "9: For @då @såg dei oss altså, men veit reiste me ned for ¤Notsetebrua, den var litt farleg, for @då\n" +
    "kunne ¤elva, og det kunne *vera hol i isen.\n" +
    "I: Mm.\n" +
    "10: Å, ja.\n" +
    "9: Og så var det hende det no siste @¤åra, siste par @¤åra #gjerne, at me klarte å *kasta litt pengar\n" +
    "om @~vårane etter det var så mykje 1 tie vorte, men det var i grunnen, det var ikkje bra.\n" +
    "10: Nei.\n" +
    "9: Det var, me @måtte, @då kunne det no *henda me blei litt lettare tilsnakka.\n" +
    "I: Mm. Leikte …?\n" +
    "9: Og så var det å @slå §ball og …\n" +
    "I: Ja.\n" +
    "9: og slikt @då @på.\n" +
    "10: Mm.\n" +
    "9: Me hadde ein valdsamt fin skuleplan\n" +
    "10: Ja. Ja.\n" +
    "9: der @på Notsete. Den blei vel opparbeidde. Dei var ikkje ferdig med han heilt @når eg begynte i\n" +
    "skulen.\n" +
    "10: Nei, eg hugsar dette der.\n" +
    "9: Det var att noko muring borti eit hjørne noko og så, og så laga dei til støypekant og fine greier\n" +
    "oppover mot dyrka ¤marka der som dei hadde teke av,\n" +
    "\n" +
    "1 Tie = isfritt\n" +
    "\n" +
    "10: Mm.\n" +
    "9: og gjorde han valdsamt fint inne og gruste han opp noko.\n" +
    "I: Mm.\n" +
    "9: Så det var, det var, valdsamt fint slik. Denne ¤pengekastinga som me for av garde med, men eg\n" +
    "trur det var #gjerne nest siste, sjetteklassen eller sjuandeklassen om @våren @då hende det me stal\n" +
    "oss til og så nett *prøva i ¤friminutta.\n" +
    "10: Ja.\n" +
    "…………………………………………………….\n" +
    "I: Bilar og slikt, men hadde de noko anna framkomstmiddel de kunne koma dykk fram på?\n" +
    "10: Det var sykkel det, veit du.\n" +
    "9: Ja.\n" +
    "10: Å, ja me sykla til Fjøra @på fest. Så sat ein @oppå ¤stonga\n" +
    "I: Ja.\n" +
    "10: #gjerne og det var to stykk og så nedover. Det hugsar eg at, og så viss det hende det at viss dei\n" +
    "hadde dårlege bremsar, at dei fann ein, ein sånn stor busk, eller dei hogg ein sloe som me sa og\n" +
    "hengde atti @når me køyrde nedover Hausadalen. <latter> Det var …\n" +
    "9: Det\n" +
    "10: Det opplevde ikkje du sjølvsagt.\n" +
    "9: Nei.\n" +
    "10: Nei. Nei, det var no før krigen dette der stort sett.\n" +
    "I: Kva trur de vil forandra seg her i Sogndal dei neste tjue åra då, forandra seg mest?\n" +
    "10: <Kremtar> No veit du det har jo forandra seg så kolossalt i siste tjue tretti @¤åra at det er veldig\n" +
    "vanskeleg å *seia kva forandringar, og den store ¤forandringa det er jo sjølvsagt §alle desse ~skulane\n" +
    "som er komne, og …\n" +
    "9: ¤Brua inne.\n" +
    "10: Ja. Ja, du tenkjer @på\n" +
    "9: ¤Loftesnesbrua.\n" +
    "10: ¤Loftesnesbrua, det var jo ein revolusjon, veit du.\n" +
    "I: Mm.\n" +
    "10: Men det er jo så langt tilbake som i @¤femtiåra eller.\n" +
    "9: @Åtteogfemti.\n" +
    "10: @Åtteogfemti ja, og du før den ¤tida så kom ikkje over Loftesnessundet utan …\n" +
    "9: Med ferje.\n" +
    "\n" +
    "10: … med ferje.\n" +
    "I: Mm.\n" +
    "10: Eg hugsar jo så langt tilbake at det berre var ein @robåt, eller ein stor @båt som dei rodde, men\n" +
    "det var ferje @då opp til som du seier @åtteogfemti. Og …\n" +
    "9: Og du veit at @då, me har eit, eit bilete som me fekk hjå Bergum @oppå flyplassen, og det var …\n" +
    "Det må *vera teke @våren niogfemti. Det er fruktblomstring.\n" +
    "10: Mm.\n" +
    "9: Og @då @står det ei gravemaskin nett over sjukestova nede, gamle sjukestova.\n" +
    "I: Ja.\n" +
    "9: @Då var dei begynte @på vegen\n" +
    "10: Mm.\n" +
    "9: oppover @på, @på nedste Leite.\n" +
    "10: Ja.\n" +
    "9: Og @då kan du *tenkja med deg sjølv. @På Stedje var det ingenting,\n" +
    "10: Nei.\n" +
    "9: var ikkje eit bustadhus.\n" +
    "10: Nei.\n" +
    "9: Det var (kun), det var (kun) ~frukthagane og så, og så han, han Anders Stedje og Hagelins-\n" +
    "eigedomen.\n" +
    "10: Ja.",

text_11og12 : "11: Hugsar ikkje, men me var i §alle fall ein gjeng. @Då var me bakom lærarbustaden og dreiv @på\n" +
    "der, så nær sagt. Mens dei andre var @på plassen og leikte.\n" +
    "12: Ja.\n" +
    "I: Hadde de treft kvarandre før de begynte i skulen?\n" +
    "11: Ja, #barnehagen.\n" +
    "12: Det var #barnehage òg @på same plassen @då liksom.\n" +
    "11: Ja.\n" +
    "12: Så at du var jo heile ¤tida kjend med dei som gjekk @på skulen eigentleg @då før du begynte der\n" +
    "òg.\n" +
    "11: Mm.\n" +
    "12: Det var som regel berre eitt @år før du begynte @på skulen at du var i #barnehage.\n" +
    "11: Ja.\n" +
    "………………………………………………………………………………..\n" +
    "I: Kva, hadde de noko sånn der ritual før de begynte timane om morgonen?\n" +
    "12: Ba ikkje me denne her @Fadervår eller eitkvart sånt, trur eg?\n" +
    "11: Jau, eg trur det var noko.\n" +
    "12: Me @måtte @stå attmed pulten @då og be den.\n" +
    "I: Ja.\n" +
    "12: Og så trur eg me song ein sånn godmorgonsong.\n" +
    "11: Ja, det ja.\n" +
    "12: I (begynnelsen) anten det var kvar time eller var\n" +
    "11: Mm.\n" +
    "12: det berre først @på dagen?\n" +
    "11: Nei, eg trur det var først @på dagen, eg, @då me kom.\n" +
    "12: Ja, så @måtte me *§stilla opp i ei sånn rekke @då før me gjekk inn. To og to og …\n" +
    "11: <latter>\n" +
    "12: Verkeleg sånt opplegg.\n" +
    "11: Ja, det var …\n" +
    "I: Når de slutta om dagen då? Var det …?\n" +
    "11: @Då var det berre å *springa ut og så i bussen og så heim att.\n" +
    "12: Ja, eg måtte @gå. Det var mykje moro @når me gjekk heimatt.\n" +
    "I: Ja.\n" +
    "12: Ein fire, fem stykke @då som @måtte @gå bort på hi sida att. @Då var det …\n" +
    "I: Kva fann de på då?\n" +
    "12: Nei, det var alt det. <humrar> Stort sett det meste.\n" +
    "11: Kom vel ikkje heim att så tidleg antakeleg.\n" +
    "12: Nei, det gjekk ikkje nett så fort. Om vinteren @då laga me #gjerne snøholer rundt §alle plassar og\n" +
    "siglde på ~gymposane ned for ¤brua og …\n" +
    "11: <latter> Huff og meg.\n" +
    "12: Mamma og dei begynte ofte å *lura på kvar me blei av eigentleg @då. Så hadde me sånn fast viss\n" +
    "me kom oss ned for ¤brua @då til dess posten for oppover, så fekk me *sitja på med ho. Av og til så\n" +
    "venta ho @på oss @då viss ikkje me var komne, så ho @såg oss oppi.\n" +
    "\n" +
    "11: Braut dykk inn.\n" +
    "12: Fekk no som regel med §alle.\n" +
    "I: Du følgde buss?\n" +
    "11: Me hadde ein sånn … Det var ein ifrå Dalen som køyrde den me …\n" +
    "……………………………………………………………………………………\n" +
    "I: Var de alltid rolege på skulen då?\n" +
    "11: Nei. <latter>\n" +
    "12: Nei, eg blei kasta @på gangen ein gong. <humrar> Det var sånn …\n" +
    "11: Huff og meg. Nei, me var no bra sånn @bråkegjeng.\n" +
    "12: Men eigentleg så trur eg det var roleg der @då mot så #gjerne i større klassar @då.\n" +
    "11: Sende på gangen og litt slik, men ja.\n" +
    "12: Eg hugsa eg blei kasta @på gangen, for at me ~jentene sat bakarst @då og så prata. Heile ¤tida.\n" +
    "@Då ville ho *flytta oss fram @då. Det nekta eg @på, for eg ville ikkje *sitja framme. <humrar> Så\n" +
    "@då var det rett @på gangen @då, fæle. <humrar> Det er det einaste eg hugsar.\n" +
    "I: Fekk du skjenn nokon sinne?\n" +
    "11: Jau det, <latter> det hugsar eg. Eg skreiv @på veggen ute. Det var i eit friminutt og @då skreiv eg.\n" +
    "<nøler> Eg hugsar ikkje kven det var eg @då, men i §alle fall at nokon var kjærastar @då. Så @då\n" +
    "hadde dei kvisla til ~lærarane @då, veit du. @Då veit du eg @måtte *stoppa @då @når me stilte oss\n" +
    "i kø for å *koma inn att @då. Eg fekk jo den ¤overhalinga. Det …\n" +
    "12: Og så ein gong @då laga me til streik. Me hengde opp plakatar @då før ~lærarane var komne\n" +
    "@på skulen at me ville *streika, for me at fekk ikkje lønn eller eit eller anna sånt.\n" +
    "11: <latter>\n" +
    "12: Det var heilt … Nekta å @gå inn og …\n" +
    "…………………………………………………..\n" +
    "I: Hugsar de korleis, nesten sagt, jenteromet dykkar såg ut?\n" +
    "12: Såg helst at ~veggane var dekte, og så masse, masse rot.\n" +
    "11: Eg delte rom med søster mi eg @då @når eg var mindre. For det @såg jo ikkje ut.\n" +
    "I: Nei.\n" +
    "11: Det var jo så rotete, og det var jo heilt sånn …\n" +
    "12: Det var jo sånn det var grusamt viss eg @måtte *rydda det, for @då treivst eg ikkje @på rommet\n" +
    "mitt. Eg hadde vel noko sånn gamle senger, sånn vanleg senger eg @då før. Og så @når eg @då fekk\n" +
    "eige rom, så fekk me sånn sovesofa. Sånn som du kunne ha som vanleg enkeltseng @då.\n" +
    "11: Mm.\n" +
    "12: Så kunne du ha som dobbeltseng.\n" +
    "I: Du då?\n" +
    "11: Nei, eg òg hadde sånn vanleg seng til eg vart litt eldre slik at me kjøpte. Tente @då, var med @på\n" +
    "¤onna @då, så fekk me ting for at me hjelpte til @då. §Sykkel og slikt.\n" +
    "I: Var det noko foreldra masa om når de kom heim att om kveldane då?\n" +
    "12: *Gjera ~leksene.\n" +
    "11: Ja, det var einaste det @då, men. Det er vel slik som det er §alle plassar.\n" +
    "\n" +
    "I: Kva de gjorde når de kom fram att om dagane då elles?\n" +
    "11: @Då var det å *hiva @på seg sånne gamle klede, eller kvardagsklede @då, så var det ut å *leika\n" +
    "og *sykla og …\n" +
    "12: *Herja ute. <humrar>\n" +
    "11: Ja. <humrar>\n" +
    "12: *Dritna seg mest mogleg til og …\n" +
    "11: Og @då var mamma ganske blid me kom inn att. <humrar>\n" +
    "12: (Hvert fall) aldri snakk om noko ned i Fjøra eller noko sånt.\n" +
    "11: Nei.\n" +
    "12: Det var alltid at du hadde meir enn nok å *gjera @på heime @då, *driva @på med der.\n" +
    "I: Så de vart aldri så gode vener med Fjøre-ungane då?\n" +
    "12: Nei, det har eigentleg aldri vorte sånn.\n" +
    "11: Nei.\n" +
    "12: (Hvert fall) ikkje før du kom @på ungdomsskulen @då, #gjerne begynte å @gå ut om ~kveldane,\n" +
    "sant. Du reiste ned i Fjøra @då.\n" +
    "11: Ja.\n" +
    "12: @Då, @då blei det #gjerne litt meir.\n" +
    "11: Ja. @Då vart me jo meir kjende, men elles så har ikkje me …\n" +
    "12: Elles har det liksom vore <nøler>. Det har jo alltid vore ~dalingane og ~fjøringane @då og.\n" +
    "11: Mm.\n" +
    "I: Korleis kom de dykk i Fjøra før då heldt eg på å seia?\n" +
    "12: Før me fekk lappen? Nei, @då var det mamma og pappaen som måtte *køyra @då, eller me\n" +
    "køyrde fleire i lag liksom.\n" +
    "11: Ja.\n" +
    "12: Og så var det moped.\n" +
    "11: Ja, moped òg.\n" +
    "12: @Frå me var seksten, @då gjekk det ein del @på det. Nokre gonger @når det var heilt krise,\n" +
    "11: Ja.\n" +
    "12: … så gjekk me ned i Fjøra òg.\n" +
    "11: <latter>\n" +
    "12: @Frå meg @då og ned.\n" +
    "11: Det var, det var krise.",

text_11pog12p : "I: Kvar gjekk de hen då?\n" +
    "11. Me gjekk @på Notsete.\n" +
    "12: Ja.\n" +
    "11: Mm.\n" +
    "I: Er det den einaste staden i Dalen der du kan gå før skulen for å seia det slik?\n" +
    "11: Ja.\n" +
    "12: Ja.\n" +
    "11. Var.\n" +
    "12: No, ja no er det #barnehage der.\n" +
    "11: Mm.\n" +
    "12: Men det var jo der me begynte @på #barneskulen òg.\n" +
    "11: Ja.\n" +
    "I: Å ja. Ja.\n" +
    "12: Ja, eller førsteklassen.\n" +
    "11: Ja. Ja. Me gjekk tre @år.\n" +
    "12: Og @då var det ikkje nokon andre … Nei, §alle (selvfølgeleg) var jo der.\n" +
    "11: Tre første @¤åra @på skulen gjekk me der framme. Ja. Så vart det ned @på Trudvang. @På\n" +
    "Notsete så @då @måtte me jo alltid *§stilla oss i kø. Dei ringde med denne her §bjølla.\n" +
    "12. §Bjølla.\n" +
    "11: Så stod me i kø. @Då hadde me faste plassar som me stod …\n" +
    "12: Ja, det hadde me sikkert.\n" +
    "11: Ja.\n" +
    "12: Eg òg hugsar det no, utanfor ¤døra der.\n" +
    "11: Utanfor ¤døra, ja. Stod @på rad og rekke.\n" +
    "12: Så opna dei ¤døra og så …\n" +
    "11: Ja, og så song me.\n" +
    "12: Ja.\n" +
    "11: Men kva me song?\n" +
    "12: Men, men eg trur me song før me gjekk òg.\n" +
    "11: Ja.\n" +
    "12: At me stod attmed ~pultane og song.\n" +
    "\n" +
    "………………………………………………….\n" +
    "11: @Ifrå me begynte @på skulen så vart det (kun) dalingar, sant, og no er det blanding. Og alt av\n" +
    "fritidsaktivitetar og … Det skjer her nede.\n" +
    "12: Ja, så trur eg at no køyrer ein dei meir òg.\n" +
    "11: Ja.\n" +
    "12: Me @måtte jo *vera i Dalen, heldt eg @på å *seia.\n" +
    "11: Ja.\n" +
    "12: For det var jo ingen som gadd å *køyra oss\n" +
    "I: Mm.\n" +
    "12: … noko sånn …\n" +
    "11: Ja. Heller så @måtte ein @gå kanskje.\n" +
    "12: Ja.\n" +
    "11: … viss så det …\n" +
    "12: Men sant du blei ikkje køyrd i Fjøra\n" +
    "11: Nei, nei, nei. Nei.\n" +
    "12: … tre gonger i veka for å @gå @på fotballtrening eller noko sånt sant?\n" +
    "11: Nei.\n" +
    "12: Det var jo ikkje aktuelt.\n" +
    "11: Nei.\n" +
    "I: De trødde ikkje?\n" +
    "11: Nei, det var for langt.\n" +
    "………………………….\n" +
    "I: Kjenner, kjenner alle alle?\n" +
    "11: Ikkje no meir.\n" +
    "12: Eg kjenner ikkje §alle. <humrar>\n" +
    "11: Ikkje no meir. Det var vel før meir at ein visste kven §alle var, liksom. Men no …\n" +
    "Men eg ser i (hvert fall) det at @når sommaren er, og ~studentane er vekke, så er det rimeleg daudt i\n" +
    "…\n" +
    "12: Ja, det vil eg tru.\n" +
    "11. … ¤bygda. @Då er det ganske så stilt både @på butikkar og, og elles det at ...\n" +
    "I: Det kjem vel turistar her og?\n" +
    "11: Ja, men det er ikkje … Det blir ikkje det same. Altså det er jo klart at ~turistane …\n" +
    "\n" +
    "12: Altså. Akkurat for ~turistane så er det ikkje så mykje å @sjå i Sogndal, tenkjer eg.\n" +
    "11: Nei.\n" +
    "12: Det er ikkje der dei kanskje helst stoppar.\n" +
    "11: Nei.\n" +
    "12: Kanskje dei overnattar.\n" +
    "11: Ja.\n" +
    "I: Kva vil du visa dei viss dei kjem hit?\n" +
    "11: ¤Elva her for eksempel.\n" +
    "12: Ja.\n" +
    "11: Det ser eg … Der er det utruleg mykje turistar som er oppe og tek bilete @når den er fin.\n" +
    "12: Ja, fordi at det er sånne ting meir enn, enn butikk\n" +
    "11: Ja. Mm.\n" +
    "12: … eller ei gate i ja…\n" +
    "11: @Då kjem me tilbake til naturen. Det er jo den dei …\n" +
    "I: Ja, hen ville de ha teke dei med @då viss du skulle ha teke dei med i naturen her?\n" +
    "11: Nei, det @måtte no bli Sogndalsdalen det, sjølvsagt.\n" +
    "12: Ja, §Anestølen, tenkjer eg.\n" +
    "11: Ja, §Anestølen, sant?\n" +
    "12: Ja.\n" +
    "11: Du har Dalavatnet ein kjempefin sommarsdag, sant?\n" +
    "12: Ja.\n" +
    "11: Du ser berre §¤fjella speglar seg nedatt @på vatnet. Det er fint.\n" +
    "12: Det tenkjer eg òg at …\n" +
    "11: Ja, det tenkjer eg òg, ja, nei det er ei …\n" +
    "12: For eg tenkjer at det er jo, det er jo viktig med fastbuande, men samtidig så er det jo viktig med\n" +
    "hyttefolk, viss så ser jo det at dei legg jo igjen pengar\n" +
    "11: Ja dei gjer, ja.\n" +
    "12: @på ein heilt annan @måte enn andre turistar for eksempel, sant.\n" +
    "………………………………………..\n" +
    "11: Kva nynorsken er jo kjempeviktig for oss.\n" +
    "I: Er han det?\n" +
    "\n" +
    "11: Ja.\n" +
    "I: Kvifor det ?\n" +
    "11: Nei.\n" +
    "12: Irritera ~austlendingane. <humrar>\n" +
    "11: Men du skal faktisk ikkje *reisa så valdsamt langt før du ...\n" +
    "12: Nei.\n" +
    "11: Eg var til Førde i ¤helga @på laurdagen, og så sa eg «saukjan». Eg skulle *seia telefonnummeret\n" +
    "mitt.\n" +
    "12: Mm.\n" +
    "11: Og så @då vart det @då «saukjan».\n" +
    "I: Mm.\n" +
    "11: Og det skjønte altså @då ikkje ~førdianarane. Og @då tenkjer eg at …\n" +
    "12: Nei, det er jo ingen som skjønar det. Eg seier det jo «saukjan» og «akjan» og sånn @på butikken\n" +
    "òg.\n" +
    "11: Ja.\n" +
    "12: Og @då berre ser dei @på meg. Eg skjønar jo at kanskje ein austlending ikkje skjønar det.\n" +
    "11: Ja.\n" +
    "12: Men eg tenkjer folk rundt her, så burde dei …\n" +
    "11: Ja.\n" +
    "12: … @på ein måte *skjøna det.\n" +
    "11: Men eg tenkjer …\n" +
    "12: Og @då blir det i (hvert fall) prinsipp at eg @må *seia det …\n" +
    "11: Ja.\n" +
    "12: … først og så …\n" +
    "11: Ja.\n" +
    "12: … ser eg om eg endrar @på det viss dei oppfører seg.\n" +
    "11: Eg berre ser viss så det er plakatar som er utstilte eller for butikkar og sånt og viss så det ikkje er\n" +
    "skrive @på nynorsk, så kjenner eg at eg eigentleg blir litt sånn halvirritert. For det liksom eg\n" +
    "forventar at det skal *vera skrive @på nynorsk her altså. Så det er valdsamt viktig, nynorsk.\n" +
    "…………………………………..\n" +
    "I: Denne her har de vel lagt merke til?\n" +
    "11: ¤Brua?\n" +
    "\n" +
    "12: Ja.\n" +
    "11: Nye bru.\n" +
    "12: Den har jo til og med eg sett.\n" +
    "11: Fin bru.\n" +
    "12: Ja.\n" +
    "11: Men det òg trong me, for den hi gamla ¤Loftesnes-brua, ho var begynt å bli §halvekkel.\n" +
    "12: Ja.\n" +
    "11: Smal og for dei mjuke ~trafikantane …\n" +
    "12: Ikkje minst.\n" +
    "11: … så var ho no ikkje noko særleg. Sånn gynga.\n" +
    "12: §Dritskummel. Ja, veit du.\n" +
    "11: Kjende det at ho …\n" +
    "12: Synest no nesten det er skummelt å *køyra over ho.",

text_13og14 : "13: Det er nok litt forskjell @på mitt, min oppvekst og, og hans.\n" +
    "14: Ja, det er greitt.\n" +
    "I: Men hugsar de den dagen de begynte i skulen då?\n" +
    "13: Ja, gjer det.\n" +
    "I: Ja.\n" +
    "13: Gjer nok det, og eg @måtte nok dra @på skulen aleine. Det var ikkje oppfunne dette her at far\n" +
    "eller mor skulle *vera med den gongen.\n" +
    "14: Å?\n" +
    "13: Så eg reiste aleine med ein liten heimelaga ryggsekk.\n" +
    "I: Ja.\n" +
    "13: Og kom meg @på skulen, og eg @måtte no antakeleg vis ha klart og gjort greie for meg, for eg\n" +
    "kom no også att om kvelden.\n" +
    "14: <humrar>\n" +
    "13: Og skuledagen @vår han var nok kanskje litt positiv @på mange @måtar det var. Me hadde ein\n" +
    "lærar som var grundig i dei fag, særleg i dei ¤faga som, som etter eg blei eldre har kanskje sett mest\n" +
    "pris @på.\n" +
    "I: Mm.\n" +
    "13: Han, han var flink i norsk, @endå kanskje litt flinkare i religion, og han hadde vel òg sterke, sterke\n" +
    "kort i det som me §kalla for, for soge den gongen.\n" +
    "I: Hadde de noko spesielt sånn ritual før de begynte timane?\n" +
    "13: Ja, me song\n" +
    "I: Ja.\n" +
    "13: … og hadde, og den første timen det var kristendom.\n" +
    "14: Alltid?\n" +
    "13: @Jadå.\n" +
    "14: Å.\n" +
    "13: Opna med song og annakvar dag hadde me …, ein dag var det bibelsoge, og den neste dagen\n" +
    "hadde me forklaring.\n" +
    "I: Å. Kva skjedde med dykk viss de ikkje hadde gjort leksene dykkar då?\n" +
    "13: <kremtar> Vel, eg hugsar ikkje så mykje til det at me, me @måtte ta opp att ~leksene. Eg oppfatta\n" +
    "meg vel den gongen som eg gjer i dag kanskje litt under middels flink, fullstendig klar over det at der\n" +
    "var elevar som var langt, langt mykje betre, men, men. Neivel, me hakka oss nok igjennom det og\n" +
    "\n" +
    "<nøler>. Både, både forklaring, dette som eg §kalla for ¤forklaringa og bibelsoga var @då @utanåt.\n" +
    "Og ¤salmeversa òg @måtte me *læra oss @utanåt.\n" +
    "14: Det var no noko slik den gongen som eg var òg det med salmevers og slikt noko at du @måtte no\n" +
    "*læra det @utanåt, men om eg var flinke i det <latter> var eg i §alle fall ikkje.\n" +
    "13: Eg oppfatta vel litt kontroversielt har gjort i ettertid, det var at me @måtte *begynna å *læra\n" +
    "salmevers @utanåt eigentleg før me hadde lært å *lesa.\n" +
    "I: Å.\n" +
    "13: Det var tungt.\n" +
    "14: Jammen, det var det no slik at, @då var det ¤foreldra @då …\n" +
    "13: Ja, ja, ja, ja, visst var det det.\n" +
    "14: … som @måtte tiltre.\n" +
    "13: Ja, ¤foreldra det som @måtte …\n" +
    "14: Nei, eg hugsar ikkje så detaljert dette der.\n" +
    "I: Nei, kva hugsar du …\n" +
    "14: Det gjer eg ikkje altså.\n" +
    "I: … frå du begynte i skulen?\n" +
    "14: Nei, akkurat (begynnelsen) det <latter> hugsar eg ikkje, sant å *seia direkte.\n" +
    "I: Nei, dei første åra?\n" +
    "14: Det første, første dagen og slikt noko det hugsar eg i §alle fall ikkje, men første ¤åra og slikt noko,\n" +
    "det var no å *læra å *lesa og *skriva og slik, for eg var vel ikkje den største kløpparen <humrar>, trur\n" +
    "eg i §alle fall der. Så det er no så men.\n" +
    "I: Kva de gjorde på i friminuttane og slikt då?\n" +
    "14: Nei, det var no @då @når eg var … Så var det no kome denne plassen som er borte på Notsete\n" +
    "no òg, så det var no forskjellig slags. Det var no fotballspeling og mang slags slik som ein fann no\n" +
    "@på. Ein hadde vel sånn å *kasta @på pinne og noko slikt styr eit eller anna sånt noko.\n" +
    "I: Ja\n" +
    "14: Sidan så. Det var no litt friidrett òg trur eg at me dreiv litt slik …\n" +
    "I: Å, ja.\n" +
    "14 :… både gymnastikken og, og elles. Dei hadde liksom sånn, slik sandhole som ein skulle *hoppa\n" +
    "langt i og *hoppa <stammar> høgde i og slikt noko.",

text_14pog29p: "I: Kva de set pris på med å bu her i Sogndal då?\n" +
    "29: Eg tykkjer me har eigentleg alt her i Sogndal som me har bruk for. Det er jo valdsamt til\n" +
    "aktivitet @på §alle område. Det er eit variert næringsliv her, for du har §alle yrkesretningar,\n" +
    "§alle yrkesgreiner. Me har ikkje noka slike hjørnesteinsbedrifter som ein er avhengige av. Så,\n" +
    "så har me flott natur rundt omkring, §alle ~stadene, for dei som likar det @då, ikkje sant? Har\n" +
    "no vorte så bra med vegstandar no viss ein vil *reisa og ta seg ein tur til Bergen eller kvar det\n" +
    "er, så er det ikkje lange ¤stunda, veit du.\n" +
    "I: Korleis var det du reiste til Bergen?\n" +
    "14: Nei, til å *begynna med @når før eg fekk no førarkort, så var det no med … Det var no\n" +
    "ofte den der @snøggbåten eg reiste med.\n" +
    "29: Mm.\n" +
    "14: Men det hende no sjølvsagt eg reiste med buss òg. Sidan så, hadde no teke førarkort @då\n" +
    "og var no begynt i arbeid, så, så hadde no eigen bil, så eg køyrde no som regel. Og det var no\n" +
    "oftast over §Vikafjellet eg for.\n" +
    "I: Det er no fint oppå der.\n" +
    "14: Ja.\n" +
    "29: Mm. Du veit at …\n" +
    "14: Men den gongen så var det eigentleg ikkje noko alternativ.\n" +
    "29: <kremtar>\n" +
    "14: Du @måtte no *køyra ytre lei, men det trur eg aldri eg gjorde.\n" +
    "29: Men var ikkje det ferje som gjekk @på Gudvangen den, den ¤tida òg?\n" +
    "14: Jau.\n" +
    "I: Ja. Når du vaks opp då, var det noko køyring de dreiv med då?\n" +
    "29: Sykla, ikkje noko anna. Eg brydde meg ikkje noko om desse ~mopedgreiene heller.\n" +
    "I: Nei.\n" +
    "29: Det var jo nokon @då som hadde nokon mopedar, men akkurat i desse der gjengen som\n" +
    "eg var med, så var det no meir §fotball me dreiv med og ein del aktivitetar slik sett. Så det var\n" +
    "i grunnen ingen som hadde mopedar eller motorsyklar av den … av dei som eg var med @då.\n" +
    "Men det var nokon @få utanom. Eg hugsar desse her som var ein seks, sju @år eldre enn\n" +
    "meg. @Då var det ein periode det var noko valdsamt med motorsyklar nede i Fjøra. For @då\n" +
    "dreiv dei @på heile sommaren og køyrde dei @på rad og rekkje, #gjerne ein @åtte, ti stykk\n" +
    "@då <uforståeleg> §alle ~stadane nede i Fjøra rundt, rundt omkring.\n" +
    "…………………………..\n" +
    "I: Ja, men de hadde no skule her framme?\n" +
    "14: Ja. Og så, så Notsete var skule, så, så #barneskulen var no der borte. Me hadde òg nokre\n" +
    "timar i Fjøra.\n" +
    "I: Å, ja.\n" +
    "14: Slik at ¤engelskundervisninga den var i Fjøra @ifrå femteklasse …\n" +
    "29: Akkurat.\n" +
    "14: … veit eg, og utetter. Og var òg der nede med, med sløyd. Me var i saman med, med\n" +
    "resten av ~dalingane som hadde skular lenger framme her, og så ~øvstedølene.\n" +
    "\n" +
    "…………………………………\n" +
    "I: Kva tykkjer de om denne her då?\n" +
    "14: Ja, det er vel naturleg me @får ei ny bru, veit eg\n" +
    "29: Det, ja, ja ¤Loftesnesbrua. Dette blir, dette blir flott dette der. Det blir det. Det er no …\n" +
    "det blir seksti @år, <nøler> seksti @år sidan sist @då.\n" +
    "I: Ja, det blir det vel då.\n" +
    "14: Ja.\n" +
    "29: Ja.\n" +
    "I: Så det er behov for ei ny ei her?\n" +
    "29: Ja, det er det vel. Du ser på desse ¤vogntoga som fer og køyrer no, ikkje sant? Dei blir jo\n" +
    "berre lengre og lengre desse ¤vogntoga og breiare og breiare òg til ein viss grad òg. Slik at det\n" +
    "er jo viss det er no to vogntog på ¤brua samtidig der, så ristar jo heile ¤brua. Og dette her\n" +
    "fortauet @då, ikkje sant, det er vel … Det, det @går akkurat med ei #barnevogn, å *køyra ei\n" +
    "¤barnevogn @på det viss dei tek det med ro og siktar godt. Er det ei litt slik brei #barnevogn,\n" +
    "så er det ikkje @råd å *køyra @på, @på ~kantane der, veit du.\n" +
    "14: Nei.\n" +
    "29: For …\n" +
    "14: Men eg tykkjer at det er godt gjort at denne ¤brua har halde så lenge, eg. For tenk @på …\n" +
    "29: Ja.\n" +
    "14: … den trafikken @på den gongen som at ¤brua vart bygd.\n" +
    "29: Ja.\n" +
    "14: Det kan ikkje samanliknast.\n" +
    "29: Nei, dei har nok vore bra framsynte den gongen ¤brua der vart planlagd, for det er jo …\n" +
    "14: Ja.\n" +
    "29: … godt, godt handtverk.\n" +
    "14: Ja.\n" +
    "29: Det er jo ikkje tvil om.\n" +
    "14: Det er heilt utruleg at det … Du veit det var ikkje nokon trafikk …\n" +
    "29: Nei.\n" +
    "14: … samanlikna med i dag.\n" +
    "29: Nei, ingen, ingenting det.\n" +
    "14: Og dei, dei <stammar> (~størrelsane) @på ¤køyretøya som var kan ikkje samanliknast\n" +
    "heller.\n" +
    "29: Nei, @då hadde dei vel … Billaget hadde vel, hadde denne Sognefjord-ruta som var så\n" +
    "populær, ikkje sant @då? Viss dei køyrde til Oslo ein gong eller to i veka.\n" +
    "14: Ja.\n" +
    "29: Det var vel den største bilen …\n" +
    "14: Det var vel det.\n" +
    "29: … som gjekk over ¤brua, og det blir berre @småtteri mot det som køyrer i dag.\n" +
    "14: Ja.",

text_15og16 : "16: Ja.\n" +
    "I: Var de kvar dag de var på skulen?\n" +
    "16: <kremtar>\n" +
    "15: Var vel ikkje det? Det var vel annakvar hæ?\n" +
    "16: Nei det var annakvar, veit du fordi at det var <nøler>\n" +
    "15: Minste og største klasse.\n" +
    "16: Minste og største klassen, ja.\n" +
    "15: Det var vel slik første og andre og tredje.\n" +
    "16: Men me gjekk vel i skulen @på ~laurdagane, gjorde ikkje me det?\n" +
    "15: Jau, det gjorde me.\n" +
    "16: Tysdag, torsdag og laurdag og …\n" +
    "15: Ja, ~laurdagane var det ..\n" +
    "16: Stemmer det.\n" +
    "15: … var det skule.\n" +
    "16: Ja.\n" +
    "I: Kva dreiv de med i friminutta då?\n" +
    "15: Nei, det var no alt mogleg det, veit du. Det var alt @ifrå @vår og sommar og @slå §ball og\n" +
    "§snøballkrig og alt.\n" +
    "16: Ja, det var å @slå §ball. Om vinteren @då var det, @då var det slalåmløype nett heimom grende-\n" +
    "heimom skulen der.\n" +
    "15: Yes. Ja.\n" +
    "16: Og han skreik mange ~gongene @på oss for å @få oss inn att, hugsar eg.\n" +
    "15: @Slå §ball det viser her fortsatt.\n" +
    "16: Ja.\n" +
    "15: Eg stod rett bakom han Torleif, og @då fekk eg ¤sleiva her, veit du … @Då var det vekk.\n" +
    "16: Nei, ~kropssøvingtimane dei …\n" +
    "15: Det hadde ikkje me, men <kremtar> @på timeplanen, men det hadde me vel i praksis.\n" +
    "16: Ja, dei blei ofte lange.\n" +
    "15: Yes.\n" +
    "I: Korleis var det dei fekk dykk inn att då til time?\n" +
    "16: Nei, han, <klappar> han klappa i ~hendene.\n" +
    "\n" +
    "15: Stemmer. Det gjorde han.\n" +
    "16: Han klappa i ~hendene.\n" +
    "15: Det, det hugsar eg no, ja.\n" +
    "16: Det §small godt, så me høyrde det.\n" +
    "15: <kremtar>\n" +
    "16: <latter>\n" +
    "I: Så de var så nær alltid de at …\n" +
    "16: Ja @då. Var ikkje meir enn ein …\n" +
    "15: Kor mange var me der @då? Ein @åtte, ti, tolv stykke?\n" +
    "16: Ja.\n" +
    "15: Antakeleg vis.\n" +
    "16: Me var vel eitkvart slikt, ja.\n" +
    "15: Du kan *seia @småskulen var ein dag, og altså første og andre og tredje klassen og fjerde, femte,\n" +
    "sjette og sjuande var …\n" +
    "16: Neste.\n" +
    "15: … dei andre ~dagane.\n" +
    "16: Ja. <kremtar>\n" +
    "15: Så minste og største klassen som dei §kalla det. <latter>\n" +
    "16: Ja.\n" +
    "15: Så slik var det.\n" +
    "I: Kva, hadde de noko sånn spesielt ritual før de starta timane om morgonen, eller?\n" +
    "16: Ja, me song ein song, og så …\n" +
    "15: Var klokka ni om morgonen det.\n" +
    "16: Ja.\n" +
    "15: Og så las ein «Fadervår», eller?\n" +
    "16: Ja. Stemmer det.\n" +
    "15: Var det det?\n" +
    "16: Ja. Det stemmer det, var heilt …\n" +
    "15: Var det ikkje det som var det vanlege ritualet den gongen?\n" +
    "………………………………………………….\n" +
    "I: Men før de begynte med bilkøyring og sånt, hadde de noko anna framkomstmiddel de kunne koma\n" +
    "dykk fram med?\n" +
    "\n" +
    "15: Nei, det var jo moped @då @ifrå me var seksten til me var atten @år.\n" +
    "I: Ja.\n" +
    "16: Ja.\n" +
    "15: Og så var det no buss, og skulebussen for no nedover ho var @åtte om morgonen og fram att\n" +
    "halv tre om dagen.\n" +
    "16: Ja.\n" +
    "15: Me hadde no den å *følgja viss me skulle noko.\n" +
    "16: Ja. Ja.\n" +
    "I: Mm. De hadde ikkje nokon tohjulingar då som de trødde på?\n" +
    "15: Hadde det, men det var vel sjeldan at me sykla ned …\n" +
    "16: Eg sykla …\n" +
    "15: Trefte vel at me sykla ned og så tok bussen fram att, og det var sykkelstativ @på bussen og styr.\n" +
    "For trefte vel òg ein sykla fram att, men det var tungt.\n" +
    "16: Eg sykla mykje @på …\n" +
    "15: Gir høyrde ikkje du om.\n" +
    "16: … fotballkamp, hugsar eg, før eg var seksten @år.\n" +
    "15: Akkurat.\n" +
    "16: @Då sykla me mykje @på fotballkamp.\n" +
    "15: Ja.\n" +
    "16: Så @då, @då var me fleire som sykla.\n" +
    "15: Ja.\n" +
    "16: @Då sykla me begge vegar. Det var aldri seigt. @Då hadde ikkje me ein og tjue gir heller. @Då\n" +
    "hadde me, @då hadde ikkje me gir. Det var greitt.\n" +
    "15: Det var eitt gir.\n" +
    "16: Vart det for tungt, så @måtte me av å @gå.\n" +
    "15: Ja, det var no, det var no vanleg …\n" +
    "16: <humrar>\n" +
    "15: … at du @måtte @gå ein del.\n" +
    "16: Ja, ja @då det var det.\n" +
    "I: Korleis trur de det vil vera å bu her i Sogndal ja sei om tjue år då?\n" +
    "16: For eg tenkjer @på viss det @går slik som ~myndigheitene vil no, så blir §alle ~gardane liggjande\n" +
    "brakk her med gule bøar og langt gras og … Det er eg redd for, at @då blir det ikkje så kjekt å bu her.\n" +
    "15: Det skjer før, før tjue @år.\n" +
    "\n" +
    "16: <kremtar> Det skjer #gjerne før heller tjue @år det.\n" +
    "15: Ja.\n" +
    "I: Kva trur, trur du vil, korleis trur du det vil vera?\n" +
    "15: Nei, der er eg einig med han.\n" +
    "\n" +
    "Utskrift av samtalen mellom informant 15 og 16 og intervjuar 2017 (panel)\n" +
    "15. Det er masse studentar. Er ikkje det ein tre, fire tusen studentar @då? I §alle fall tre #gjerne, slik\n" +
    "cirka? Du veit at hadde ikkje ~skulane vore i Sogndal, så hadde det vore ganske stilt her nede.\n" +
    "16: Ja. Ja.\n" +
    "15: Det er i §alle fall greitt.\n" +
    "16: Nei, eg veit ikkje, men du ser no det som har skjedd rundt om i §¤fjella her no og …\n" +
    "15: Ja.\n" +
    "16: … og slikt noko. Det er … Det er flott å @sjå @når du sit om ~kveldane at det lyser oppi §alle\n" +
    "~stadene, og kjem ned att i ti-, §elleve-¤tida. Det er ofte dei reiser …\n" +
    "15: Ja.\n" +
    "16: Dei reiser #gjerne i … fem, fem, fire-, ¤femtida …\n" +
    "15: Om morgonen.\n" +
    "16: … om morgonen, og så fer dei opp, og så kjem dei ned att, og så skal dei @på skulen.\n" +
    "I: Ja vel.\n" +
    "15: Det er ofte at du ser hovudlykter\n" +
    "16: Ja.\n" +
    "15: grytidleg om morgonen.\n" +
    "I: Seier du det, ja?\n" +
    "16: Heller dei kjem fram etter skulen og fer opp @då i seks-, ¤sjutida, og kjem ned att i §¤ellevtida.\n" +
    "15: @Såg i @går kveld så var det jo masse, mange …\n" +
    "16: Ja.\n" +
    "15: … lykter hit i her.\n" +
    "I: Ja.\n" +
    "16: Ja, det kom eit heil tog borti der.\n" +
    "I: Å ja.\n" +
    "16: Eg har nett sete og sett @på nokon no som har kome ned att.\n" +
    "15: Har det kome nokon no i kveld òg?\n" +
    "\n" +
    "16: Ja, dei er nett, nett nedkomen.\n" +
    "15: akkurat.\n" +
    "I: Korleis kjem dei seg opp då?\n" +
    "16: Nei, @då har dei noko …\n" +
    "15: @Då @går dei @då.\n" +
    "16: Dei, du sel no #gjerne slikt?\n" +
    "15: Ja, du veit at mange @går jo @på, @på truger opp.\n" +
    "16: Ja.\n" +
    "15: Og enkelte dei tek jo skitrekket opp, og så @går dei vidare @ifrå toppen av skitrekket og fram\n" +
    "§fjellet.\n" +
    "16: Ja.\n" +
    "15: Det er ikkje så langt veit du.\n" +
    "16: Nei, det er no …\n" +
    "15: Og så ned att der og over Gunvordalen og …\n" +
    "I: Ja.\n" +
    "15: … slike ting.\n" +
    "16: Ja. Ja @då.\n" +
    "15: Så dei er spreke, veit du. Men slik som framme i Togga og dette der, der @må dei jo @gå opp.\n" +
    "I: Ja.\n" +
    "15: Der er inga hjelp opp.\n" +
    "16: Nei, no er dei @på §alle §fjell.\n" +
    "15: Ja.\n" +
    "16: No er dei @på Togga her, og dei er @på Frudalshesten.\n" +
    "15: Nakken.\n" +
    "16: Og dei er @på Nakken, og dei er @på Grøssete og opp att @på, heim att her.\n" +
    "15: Ja. Ja.\n" +
    "16: Og heim att i Tylderingen og …\n" +
    "I: Ja.\n" +
    "16: … kjem ned att her heime.\n" +
    "15: Ofte det at du ser det @står bilar heime i Kvamshagen, ukjende bilar med …\n" +
    "16: Ja.\n" +
    "15: … med skibøylar @på taket og slike ting og ...\n" +
    "\n" +
    "I: Mm.\n" +
    "16: Dei brukar i eigentleg §alle §¤fjella, og det tykkjer eg er …\n" +
    "15: Ja.\n" +
    "16: … positivt at dei kjem seg ut og @får *gjera. Det er flott.\n" +
    "I: Ja.\n" +
    "16: Det er, det er eksplosjon no siste @¤åra.\n" +
    "15: Ja.\n" +
    "16: Det er no fredeleg og godt her framme @då. Me har @fått Fjærlandsvegen og … , og det er fint,\n" +
    "fin veg ned og. Tjue minutt @ifrå tunet her og ned, og det er … Ja.\n" +
    "15: Du kan *seia det hadde vore surare hadde Fjærlandsvegen kome @på denne sida,\n" +
    "16: Ja. Ja.\n" +
    "15: det var jo snakk om det òg, veit du.\n" +
    "16: Ja @då. Og så §Anestølen var det snakk om.\n" +
    "I: Ja.\n" +
    "15: Ja.\n" +
    "16: At det skulle *koma tvert gjennom der òg så. Nei @då.\n" +
    "15: Det hadde ikkje vore så kjekt hatt den trafikken rett forbi ~husdørene i §alle fall.\n" +
    "16: Det er både positivt og negativt å bu her framme.\n" +
    "15: Ja.\n" +
    "I: Mm.\n" +
    "16: Eg trur det er slik §alle ~stadene.\n" +
    "15: Ja. <humrar> Det er ein fare for det.\n" +
    "16: men me har det godt her framme, stilt og roleg og …\n" +
    "15: Ja.\n" +
    "I: Ja, hadde du villa budd ein annan plass?\n" +
    "15: Tja, eg veit ikkje. Vanskeleg å *svara @på.\n" +
    "I: Ja.",

text_15pog16p : "15. Det er masse studentar. Er ikkje det ein tre, fire tusen studentar @då? I §alle fall tre #gjerne, slik\n" +
    "cirka? Du veit at hadde ikkje ~skulane vore i Sogndal, så hadde det vore ganske stilt her nede.\n" +
    "16: Ja. Ja.\n" +
    "15: Det er i §alle fall greitt.\n" +
    "16: Nei, eg veit ikkje, men du ser no det som har skjedd rundt om i §¤fjella her no og …\n" +
    "15: Ja.\n" +
    "16: … og slikt noko. Det er … Det er flott å @sjå @når du sit om ~kveldane at det lyser oppi §alle\n" +
    "~stadene, og kjem ned att i ti-, §elleve-¤tida. Det er ofte dei reiser …\n" +
    "15: Ja.\n" +
    "16: Dei reiser #gjerne i … fem, fem, fire-, ¤femtida …\n" +
    "15: Om morgonen.\n" +
    "16: … om morgonen, og så fer dei opp, og så kjem dei ned att, og så skal dei @på skulen.\n" +
    "I: Ja vel.\n" +
    "15: Det er ofte at du ser hovudlykter\n" +
    "16: Ja.\n" +
    "15: grytidleg om morgonen.\n" +
    "I: Seier du det, ja?\n" +
    "16: Heller dei kjem fram etter skulen og fer opp @då i seks-, ¤sjutida, og kjem ned att i §¤ellevtida.\n" +
    "15: @Såg i @går kveld så var det jo masse, mange …\n" +
    "16: Ja.\n" +
    "15: … lykter hit i her.\n" +
    "I: Ja.\n" +
    "16: Ja, det kom eit heil tog borti der.\n" +
    "I: Å ja.\n" +
    "16: Eg har nett sete og sett @på nokon no som har kome ned att.\n" +
    "15: Har det kome nokon no i kveld òg?\n" +
    "\n" +
    "16: Ja, dei er nett, nett nedkomen.\n" +
    "15: akkurat.\n" +
    "I: Korleis kjem dei seg opp då?\n" +
    "16: Nei, @då har dei noko …\n" +
    "15: @Då @går dei @då.\n" +
    "16: Dei, du sel no #gjerne slikt?\n" +
    "15: Ja, du veit at mange @går jo @på, @på truger opp.\n" +
    "16: Ja.\n" +
    "15: Og enkelte dei tek jo skitrekket opp, og så @går dei vidare @ifrå toppen av skitrekket og fram\n" +
    "§fjellet.\n" +
    "16: Ja.\n" +
    "15: Det er ikkje så langt veit du.\n" +
    "16: Nei, det er no …\n" +
    "15: Og så ned att der og over Gunvordalen og …\n" +
    "I: Ja.\n" +
    "15: … slike ting.\n" +
    "16: Ja. Ja @då.\n" +
    "15: Så dei er spreke, veit du. Men slik som framme i Togga og dette der, der @må dei jo @gå opp.\n" +
    "I: Ja.\n" +
    "15: Der er inga hjelp opp.\n" +
    "16: Nei, no er dei @på §alle §fjell.\n" +
    "15: Ja.\n" +
    "16: No er dei @på Togga her, og dei er @på Frudalshesten.\n" +
    "15: Nakken.\n" +
    "16: Og dei er @på Nakken, og dei er @på Grøssete og opp att @på, heim att her.\n" +
    "15: Ja. Ja.\n" +
    "16: Og heim att i Tylderingen og …\n" +
    "I: Ja.\n" +
    "16: … kjem ned att her heime.\n" +
    "15: Ofte det at du ser det @står bilar heime i Kvamshagen, ukjende bilar med …\n" +
    "16: Ja.\n" +
    "15: … med skibøylar @på taket og slike ting og ...\n" +
    "\n" +
    "I: Mm.\n" +
    "16: Dei brukar i eigentleg §alle §¤fjella, og det tykkjer eg er …\n" +
    "15: Ja.\n" +
    "16: … positivt at dei kjem seg ut og @får *gjera. Det er flott.\n" +
    "I: Ja.\n" +
    "16: Det er, det er eksplosjon no siste @¤åra.\n" +
    "15: Ja.\n" +
    "16: Det er no fredeleg og godt her framme @då. Me har @fått Fjærlandsvegen og … , og det er fint,\n" +
    "fin veg ned og. Tjue minutt @ifrå tunet her og ned, og det er … Ja.\n" +
    "15: Du kan *seia det hadde vore surare hadde Fjærlandsvegen kome @på denne sida,\n" +
    "16: Ja. Ja.\n" +
    "15: det var jo snakk om det òg, veit du.\n" +
    "16: Ja @då. Og så §Anestølen var det snakk om.\n" +
    "I: Ja.\n" +
    "15: Ja.\n" +
    "16: At det skulle *koma tvert gjennom der òg så. Nei @då.\n" +
    "15: Det hadde ikkje vore så kjekt hatt den trafikken rett forbi ~husdørene i §alle fall.\n" +
    "16: Det er både positivt og negativt å bu her framme.\n" +
    "15: Ja.\n" +
    "I: Mm.\n" +
    "16: Eg trur det er slik §alle ~stadene.\n" +
    "15: Ja. <humrar> Det er ein fare for det.\n" +
    "16: men me har det godt her framme, stilt og roleg og …\n" +
    "15: Ja.\n" +
    "I: Ja, hadde du villa budd ein annan plass?\n" +
    "15: Tja, eg veit ikkje. Vanskeleg å *svara @på.\n" +
    "I: Ja.",

text_17og18 : "18: Ja.\n" +
    "17: Ja. Hugsar godt @når me gjekk for presten,\n" +
    "I: Ja.\n" +
    "17: for @då gjekk me i lag, og så rodde me over fjorden. Og eg @måtte i fjøsen før eg reiste\n" +
    "18: Jaha.\n" +
    "17: og @endå hadde eg tid å *koma @oppå ein haug som me §kalla ~Haugane.\n" +
    "18: Ja.\n" +
    "17: Og så stod eg der og venta til ho\n" +
    "18: ~Baringane kom.\n" +
    "17: til ~baringane kom.\n" +
    "18: Ja.\n" +
    "17: Ja, og så rodde me over fjorden.\n" +
    "18: Og så @når me for bort att,\n" +
    "17: Ja.\n" +
    "18: @då var me alltid inne @på kjøkenet til mor di og @åt.\n" +
    "17: Ja, ho hadde vel eitkvart lite.\n" +
    "18: Og same kva ho hadde så fekk me.\n" +
    "17: Ja @då.\n" +
    "18: Og dette, dette visste me om.\n" +
    "17: Dette var vanleg den gongen.\n" +
    "18: Ja, og ho hadde §fulle huset med folk òg ho, veit du.\n" +
    "17: Ja. Folk kom innom og …\n" +
    "18: Ja, alle @åt.\n" +
    "17: Ja, alltid hadde ein noko å gi.\n" +
    "18: Ja, ja @då.\n" +
    "17: Så det var så koseleg. Så det hugsar me godt.\n" +
    "18: Kor travelt dei hadde det @på ~gardane i dei dagar,\n" +
    "17: Ja.\n" +
    "18: så hadde dei tid å *snakka med folk,\n" +
    "\n" +
    "17: Ja.\n" +
    "18: sette dei seg ned, og me sat @på kjøkenet, og ho stelte like godt og prata.\n" +
    "17: Hadde …\n" +
    "18: Hadde ho #gjerne @gått i fjøsen om kalva ei kyr om ¤natta,\n" +
    "17: Ja.\n" +
    "18: men ho kunne *sitja og *prata like sprek.\n" +
    "17: Ja, kunne ikkje *merka det at ho hadde noko å *gjera\n" +
    "18: Nei @då.\n" +
    "17: Nei, alltid tid til folk som kom innom.\n" +
    "18: Jaha, ja.\n" +
    "17: Så det var artig. Ja.\n" +
    "18: Mor di veit du. Ho var @frå Barsnes ho veit du.\n" +
    "17: Ja @då. Ho var @frå Barsnes.\n" +
    "18: Ja.\n" +
    "17: Så @då veit du det er noko anna i dag.\n" +
    "18: Ja.\n" +
    "17: I dag ser me nesten ikkje folk.\n" +
    "18: Men så veit det at @når me trefte presten og, så var me raud like oppi @hårgarden\n" +
    "17: Ja.\n" +
    "18: og neia djupt.\n" +
    "17: Og beskjedne.\n" +
    "18: Ja. <latter>\n" +
    "17: Ja, det er sant at.\n" +
    "18: Ja. Og det var me i skulen òg det, veit du.\n" +
    "17: Å, ja @då. Me hadde respekt for læraren.\n" +
    "18: Å, og så §alle lekser @måtte me *kunna,\n" +
    "17: Ja @då.\n" +
    "18: og me var sikker om at me\n" +
    "17: Å.\n" +
    "18: blei høyrde.\n" +
    "17: Ja, elles var me nervøse.\n" +
    "\n" +
    "18: Ja, det var til å @stå i ¤døra\n" +
    "17: Ja.\n" +
    "18: og *lesa salmevers og bibelsoge til ho mor før me gjekk.\n" +
    "17: Ja, ja.\n" +
    "18: Og så sa ho: «No må de vera snille og lydige no då»\n" +
    "17: Ja. Ja.\n" +
    "18: Det var ikkje …\n" +
    "17: Ja, slik var det den gongen.\n" +
    "18: Det var ikkje slik at dei sa det at ~lærarane dei har betaling for å ha dykk.\n" +
    "17: Ja, nettopp.\n" +
    "18: Dei kan visst *læra dykk.\n" +
    "17: Ja, det er i dag.\n" +
    "18: Nei, me skulle *lyda me og *høyra etter kva han sa.\n" +
    "17: Ja.\n" +
    "18: Og me ville no ikkje ha fornærma han for alt i ¤verda heller.\n" +
    "17: Å, kan *skjønna me ville ikkje det.\n" +
    "18: Nei.\n" +
    "17: Nei, nei @då.\n" +
    "18: Nei, om morgonen @når me begynte veit du, så var det song først.\n" +
    "17: Ja, song me alltid ei salme. Ja.\n" +
    "18: Ja, og første timen @då var det, me gjekk annakvar dag me, ja de òg det.\n" +
    "17: Ja, ja @då.\n" +
    "18: Og @då var det bibelsoge ein dag\n" +
    "17: Ja.\n" +
    "18: og forklaring hin dagen.\n" +
    "17: Ja.\n" +
    "18: Og det me hadde @fått i lekse, det måtte me *kunna.\n" +
    "17: Å.\n" +
    "18: Me visste me blei høyrde.\n" +
    "17: Kunne det på ~fingrane.\n" +
    "18: Og det er sørgjeleg i dag …\n" +
    "\n" +
    "17: Ja, ikkje syng dei\n" +
    "18: … at ikkje dei spør ~ungane. Nei, me hadde songtime for veka.\n" +
    "17: Ja.\n" +
    "18: Kunne @då så mykje salmevers.\n" +
    "17: Ja.\n" +
    "18: Og desse gamle veit du «Millom bakkar og berg» og\n" +
    "17: Å.\n" +
    "18: Og «At far min kunde gjera».\n" +
    "17: Å.\n" +
    "18: «Første song» og …\n" +
    "17: Mm.\n" +
    "18: … alt dette der, det, det kunne me.\n" +
    "17: Ja.\n" +
    "18: Og @når læraren kom inn om morgonen, så var det til å *reisa seg og *seia god morgon …\n" +
    "17: Ja.\n" +
    "18: … og nei, veit du, litt flaue.\n" +
    "17: Ja.\n" +
    "18: Me var samla skule me, veit du.\n" +
    "17: Ja, der inne @på Barsnes ja, mm.\n" +
    "18: Me var tolv elevar det\n" +
    "17: Ja.\n" +
    "18: @året som eg begynte.\n" +
    "17: Ja, @då var det noko anna her i …\n" +
    "18: Og @då var me @frå sjuande til første. Me var tre i førsteklasse,\n" +
    "17: Ja.\n" +
    "18: og så var dei to i sjuandeklassen.\n" +
    "17: Ja.\n" +
    "18: men §alle i saman.\n" +
    "17: Ja.",

text_19og20: "20: Det var #gjerne nitten @frå\n" +
    "19: Ja.\n" +
    "20: @frå første @då så … Nei, me var niogtjue me.\n" +
    "19: Ja, me var @åtteogtjue; @åtte jenter og tjue gutar. Ja, det me hadde valdsamt grei lærar\n" +
    "@då så me fekk jo … Me skreiv bok. Først begynte, nei først hadde me avis, skreiv avis og\n" +
    "selde. Eg veit ikkje kva me selde det til eg. @Når me hadde prøvd det, så laga me bok i\n" +
    "klassen som me selde. Den har dei no hatt på §bokhandelen til og med @etterpå. <latter> Så\n" +
    "laga me akvarium, hugsar eg. Det var ~jentene som gjorde, og ~gutane dei stoppa ut fuglar.\n" +
    "Det var @på #barneskulen @då. Me, me følgde ikkje så mykje akkurat ¤faga @då. Me fekk\n" +
    "*gjera mykje rundt det.\n" +
    "I: Mm.\n" +
    "19: Det var ikkje slik akkurat norsk, sant. Me fekk *skriva ei bok i staden for. Det var jo\n" +
    "mykje kjekkare. Stort sett det eg hugsar.\n" +
    "I: Kva de gjorde på i friminutta for eksempel?\n" +
    "19: Hoppa strikk. <latter>\n" +
    "20: Hoppa strikk, ja. Det trur eg me gjorde @på heile ¤tida.\n" +
    "19: Ja.\n" +
    "20: Spelte §fotball òg @då.\n" +
    "19: Det gjorde ikkje eg så mykje.\n" +
    "20: Det var eg ein del med @på, spelte mykje §fotball @på ¤fritida òg @då så. Det var helst\n" +
    "til at eg vart med ~gutane og spelte §fotball òg @då.\n" +
    "I: Ja, kva gjorde gutane på i friminutta då?\n" +
    "19: Nei, det veit eg ikkje. Dei spelte sikkert §fotball. <latter>\n" +
    "20: <latter> Ja, det var mest det, trur eg.\n" +
    "I: Var de på skulen kvar dag, i Trudvang?\n" +
    "19: Nei, me hadde fri. Men det bytte fridag, hugsar eg. Det var ein dag i veka, var ikkje det\n" +
    "det [då]?\n" +
    "20: Jau, det hugsar eg ikkje.\n" +
    "19: Jau, det hugsar eg, for mamma ho har, jobba fire … eller ho jobba dagar i veka, så hadde\n" +
    "ho … bytte ho @friår kvart @år etter kva slags dag eg hadde fri. Hugsar det var alltid slikt\n" +
    "bal, for det var litt vanskeleg å @få *byta @då, men ho fekk no det til slutt. Så men\n" +
    "sjetteklassen hadde me vel ikkje fri, trur eg.\n" +
    "20: Ikkje femte heller sikkert, hæ?\n" +
    "19: Ja, eg veit ikkje eg. Nei, me hadde #gjerne ikkje det.\n" +
    "20: Me begynte no engelsk i fjerdeklassen @då.\n" +
    "19: Meir enn nok med engelsk.\n" +
    "I: Sånn som om morgonane når de, når de, før de begynte timen liksom.\n" +
    "19: Mm.\n" +
    "I: Hadde de noko sånn ritual før de kom i gang?\n" +
    "19: Song.\n" +
    "\n" +
    "20: Me hadde oppsamling ute i §alle fall. Det var sikkert dei tre første @¤åra. Det var slik at\n" +
    "me @måtte §*stilla @på rekke ute @då, så skulle me @gå i lag inn.\n" +
    "I: Ja.\n" +
    "20: Så trur eg me song eitt eller anna òg.\n" +
    "19: Eitkvart at me samlast ute og så gjekk inn, eller.\n" +
    "I: Hadde de noko spesielt de gjorde på 17. mai?\n" +
    "19: Eg var med i speidaren. Tidleg opp.\n" +
    "I: Ja.\n" +
    "20: Eg hugsar ¤tida, eller ¤stunda før 17. @då så var, dreiv me @på og laga slik fana @då, …\n" +
    "I: Mm.\n" +
    "19: Mm.\n" +
    "20: … i klassen. Så var det alltid sånn halvvegs krangel om kven som skulle *bera den @då\n" +
    "@på 17. mai. Så det var om og *gjera å *laga til slike duskar, <humrar> sånn at nokon fekk\n" +
    "noko å *bera @på dei og … <latter>\n" +
    "19: Fekk *bera dei òg, ja.\n" +
    "20: Var no med og gjekk i dette #barnetoget @då og så …\n" +
    "19: Nei, det var grusamt å @stå opp til desse ¤speidartoga. Der òg har me bilete heime @då,\n" +
    "og eg ser ikkje augo. <latter>\n" +
    "20: <humrar>\n" +
    "19: @Står @på tunet og er heilt … Nei, så var det masse is og pølse med brød.\n" +
    "20: Mm.\n" +
    "19: Einaste gongen eg fekk det eg spurde om. <latter>\n" +
    "20: <humrar>\n" +
    "I: Når de var, kom heim att om dagane då. Kva gjorde de på då?\n" +
    "19: @Då @åt eg middag, og så @måtte eg *gjera lekser og så *huka einkvan så me kunne …\n" +
    "<humrar> Nei, kva me gjorde @på @då @då?\n" +
    "20: Hadde alltid eitt eller anna me fann @på. Og så om vinteren @då, så dreiv me @på og\n" +
    "bygde desse her ~snøborgene @nedpå §vollen som me §kalla det. <latter>\n" +
    "19: <latter>\n" +
    "20: Så @då, @då gjekk jo gata forbi der @då. Hadde me skikkeleg sånn borg, så dreiv me\n" +
    "@på og så kasta §snøballar @då @på ~bilane.\n" +
    "19: Ja.\n" +
    "20: Fordi at <humrar> det var mange som var rasande @på oss @då @då, kan du *seia.\n" +
    "19: Ja, desse galne ~fjøreungane. <humrar>\n" +
    "20: Ja.\n" +
    "…………………………………………….\n" +
    "19: #Barnetimen for dei minste. Den høyrde eg @på kvar morgon og … Så var det @på\n" +
    "~laurdagane, @då var det sånn her songen som kom først. <humrar>\n" +
    "20: <humrar>\n" +
    "19: Høyrespelet og …\n" +
    "I: Ja.\n" +
    "19: Det var … @Då var det laurdag. Så seinare så vart det no «Norsktoppen» og «Ti i\n" +
    "skuddet» og … <humrar> Sat og høyrde og tok opp og …\n" +
    "\n" +
    "20: Kan ikkje *hugsa noko slik @frå @når eg var heilt liten. Det er som du seier, var «Ti i\n" +
    "skuddet», spesielt @då. Det @måtte alltid ha med seg. Det er ikkje noko slik før … Trur ikkje\n" +
    "eg høyrde noko særleg @på radio eg før einaste @når eg var mindre, som han sa at …\n" +
    "19: Jau, det @måtte eg ha med meg.",

text_19pog60p: "19: ¤Brua, veit du.\n" +
    "60: Ja.\n" +
    "19: @Då veit ikkje eg å ha tid å *vera på jobb @når dei … ¤brua kom. Sat i glaset og prøvde\n" +
    "å @få ~ungane med meg @då. Var fasinerande å @sjå @på.\n" +
    "I: Idet ho kom inn fjorden?\n" +
    "19: Ja.\n" +
    "I: Følgde de med då?\n" +
    "19: @Då følgde me …\n" +
    "60: Svære @båtar. Det var vel eigentleg me det var, eller oss det var spennande for. ~Ungane\n" +
    "skjønte ikkje heilt.\n" +
    "19: Nei.\n" +
    "I: For det tok vel ei stund det? Det var vel sånn langsam …?\n" +
    "19: Ja, dei var no komne inn fjorden før @då. Så dei køyrde @ifrå kaien, veit eg?\n" +
    "60: Ja, så kom det vel ein og ein del, gjorde ikkje det @då?\n" +
    "19: Jau.\n" +
    "I: Men har det ikkje … har det vore mykje bråk då?\n" +
    "60: Me har høyrt det av og til @når eg har vore oppe i skogen, opp mot ~Røvhaugane. Der er\n" +
    "det høyrt litt sånn dunking og klakking. Men var ikkje …\n" +
    "19: Men dei var no og tok sånne lydtestar òg før dei begynte, for å *finna ut om me tålte\n" +
    "støyen liksom.\n" +
    "I: Hen er det de går når de skal ut med ungane då sånn i naturen som du seier? Kva de … ?\n" +
    "60: Me har ein skog som er rett over #barnehagen. Det blir opp mot ~Røvhaugane som det\n" +
    "heiter.\n" +
    "………………………………..\n" +
    "60: Men syttande mai-toget @då … <humrar>\n" +
    "I: Ja.\n" +
    "60: … er jo faktisk ganske masse studentar.\n" +
    "19: Ja.\n" +
    "60: Det hadde ikkje vore så langt tog viss det ikkje var for ~studentane, tenkjer eg.\n" +
    "19: Nei.\n" +
    "60: For dei lagar jo til litt arrangement i forhold til denne studentveka og … Ja, slik sett så …\n" +
    "I: Ja, er det noko som bygdefolket er med på òg det? Den veka?\n" +
    "60: <nøler> Eg trur det er ein del som, ein del lokale som heng …\n" +
    "I: Mm.\n" +
    "19: Ja.\n" +
    "60: … med i studentmiljøet @når dei studerer. Det er #gjerne ikkje §alle som er like flinke til\n" +
    "det, men. Ho som leiar denne studentveka, ho er lokal, trur eg.\n" +
    "……………………………..\n" +
    "60: §Fotballen har vel stor plass, eigentleg?\n" +
    "19: Ja. Jau, §fotballen har mykje å *seia, og Lerum har no òg mykje å *seia.\n" +
    "60: Ja.\n" +
    "\n" +
    "19: For dei òg ser du no utad.\n" +
    "60: Men eg trur det at det trekkjer ganske mange.\n" +
    "19: Ja.\n" +
    "60: Du har denne fjellsportfestivalen som dei har @fått opp no. Og det kjem jo folk over heile\n" +
    "landet <uforståeleg> som vil hit @på grunn av, ja §¤fjella og snøen og litt òg det med\n" +
    "studentar, trur eg at det er mange som søkjer seg hit @på grunn av naturen og dei\n" +
    "~mogelegheitene det gir @då.\n" +
    "I: Mm. Men er det noko viktig at me har noko godt fotballag her då?\n" +
    "19: Ja, det dreg no folk til ¤bygda det òg, og næringslivet gjer vel godt av det, og ~pengane\n" +
    "kjem inn som dei kan …\n" +
    "I: Veksa endå meir?\n" +
    "19: Ja.\n" +
    "60: <humrar>\n" +
    "I: Men er ikkje me store nok då?\n" +
    "19: Jau, eg tenkjer ikkje at ¤bygda skal bli større, men at me kan no *overleva her og\n" +
    "arbeidsplassar og …\n" +
    "…………………………………….\n" +
    "I: Ja, friminutta, korleis var dei då?\n" +
    "60: Nei, altså, det var ikkje noko spesielt med dei heller, var jo dette at me #gjerne gøymde\n" +
    "oss inne viss me @måtte ut @då, eller var fleire jenter inn @på same doen og … Det var\n" +
    "ikkje noko spesielt med ¤friminutta, men. Var det kaldt, så ville ikkje du ut. @Då stod du\n" +
    "helst att inne, og så @måtte du #gjerne ut @då.\n" +
    "…………………………………….\n" +
    "19: Eg håpar ikkje det skjer @då. Fjøra har sin sjarm og …\n" +
    "60: Absolutt. Det er jo òg litt denne ¤blokka som dei snakkar om @nedpå Lerum …\n" +
    "19: Ja.\n" +
    "60: … eller @på kaien der.\n" +
    "19: Mm.\n" +
    "60: @Når dei legg så mykje pengar i Fjordstien fordi det skal *vera fint og koseleg å @gå\n" +
    "blant gamle trehus og naust @på kaien. Og så skal dei ha opp den eine ¤høgblokka etter den\n" +
    "andre, heilt nedst. Eg trur det var snakk om var det ni etasjar eller noko @på den?\n" +
    "19: Ja.\n" +
    "60: @På Lerum. Sjøkanten er det sju, @åtte etasjar med … Ja, det er vel #gjerne ni der òg\n" +
    "@då, men at det blir @åtte, eller at det blir med ~butikkane.\n" +
    "19: Butikken, ja. Ja, ~butikkane.\n" +
    "60: Ja.\n" +
    "19: Ja.\n" +
    "………………………………\n" +
    "I: Du har noko nede i hjørnet der …\n" +
    "19: MOT eller …?\n" +
    "I: Ja.\n" +
    "19: §Fotball eller …? Ja, eg hugsar ~ungane hadde, men det var sånn mot til å *gleda andre-\n" +
    "dagen.\n" +
    "\n" +
    "60: Hugsar me hadde sånn mot til å *seia nei og … eller *seia @ifrå, eller kva det var. @Då\n" +
    "drog dei fram dette her, viss du sat @på med nokon som var eldre som køyrde bil @då. Så\n" +
    "skulle du *seia ifrå viss du tykte det gjekk for fort, eller. Eller dette med bilbelte.\n" +
    "19: Ja.\n" +
    "60: Men mot til å *gleda har jo vore innom @på jobb òg det.\n" +
    "19: Og ~ungane dei hadde opplegg at dei … ja, skulle *planleggja. Dei skulle *gleda nokon.\n" +
    "Og det var ein fast dag dei skulle ha det og @måtte *planleggja kven dei ville *gleda, og\n" +
    "korleis dei ville *gleda, og kvifor dei ville *gjera det. Og @då var det #gjerne om å *gjera at\n" +
    "dei sjølve valde ut ein elev som dei følte #gjerne trong det, eller dei ikkje hadde så mykje med\n" +
    "å *gjera. Einkvan i klassen eller @på skulen eller … Men den andre skulle ikkje *vita om det,\n" +
    "så det var sånn …\n" +
    "I: Hemmeleg venn?\n" +
    "19: Men du skulle @då inn og *gjera noko bra for den @då.\n" +
    "I: Mm.\n" +
    "19: Så var det no evaluering @etterpå kva dei hadde gjort det leis?\n" +
    "60: Ja.",

text_21og22: "22: Først så veit du vart jo skulen utbygd eller ombygd.\n" +
    "21: Ja. Og det, og det var @når eg gjekk i sjuandeklassen, trur eg det var.\n" +
    "22: Ja.\n" +
    "21: Første halvdelen av, @på hausten @då. @Då begynte dei og, og grov ut og bygde denne\n" +
    "kvite bygningen som dei har no.\n" +
    "I: Mm.\n" +
    "21: Så siste halve @året i sjuandeklassen @då var eg i andreetasjen i nye bygget. Og det blei\n" +
    "@då i @åtteogfemti det veit eg, tenkjer eg at den var ny.\n" +
    "22: Elles så var det til å @slå §ball.\n" +
    "I: Mm.\n" +
    "22: Og så …\n" +
    "21: Ja. Det var det mykje av, tykkjer eg.\n" +
    "22: Ja.\n" +
    "21: <humrar> Eg tykkjer det er det eg minnest.\n" +
    "22: Å, å *spenna §fotball, og så heldt me @på med noko som heitte bond … @slå @på …\n" +
    "21: @Slå @på ringen.\n" +
    "22: @Slå @på ringen var det det?\n" +
    "21: Ja.\n" +
    "22: Og så var det bonde?\n" +
    "21: Ja.\n" +
    "22: Og @då sprang me rundt og gøymde oss, og så @når det var tomt, der nede for kan du\n" +
    "*seia, der som bonden skulle @stå, @då smatt me oss ned, og @då slo me i, i veggen, og så\n" +
    "ropte me «bonde».\n" +
    "I: Ja.\n" +
    "22: Og så sprang me og gøymde oss att.\n" +
    "21: Eg kan ikkje *hugsa så godt dette der [no].\n" +
    "22: Nei, og så hadde me noko som me §kalla for tresko.\n" +
    "I: Ja.\n" +
    "22: Og det siglde me @på.\n" +
    "I: Å ja.\n" +
    "21: Det, brukte ikkje me altså.\n" +
    "22: Gjorde ikkje de?\n" +
    "21: Nei.\n" +
    "22: Og det hugsar eg, det hadde me @når me skulle i skulen òg.\n" +
    "21: Ja.\n" +
    "22: Og då i ¤friminutta, @då sigla me @på tresko.\n" +
    "21: Ja.\n" +
    "22: Og @når me kom inn att i skulestova, så var det til å *setja #treskoa @ifrå seg …\n" +
    "21: Ja.\n" +
    "22: … og så var det til å ha tjukke, gode labbar @på seg.\n" +
    "21: Ja, men datt ikkje de fælt @då @når de gjekk …\n" +
    "\n" +
    "22: Nei. Me hadde trening me å *bruka tresko.\n" +
    "21: Ja, men @måtte, @måtte no bli grueleg @hålt. <humrar>\n" +
    "22: <humrar> Ja, @hålt var det. Me var ofte blaute @når me sette oss ned i, til ~skuletimane.\n" +
    "21: Ja.\n" +
    "………………………………………\n" +
    "22: Men eg gjekk @oppå Ulvehaugen, det er jo oppi her.\n" +
    "21: Ja.\n" +
    "22: Og der var me oppe og kjøpte egg.\n" +
    "21: Ja.\n" +
    "22: For dei ~bøndene selde egg …\n" +
    "21: Ja.\n" +
    "22: … privat, sant?\n" +
    "21: Ja.\n" +
    "22: Og @då var eg ofte oppe og, og kjøpte egg @på garden der oppe.\n" +
    "21: Ja.\n" +
    "I: Ja. Har de vore på noko, nokon tur de hugsar?\n" +
    "22: Jau du, me hadde skuletur i tredje klasse.\n" +
    "I: Ja.\n" +
    "22: Og @då var det bestilt ein buss.\n" +
    "21: Var om @våren det @då sikkert før skulen slutta.\n" +
    "22: Ja, før, ja.\n" +
    "I: Mm.\n" +
    "21: <utydeleg> me hadde tur.\n" +
    "22: Og det var i tredje klasse, ja. Og det var om … @Då hugsar eg at @då vart det teke bilete.\n" +
    "Og @då var me framme @på Selseng.\n" +
    "I: Mm.\n" +
    "22: Og @då sat me med ein gjeng utav ~jentene med ein kvar sin §killing i fanget, for det\n" +
    "tykte me var så ….\n" +
    "21: Å ja.\n" +
    "22: … svære greier, veit du.\n" +
    "I: Mm.\n" +
    "22: Ja.\n" +
    "I: Var de på noko …?\n" +
    "21: Me, me hadde no skuleturar til, var no museet @då borte.\n" +
    "22: Ja, det var me òg ja. Men det var …\n" +
    "21: Og så, og så fekk i grunnen så stygt i det, for me likte ikkje ¤lukta. <latter>\n" +
    "22: Nei, akkurat slik var det med meg òg.\n" +
    "21: Ja.\n" +
    "22: Var så stygg lukt. Uff.\n" +
    "21: Var så stygg lukt og det, liksom det mørkt tykte me, og du veit me var no #gjerne litt for\n" +
    "unge til å *vera altfor interesserte i dette der …\n" +
    "22: Ja, me var ikkje interesserte i nokon ting …\n" +
    "21: … ~museumsgreiene. Nei.\n" +
    "\n" +
    "22: … av det som var der borte.\n" +
    "21: Nei.\n" +
    "22: Det var berre ¤lukta me gjekk og kjende @på.\n" +
    "21: Så me hadde no litt forskjellige slike skuleturar @då.\n" +
    "………………………………………………..\n" +
    "I: Er det noka gruppa dei bør ta meir omsyn til her, ja, politikarane bør ta meir omsyn til?\n" +
    "21: Nei, det er no som regel det at dei trekkjer fram heile ¤tida at, at dei skal <stammar>\n" +
    "*hjelpa betre dei eldre @då og *leggja betre til rette for dei og betre plass. Men i og for seg så\n" +
    "er det vel ikkje så verst heller her i Sogndal, @når … Eg las akkurat eit stykke no i Bergens\n" +
    "Tidende oppe eg sat oppe @hjå augnelegen at, …\n" +
    "22: Ja.\n" +
    "21: … at i Bergen der skulle det *vera eit slags møte no i dag, og at dei var tre @på rommet,\n" +
    "og at no var ein død, så no var dei berre to.\n" +
    "I: Mm.\n" +
    "21: Så vel eigentleg så har, så er det vel mange plassar det er verre enn her, sjølv om at det er\n" +
    "for lite her òg, for dei som #gjerne treng fort plass og ikkje, …\n" +
    "22: Ja, ja.\n" +
    "21: … men dei strekkjer seg no i grunnen langt her nede @på sjukeheimen, vil eg *seia òg.\n" +
    "22: Eg tykkjer dei har valdsamt god pleie nede @på alders- …\n" +
    "21: Ja.\n" +
    "22: … og sjukeheimen her i Sogndal.\n" +
    "21: Men korleis det ligg rundt omkring i ¤bygda, om det er mange som ikkje har plass, det er\n" +
    "eg ikkje så sikker @på.\n" +
    "22: Eg …",

text_21pog26p: "I: Ja, kva hugsar du best ifrå skulen då? Korleis var skuledagen?\n" +
    "26: Nei @då, du, du hugsar jo det at me .... Kom i §alle fall tidsnok den gongen til timar og\n" +
    "@når skuledagen begynte. Det veit eg i dag at det er vel litt meir ymse. <latter> I dag ser eg,\n" +
    "men, men … Og så hugsar eg det at me song mykje.\n" +
    "I: Å, ja.\n" +
    "26: Ja.\n" +
    "I: Men kva er det de vil dra fram viss de skal liksom seia noko typisk for Sogndal?\n" +
    "21: Nei, me @går oppi det heile ¤tida, tenkjer ikkje etter det.\n" +
    "26: Å, det er klart at §fotball har jo spelt ei stor rolle der, …\n" +
    "21: Å, ja.\n" +
    "26: … ikkje sant? Og sett Sogndal @på, @på kartet.\n" +
    "21: Ja.\n" +
    "26: <nøler> Og så føler eg òg det at Sogndal som studentplass har …\n" +
    "I: Mm.\n" +
    "26:… @fått ein, ein stor (anerkjennelse) …\n" +
    "21: Ja, ja @då.\n" +
    "26: … rundt omkring. Og det er klart det er fleire ikkje sant …\n" +
    "21: Ja.\n" +
    "26: … som har @gått på skule og studert i Sogndal. Og det begynner å bli mange, veit du,\n" +
    "som har …\n" +
    "21: Å, ja.\n" +
    "26: … snakkar med folk som gjekk @på lærarskulen, ikkje sant, @på, @på, @på syttitalet og\n" +
    "...\n" +
    "I: Mm.\n" +
    "26: Og det er klart at, at til fleire som har vore innom ¤bygda og studert, til fleire er det som\n" +
    "@på ein @måte kan *marknadsføra ¤bygda som studentplass.\n" +
    "I: Mm.\n" +
    "21: Ja, ja.\n" +
    "I: Kva er det dei set så pris på her då med å koma hit?\n" +
    "21: Nei, det har eg ofte tenkt @på òg kva dei … men det er #gjerne (størrelsen) òg?\n" +
    "26: Ja, at det ikkje blir for stort.\n" +
    "21: Ja.\n" +
    "26: Og så er det nok noko med, sant … Det er jo mange, har eg inntrykk av, inne @ifrå\n" +
    "bergenskanten, sant …\n" +
    "21: Ja. Mm.\n" +
    "26: … som studerer i Sogndal. Og du veit at i dag, så er det reise-avstandsmessig, veit du, så\n" +
    "er det snautt å *koma seg …\n" +
    "21: Ja.\n" +
    "26: … til Bergen att, ikkje sant?\n" +
    "21: Ja.\n" +
    "\n" +
    "26: Dei, dei kan *setja seg @på @båten, eller det er jo ikkje meir enn fire timar å *køyra no,\n" +
    "veit du, sant og ...\n" +
    "21: Nei @då.\n" +
    "26: Og, og reiser du @på Haukåsen, så flyg du til Oslo @på ein time, sant?\n" +
    "21: Mm.\n" +
    "26: Slik at det er nok noko med …\n" +
    "21: Ja.\n" +
    "26: … (beliggenheiten) og, …\n" +
    "21: Trur det er skikkeleg bra at me har flyplassen òg så nær.\n" +
    "26: Ja.\n" +
    "21: For @då blir det lettare å *koma til.\n" +
    "26: Ja.\n" +
    "I: Her er noko nytt som …\n" +
    "21: Ja.\n" +
    "I: … snart kjem.\n" +
    "21: Dette, dette skal bli bra at det blir ny bru.\n" +
    "26: Ho er fin òg.\n" +
    "21: Ja, for eg begynte å bli litt … eg tykkjer ho er litt ekkel òg denne gamla ¤brua no.\n" +
    "26: Ja, ja, ja, ja.\n" +
    "21: Ho begynner å bli utsliten, tykkjer eg. <humrar>\n" +
    "26: Ja. Ja.\n" +
    "I: Ja.\n" +
    "21: At ho held. <humrar>\n" +
    "I: <humrar>\n" +
    "21: Dei lyt vel *vøla ho litt òg?\n" +
    "26: Ja. Nei, veit ho har vore for trong. Veit det har vore stor trafikk over der, veit du.\n" +
    "21: Ja.\n" +
    "26: Både med bilar, sant …\n" +
    "21: Og så ikkje snakk om å @gå.\n" +
    "26: … og så med folk, veit du. Folk @bortpå #Kjørnes, ikkje sant?\n" +
    "21: Mm.\n" +
    "26: Og med #barnevogner …\n" +
    "21: Ja.\n" +
    "26: … og med, med syklar og … Så det @måtte jo *koma.\n" +
    "21: Mm.\n" +
    "I: Ja, men det er no livsfarleg å gå over denne som er der …\n" +
    "21: Ja.\n" +
    "I: … no (i hvert fall), på fortauet.\n" +
    "21: Ja. Denne hadde lote kome uansett.\n" +
    "26: Ja.\n" +
    "I: Mm.\n" +
    "21: Denne ¤brua.\n" +
    "26: Ja.\n" +
    "\n" +
    "21: Dei kunne ikkje *risikera det at ein … Sogndal @på ein så stor plass at dei ikkje kunne\n" +
    "@gå, *koma trygt over. Det var no ikkje snakk om nede.\n" +
    "……………………………………………..\n" +
    "I: Dei har jo flytt ein del av trinna opp på Kvåle?\n" +
    "21: Ja.\n" +
    "I: Ja.\n" +
    "21: Det blir for trong her nede, veit du.\n" +
    "26: Ja, ja dei landa no @på, @på dette her med, med den nye #barnehagen dei har … Det var\n" +
    "så mykje snakk om.\n" +
    "21: Ja, har dei landa @på den?\n" +
    "26: Nei, eg trur ikkje det.\n" +
    "21: Nei.\n" +
    "26: Eg har ikkje høyrt att noko meir.\n" +
    "21: Det var snakk om Rutlin, veit du.\n" +
    "26: Ja.\n" +
    "I: Ja, kva meiner de om det?\n" +
    "21: Nei, eg meiner dei kan *byggja @på Bjelde, så @når dei <stammar>, der blir dei tilbode\n" +
    "ein plass.\n" +
    "26: Ja, Ragnvald har sagt at dei kan *byggja @hjå han.\n" +
    "21: Ja. Og der kan dei *byggja ut, og §alle #barnehagar behøver ikkje *vera her nede.\n" +
    "26: Nei.\n" +
    "21: Og @då er det snaupen veg til …\n" +
    "26: Ja.\n" +
    "21: … @frå ¤utbygginga der oppe @ifrå Gurvin …\n" +
    "26: Ja, ja, ja.\n" +
    "21: @Då er ikkje det så langt for ¤foreldra å *køyra ~ungane der og …\n" +
    "26: Nei. Og du veit at …\n" +
    "21: Der er det god plass og kjem ut @ifrå …\n" +
    "26: Ja, ja, og dette blir jo…\n" +
    "21: … gryta her.\n" +
    "26: Dette vil jo bli sentrum, meir og meir sentrumsnært etter kvart det.\n" +
    "21: Ja, ja, ja.\n" +
    "26: Du ser jo …\n" +
    "21: Ja.\n" +
    "26: … (bebyggelsen) strekkjer seg oppover.\n" +
    "21: Ja.\n" +
    "26: Sant? Du har jo @på @Kvåle no, ikkje sant, og Gurvin er det jo … <humrar>\n" +
    "21: Ja.\n" +
    "26: Det er jo så mykje hus, veit du.\n" +
    "21: Ja.\n" +
    "I: Ja.\n" +
    "26: Oppi der at …\n" +
    "21: Så dei hadde sikkert fylt ein #barnehage berre @frå …\n" +
    "\n" +
    "26: Ja.\n" +
    "21: … Gurvin og der.\n" +
    "I: Ja.\n" +
    "26: Ja. Ja. Så det er jo noko med at @når ein grunneigar @då vil …\n" +
    "21: Ja.\n" +
    "26: … @på ein @måte gje @frå seg areal og, og …\n" +
    "21: Ja. Og så her blir det drive oppe. Og så … <uforståeleg>\n" +
    "26: Det blir drive med frukt, veit du og dyrka, …\n" +
    "21: Ja.\n" +
    "26: … ikkje sant? Å ta dyrka mark til\n" +
    "21: Nei.\n" +
    "26: … @når dei har andre alternativ …\n" +
    "21: Ja.\n" +
    "26: … Det er jo ikkje rett.\n" +
    "21: Nei @då.",

text_23og24: "I: Kva de gjorde på i friminutta?\n" +
    "M: @Då …\n" +
    "23: Æh. Me hoppa no strikk og …\n" +
    "24: §Balla.\n" +
    "23: §Balla, politi og tjuv og …\n" +
    "24: Bytte glansbilete. Det gjekk no valdsamt i periodar dette der kva som var …\n" +
    "23: Ja.\n" +
    "24: … kva som var, sant. Ei stund så var det, dreiv §alle med femøringar.\n" +
    "23: <humrar> Ja.\n" +
    "24: Ei stund dreiv alle med §balling. Og så ei stund …\n" +
    "23: Ja.\n" +
    "24: … hoppa du strikk.\n" +
    "23: Ja.\n" +
    "24: Så bytte du glansbilete.\n" +
    "23: Ja.\n" +
    "24: Så var det dette her Titten @på hjørnet.\n" +
    "23: Ja. Det er [sant.]\n" +
    "24: Så gjekk valdsamt sånn i periodar.\n" +
    "23: Ja.\n" +
    "I: Mm.\n" +
    "23: Så var det litt hugsar eg @når det gjeld, <nøler> @når me vart litt fjerde, femte\n" +
    "sjetteklassen, kom ned @på dette her andre bygget @då nedfor hovudskulen som var @då.\n" +
    "24: Ja.\n" +
    "23: @Då, @då var det slik at me hadde kvar sine … Var det kvar si veke at eller eitkvart at\n" +
    "me hadde @slåballbana, som var på eine sida. Så den veka me hadde @§slåball, @då var den\n" +
    "veka.\n" +
    "24: Ja.\n" +
    "23: @Då heldt me @på med det i §alle friminutt.\n" +
    "24: Ja, så hugsar eg det at av og til så fekk me fri siste timen for å *driva med @§slåball.\n" +
    "I: Å.\n" +
    "24: Ein fredag siste timen og slikt. Og @då var me kjempefornøgde. [Då var me …]\n" +
    "I: Hadde de noko, noko framandspråk på skulen?\n" +
    "23: Ja, engelsk @frå fjerde klassen.\n" +
    "24: Ja, me hadde vel engelsk, hadde me det? Eg hugsar ikkje kva tid me begynte med den,\n" +
    "men me hadde no engelsk, ja. Var vel fjerdeklassen, ja.\n" +
    "I: Hugsar de nokon spesielle episodar frå de gjekk her nede? Kan no vera inne og alt i timane.\n" +
    "23: Hugsar me var valdsamt @bråkete klasse i førsteklasse. Me var tretti stykk i klassen.\n" +
    "<latter> Så …\n" +
    "24: Ja, altfor mange.\n" +
    "23: Var så §ville. Og at fleire elevar hoppa ut vindauget i klassen. Og <latter> så eg hugsar\n" +
    "det var berre kaos første halve @året, trur eg. Me … Det tenkte eg @på her ein dag óg altså\n" +
    "\n" +
    "slik som i forhold til no. No begynner dei altså @når dei er i #barnehagen, og sosialiserer seg\n" +
    "@når dei er tre @år.\n" +
    "24: Ja.\n" +
    "23: Begynner å @gå @på besøk til kvarandre @når dei er fire @år, gebursdagar tre, fire @år\n" +
    "og …\n" +
    "24: Ja.\n" +
    "23: ... blir litt vande.\n" +
    "24: Ja.\n" +
    "23: Før så var ikkje ein i lag før ein var sju @år.\n" +
    "24: Nei.\n" +
    "23: Det gjekk eit @halvår altså før ein ….\n" +
    "24: <latter>\n" +
    "23: Eg hugsar berre det var heilt kaos <humrar> eg første halve @året. Ja, dei hoppa ut og\n" +
    "sprang inn og ut, og det var ikkje, <humrar> det var ikkje styr.\n" +
    "24: @Hjå oss hugsar eg det at der var det motsett.\n" +
    "23: Å.\n" +
    "24: Me var ein valdsamt, valdsamt lydig klasse.\n" +
    "I: Jaha.\n" +
    "24: @Hjå oss der var det konkurranse. @Når me hadde prøvar og sånn, så gjaldt det å *vera\n" +
    "først framme og *henta nytt ark, for det var teikn @på at du hadde skrive masse.\n" +
    "23: <latter>\n" +
    "24: Og @då hugsar eg enkelte dei skreiv jo, sant? @Då sat me sånn …\n" +
    "23: <latter>\n" +
    "24: … skulte rundt og så skreiv fort. Og så mange skreiv med stor bokstav, veit du sant?\n" +
    "23: <latter>\n" +
    "I: Ja.\n" +
    "24: Og så var det fram, *henta ark, ned att.\n" +
    "23: <latter>\n" +
    "I: Hadde de noko spesielt morgonritual før de begynte timane og slik? Kan de hugsa det?\n" +
    "23: Ja, me dagen …\n" +
    "24: Stod og song.\n" +
    "23: Første timen meiner eg, @då song me ein song.\n" +
    "24: Ja, det gjorde me. Stod og song, trur eg.\n" +
    "23: Ja.\n" +
    "24: Meiner å *hugsa. Så fekk me *setja oss.\n" +
    "I: Å ja.\n" +
    "23: Og så lurer @på om me avslutta med song òg eg …\n" +
    "24: Og så gjekk …\n" +
    "23: … første @¤åra. Fager …\n" +
    "24: Ja, det hugsar ikkje eg. Men eg hugsar [i hvert fall] alltid me stod og song. Og så @då\n" +
    "@når me skulle inn, så stilte me opp to og to @på rekke.\n" +
    "I: Ja.\n" +
    "23 Ja.\n" +
    "\n" +
    "24: Og @då hugsar eg @hjå oss, @då var det kamp om å @stå inst attmed veggen, og så var\n" +
    "det @då *hengja jakka @på fremste knagg.\n" +
    "…………………………………………………..\n" +
    "I: Hadde de eige rom når de var småe? Korleis såg det ut i tilfelle?\n" +
    "24: Ja, det hadde me.\n" +
    "23: Ja.\n" +
    "24: @Då hadde eg no eige rom, ja. [For] me sånn @skråtak me, og så var det innebygd seng i\n" +
    "@skråtaket. Så der hadde no sikkert §alle ~dokkene og styret.\n" +
    "I: Kva skjedde me dykk viss de ikkje hadde gjort leksene dykkar då?\n" +
    "24: Så hugsar eg faktisk ein gong, så tabba eg meg ut, fordi at eg hadde no nesten alltid gjort\n" +
    "leksa …\n" +
    "I: Mm.\n" +
    "24: … og kunne det. Men så var det ein gong eg hadde slurva med eit lesestykke. Hugsar eg\n" +
    "ho spurde eit spørsmål, og §alle opp med ¤handa. Og så plutseleg oppdaga eg at det var\n" +
    "nesten berre eg som ikkje hadde opp ¤handa.\n" +
    "23: Ja.\n" +
    "24: Og @då @måtte eg liksom …\n" +
    "23: <latter>\n" +
    "24: <humrar> Og kven der plukka ho ut meg, og så visste ikkje eg svaret. <latter> Elles så\n" +
    "svarte eg feil. Så det vart sjølvsagt kjempepinleg for meg @då.\n" +
    "23: Eg kan ikkje hugsa at me vart sånn høyrde, eg. Det var slik som du seier. Dei tok opp\n" +
    "¤handa dei som kunne *svara.\n" +
    "24: Ja.\n" +
    "23: Viss du ikkje hadde gjort ~leksene, så tok ikkje du opp ¤handa.\n" +
    "24: <latter>\n" +
    "23: Og @då var det ikkje nokon reaksjon heller.\n" +
    "24: Vart avslørte <utydeleg>.\n" +
    "23: Sei viss eg @då gjorde skriftlege arbeidet som eg skulle *gjera, så var ikkje det så nøye\n" +
    "med …\n" +
    "24: Nei, eg kan ikkje *hugsa …\n" +
    "23: … leseleksing.\n" +
    "24: … var ikkje noko. Eg kan aldri *hugsa nokon straffereaksjonar altså.",

text_23pog24p: "I: Kva vil de seia har endra seg mykje her i, i Sogndal då på desse her åra?\n" +
    "24: Arbeidsplassar, men det … og så har jo @fått …\n" +
    "23: Mm.\n" +
    "24: … dette her kunnskapsparken som òg …\n" +
    "23: Ja, valdsamt.\n" +
    "24: … genererer litt (i hvert fall), men det er no statlege pengar, men det er no …\n" +
    "23: Og så …\n" +
    "24: … ein form for gründerverksamheit.\n" +
    "23: Ja, og så tenkjer eg òg at dette her, det kom denne her snuen at, at me var gode @på …\n" +
    "hadde den beste snøen, dei beste §¤fjella og dei beste ¤folka.\n" +
    "24: Ja.\n" +
    "23: Ja, at ein fekk ein … vart liksom sånn (aha-opplevelse) med fjellsportfestivalen og dette\n" +
    "… alt som har §balla seg rundt med det at det var så … ja, at dette her plutseleg vart sånn in\n" +
    "plass for …\n" +
    "I: Mm.\n" +
    "24: Ja.\n" +
    "23: … altså unge gründerar å *koma hit og … det var, det var litt spesielt. Og folk …\n" +
    "24: Ja, og så litt sånn trend i ¤tida at ein greidde å *treffa den …\n" +
    "23: Ja.\n" +
    "24: … trenden samtidig som ein hadde ~midlane til det. Kreativitet i dag, slik som eg tenkjer,\n" +
    "er jo like mykje å @få tak i midlar og …\n" +
    "23: Ja, ja, ja.\n" +
    "24: … *byggja dei rette ¤nettverka for å @få til ¤tinga.\n" +
    "23: Men det er ikkje slik at, at eg nødvendigvis sant, føler meg kjempeheime med puddersnø\n" +
    "og §fotball\n" +
    "I: Kva vil du trekkja fram då?\n" +
    "23: Nei, @då vil eg … <nøler> @Då vil eg faktisk *trekkja fram §¤fjella, eg altså, at fine\n" +
    "fjellturar. At det … om sommaren og … Det tykkjer eg er meir …\n" +
    "I: Ja.\n" +
    "23: … dei ~kvalitetane …\n" +
    "24: Ja.\n" +
    "23: … @oppå ¤Fageregga, @oppå Tylderingen og sånn fine, fint å @sjå … eller ja,\n" +
    "sykkelturar litt meir …\n" +
    "24: Ja, at du har eit fritt friluftsliv, kanskje.\n" +
    "23: Ja.\n" +
    "24: Og at ¤bygda med naturen er jo eigentleg det som kan *treffa §alle.\n" +
    "23: Mm.\n" +
    "I: Mm.\n" +
    "23: Ja. Men sånn som fjellsportfestivalen føler ikkje eg er … men som at eg er @målgruppa.\n" +
    "24: Nei. Nei.\n" +
    "23: Men det er eg ikkje heller sjølvsagt ikkje. Men det er noko som det nye Sogndal.\n" +
    "\n" +
    "24: Den satsar jo ikkje på ¤lokalbefolkninga …\n" +
    "23: Nei.\n" +
    "24: … heller den …\n" +
    "23: Nei.\n" +
    "24: Den satsar @på …\n" +
    "23: Dei nye Sogndal, nye ~sogndølene. Ja. Ja.\n" +
    "24: … @på dei nye studentar i … sant, og korleis skal me …?\n" +
    "23: Og entreprenørar eller nye …\n" +
    "24: Ja.\n" +
    "23: … ~gründerane og …\n" +
    "24: Så det … Det opprinneleg var vel det, det ein litt lokal forankring?\n" +
    "23: Ja.\n" +
    "24: Men den er jo vekke,\n" +
    "I: Ja.\n" +
    "24: No tenkjer ein litt meir korleis skal me, altså min, det veit me no ikkje om stemmer, men\n" +
    "ein del av dei ¤tinga no er jo like mykje korleis skal me *tena pengar @på det?\n" +
    "23: Me gler oss, eller eg gler meg i §alle fall over at eg tykkjer det er moro at det skjer …\n" +
    "24: Mm.\n" +
    "23: … ting i ¤bygda, sant at oi sant…?\n" +
    "24: Det er jo kjempemoro. Ja.\n" +
    "23: Her kjem det folk, store band, og at det er populært å *koma hit, sant? Du gler deg over\n" +
    "det, men det, det er ikkje for meg.\n" +
    "24: Og det trur eg det Sogndal har vore ganske flinke til å …\n" +
    "23: Ja.\n" +
    "24: … @få opp litt sånn arrangement. <nøler> Så det er jo veldig bra, og det treffer jo\n" +
    "ungdomsgruppa, sjølvsagt, og @då er det jo alltid …\n" +
    "…………………………………..\n" +
    "I: Her er meir i dykkar gate då?\n" +
    "23: Ja, skorar høgt før, @hjå ~studentane, ja.\n" +
    "24: Ja.\n" +
    "23: Det er kjekt.\n" +
    "24: Det er jo veldig kjekt.\n" +
    "23: Ja.\n" +
    "24: Eg trur ~studentane trivst i Sogndal. Eg tenkjer òg at mange seier òg som @går her så\n" +
    "skryter dei og studentmiljøet, og @då trur eg nettopp det at det er ikkje så stort. Det er\n" +
    "oversiktleg. Du har som ordføraren seier fem-minutt, fem-minutt-samfunn. Du kan @gå til\n" +
    "alt. Det som faktisk er litt spennande no som er, det er jo litt kva dei gjer med den student …\n" +
    "meieriet nedi Fjøra. Det har jo vore litt diskusjonar. @Då vil … Er det bra å *flytta alt opp\n" +
    "@på ein plass? Eller skal me *greia å … altså det er ikkje større ~avstandane enn at det er litt\n" +
    "ok. Me som er litt tradisjonelle vil jo *halda litt @på Fjøra, sant?\n" +
    "23: Mm.\n" +
    "24: Og aktivitet i Fjøra er me no litt begeistra for.\n" +
    "23: Nedste Fjøra, sant? Så var det sentrum, og så vart det drege opp til Gravensteinsgata.\n" +
    "\n" +
    "24: Ja.\n" +
    "23: Og så er det, no er det ~Fosshaugane … Det er her sentrum, det er her det skjer.\n" +
    "24: Men det er ikkje butikkar her @då @endå.\n" +
    "23: Nei. Det er no, det kjem sikkert ikkje heller. Men, men likevel det er her ¤folka er, sant?\n" +
    "Det er her det er liv og røre, så …\n" +
    "24: Dette der trur eg at me har så tett §imellom §alle skott her administrasjon, fagmiljø,\n" +
    "student. Alt dette her gjer at me … Det trur eg …\n" +
    "23: Mm.\n" +
    "24: … eitt av den store styrken.\n" +
    "………………………………………..\n" +
    "24: Den her …\n" +
    "23: Så skal me @sjå. Så har me denne her fine ¤Loftesnesbrua @då. Det gler me oss til. Den\n" +
    "blir no fin.\n" +
    "24: Den blir fin, og der trengst vel ei ny bru.\n" +
    "23: Ja. Og det trur eg blir kjempebra med forhold, i forhold til #Kjørnes òg altså, fordi at han\n" +
    "er ikkje god den der å ha, å *vera #barnefamilie @på hi sida, altså. For at han er så stygg den,\n" +
    "den ¤brua. Kanskje at det blir litt, at #Kjørnes blir @endå meir …\n" +
    "24: Ja, <uforståeleg>\n" +
    "23: … nærmare?\n" +
    "24: Ja, så liksom fint … det estetiske.\n" +
    "23: Ja, den er valdsamt fin òg.\n" +
    "24: Ja. Ja.\n" +
    "23: @Sjå kor fin ho var her du med den her …\n" +
    "24: Ja. Ja, det.\n" +
    "23: … snurren.\n" +
    "24: Ja. Ja.",

text_25og26: "26: Fagleg så var det vel mykje same ¤faga @då som me har no. Det, <nøler> engelsk\n" +
    "begynte me vel ikkje før i fjerdeklassen, viss eg ikkje hugsar feil, men. No begynner dei vel\n" +
    "tidlegare, trur eg, med engelsk.\n" +
    "25: Men me heller begynte ikkje før i fjerde.\n" +
    "26: Nei.\n" +
    "25: Med engelsk.\n" +
    "26: Med engelsken, ja. Elles er det vel mykje same ¤faga, men dei har vel data #gjerne òg no.\n" +
    "Det hadde me no ikkje før.\n" +
    "25: Ja, det bruker dei litt.\n" +
    "I: Hugsar de nokon episodar frå de gjekk der nede?\n" +
    "26: Det er løye med det @når du blir så §gamal, veit du. @Då er det så <stammar> langt\n" +
    "tilbake dette der. <humrar>\n" +
    "25: <latter> Nei, i ¤friminutta og slik så var vel om vinteren så var det snøballkasting. Og\n" +
    "sommaren så hoppa me strikk og …\n" +
    "I: Ja.\n" +
    "25: … hoppa tau og.\n" +
    "26: Paradis og.\n" +
    "25: Så i sjette begynte me vel å @sjå litt @på ~gutane òg, kanskje. <humrar>\n" +
    "I: Men leikte jentene og gutane i lag?\n" +
    "25: <nøler> Nei, det var ikkje så mykje av. Dei var no helst §fotball. Og me hadde meir slik\n" +
    "… Det fekk ikkje me *vera med @på @då.\n" +
    "I: Å?\n" +
    "25: Nei, det gjekk ikkje an med jenter @på laget. Ja, me hadde ei i klassen. Ho blei godteken\n" +
    "@då.\n" +
    "26: Men det var jo §fotball @på den @tida der òg, altså. Det var nede @på den der\n" +
    "handballbana, den asfalterte.\n" +
    "I: Mm.\n" +
    "26: Det var §fotball. Eg hugsar me kom @på skulen #gjerne ho var kvart over @åtte, sant, tre\n" +
    "kvarter før begynte skulen for at dei skulle *spela §fotball.\n" +
    "25: *Spela §fotball først.\n" +
    "26: Og så var det oppdelt slik @då at i ¤friminutta så var det <nøler> forskjellige klassar @då\n" +
    "som hadde bana til forskjellige ¤friminutta @då.\n" +
    "I: Mm.\n" +
    "26: Nei, ¤friminutta så var det jo mykje §fotball og skøyter @på vinteren.\n" +
    "I: Og de jentene fekk ikkje vera med?\n" +
    "25: Nei, det var liksom ikkje noko vanleg at me fekk *vera med @på det.\n" +
    "I: Nei.\n" +
    "25: Nei.\n" +
    "26: Det er vorte no siste @¤åra det, ~damene er begynte å …\n" +
    "25: Nei, eg hugsar @då me begynte @på fotballtrening, @då var det blanda lag.\n" +
    "I: Ja.\n" +
    "\n" +
    "25: Eg var mygg @då. Det var slik at det var liksom … Dei sentra ikkje så mykje til oss\n" +
    "~jentene @då. Så det var §alle i klyngje.\n" +
    "I: Ja, hadde de bading på skulen då?\n" +
    "25: Det hadde me vel. Det hugsar eg ikkje kva @når me begynte med det. Hugsar du?\n" +
    "26: Me begynte i #barneskulen altså, for @då gjekk me jo @nedpå Trudvang dei seks første\n" +
    "@¤åra. Så var me jo ungdomsskulen. Så det hugsar eg at me begynte i (hvert fall) me gjekk\n" +
    "@på #barneskulen.\n" +
    "I: Mm.\n" +
    "26: For eg hugsar denne turen opp her, ikkje sant? Du hadde sumt tusen meteren og greier og\n" +
    "@fått knapp og var godt kry du liksom for ned att @på skulen @då.\n" +
    "25: Ja.\n" +
    "I: Mm.\n" +
    "26: Så eg kan *tenkja meg det at me begynte i femteklassen eller eit eller anna slikt, trur eg.\n" +
    "I: Og de då?\n" +
    "25: Nei, eg trur ikkje me begynte før i … ikkje dei tre første @¤åra i §alle fall med bading\n" +
    "@på skulen. Så gym var no moro.\n" +
    "I: Ja, før de begynte timane om morgonen hadde de noko spesielt ritual då liksom før de …?\n" +
    "26: Det var vel noko …\n" +
    "25: Ja, me song.\n" +
    "26: Me song for maten hugsar eg.\n" +
    "I: Ja.\n" +
    "25: Ja, det gjorde me.\n" +
    "26: «Du som mettar liten fugl» og så song me @då før me begynte liksom og …\n" +
    "25: Og @når me slutta for dagen.\n" +
    "26: Ja,\n" +
    "25: Det gjorde i §alle fall me. @Då song me òg, før me reiste heim att @då.\n" +
    "I: Akkurat. Du som har budd her oppe, har du merka mykje forandringar?\n" +
    "25: Det er no mykje som skjer slik treningsmessig der oppe, og @då er det … så det er trafikk\n" +
    "heile ¤tida, eigentleg.\n" +
    "I: Mm. Mm.\n" +
    "25: Og det meiner eg det var ikkje før, for eg hugsar @når me leikte slik @§slåball uti gata\n" +
    "her og … Det hadde no ikkje @gått an i dag.\n" +
    "I: Nei. Er det ikkje ungar her lenger heller så?\n" +
    "25: Ikkje så mykje, nei. Det er nokon ungar her, men det er ikkje så mange, nei.\n" +
    "I: Kva, kva kunne de gjera? De hadde store friområde de kunne boltra dykk på. Var det mykje\n" +
    "…\n" +
    "25: Ja.\n" +
    "I: … springing …\n" +
    "25: Ja @då.\n" +
    "I: … i skogane her?\n" +
    "25: Var mykje me leikte Per @på boks, og me leikte alt mogeleg oppi ~skogane her, så det.\n" +
    "I: Var det nokon dyr her då?\n" +
    "25: Nei, det er no #ekorn oppi ~skogane. Det kan du no @sjå @endå òg. Elles så …\n" +
    "\n" +
    "………………………………..\n" +
    "25: <utydeleg> Skogly var no liksom det, der det var moro før @då.\n" +
    "I: Mm. Kan du ikkje fortelja om kva de dreiv med der sånn ein kveld liksom?\n" +
    "25: Nei, det var no å … @Måtte no ha eitkvart å *kosa oss med @då. <humrar> Og så var me\n" +
    "no der borte. Og det hugsar eg at eg fekk ikkje … Dei hadde eitkvart @på, @på ~onsdagane\n" +
    "der før, så det varte berre til ho var tolv, trur eg.\n" +
    "26: Mm.\n" +
    "25: Og det fekk eg lov å @gå @på @då @når eg gjekk @på ungdomsskulen. Men eg fekk\n" +
    "liksom ikkje lov å *reisa bort der @på ~laurdagane @då å …\n" +
    "I: Nei.\n" +
    "25: For det var eg for ung til. Det var no om å *gjera å *snika seg av garde og liksom @då ...\n" +
    "Brukte jo det med at eg skulle *liggja @hjå veninne.\n" +
    "I: Mm.\n" +
    "25: Det blei jo @endå meir spennande @når me ikkje hadde lov, kanskje. <humrar>\n" +
    "I: Var det noko musikk der borte?\n" +
    "25: Ja, det var grupper, hadde alltid bra musikk. Det var vel forskjellige slike, ja\n" +
    "handballgruppa hadde mykje arrangement der at dei fekk inn ~pengane sine @på den @måten\n" +
    "og … Det var mykje, men no er det ingenting borti der @då.\n" +
    "I: Nei.\n" +
    "25: Det, det var #gjerne par veker, nei par helgar i månaden det, så var det arrangement der.\n" +
    "I: Var det nokon maratondans der nokon gong?\n" +
    "25: Nei, det veit eg …. Det hugsar ikkje eg noko.\n" +
    "26: Det var det ein gong, ja, prøvde å *setja eit slik rekordforsøk.",

text_27og28: "27: Hugsar …\n" +
    "I: Hadde de noko morgonritual og sånn før de begynte timane då?\n" +
    "27: Det veit du var fast det. Det var vel ein song, #gjerne ei bøn, det er eg ikkje sikker @på.\n" +
    "Det hadde vel #gjerne ho. Trur de song ein song.\n" +
    "28: Me hadde i §alle fall eit, eit valdsamt bestemt ritual\n" +
    "27: Å, hadde de det, ja?\n" +
    "28: Ja, for det at, eg vil tru at desse …\n" +
    "27: Ja, ja.\n" +
    "28: … to var ganske like, ganske like.\n" +
    "27: Ja, det kan eg *tenkja meg.\n" +
    "28: Ganske like.\n" +
    "27: Ja.\n" +
    "28: Og me @måtte for eksempel @når ho kom inn om morgonen, så @måtte me *reisa oss.\n" +
    "Og me @måtte *seia god morgon og *bukka. Og ~jentene dei @måtte *neia.\n" +
    "I: Javel.\n" +
    "28: Så det var ikkje nokon ting.\n" +
    "27: Ja.\n" +
    "28: Og så var det til å *syngja. Og første timen det var form for religion.\n" +
    "27: Ja, det var det.\n" +
    "28: Det var bibelsoge,\n" +
    "27: Bibelsoge, ja.\n" +
    "28: bibellesing eller forklaring.\n" +
    "27: Ja.\n" +
    "28: Det slo ikkje feil. Men noka form for moro kan eg aldri *hugsa me @såg …\n" +
    "I: Nei.\n" +
    "28: … før langt @utpå ettermiddagen.\n" +
    "27: No veit du de var, de var vel, var de todelt eller kor mykje var de?\n" +
    "28: Der frami …\n" +
    "27: De var ikkje delt? Jau.\n" +
    "28: Jau, frami Sogndalsdalen, der var tre skular, og §alle tre ~skulane var todelte.\n" +
    "27: Var todelte, ja. Store og @småe.\n" +
    "28: Så der var det … Ja.\n" +
    "27: Første, andre og tredje @då.\n" +
    "28: Ja, @ifrå første til og med tredje.\n" +
    "27: Også fjerde til sjuande.\n" +
    "28: Og @ifrå fjerde til og med sjuande, @då.\n" +
    "I: Mm.\n" +
    "28: Så …\n" +
    "27: Men du det er ikkje nokon dårleg skule.\n" +
    "28: Nei, men det kunne *vera ein beinhard skule dei @¤åra som me …\n" +
    "27: Ja.\n" +
    "\n" +
    "28: … var litt mange. For i skulen @hjå oss så høyrde ho §alle i §alle fag kvar time.\n" +
    "27: Ah, fytti.\n" +
    "28: Så me hadde ikkje noko å *tenkja @på at nei i morgon slepp eg.\n" +
    "27: Ta sjansen, hæ? Ja.\n" +
    "I: Nei, akkurat.\n" +
    "28: Ho høyrde §alle i §alle fag.\n" +
    "27: Nei.\n" +
    "28: Og viss me skulle ha salmevers, anten det var to eller tolv vers i salmen, så skulle me\n" +
    "*kunna dei @utanåt.\n" +
    "I: Ja.\n" +
    "28: Så det var greitt. Det var berre til å *setja seg til å *pugga og *lesa.\n" +
    "27: Ja.\n" +
    "28: Å, men at me, at det var ein valdsam respekt, …\n" +
    "27: Kor mange var de der framme @då du, slik som eit @år?\n" +
    "28: Det varierte, men §imellom ti og <stammar> opp i fjorten.\n" +
    "27: Ja.\n" +
    "I: Kva de gjorde på i friminutta de då?\n" +
    "27: Nei, du veit der nede i Trudvang, der var det vel … er ikkje sikker @på kor mykje\n" +
    "aktivitet det var altså, utanom @§slåball. Det var valdsamt. Å, ja, ja, det var vel mykje slik,\n" +
    "slik, alminneleg vippepinn dreiv me med ein heil del, hugsar eg.\n" +
    "28: Ja. Ja.\n" +
    "27: Og så om @våren kasta me no @på pengar, veit du.\n" +
    "I: Mm.\n" +
    "27: «Kasta peng», sa vel me. Eg trur ikkje me snakka om stikka så mykje. Me hadde eit strek,\n" +
    "trur eg. Var ikkje det?\n" +
    "28: Ja, det brukte me òg.\n" +
    "27: Strek.\n" +
    "28: Strek, som me kasta etter strek.\n" +
    "27: Og det var no, …\n" +
    "28: Ja.\n" +
    "27: … det var no eittøringar @då, veit du. Å, å, desse femøringar hugsar eg, hugsar eg, men\n" +
    "det har no ikkje akkurat med dette her å *gjera. Men eg hugsar dei snakka om at borti Fjøra,\n" +
    "vaksne folk, kasta kronestykke.\n" +
    "28: Ja.\n" +
    "27: Høyrde eg dei sa, men eg har aldri sett det.\n" +
    "28: Har ikkje eg sett heller.\n" +
    "27: Det var no, det var no, ~femøringane var jo valdsamt fine, veit du, store og gode og. Men\n" +
    "det var om @våren. Men, men §fotball det trur eg neimen ikkje det var altså, hugsar eg ikkje\n" +
    "noko særleg til. Me hadde no @på osen her og mykje elles, men i skulen … Eg trur det var\n" +
    "@§slåball. Det var no i ~timane òg det, veit du, form for gymnastikk. @§Slåball. De hadde\n" +
    "vel, hadde de nokon skuleplass der framme de?\n" +
    "28: Nei.\n" +
    "27: Nei.\n" +
    "\n" +
    "28: Å, nei.\n" +
    "27: Eg har ikkje sett det.\n" +
    "28: Nei, der det var ikkje skuleplass for §fotball, veit du, for @då blei me jo nesten hundsett,\n" +
    "for @då var det bort @på ~bøane til einkvan.\n" +
    "27: Ja, ja, ja.\n" +
    "28: @Då det gjekk ikkje an dette der.\n" +
    "27: §Riksvollen og der nede.\n" +
    "28: @Då trakka me @på, i graset og @¤håa, så @då.\n" +
    "27: <humrar>\n" +
    "28: Me hadde ikkje noka §ball-greier i heile teke.\n" +
    "27: Det var vel #gjerne du kan jo *seia at eggaleiting var det vel slik som om @våren kan eg\n" +
    "*tenkja meg, og me hadde no heile Leite me, veit du.\n" +
    "28: Ja.\n" +
    "I: Ja.\n" +
    "28: Ja. Men me hadde i grunnen ikkje, me hadde ikkje noko, noko, noko fritid heller me altså.\n" +
    "27: Nei.\n" +
    "28: For det at ho køyrde så beinhardt, for ein time det. Det var ikkje snakk om at ein time\n" +
    "hadde seksti minutt. Han kunne like godt ha, ha bortimot hundre og tjue han. For det at @då,\n" +
    "@då sa ho berre at det er nokon som @må ut, så @går dei ut ein og ein.",

text_27pog28p: "28: Det er éin ting som er litt merkeleg. Eg fekk stygt i fisking altså. Eg har aldri *fiska.\n" +
    "27: Ja, du eg tenkte @på det i stad.\n" +
    "I: Å?\n" +
    "27: Og @då, @då lurte eg rett og slett @på om, om du fiska. Det har eg aldri høyrt.\n" +
    "28: Nei.\n" +
    "I: Kva var det som skjedde då?\n" +
    "27: Nei, eg skjønar det, men #gjerne du har no noko spesielt, men …\n" +
    "I: Ja.\n" +
    "27: … det vart for mykje. Vart ikkje det?\n" +
    "28: Eg trur det blei for mykje …\n" +
    "27: Ja.\n" +
    "28: For du veit dei laut *vera #barnejente litt desse ~karane òg, veit du.\n" +
    "27: Ja. Ja, du veit dei reiste.\n" +
    "28: Og så @når. Og @når han @såg at det begynte å dra opp ei regnbyge veit du, så var det\n" +
    "… og så §sykkelen og så fram i Osen. Men så @måtte eg *sitja @på same steinen, så mykje\n" +
    "han skulle @sjå kvar eg sat hen og kvar … at eg var der, skjønar du.\n" +
    "I: Ja.\n" +
    "27: <humrar>\n" +
    "28: Så eg trur eg har rett og slett @fått stygt i det.\n" +
    "27: Men du …\n" +
    "28: Eg har aldri hatt interesse av det.\n" +
    "27: Men dette skjønar eg for så vidt altså, men eg har @fått no … Eg har ikkje @fått heilt\n" +
    "stygt … Eg var med far min og så var det <stammar> … Me sykla altså og, og det var, for ned\n" +
    "att, og så hadde han ofte ein sloe bakom §sykkelen, veit du, for at det var dårlege bremsar.\n" +
    "28: Ja.\n" +
    "27: Men så til @når eg var ein sånn fjorten, femten @år, @då vart det ikkje meir av meg.\n" +
    "28: Nei.\n" +
    "27: Men eg er heilt, heile ¤tida sjuk @når eg ser ei elv, og @når eg ser eit vatn, og eg ser ein\n" +
    "fisk som sprett. @Då er eg uroleg altså, så eg ser ikkje … har eg … kjem eg til å ha helsa, så\n" +
    "trur eg jammen eg skal til att, for eg har ikkje @fått direkte slik som du seier. Men du veit det\n" +
    "vart nok. Me var ein biltur. Me var no aldri @på noko særleg tur, men me var til Nord-Noreg\n" +
    "ein gong. Og @då stoppa ikkje anna enn for fiskevatn, veit du …\n" +
    "28: <humrar>\n" +
    "27: … og elvar. Og mor mi ho sleit og med kassar med primus og med mat. Me var ikkje\n" +
    "@innpå ein kafé eller nokon ting, hadde med seg. Men @då var det berre … @Då\n" +
    "<stammar>, @når han … og som du seier, det var ikkje mykje #barnejente viss det var det du\n" +
    "meinte.\n" +
    "28: Nei, det var … <uforståeleg>\n" +
    "27: For du skjønar far min reiste han om sommaren altså …\n" +
    "28: Ja.\n" +
    "27: §Alle ettermiddagar.\n" +
    "\n" +
    "28: Ja.\n" +
    "27: Så for han @på fiske.\n" +
    "28: Ja @då.\n" +
    "27: Du såg sikkert til han der framme òg?\n" +
    "28: Å, ja.\n" +
    "27: For forbi.\n" +
    "28: For av garde, veit du.\n" +
    "27: Ja.\n" +
    "28: Så var dei @på ¤Fageregga.\n" +
    "27: Ja. Ja.\n" +
    "28: Og var det verkeleg fiskevêr, @då hugsar eg ein gong, @då var det til Fjærlandsete.\n" +
    "27: Ja. Ja.\n" +
    "28: Men det var jo ein heil tur å @gå …\n" +
    "27: Men du skjønar, eg skjønar dei, altså. For det er noko spennande.\n" +
    "I: Men det var no matauk òg dette der då?\n" +
    "27: Sjølvsagt.\n" +
    "I: Sant?\n" +
    "28: Det var det den gongen.\n" +
    "27: Det var no vel det.\n" +
    "28: Ja.\n" +
    "27: No du skjønar det at me @åt. Mor mi steikte. Dei var ikkje så store.\n" +
    "28: Nei.\n" +
    "27: Men, men kjære deg kor godt det var. Og heldigvis så likte eg det altså. Steikte aure, og\n" +
    "me @åt #gjerne to, tre dagar i … heile sommaren me, i veka. Hæ?\n" +
    "28: <latter> Me, under krigen, så slo me frami Vassli, der som han Jens Rutlin eig.\n" +
    "27: Ja. Eg veit kvar det er.\n" +
    "28: Ja. Og @då levde me av fisk.\n" +
    "27: Nemleg, nemleg.\n" +
    "28: Kvar kveld så … Ja @då, me fekk no *vera òg, og @då var ikkje eg meir enn ein seks, ja,\n" +
    "fem, seks @år.\n" +
    "27: Ja, ja, ja.\n" +
    "28: Ned, uti med to #garn. Og så var det fisk, det var det me levde av heile sommaren.\n" +
    "27: Men du veit det at det var vel #gjerne ikkje så godt for ungar. Men du veit, no … hadde\n" +
    "me, eg @fått det. Eg tykkjer det er noko av det beste du kan @få. Det er fisk.\n" +
    "28: Å, ja. Ja.\n" +
    "27: Ikkje sant? Men du veit ein vart vel lei av det vil eg tru. Eg hugsar ikkje slik … eg hugsar\n" +
    "me, det var no berre til å *eta det. <humrar>\n" +
    "28: Ja.\n" +
    "27: Men, men ein @såg vel ikkje på det som noko valdsamt godt, eller fint. <nøler> Men du\n" +
    "kan du *tenkja deg kva dei sparte @då med …\n" +
    "28: Ja, men me hadde ikkje noko anna heller me, veit du.\n" +
    "27: Nei.",

text_29og30: "I: Hugsar de den dagen de begynte i skulen?\n" +
    "30: Ja, eg kan vel hugsa det for så vidt i §alle fall at me gjekk no oppe i Trudvang.\n" +
    "I: Kva de gjorde på i friminutta og slik?\n" +
    "30: Ja, @når me var komne i gang der oppe, så var det litt §@slåball @då, vippepinn, kanskje\n" +
    "som me §kalla det. Det var helst dei ¤tinga me dreiv med der oppe.\n" +
    "I: Kva, var de stor klasse?\n" +
    "30: Nei, men var sju og tjue stykk.\n" +
    "I: Leikte jentene og gutane i lag?\n" +
    "30: Me var stort sett i saman om det me heldt @på med der oppe, var ikkje nokon stor\n" +
    "forskjell. Det kunne *vera at ~jentene hadde lite vetta med §ball oppetter ein vegg og [noko].\n" +
    "29: Nei, eg hugsar (i hvert fall) første dagen @då var det jo slik me hadde med ¤foreldra, og\n" +
    "det var liksom ikkje avklart kven ein skulle ha til lærar @då før ein møtte opp @på skulen.\n" +
    "Og det var liksom valdsamt hemmeleg kven ein skulle @få til lærarar. Så @då vart ein\n" +
    "oppropt @då, for me var to klassar, hugsar eg. Og @då vart det liksom fordelt @då. Me var\n" +
    "vel omtrent ein førti stykk til saman, slik at me var vel omtrent tjue i kvar klasse. Det var\n" +
    "nokolunde likt dette der @då. Så det var no ikkje §alle som var fornøgde med ~lærarane dei\n" +
    "fekk @då veit du. Men det var ikkje så godt å @få *byta heller. Det var no slik at viss først\n" +
    "nokon fekk *byta, så vart det eit fæle spetakkel @då, så dei hadde vel liksom sett opp at @når\n" +
    "dei først kom i klasse, så skulle dei *vera i ein klasse.\n" +
    "I: Mm.\n" +
    "29: Slik var det. Eg har inntrykk av at me begynte litt enklare den gongen enn dei gjer no,\n" +
    "altså. For me tok liksom bokstav for bokstav og. For det eg har sett litt no @hjå ein del rundt\n" +
    "omkring, så verkar det som at dei @går mykje hardare til verks med ¤læringa. Det skal lærast\n" +
    "meir @på kortare tid.\n" +
    "I: Mm. Hadde de noko sånn fast ritual før de begynte timane om morgonen?\n" +
    "30: Nei, me begynte vel kanskje med ein song om morgonen. Og så vidare så hadde me vel\n" +
    "ein timeplan for desse ¤tinga som me hadde stort sett heile ¤tida utover. Det var vel det det\n" +
    "gjekk @på.\n" +
    "29: Det er mykje same inntrykket eg sit med òg, men eg hugsar det at me @måtte *§stilla opp\n" +
    "@då utafor klasserommet. Og @då var det to og to attmed kvarandre @då, og ikkje nokon\n" +
    "fekk *koma inn liksom før at det var heilt, at det var stilt, og at læraren var klar. Så det var lite\n" +
    "grann liksom … Ja, kan ikkje *seia akkurat §*kalla det militært, men det skulle (i hvert fall)\n" +
    "*vera skikkeleg og ordna forhold, skulle ikkje *vera noko spetakkel @på gangen før, me\n" +
    "skulle, skulle inn @då. Og me song vel alltid eitkvart om morgonen, og det trur eg at me\n" +
    "gjorde avslutta om ettermiddagen òg. @Då var det liksom ein song, og så var det no dette her\n" +
    "@då at skulle alltid *helsa på læraren, morgonen og likeins @når me gjekk att, så skulle me\n" +
    "*helsa på læraren. Slik at det var ikkje noko slendrian, og den som ikkje ville *vera med @på\n" +
    "det, han fekk no liksom beskjed om at slik, slik ville ikkje læraren ha det. Det måtte *vera litt\n" +
    "strengt.\n" +
    "30: Ja, det var [i hvert fall] stor forskjell med respekten @då og så no.\n" +
    "29: Ja, det trur eg nok …\n" +
    "\n" +
    "30: Å, ja.\n" +
    "29: … at det har vorte litt, litt annleis.\n" +
    "30: Det er klart at det [har det], veit du. For @då @når me gjekk i skulen, så hadde me jo\n" +
    "respekt for læraren, var jo redd læraren,\n" +
    "I: Mm.\n" +
    "30: … for han var jo heilt nok anna enn kva han blir behandla i dag, i §alle fall @hjå mange.\n" +
    "I: Kva de gjorde når de kom heim att då om dagane?\n" +
    "29: Du veit at det gjekk vel litt @på dette her med at skulle ein @få til noko @på\n" +
    "~ettermiddagane, så @måtte ein liksom *gjera noko sjølv. For du hadde ikkje det sirkuset\n" +
    "som det er no med alt mogeleg organisert i §alle former.\n" +
    "30: Nei, nei.\n" +
    "29: Og alt dette her med fritidsklubbar og automatar som dei fer og heng no rundt @på\n" +
    "¤gatehjørna @på desse her ¤gatekjøkkena og styr. Så eg trur nok det at ¤tiltakslysta var\n" +
    "kanskje litt større før.\n" +
    "30: Ja.\n" +
    "29: Du hadde heller ikkje noko fjernsyn. Det kom jo ikkje før i, ja, tre fire og fem og seksti\n" +
    "veit eg …\n" +
    "30: Nei.\n" +
    "29: … her @hjå oss, og @då blei ikkje det liksom slike ting heller å *sitja og så *kika på om\n" +
    "~kveldane.\n" +
    "30: Nei, og så før den ¤tida for mitt vedkomande, så var det ut @på, @på @åkeren som me\n" +
    "§kalla her for Lægreid.\n" +
    "I: Mm.\n" +
    "30: Der var det slik ein fin plass der\n" +
    "29: Mm.\n" +
    "30: som me brukte til og så *spela §fotball @på.\n" +
    "29: Ja, ja.\n" +
    "30: Og så inni parken. Det er der no som, som Flåten har frisørsalongen sin.\n" +
    "29: Ja, ja.\n" +
    "30: Der var ein open plass som var gjeven der til, til ~ungane liksom her i Sogndal.\n" +
    "29: Ja.\n" +
    "30: Og der, og der sparka me §fotball og stod i.",

text_31og32: "I: Hugsar de nokon spesielle episodar frå de gjekk oppi Trudvang?\n" +
    "31: Ja, eg hugsar ein gong. Eg hadde @fått nokon kameratar @då til og så *læra meg litt\n" +
    "bannord @då, og så heldt eg @på og slost med ein annan, det var ein kamerat av meg @då,\n" +
    "men så var det eitkvart me slost om @då, for han hadde §briller @då, og det tykte eg var så\n" +
    "løye, Slost me @då, så hugsar eg det eg slo han, §brillene av han ein gong @då, og så mista\n" +
    "han eine §brilleglaset @då. Og @då kom læraren @då, og @då hugsar eg sa det til han at:\n" +
    "«Din forbanna lakabodl, lakabodl, lakabodl», sa eg til han. Den episoden gløymer eg no aldri.\n" +
    "I: Kva de gjorde på i friminutta og slik då?\n" +
    "31: Det var som regel §fotball det, kvart friminutt. Om å *gjera å *koma først mogeleg, eller\n" +
    "fortast mogeleg ut, fordi at @då han som kom først ut, han hadde bana. Så @då var det han\n" +
    "som var sjef @då. Så @då bestemte han kor mange som skulle *vera med @då @på den bana\n" +
    "og alt det ~greiene der. Så det, det var som ei kule ut or klasserommet det kvar gong det\n" +
    "ringde.\n" +
    "32: Nei, eg var no med og spelte §fotball eg òg @då. Hugsar no dette der som han seier om\n" +
    "desse ~banene.\n" +
    "31: <kremtar>\n" +
    "I: Kva de gjorde på sånn som i musikktimane då?\n" +
    "31: Me song no mykje @då, slik korgreier @då og så.\n" +
    "32: Nei, me pleidde no … Eg hugsar i (begynnelsen) så hadde dei slike §troll som dei dreiv\n" +
    "@på og lærte om slike melodiar og slik. Veit ikkje kva dei §kallar dei heilt desse §¤trolla.\n" +
    "Hadde no forskjellige namn @på dei og slik @då.\n" +
    "I: Og 17. mai og slik, kva gjorde de då då?\n" +
    "31: Nei, @då var det å @gå i tog @då, og så *bera, hadde me laga til slik fæl fana @då, så\n" +
    "me gjekk og bar @på det @då. Først så bar nokon det. Eller me hadde visse etappar @då me\n" +
    "skulle *bera det. Så begynte me vel nedfor … Eg hugsar ikkje om me begynte for skulen, eller\n" +
    "om me begynte, nedfor Fossetunet begynte me no ofte. (Hvert fall) første @¤åra, meiner eg\n" +
    "så begynte eg der nede. Så for me no og bar @på desse der og så … Første @¤åra @då var\n" +
    "det vel … Var det ~førsteklassingane eller var det #barnehagen, trur eg. @Då vart dei køyrde\n" +
    "rundt @då med traktor og hengjar @då og alt mogeleg. Sidan så var det no å *fara rundt med\n" +
    "dette flagget og *hoa og *gaula.\n" +
    "I: Heiste de flagg eller noko slikt nokon plass nokon stader?\n" +
    "31: Desse her ~lærarane dei heiste vel #gjerne flagg @oppå @på skulen, men.\n" +
    "32: Slik flaggheising det var vel meiner speidaren hadde det ofte om ~morgonane på 17. mai.\n" +
    "For det var @når dei hadde slik speidarfrukost @då nede i banksalen hugsar eg. Elles så\n" +
    "gjekk no me i tog og desse ~traktorane og dette @når dei var yngre.\n" +
    "I: Kva var det nokon som såg etter dykk når de var mindre før de begynte i skulen?\n" +
    "\n" +
    "32: Nei, eg var no ein del ute @hjå ¤besteforeldra mine @då, men. Det var no dette hit på\n" +
    "Solhov, men det var no vel … Eg trur ikkje det var før … @Når eg var @på den alderen, så\n" +
    "var ikkje det før at me begynte i ungdomsskulen trur eg me fekk *vera der hite. Det var\n" +
    "#gjerne eit par kveldar.\n" +
    "I: Mm.\n" +
    "32: Det var for dei yngre òg.\n" +
    "I: Kva slags aktivitetar som var borti Solhov då?\n" +
    "31: Nei, det var no … Dei hadde no all slags mogeleg spel der inne @då. Så hadde dei dette\n" +
    "biljardbord og slikt @då. Så nede i §kjellaren så hadde dei vel slik bordtennisbord òg.\n" +
    "……………………………………\n" +
    "32: Sidan så var me no til Oslo me òg @då. Det var no eit fæle liv der.\n" +
    "I: Det var det?\n" +
    "32: Ja.\n" +
    "I: Kva som skjedde?\n" +
    "32: Nei, me klarte no alltid å *koma for seint til bussen @då. Bussen skulle @gå @ifrå\n" +
    "hotellet @på den siste dagen, så skulle han @gå klokka halv §elleve. Og eg og så eit par til,\n" +
    "me var av garde @på ei slik spelebule, så fekk me så mykje peng, eller kreditt @på desse\n" +
    "¤spela at me vart @ståande å *spela til ho var kvart på §elleve, veit du. Så var det i §vill fart,\n" +
    "og @då stod §alle og venta @på oss utanfor hotellet. Kom me til IKEA @då, og @då laut dei\n" +
    "*venta eit kvarter, tjue minutt på oss @då òg. Så @då var ikkje dei blide.\n" +
    "…………………………………………..\n" +
    "I: Var det noko radioprogram som merkte seg ut?\n" +
    "31: Eg høyrde no aldri @på noko radio heller så. Det var no helst å @sjå @på dette #barne-tv\n" +
    "@då ein var mindre.\n" +
    "32: Men @når me var @småe så, gjekk vel @på #barneskulen @då, trur eg, så høyrde\n" +
    "litevetta @på dette #barne, <stammar> #barnetimen eller kva det var. Veit ikkje om det gjekk\n" +
    "@på ~laurdagane i ¤femtida eller eitkvart.\n" +
    "I: Når vinteren kom då, kva fann de på då nesten sagt?\n" +
    "31: Nei, @då var det no å *reisa fram i §Hollekve @då og så *sigla @på slalåm @då, alt\n" +
    "dette ~greiene der. Sidan så gjekk ein no ofte nokre turar @då viss hadde med ¤foreldra @då,\n" +
    "så gjekk me no ofte skiturar @då. Men det var no ikkje så langt eg giddar @gå. Eg var no\n" +
    "ikkje nokon racer @på desse ~skia @då, var mindre (i hvert fall). Har no kome seg litt etter\n" +
    "@¤åra.\n" +
    "32: Eg var no i §Hollekve @då, og var no på ein del turar, hadde med oss pølser og slik og\n" +
    "grilla med familien. Så var det vel ein slik, var noko slik cup greier hit @på Kaupangerskogen\n" +
    "som ein var med @på. Var ikkje heilt det store, for eg var ikkje så fæle flink, kom ofte seint i\n" +
    "@mål så.",

text_31pog32p: "31: Det er no @på tide me @får den nye ¤brua @vår.\n" +
    "I: Ja.\n" +
    "31: Det blir no ein revolusjon.\n" +
    "I: Så det er de berre positive til det då?\n" +
    "31: ¤Brua ja?\n" +
    "I: Ja?\n" +
    "31: Ho burde no ha kome for hundre vis av @år sidan. Det er spikk at det ikkje har @gått liv\n" +
    "@på ¤gamlebrua, veit du. Så smal som den har vore med folk som @går over der med både\n" +
    "#barnevogner og alt mogeleg, veit du. Og så kjem det to store køyretøy. @Då er det fort at det\n" +
    "kan, kan @gå gale. Men heldigvis har ikkje det @gått gale, @endå. Og så har du no dette\n" +
    "Fossbygget som kom @då. Eg veit ikkje kor mykje folk det rommar dette bygget der eg, men.\n" +
    "Eg har ikkje vore inni og sett det, men. Eg har nokre kameratar som jobbar der, og dei seier\n" +
    "det at det er steike fint bygg.\n" +
    "32: Mm. Flott kantine har dei og …\n" +
    "31: Ja.\n" +
    "32: ~Studentane kan @få seg ein billeg middag og …\n" +
    "31: Ja, ja, ja, ja.\n" +
    "32: Eller vanlege folk òg @får …\n" +
    "31: Ja, det, det skryter dei fæle over, ser du.\n" +
    "32: Ja, så det er bra. Dette er eit bra bygg det.\n" +
    "31. Det er heilt sikkert.\n" +
    "………………………….\n" +
    "32: Det blir bra @når ¤brua kjem. @Då … @når blir *opna, @då …\n" +
    "I: Ja, det er fæle smalt dette fortauet som er på den gamle.\n" +
    "32: Ja. <humrar>\n" +
    "I: Det er no farleg å gå òg der utan noko …\n" +
    "31: Ja, der er farleg å @gå. No blir det betre her, veit du, for no @får du tre meter gangveg.\n" +
    "I: Så har de ein sti som nesten går bort til, til brua, men ikkje heilt.\n" +
    "31: Fjordstien, ja?\n" +
    "I: Ja.\n" +
    "32: Ja.\n" +
    "31: Ja, han stoppar @på Hofslund, han, men han skal vel @gå @derifrå att om ikkje så lenge,\n" +
    "veit eg, reknar eg med.\n" +
    "32: Æh.\n" +
    "31: No blir det vel omtrent sti @herifrå og hm, ja, ja, ut att i ¤Bondevika?\n" +
    "32: Å, ja.\n" +
    "31: Og så kjem du til ¤Stoa att, så blir det gangveg @ifrå der og ut att @på @Ylvisåker. Men\n" +
    "det dei ønskjer, veit eg, som dei har @på tapetet og alt mogeleg, det er at dei skulle ha sti\n" +
    "@ifrå her og heilt til @Ylvisåker. Gang- og sykkelveg.\n" +
    "I: Ifrå Helgheim?\n" +
    "31: Ja.\n" +
    "\n" +
    "I: Ja.\n" +
    "31: Det er det som dei ønskjer. Men @då @må òg ~syklistane *prøva å *halda seg @på desse\n" +
    "~greiene der og ikkje midt etter ~vegane.\n" +
    "32: Mm. Dei vil vel … Dei ønskjer vel å ha ein gang- og sykkelveg inn i Kvam òg @på sikt.\n" +
    "31: Ja, det har dei òg snakka om.\n" +
    "32: Så …\n" +
    "31: Og det burde dei eigentleg hatt.\n" +
    "32: Ja.\n" +
    "31: For der òg er eit spikk at det ikkje er nokon som er ihelkøyrde, altså.\n" +
    "32: Ja, det er stygg veg, altså.\n" +
    "31: @Ifrå Krossen og så inn i …\n" +
    "I: Ja.\n" +
    "31: … inn i Kvam.\n" +
    "32: Mm.\n" +
    "31: Der er det stygt altså.\n" +
    "32: Ja. Og så …\n" +
    "………………………………………….\n" +
    "I: For sjølv om det ikkje er ein by, så blir det litt meir sånn by-, bypreg på det.\n" +
    "32: Ja. Ja, det er no kanskje ein av dei, det er no kanskje ein av dei ¤tinga som eg tykkjer at\n" +
    "#gjerne er det negative med at Sogndal veks. Det er no før i ¤tida så sette du no bilen @ifrå\n" +
    "deg med ~lyklane i uansett kvar det var.\n" +
    "31: Ja.\n" +
    "32: Han kunne @stå og @gå og #gjerne.\n" +
    "31: Ja.\n" +
    "32: Men du, passar deg litt meir for det no altså.\n" +
    "31: Du, du, du gjer det, ja.\n" +
    "32: Ja.\n" +
    "31: Det er ikkje tvil om heller det.\n" +
    "I: Nei.\n" +
    "…………………………………………\n" +
    "31: Så uti Øvstedalen der er det (kun) att, (kun) att to stykk.\n" +
    "32: Mm.\n" +
    "I: Som driv med mjølk?\n" +
    "31: Ja. For, for nokre @år sidan så var det, ja det er snakk om ein sju, @åtte @år sidan så var\n" +
    "det nitten eller tjue som hadde mjølk.\n" +
    "I: Mm.\n" +
    "31: No er det att to. @Då seier det seg litt sjølv at det er no eitkvart som er gale, hadde eg så\n" +
    "nær sagt. Og no skulle dei vel *flytta konsumet òg til, @frå Byrkjelo til Bergen.\n" +
    "32: Ok.\n" +
    "31: No skal dei (kun) ha ost @oppå Byrkjelo.\n" +
    "32. Å, ja.\n" +
    "31: Det høyrde eg i dag.\n" +
    "…………………………………..\n" +
    "\n" +
    "I: Har dei mykje meir å driva med?\n" +
    "31: No?\n" +
    "I: Ja.\n" +
    "32: <humrar> Ja.\n" +
    "31: No har dei no telefonar og Ipadar og (you name it).\n" +
    "I: Ja, men då treng dei ikkje reisa nokon plass.\n" +
    "31: Så, så dei har no endeleg å *gjera. Me hadde no ikkje nokon ting før. Det einaste me\n" +
    "hadde, det var no ein §fotball.\n" +
    "32: Ja, §fotball, men eg hugsar no … Det er no mange ting som dei ikkje gjer no til dags\n" +
    "<nøler>.\n" +
    "31: Ja @då, ja @då.\n" +
    "32: Hadde me, hadde me fri, så pakka me ein sekk og var @på ~Røvhaugane og @Åberge …\n" +
    "31: Ja, ja, ja, ja, ja, ja.\n" +
    "32: … heile ~dagane.\n" +
    "31: Ja, ja.\n" +
    "32: #Gjerne vekke heile ~dagane og hadde med niste og slikt og …\n" +
    "I: Mm.\n" +
    "32: … samla fugleegg og alt mogeleg.\n" +
    "31: Ja.\n" +
    "32: Eg veit ikkje om det er nokon <humrar> som gjer det lenger eg.\n" +
    "31: Det trur ikkje eg er nokon som gjer lenger.\n" +
    "32: Nei.\n" +
    "I: De samla på ting de og rett og slett?\n" +
    "31: Før ja?\n" +
    "I: Ja?\n" +
    "31: Ja, ja, ja, ja.\n" +
    "32: Ja.\n" +
    "31: Det var no ofte fugleegg det og alt forskjellig, ser du.\n" +
    "I: Kva skulle de gjera med dei då?\n" +
    "31: Nei, det var no å *samla og så @sjå kor mange me fekk @då. Og slik at … Men me reiste\n" +
    "ikkje med ¤egga viss det var berre eit egg som var i reiret eller i kassen.\n" +
    "32: Nei.\n" +
    "31: Det @måtte *vera minimum ein fem, seks stykk.\n" +
    "I: Mm.\n" +
    "32: Tok du eitt så hadde du, samla du dei @frå dei forskjellige, tømde ut plomma @då og så,\n" +
    "slik at du hadde ¤egga utan noko inni, og så …\n" +
    "31: Mm.\n" +
    "32: … hadde du forskjellige ~artane. Så elles så hadde eg no @båt og var no @utpå. Eg for no\n" +
    "og dorga om …\n" +
    "31: Ja, ja.\n" +
    "32: … ~somrane etter laks og slikt og … Det var no mykje slikt eg dreiv med. Hugsar du\n" +
    "kveita me fekk @då?\n" +
    "31: Ja.\n" +
    "\n" +
    "32: Det beistet.\n" +
    "I: Kva de fekk, fekk den på?\n" +
    "32: Stong.\n" +
    "31: Dei fekk ho @på ei stong.\n" +
    "32: Ja.",

text_33og34: "I: Kva de gjorde på i friminutta då?\n" +
    "33: Sparka §fotball.\n" +
    "34: Ja, ja det var vel mykje §fotball og. Det var no slik sandkasse der òg så, dreiv no sikkert\n" +
    "og sprang rundt og rundt.\n" +
    "33: Så hadde me nokon sånne leikar òg, sånne meir som §@slåball og. Og i gymmen så var\n" +
    "me @på eit trongt loft. <humrar>\n" +
    "34: Ja, <latter> det var berre å ha av seg ¤broka så …\n" +
    "33: Ja. <latter>\n" +
    "34: … @gå i stillongsen. <latter>\n" +
    "………………………………………\n" +
    "34: Men @når me reiste att, @då song me.\n" +
    "33: Song me alltid ein song.\n" +
    "34: Ja, var ein viss song.\n" +
    "33: Fager kveldssol smiler, trur eg og så ...\n" +
    "34: Ja, ja. Det var i §alle fall i første klassen kvar dag.\n" +
    "33: Ja, så nett etter det me hadde vorte ferdige @då. Så sprang me ut for å …\n" +
    "34: Ja. <humrar>\n" +
    "33: … *finna bussen, *koma først.\n" +
    "34: Ja. <humrar> Sprang fort.\n" +
    "33: Ja.\n" +
    "34: Men om ~morgonane var no ofte me song ein song @då òg, trur eg.\n" +
    "33: I ¤friminutta @når me skulle inn, @då ringde dei med ei sånn §bjølle.\n" +
    "34: Ja, §sauebjølle.\n" +
    "33: Ja. <humrar> Ja og så stilte me oss opp @på ei rekkje @då.\n" +
    "34: Ja, tredje klasse bakarst. <humrar>\n" +
    "33: Ja, to rekkjer. Ja.\n" +
    "34: Ja, så @måtte me @stå i ro til om, til dei hadde talt om §alle var der.\n" +
    "33: Ja.\n" +
    "34: Så sleppte me inn. <humrar> Det var ikkje rare gjengen. Siste @året var me no vel ikkje\n" +
    "meir enn nitten stykk @då vel.\n" +
    "I: Kva de gjorde på når de kom heim att om dagane då?\n" +
    "33: Her hadde me no sommarprosjekt og vinterprosjekt.\n" +
    "34: Ja, ja. <humrar>\n" +
    "33: Skulle *fiksa ein platespelar.\n" +
    "34: Ja. <humrar>\n" +
    "33: §Eldgamal platespelar.\n" +
    "I: Ja.\n" +
    "33: @Måtte no gi opp det @då. <latter>\n" +
    "34: <latter> Ja, det klarte me ikkje @då.\n" +
    "33: Nei. Skulle me *laga ein snuplass her nede.\n" +
    "34: Ja, det stemmer. Tok …\n" +
    "\n" +
    "33: Me begynte no litt @på han, men det var noko stein uti ¤grøfta.\n" +
    "34: Ja. Tok, tok, hivde stein uti ¤grøfta [der så] skulle me *laga det fint til @då så @når dei\n" +
    "kom, @når dei skulle *asfaltera vegen, så kom dei sikkert til …\n" +
    "33: Ja.\n" +
    "34: … å *asfaltera der òg. <humrar>\n" +
    "33: Ja, det var jo det me håpte @på.\n" +
    "34: Så laga me no denne fæle ¤snøborga hugsar eg og …\n" +
    "33: Ja.\n" +
    "34: Så laga me slik skjult veg som ikkje nokon skulle *vita om. <latter>\n" +
    "33: <latter>\n" +
    "34: @Då kraup me inn ein annan plass og så inn i ¤borga der. Var ikkje @råd å *koma seg\n" +
    "inni elles. Det var no litt skummelt å *fara inni der, tykte no eg @då men. Likar ikkje det eg\n" +
    "@når det blir slik trongt.\n" +
    "I: Kva tid vart dei vaksne sinte eller irriterte på dykk då når de var mindre?\n" +
    "34: Nei.\n" +
    "33: Skulle no ikkje så masse til det tykte me no @då men.\n" +
    "34: Nei. <humrar>\n" +
    "I: Kva de hadde funne på?\n" +
    "34: Nei, eg hugsar ein gong eg … Me er tre stykk her framme @då, så har me no vore i lag\n" +
    "§alle @då, men det er ofte to og to som har vore i lag. Så eg hugsar eg og han hin @då, me la\n" +
    "stein … Me tok [liksom] sånn bra store steinar og la over vegen og …\n" +
    "33: <latter>\n" +
    "34: … heilt over, på tvers over vegen, og så venta me til det skulle *koma bilar @då. Så la me\n" +
    "oss i ¤grøfta <humrar> og @låg slik at me skulle *gøyma oss. Så kom postmannen @då.\n" +
    "<latter>\n" +
    "33: <latter>\n" +
    "34: Og så @då [@mått] @då @såg han oss @då, veit du, for me var så nysgjerrige og skulle\n" +
    "@sjå korleis det gjekk. Så @måtte me fint *bera vekk att ~steinane så han kom forbi. <latter>\n" +
    "…………………………………………\n" +
    "I: Var det noko anna de kunne driva med då resten av veka?\n" +
    "34: Ja.\n" +
    "33: Det var no sånn #barnelag me hadde.\n" +
    "34: Det var ikkje noko slik fæle arrangerte greier. <uforståeleg>\n" +
    "33: Nei\n" +
    "34: Men me spelte valdsamt mykje §fotball rundt om …\n" +
    "33: Ja.\n" +
    "34: … @på ~markene @då.\n" +
    "33: Ja, var ei stund @då var det …\n" +
    "34: Ja, @då var det heile tid.\n" +
    "33: … kvar dag. Egg det dreiv me no med, sånn fugleegg.\n" +
    "34: Ja, egg ja, var moro det om @våren.\n" +
    "33: Det var ikkje akkurat samling men.\n" +
    "34: Nei.\n" +
    "\n" +
    "33: Men gjekk no rundt og …\n" +
    "34: Gjekk rundt og @såg me. Og ofte så talde me dei berre òg og @såg kor mange me hadde\n" +
    "funne.\n" +
    "I: Ja.\n" +
    "33: Mm.\n" +
    "34: @Då var det om å *gjera å *halda pusten @når me talde. <latter>\n" +
    "33: <latter>\n" +
    "34: Det var heilt sånn …\n" +
    "33: Elles så ville ikkje fuglen …\n" +
    "34: Nei.\n" +
    "33: … ha @når dei kom tilbake.\n" +
    "34: Hugsar me fann seksogseksti stykk eller noko slikt ein gong.\n" +
    "33: Ja, så @måtte me alltid *lata det *vera att tre …\n" +
    "34: Ja,\n" +
    "33: … egg i reiret, fordi at fuglen kunne ikkje *telja lenger enn til tre. <humrar>\n" +
    "34: Så viss det, viss det var fem @då, så kunne me ta to.\n" +
    "……………………………………….\n" +
    "I: Har de drive noko med fisking?\n" +
    "34: I @år har det vore mindre enn vanleg, men eg har, pleier alltid å *fiska mykje [helst].\n" +
    "I: Kva fiskereiskapar brukte du då?\n" +
    "33: Nei, me brukte vanleg fiskestong, og så hadde me sluk. Det brukte eg (i hvert fall). Men\n" +
    "eg har ikkje, eg har ikkje teke fram fisketong i @år. Eg har ikkje fiska ein einaste gong.\n" +
    "34: Eg har ikkje fiska så mykje eg heller i @år. Det har vore litt for travelt med alt mogeleg,\n" +
    "men før om ¤åra så. Har no, ja, ja, eg har no fiska ein del i @år, men ikkje så mykje som eg\n" +
    "pleier @då.\n" +
    "33: Fiskar jo litt med #garn òg.\n" +
    "34: Mm.\n" +
    "I: Kva du fiskar med då?\n" +
    "34: Eg fiskar med stong eg òg. Ja [før] fiskar mykje på §Anestølen eg, for her @må du ut med\n" +
    "@båt. Eg har ikkje fiska noko særleg med @båt her ute i @år heller så, men det …\n" +
    "33: Me treng jo ikkje ut med @båt.\n" +
    "34: Nei.\n" +
    "33: Så det er liksom så mykje mindre for å @gå ned.\n" +
    "34: Ja, det er berre å *reisa rett ned liksom.\n" +
    "33: Mm.",

text_35og58 : "I: Hugsar de nokon episodar frå de var mindre?\n" +
    "58: Ja, eg hugsar ein gong i #barnehagen. Så, eg og besteveninna mi, me var alltid ute i regnet. Me\n" +
    "dreiv og, me var dei einaste ute i regnet som huska og sånt og. Me, me fekk eigentleg ikkje lov å\n" +
    "*vera ute, og så var me alltid ute.\n" +
    "35: <humrar> Nei, eg hugsar ein gong @då @når me skulle *begynna i førsteklasse så, så kom me og\n" +
    "@då stod ~klassane a og b klassen for seg sjølv. Og så sa rektor at no kunne me *koma inn og så fekk\n" +
    "me @sjå klasserommet første gong og hugsar mamma og pappaen sat baki klasserommet og @såg\n" +
    "fram @på oss og skikkeleg stolte.\n" +
    "58: Ja, og så me fekk, av klassestyraren så fekk me ein blomster kvar, fordi at §alle var ~blomstrane\n" +
    "hennar @då liksom til klassestyraren @vår.\n" +
    "I: <nøler> Trefte de nokon jamaldringar før de begynte i skulen nokon plass?\n" +
    "35: Det var jo i #barnehagen @då, men.\n" +
    "58: Ja.\n" +
    "35: Så var det no litt sånn som du var på ferie og sånt.\n" +
    "58: Mm.\n" +
    "35: Ikkje så mykje her i Sogndal utanom #barnehagen eigentleg.\n" +
    "58: Besteveninna mi og eg gjekk i #barnehagen @då, så me har kjent kvarandre sidan me var to @år\n" +
    "eller noko sånt.\n" +
    "I: Mm.\n" +
    "35: Mm.\n" +
    "I: Når nokon i klassen hadde bursdag eller noko sånn, gjorde de noko spesielt då?\n" +
    "35: Ja, me feira jo bursdagar og kjøpte presangar og laga sånn krone hugsar eg…\n" +
    "58: Ja.\n" +
    "35: frå #barnehagen, så stod det liksom namnet, og kor §gamal eg blei @på krona @då. Så laga dei\n" +
    "§alle saman teikning, så blei det bok ut av det og sånt.\n" +
    "58: Ja, laga sånn bok til kvar…\n" +
    "35: Mm.\n" +
    "58: med §alle teikningar. Så stod alltid den som hadde bursdag @oppå stolen, og så song §alle\n" +
    "saman.\n" +
    "35: Ja, og så fekk den som hadde bursdag *bestemma kven som skulle *sitja attmed han.\n" +
    "58: Ja.\n" +
    "35: He\n" +
    "58: <humrar> og kva, kva skulle ha til mat @då.\n" +
    "35: Ja.\n" +
    "I: Når de begynte på skulen då, kva gjorde de då når nokon hadde bursdag?\n" +
    "58: @Då song me. Me har sunge heile ¤tida liksom og.\n" +
    "35: Mm, blei, det var ikkje krona og teikning i …\n" +
    "58: Nei.\n" +
    "\n" +
    "35: @på skulen eigentleg.\n" +
    "35: @Då var det berre heilt vanleg.\n" +
    "58: Mm, song og sånt.\n" +
    "I: Å, ja.\n" +
    "58: Men av og til fekk me kake,\n" +
    "35: Ja.\n" +
    "58: eller bollar eller noko sånt.\n" +
    "I: Var det noko, noko spesielt radioprogram som var interessant når de var småe?\n" +
    "58: Radioprogram?\n" +
    "I: Ja.\n" +
    "58: Eller, eg syntest ikkje det var spennande, men pappaen min han jobba i radioen @når eg var liten.\n" +
    "I: Ja.\n" +
    "58: Så eg høyrde ofte @på kva han sa @då. Det var liksom ikkje noko sånn som eg følgde med @på.\n" +
    "35: Nei, same her.\n" +
    "58: Det var berre @når han var der, så høyrde eg etter kva han sa, sant.\n" +
    "35: Av og til så høyrde eg @på sånn der, det var sånne spennande detektiv-historier @då.\n" +
    "I: Å, ja.\n" +
    "58: Og så sånne #barnetimen og sånn.\n" +
    "35: Ja. Men eg likte best å @sjå @på TV.\n" +
    "I: Kva dreiv de med i friminutta då?\n" +
    "35: Hoppa tau.\n" +
    "58: Ja, (i hvert fall) i dei første, eller…\n" +
    "35: Ja.\n" +
    "58: første @åra så var det meir *hoppa tau og *slenga oss i det ¤slengstativa og\n" +
    "35: Eller det var ein periode der det var så populært med pog,\n" +
    "58: Ja.\n" +
    "35: hugsar eg. Og så *byta serviettar og.\n" +
    "58: Viskelær og.\n" +
    "35: Ja, og @då heldt me @på med det timevis, veit du.\n" +
    "I: Var det noko forskjell kva jenter gjorde på og gutar gjorde på?\n" +
    "35: Ja.\n" +
    "58: Ja.\n" +
    "35: ~Gutane bytte ikkje serviettar og sånt.\n" +
    "58: Nei, men pog.\n" +
    "35: Ja.\n" +
    "58: Det var veldig populært blant §alle.\n" +
    "35: Ja.\n" +
    "I: Var det noko foreldra masa om då de var mindre, om kvelden og?\n" +
    "35: Det var sånn *pussa godt ~tennene og *vaska godt i augo <ler> og *kamma @håret og sånt.\n" +
    "\n" +
    "58: Eg hugsar ein gong eg nekta å *leggja meg, @då blei mamma og pappa skikkeleg sinte for det at\n" +
    "eg ville ikkje *sova berre @på, @på grunn av at eg ville @sjå @på TV og sånn. Det hugsar eg så godt.\n" +
    "35: Det var mykje om den same ¤masinga eigentleg.\n" +
    "58: Ja.\n" +
    "…………………………………………………………………………\n" +
    "I: Hugsar de noko de har gjort, opplevd?\n" +
    "35: <nøler> Me har vore @på leirskule i lag. Me har vore i masse bursdagar.\n" +
    "58: Ja.\n" +
    "I: Kan de fortelja noko ifrå det? Nokon sånne småting?\n" +
    "58: For eksempel første bursdagen min @då, eller, eller i @sjuårsbursdagen min @då @når ho kom\n" +
    "for første gong. Me har liksom film[opptak] av det @då. Og @då skal *fortelja ein vits. Så blir det\n" +
    "alltid avbrote heile ¤tida av brus som søler over og @får aldri sagt vitsen sin, veit du. Det er ingen\n" +
    "som høyrer @på og. Det er så komisk.\n" +
    "35: Mm, @då @når me var @på leirskule så @låg me og sov, og så om ¤natta klokka fem så ringjer\n" +
    "brannalarmen, sant. Og §alle spring ut, veit du, og livredde heile gjengen, veit du. Så nei, det var falsk\n" +
    "alarm, seier dei. Og så time @etterpå så ringjer han ein gong til <humrar>.\n" +
    "58: Og så ringjer han @endå ein gong.\n" +
    "35: Ja, og så nei det var falsk alarm det òg, og me så sinte, veit du, og ville *sova.\n" +
    "58: @Står og skjeller ut, ut ~lærarane.\n" +
    "35: Å, og så ti minutt @etterpå, så ringjer han ein gong til, sant <ler>.\n" +
    "58: Tre gonger den ¤natta.\n" +
    "35: Ja, og me veit du, så sinte og. Eg lo eigentleg mest av det @då @etterpå.\n" +
    "58: Ja, @då fekk me *sova ein time ekstra @då så.\n" +
    "I: Ja, var det noko anna som skjedde når de var på leirskule?\n" +
    "35: Me var @på @båttur i to timar og @såg @på §fuglefjellet.\n" +
    "58: Mm.\n" +
    "35: Me var @på Runde. Det var ein halv million fuglar der, @på det §fjellet.\n" +
    "58: Mm.\n" +
    "35: Og så @såg me @på selar, så var me @på fjelltur.",

text_36og47 : "47: Om sommaren så hugsar eg at me pleidde å @gå ned og *bada i ¤Leirviki. Det er badeplassen\n" +
    "@då @på Nornes.\n" +
    "I: Ja.\n" +
    "47: Så @då hugsar eg at eg var så redd brennenesle, eller. Så dei @måtte alltid *bera meg, elles tok\n" +
    "eg alltid med meg ei ekstra bukse slik at eg kunne ha ho @på meg @når eg gjekk gjennom\n" +
    "brennenesla.\n" +
    "I: Hugsar du noko frå du var mindre?\n" +
    "36: Ja. Det var ein gong eg, eg har større søster @då, så skulle me *liggja i ¤senga til mamma. Og så\n" +
    "var eg ikkje heilt frisk, så spydde eg @oppå ho.\n" +
    "I: Trefte de nokon jamaldringar før de begynte i skulen?\n" +
    "47: Eg gjekk @på Lomelde #barnehage, eller i Lomelde #barnehage. Og så siste @året i #barnehagen,\n" +
    "@då gjekk eg @på, i @Ylvisåker #barnehage, for Lomelde blei lagt ned. @Då var eg veninne med ei\n" +
    "som er eitt @år yngre. Og så @då, så to jenter som er like gamle som meg, og så eg og ho hi @då me\n" +
    "pleidde alltid å *krangla med kvarandre, sant, for me var bestevenner to og to. Og så krangla me\n" +
    "heile ¤tida. Så @når me kom i @~seksåringane, @då blei eg venn med ho som eg hadde krangla\n" +
    "med.\n" +
    "I: Du var ikkje nokon annan plass du trefte nokon?\n" +
    "36: Jau, eg var i #barnehagen, og så gjekk eg litt @på Lomelde óg i (begynnelsen), men eg var mest\n" +
    "@på @Ylvisåker @då.\n" +
    "Int: Fekk de høyra mykje om skulen før de begynte sjølve då?\n" +
    "47: Nei, ikkje som eg hugsar. Eller i @~seksåringane @då hugsar eg at klasseforstandaren kom, eller\n" +
    "ho me skulle ha @då, kom. Då teikna me teikningar til ho og sånn Om sommaren før me skulle\n" +
    "*begynna i skulen @då hugsar eg at eg fekk eit brev av ho, klasseforstandaren, der ho takka for\n" +
    "¤teikninga og litt sånn.\n" +
    "I: Var det noko spesielt radioprogram som var interessant når de var små?\n" +
    "47 og 36: Nei\n" +
    "36: Det var berre #barneteve me pleidde å @sjå @på.\n" +
    "47: Eller, eg har aldri høyrt @på radio så mykje @då men.\n" +
    "36: Eg hadde sånne der kassettar @då, sånn lydbok greier. Elles høyrde ikkje eg @på radio.\n" +
    "I: Når de ikkje gjekk på skulen då, kva gjorde de då, når de hadde fri?\n" +
    "36: Eg var masse heime. Og så var eg trur, var ein del @på §fjellet. Me har hytte @på §fjellet. Så var\n" +
    "eg med venner og @på besøk og sånt.\n" +
    "………………………………………………………………………………….\n" +
    "I: Korleis vil de karakterisera rommet dykkar heime? Korleis ser det ut?\n" +
    "\n" +
    "36: Eg har ei seng, og så har eg §hylle og pult og kommode og så har eg stol, to stolar.\n" +
    "I: Er det heilt nake rundt omkring?\n" +
    "36: Nei, eg har bilete. Så har eg plakatar.\n" +
    "I: Førebileta dine som er der?\n" +
    "36: Nei, eg har ikkje så masse sånne bilete. Eg hadde det før, før eg flytta rommet mitt, men så gidd\n" +
    "eg ikkje *henga opp att så masse @på, @på ~veggane, for det at ¤målinga dett berre av viss eg\n" +
    "teipar.\n" +
    "47: Nei, eg har eit sånn passe stort rom, så er det grønt rom, eller sånn mørkegrønt. Det nærmar seg\n" +
    "@blått kan du *seia. Så har eg kvite lister, og kvitt tak og kvit dør. Og over ¤senga @då @går det\n" +
    "@skrå nedover og så @oppå, eller der har eg stjerner @då, som er sjølvlysande. Så har eg @klesskåp\n" +
    "og nattbord, så har eg skrivepult og §hylle. Der har eg alle ¤pyntetinga mine og bøker og sånn. Og\n" +
    "@oppå @skåpet har eg §alle ¤kosedyra mine. Dei @står liksom til pynt.\n" +
    "…………………………………………………………………………………………………………………..\n" +
    "36: Me var @på Sørlandet med ~naboane @våre for nokre @år sidan. Det var veldig langt å *køyra.\n" +
    "Me begynte å *køyra klokka seks om morgonen, kom fram klokka @åtte om kvelden.\n" +
    "47: Eg var med @på sånn korpstur, for @då fekk eg lov å *vera med, til Trondheim, og @då var det ti\n" +
    "timar å *køyra.\n" +
    "…………………………………………………………………………………………………………………….\n" +
    "I: Det er store planar for når det gjeld skulane her i Sogndal. Veit du kva det skal bli?\n" +
    "36: M, er ikkje det sånn at skulen skal *vera sånn litt oppi der?\n" +
    "47: Det skal *vera, eg trur eg har høyrt at det skal *vera sånn gang under ¤jorda eitt eller anna til hin\n" +
    "skulen. For det skal *vera to bygg, og @då skal du *kunna @gå under.\n" +
    "36: Sånn der, Sikkert sånn nødutgang kanskje.\n" +
    "47: Men eg veit (i hvert fall) at det skal *vera (gårdspla-) , eller §mellom to bygg, og så skal, skal det\n" +
    "*vera sånn gang under, så\n" +
    "36: Men, #barneskulen skal no *koma opp her òg, trur eg.\n" +
    "I: Ja.\n" +
    "47: Ja.",

text_36pog48p: "I: Kva trur de det er som gjer at de vil bu her då?\n" +
    "36: Nei, eg likar meg godt i Sogndal. Det er ikkje så stort @då, trur det har mykje med det å\n" +
    "*seia. Men så har eg alltid trivest å *vera heime @då. Eg er … @får heimlengsel etter fjorten\n" +
    "dagar, så.\n" +
    "I: Kva er det då du saknar?\n" +
    "36: Nei, eg veit ikkje. Eg berre blir glad @når eg kjem i #Kjørnessvingen og ser Sogndal. Det\n" +
    "er litt sånn rar ting, men eg blir det, altså. Berre å *vera heime, trur eg.\n" +
    "I: Så treng ikkje vera folka, det er rett og slett berre plassen <uforståeleg>?\n" +
    "36: Ja, det er vel litt sånn du kjenner jo §alle. Eg føler litt liksom at … eg føler litt den der viss\n" +
    "eg er i Fjøra @då, så seier du i (hvert fall) hei mange gonger liksom …\n" +
    "48: Mm.\n" +
    "36: … i løpet av ein tur. Det, eg har jo ikkje akkurat prøvd så valdsamt og vore vekke heller\n" +
    "@då.\n" +
    "I: Men dei som kjem her til som studentar eller innflyttarar, kva er det dei set pris på her då?\n" +
    "48: Eg vil tru det er mykje naturen og §¤fjella. Det er filuftslivtilbodet. Så det er no det som\n" +
    "… ein av dei ~hovudgrunnane til at eg kunne tenkt meg å *busetja meg her i all overskodeleg\n" +
    "framtid er no, er no nettopp §¤fjella og dei ¤friluftslivtilboda …\n" +
    "36: Mm.\n" +
    "48: … at dei er liksom rett utanfor ¤døra. For det har eg sakna litt andre plassar eg har budd.,\n" +
    "så …\n" +
    "I: Ja.\n" +
    "48: Så er det ikkje så tilgjengeleg @då. Men har vore vant med at … Eg bur no i Dalen og kan\n" +
    "gå ut ¤døra og så er du i skogen og berre @går rett opp på §fjellet. Og det er ti minutt i bil, så\n" +
    "er du framme i §Hollekve i heisen og kan @gå langt oppi @på ski. Det trur eg nok er veldig\n" +
    "positivt med Sogndal.\n" +
    "I: Er det noko med naturen du set pris på?\n" +
    "36: Ja, det … veit ikkje. Altså no skal ikkje eg, er no ikkje akkurat sånn som driv og er mest i\n" +
    "§fjellet @då. Men det er jo fint synest eg liksom å @sjå. Så er eg jo @på hytta og sånne ting,\n" +
    "sant? Så det …\n" +
    "I: Er den i nærheita?\n" +
    "36: Me har hytte @på fjellet. Me er no @på aking @då med ~ungane og sånne ting.\n" +
    "I: Ordføraren sa før, eller i fjor då, at Sogndal var kjend for fotball og så var det saft og\n" +
    "puddersnø.\n" +
    "48: Mm.\n" +
    "I: Korleis ville de ha karakterisert denne plassen her?\n" +
    "36: Det er liksom … Eg ser aldri ~fotballkampane, men eg kan liksom @sjå at det var bra at\n" +
    "dei vann. <humrar>\n" +
    "48: Men sånn @når du er vekke @ifrå Sogndal, og folk høyrer Sogndal, så er det som regel\n" +
    "dei ¤orda dei nemner.\n" +
    "I: Mm.\n" +
    "36: Mm.\n" +
    "\n" +
    "48: Altså, det er ¤saftbygda, og så er det «stå no på» og så meir og meir det med snøen @då.\n" +
    "Men i mindre grad føler eg enn, enn ¤saftbygda òg så …\n" +
    "36: Eg føler kanskje at den ¤saftbygda er …\n" +
    "48: Mm.\n" +
    "36: … tingen.\n" +
    "48: Ja, eg synest det óg er litt kult eigentleg at Sogndal er jo ei ganske lita bygd. Det er ganske\n" +
    "mange som veit …\n" +
    "36: Kvar …\n" +
    "48: Som har eit visst forhold … i §alle fall høyrt om Sogndal. Men for ¤bygda sin del, så er\n" +
    "det, det òg er jo … det er bra at dei har klart og etablert eit sånn forhaldsvis stabilt\n" +
    "tippeligalag.\n" +
    "I: Mm.\n" +
    "48: Som klarer å *halda seg @på øvste nivå @frå ei så lita bygd @då. Det blir jo litt sånn\n" +
    "merkevare av ¤bygda.\n" +
    "………………………………\n" +
    "48: Det er no denne ¤brua @då. Det er no det folk flest har snakka om dei siste @~månadene\n" +
    "føler eg.\n" +
    "I: Ja. Kva synest de om den?\n" +
    "48: Det er no bra at me @får ny bru, for det er ekkelt å @gå over han …\n" +
    "36: Ja, Gud.\n" +
    "48: … @når det kjem tungtransport og han gyngar.\n" +
    "36: Mm.\n" +
    "48: Det er ganske ubehageleg. Før eg begynte å *jobba, så gjekk no eg @på tur nokre timar.\n" +
    "Så gjekk me nedom ¤brua. Eg trur det stod sånn førti stykke eg og @såg @på at dei løfta opp\n" +
    "den eine biten. Masse …\n" +
    "36: Eg trur det er mange som synest det er fasinerande, for ¤brua kom jo opp @på ein gong\n" +
    "liksom. Sånn @på ein dag, …\n" +
    "48: Ja.\n" +
    "36: … så var ho der eigentleg.\n" +
    "I: Men viss Sogndal skal veksa, kvar skal det veksa hen då liksom?\n" +
    "48: Ja, det er no det som er …\n" +
    "36: Det er jo ikkje så lenge sidan det kom @på Gurvin @då.\n" +
    "48: Nei. Det er jo ikkje det. Der er det jo veldig masse #barnefamiliar.\n" +
    "36: Ja.\n" +
    "48: Og @ifrå Gurvin og fram her, så er det jo ikkje meir enn … Ja, viss du @går seint, med\n" +
    "#barnevogn, så bruker du ti minutt.\n" +
    "……………………….\n" +
    "I: MOT, var det noko som de dreiv med på skulen?\n" +
    "36: Ja.\n" +
    "48: Stemmer det.\n" +
    "I: Kva gjekk det ut på då?\n" +
    "36: Det var jo det at me skulle *vera §snille med kvarandre.\n" +
    "48: Ja.\n" +
    "\n" +
    "36: Eigentleg.\n" +
    "48: Mot å gi beskjed og …\n" +
    "36: Ja.\n" +
    "48: Mot å *seia nei, var vel det?\n" +
    "36: At me skulle *tora å *seia @ifrå viss det var noko me ikkje ville bli med @på @då.\n" +
    "48: Ja.\n" +
    "……………………………………\n" +
    "I: Du har vore framme og fiska i Dalavatnet?\n" +
    "36: Me har vore der framme og fiska og sånn @på ~somrane. Det er eigentleg berre fint å\n" +
    "@gå etter vatnet der @då.\n" +
    "48: Ja, det er det òg.\n" +
    "I: Ja, står de på land då, kva?\n" +
    "36: Nei, dei har @båt. Må ro og sånt @då.\n" +
    "I: Men kva, kva har de å fiska med viss du har fiska?\n" +
    "48: Nei, det er fiskestong.\n" +
    "I: Ja.\n" +
    "48: Ja, og så har eg vore ute og sett <nøler> #garn. Mm.\n" +
    "I: Har du fått noko då?\n" +
    "48: Nei, @då me fekk vel litt meir @når me hadde #garn. <uforståeleg>\n" +
    "………………………………….\n" +
    "I: Har de vore nede der og klatra noko, eller sett andre har gjort det?\n" +
    "36: Nei.\n" +
    "48: Nei. Eg har prøvd å *klatra før liksom, berre eit par gonger liksom, men … ~Ungane, eg\n" +
    "veit ikkje kor gamle dei kan *vera eg, sånn @åtte, ti @år og fer oppi den …\n" +
    "36: Mm.\n" +
    "48: … brattaset veggen og heng og dinglar liksom. Eg @får heilt sånn …\n" +
    "36: Ja, det er litt ekkelt.\n" +
    "48: ¤Foreldra @står nede og heiar.",

text_37og38 : "I: Hugsar de nokon episodar ifrå de var mindre, før de begynte I skulen? Kva gjorde de på då?\n" +
    "37: #Barnehagen, <humrar>.\n" +
    "38: <humrar>.\n" +
    "I: Ja.\n" +
    "37: Ja.\n" +
    "38: Ja.\n" +
    "37: Det var vel eitt eller to @år me gjekk I same #barnehage.\n" +
    "38: Men kva gjekk du @på?\n" +
    "37: Eg gjekk @på Lomelde òg.\n" +
    "38: Å, ja.\n" +
    "37: Men eg gjekk @på Ylvisåker eitt eller to @år,\n" +
    "38: Ja.\n" +
    "37: trur eg.\n" +
    "38: Eg hugsar me sykla rundt #barnehagen. Det var sånn veg rundt der.\n" +
    "37: Ja, ja.\n" +
    "38: Så dreiv me og *sykla.\n" +
    "37: Det var sånn asfaltert veg rundt.\n" +
    "38: Mm.\n" +
    "37: Og så var det sånn bensinstasjon @på hjørnet der.\n" +
    "38: Ja, <latter>. Og så eg hugsar ein gong eg og besteveninna mi @då. Me dreiv og huska og så\n" +
    "begynte det å *regna. Og så jobba tremenningen min der. Og så fekk me lov til å *sitja med T-skjorter\n" +
    "@når det regna. Me var så stolte, veit du <latter>.\n" +
    "I: Fekk de høyra mykje om, om skulen før de begynte sjølve?\n" +
    "37: Litt kanskje.\n" +
    "38: <nøler>\n" +
    "37: Me gjekk jo i [det var sånn] @~seksåringane. @Då var me jo i skulen.\n" +
    "38: Ja, så.\n" +
    "37: Og så gjekk jo me i, #barnehagen var jo attmed skulen.\n" +
    "38: Ja, over bana liksom. Eg trur (i hvert fall) me gledde oss @då til å *begynna @på skulen.\n" +
    "37: Ja, og så var det litt skummelt òg.\n" +
    "38: Og å @få lekser og sånt. @Då var liksom vaksne @då <humrar>.\n" +
    "\n" +
    "I: Dreiv de med noko anna der borte enn det de dreiv med?\n" +
    "37: Det var no lekser og så @måtte du *gjera ting, bestemt liksom.\n" +
    "38: Ja, me hadde sånn bok @då, hugsar eg. Det svære.\n" +
    "37: Ja, det §trylleskipet.\n" +
    "38: Ja, høyrde me @på sånn kassett, og så @måtte me *gjera sånn @oppgåver i den ¤boka og\n" +
    "*teikna.\n" +
    "37: Ja, eg var berre annakvar dag, så eg fekk ikkje med meg alt.\n" +
    "38: Å, ja <humrar>\n" +
    "I: Viss nokon i, i klassen hadde bursdag, gjorde de noko spesielt då?\n" +
    "38: I #barnehagen så hadde me krona, laga me krona, så laga me sånn\n" +
    "37: Ja, og så teikning, eit sånt hefte.\n" +
    "38: Og så hadde me gele eller is @då.\n" +
    "37: Ja.\n" +
    "38: Det var …\n" +
    "37: Og så kasta me dei opp i ¤lufta så mange gonger som dei hadde.\n" +
    "38: Ja.\n" +
    "I: Hm?\n" +
    "38: Kasta, eller dei\n" +
    "37: Vaksne\n" +
    "38: Ja.\n" +
    "37: kasta dei opp.\n" +
    "I: Når de begynte i skulen då, var det noko spesielt ritual då når de hadde bursdag?\n" +
    "38: Me laga vel krona.\n" +
    "37: Ja, første @¤åra kanskje.\n" +
    "38: Ja, og så song me @då.\n" +
    "37: Ja.\n" +
    "38: Men @då var det ikkje noko sånn meir spesielt, sånn som det var i #barnehagen.\n" +
    "37: Det var av og til viss at det var mange som hadde samtidig, så fekk me kanskje *laga ei kake eller\n" +
    "noko sånt.\n" +
    "38: Ja.\n" +
    "…………………………………………………………………………………………….\n" +
    "37: Men @når me var heilt @småe, så var det jo sånn turneringar og sånn.\n" +
    "\n" +
    "38: Ja. @Då spelte me ikkje kampar.\n" +
    "37: [Det var i] ~helgane.\n" +
    "I: Å, ja. Korleis vil de karakterisera rommet dykkar? Korleis ser det ut?\n" +
    "38: Det er to store @klesskåp <humrar>, og så er det ein, dei er kvite, og så er det ein lang pult, så er\n" +
    "det eit anna @skåp med noko sånn glas, vindauge @på, @på ein @måte, og så er det eit lite bord og\n" +
    "¤senga med §spegel over.\n" +
    "I: Er det heilt nake på, rundt omkring?\n" +
    "38: På ~veggane?\n" +
    "I: Mm.\n" +
    "38: Eg har ikkje plakatar @då. Det gidd eg ikkje å ha <humrar>. Men eg har bilete. Eg har eitt bilete\n" +
    "der det eg @målte @når eg var seks @år <ler>. Store sommarfuglar og noko sånn greier.\n" +
    "I: Mm.\n" +
    "38: Det er berre hengt opp.\n" +
    "37: Nei, det er liksom rosa @då,\n" +
    "38: <humrar>\n" +
    "37: for at det er lenge sidan eg @målte det. Og så er det @skråtak, og så er det @klesskåp, pulten,\n" +
    "ein kommode, seng, sakkosekk, og så er det noko sånn speglar og bilete og noko sånn @på\n" +
    "~veggane.\n" +
    "……………………………………………………………………………..\n" +
    "I: Har de vore på nokon skuleturar som de kan hugsa?\n" +
    "37: Og så var me @på telt-tur i sjetteklassen.\n" +
    "38: Ja.\n" +
    "I: Ja, fortel om den då!\n" +
    "38: Det var ganske langt å @gå <ler>.\n" +
    "37: Ja.\n" +
    "38: Og så hadde me sånn, me vog ~sekkane før me reiste @då, var ti …\n" +
    "37: §Alle var @på sånn ti-tolv kilo og sånn.\n" +
    "38: Ja, det var ganske tungt, så det. Det var godt @når me kom opp og fekk sett opp ¤telta og …\n" +
    "37: §Alle var heilt sånn @såre bakpå ~ryggane og.\n" +
    "38: Mm. Så var me berre ti stykke i klassen @då, så var ikkje så (valdsamt) mange, men det var berre\n" +
    "koseleg.\n" +
    "37: Mm.\n" +
    "38: Så @låg me sånn i telt ja. Og læraren @vår kokte tomatsuppe til frukost neste dag <humrar>.\n" +
    "\n" +
    "37: <humrar>.\n" +
    "…………………………………………………………….\n" +
    "I: Kva har de inntrykk av at andre veit om Sogndal når det snakkar med dei?\n" +
    "38: Trur eigentleg ikkje dei veit så mykje,\n" +
    "37: Nei\n" +
    "38: for Sogndal er ikkje sånn veldig stort og kjent liksom.\n" +
    "I: Nei.\n" +
    "37: Hadde det kanskje vore by, så hadde dei #gjerne visst meir.\n" +
    "38: Ja.\n" +
    "I: Er det noko de set spesielt pris på her, for eksempel noko med naturen eller noko?\n" +
    "38: Sjøen\n" +
    "37: Ja.\n" +
    "38: eigentleg , og så ¤§fjelli @då. Det er jo av og til kjekt å @gå @på fjelltur <humrar>.\n" +
    "I: Mm.\n" +
    "37: Det passar liksom i lag.\n" +
    "…………………………………………………………..\n" +
    "38: Eg har (i hvert fall) bestemt meg for at @når eg er ferdig @på allmenn, så har eg lyst å ta eit @år\n" +
    "fri og *jobba og berre *reisa og …\n" +
    "37: Ja, peng og reist …\n" +
    "38: Mm.\n" +
    "37: nett der du ville akkurat liksom.\n" +
    "38: Langt vekk. Syden og …\n" +
    "I: Ja, kvifor det då?\n" +
    "37: §Alle plassar liksom.\n" +
    "38: Fordi det synest, eg synest …\n" +
    "37: *Oppleva korleis det er\n" +
    "38: Ja, og eg synest det er morosamt å *reisa og ikkje berre *vera her i Sogndal og bli sitjande her\n" +
    "liksom heile livet.",

text_39og48 : "I: Fekk de høyra noko om skulen før de begynte sjølve?\n" +
    "48: Om me fekk *høyra noko om han?\n" +
    "I: Ja, var det nokon som fortalde noko om?\n" +
    "39: @Når me var, @når me gjekk i #barnehagen, så var me ute og besøkte skulen.\n" +
    "48: Mm.\n" +
    "39: Ein dag liksom, men det var @~seksåringane trur eg først.\n" +
    "I: Mm.\n" +
    "48: Mm.\n" +
    "39: Så var og besøkte der og @såg og sånn eller så …\n" +
    "I: Var det noko radioprogram som var interessant når de var småe, interessant å høyra på?\n" +
    "48: Eg høyrde ikkje så masse @på radio, eg @såg mest på #barne-TV og så.\n" +
    "39: Ja, av og til så høyrde eg @på denne her #barnetimen som var.\n" +
    "I: Mm.\n" +
    "39: Men det var ikkje så ofte. Det var berre @når eg liksom tilfeldig. Elles så @såg eg mest på #barne-\n" +
    "TV.\n" +
    "I: Mm.\n" +
    "48: Ja.\n" +
    "I: Kva leikte de med heime då?\n" +
    "39: Det var …\n" +
    "48: Barbie-dokker <ler>.\n" +
    "39: <nøler> Barbie-dokker og dokker\n" +
    "48: Eg og ho me slost heile ¤tida om kva me skulle *leika med …\n" +
    "39: <ler>\n" +
    "I: Ja.\n" +
    "48: barbie-dokker eller dokker, for ho hadde lyst til å *leika med dokker og eg med barbie-dokker og\n" +
    "så …\n" +
    "39: @Då var det alltid sånn at me tok begge delar.\n" +
    "48: Ja.\n" +
    "39: Og så leikte me kontor. Ja.\n" +
    "48: Mm. Så leikte me dyresjukehus.\n" +
    "39: Ja.\n" +
    "48: Surra inn ~bamsane med dopapir og plaster.\n" +
    "……………………………………………………………………………….\n" +
    "I: Hugsar de nokon episodar frå de gjekk i Trudvang?\n" +
    "48: At, det var sånn a mot b heile ¤tida, så me ville absolutt ikkje *vera med a-klassen. Det var\n" +
    "skikkeleg krig, dreiv og §kalla kvarandre og <ler>. Så i femteklasse @då fekk me nye lærarar, og dei\n" +
    "prøvde å @få oss i lag.\n" +
    "39: I sjuandeklasse blei me nesten altfor gode vener <humrar>.\n" +
    "\n" +
    "48: Ja.\n" +
    "I: Kva vil det seia?\n" +
    "39: Nei, me, me var mykje med dei og hadde alltid lyst til å ha dei med @på dei ¤tinga me gjorde og\n" +
    "sånt.\n" +
    "I: Ja.\n" +
    "48: Var vanskeleg å *bestemma @når me skulle *skriva to namn som me hadde lyst til å @gå med i\n" +
    "klasse med @når me skulle opp @på ungdomsskulen. Så var det veldig vanskeleg, for det var så\n" +
    "mange.\n" +
    "I: Når nokon i klassen hadde bursdag, gjorde de noko spesielt då?\n" +
    "39: Ja, det var alltid sånn song i klassen.\n" +
    "I: Hadde de noko fast de gjorde når de starta om morgonen på skulen?\n" +
    "39: Nei, det trur eg ikkje.\n" +
    "I: Når de slutta om dagen då?\n" +
    "48: Me pleidde å, [då] @når me gjekk i første og andre og tredje, så hadde me samlingsstund om\n" +
    "morgonen.\n" +
    "I: Ja, kva gjorde de?\n" +
    "48: Me hadde det spesielt @måndagar. @Då prata me om kva me hadde gjort i ¤helga og sånt.\n" +
    "I: Ja.\n" +
    "39: Så song me av og til songar.\n" +
    "…………………………………………………………………\n" +
    "I: Har de fått nokon oppgåver i forhold til han minste?\n" +
    "39: Nei, eller me pleidde å *passa han @på onsdagar @når me hadde fri. Så pleidde me å *§trilla han\n" +
    "og sånn i ¤vogna <ler>.\n" +
    "I: Er de av den typen som er tidleg oppe og har god tid om morgonen?\n" +
    "39: Ja.\n" +
    "I: Kva tid står du opp?\n" +
    "39: Nei, eg @står opp klokka @åtte.\n" +
    "I: Ja, og kva gjer du då når du står opp?\n" +
    "39: Først så kler eg @på meg og sånn og vaskar meg og ordnar meg. Så @går eg ned og et og lagar\n" +
    "niste og pakkar sekken og @går eg opp att og pussar ~tennene. Og så @går eg til skulen.\n" +
    "I: Mm.\n" +
    "48: Eg, he, eg blir vekt halv @åtte, men ofte så @står eg ikkje opp før kvart @på @åtte, ti @på @åtte\n" +
    "@då blir litt sånn dårleg tid. @Då kler eg @på meg, så et eg frukost, så ordnar eg meg, pussar tenner,\n" +
    "og så køyrer me ned til skulen.\n" +
    "\n" +
    "I: Korleis vil de beskriva rommet dykkar heime? Korleis ser det ut?\n" +
    "48: Lite med masse §dill og porselensdokker, sånn der @småe ting.\n" +
    "I: Men kva er det der av møblar og sånn då?\n" +
    "48: Seng og pult og så er det §hyller, og eit @klesskåp, liten cd-spelar elles så masse, masse ting\n" +
    "<humrar>.\n" +
    "I: Mm.\n" +
    "39: Mitt er raudt, lite rom med ei seng, to kommodar, §hyller ein pult, og så har eg eit lite nattbord,\n" +
    "og så har eg nokre plakatar og bilete, ja.\n" +
    "………………………………………………………………………………………..\n" +
    "39: Eg hugsar berre eg var i trekket. Det var no i vinter, køyrde eg telemark og (snowboard), stod eg\n" +
    "og venta @på ho og så merka ikkje eg at ho kom. Ho kom og køyrde rett i føtene mine så me flaug\n" +
    "begge to.\n" +
    "I: Har de nokon sånne testløp av og til?\n" +
    "39: Ja.\n" +
    "48: Mm.\n" +
    "I: Kva går det ut på?\n" +
    "39: @Oppå bana her så skal me *springa, skal me @sjå kor mange rundar me klarer å *springa rundt\n" +
    "@på tolv minuttar.\n" +
    "48: Me har berre hatt det ein gong, skal me ha det att no til @våren òg, trur eg.\n" +
    "………………………………………………………………………………….\n" +
    "I: Kva slags område er det som de rekna utafor sentrum no? Kva er liksom utafor?\n" +
    "48: Lite stykke oppi Dalen og Skjeldestad om\n" +
    "39: @Åberge, Barsnes …\n" +
    "48: mot Kaupanger\n" +
    "39: over ¤brua.\n" +
    "I: Trur de Sogndal vil vera ein god plass å bu om tjue år?\n" +
    "39: Ja, det trur eg.\n" +
    "I: Korleis er tanken på å flytta til ein annan plass?\n" +
    "48: Eg vil heilt sikkert *flytta vekk @når eg blir litt eldre. Kan *henda eg flyttar tilbake @når eg skal\n" +
    "ha familie og sånn, men eg kjem ikkje til å bu her heile ¤tida. Det har eg ikkje lyst til.\n" +
    "39: Nei, det har ikkje eg heller. @Når eg skal *studera og sånn, så kjem eg sikkert til å *reisa ein heilt\n" +
    "annan plass.\n" +
    "I: Ja, vil du koma att her?\n" +
    "39: Det kan *henda.",

text_40og52: "I: Hugsar de nokon episodar frå de var mindre?\n" +
    "40: Eg hugsar at ein gong så var eg @då @på besøk til ein kamerat, dreiv me @på og så leikte sånn i\n" +
    "¤senga @då, så datt eg ned @frå ¤køysenga.\n" +
    "I: Ja.\n" +
    "40: Så klakka eg hovudet i bordet.\n" +
    "I: Kva tid var det?\n" +
    "40: Eg var vel seks @år, sånn cirka.\n" +
    "52: Eg spelte TV-spel, som oftast og @såg @på TV. Det var nesten det eg, (kun) det eg gjorde.\n" +
    "I: Kva typar spel var du likte då?\n" +
    "52: <nøler> Det var @då sånne <nøler> @slåstspel det, krigsspel. Me skulle @då *springa til eit slott\n" +
    "og så befri prinsessa, så skulle me @då @slå ned og *drepa alt me @såg @på vegen, så fekk me\n" +
    "forskjellige våpen @då, nokre sånne kister og sånt styr.\n" +
    "………………………………………………………………………….\n" +
    "52: Eg kjende nesten @då §alle, @på alder, som var @på min alder @då som budde der uti før eg\n" +
    "begynte i skulen. [For] me gjekk no @då i same #barnehage @då.\n" +
    "I: Mm.\n" +
    "52: Difor kjende eg dei.\n" +
    "I: Kva dreiv de med då?\n" +
    "52: Nei, me var i, @på epleslang og, nei me leikte no mykje òg.\n" +
    "I: Men var det andre plassar enn heime de kunne treffa dei som var på dykkar alder? Var de nokon\n" +
    "annan plass de gjekk før de begynte her, førskule eller?\n" +
    "40: Ja, me har ein slik førskule @då, @~seksåringane. @Då trefte eg dei fleste som eg @går i klasse\n" +
    "med no.\n" +
    "52: Ja, det gjorde no eg òg @då, ikkje dei @herifrå Sogndal, men dei der uti.\n" +
    "I: Når de begynte i skulen då, kva, kva dreiv de med i friminutta?\n" +
    "52: I første klasse og slik @då dreiv me @på [berre] @på og leikte @på sånn i, @på masse løye saker\n" +
    "og ting @då. For @då var det ein sånn leikeplass utafor skulen som me dreiv @på og leikte @på.\n" +
    "40: Dreiv @på og leikte sånn, sykla og slikt utafor.\n" +
    "I: Var jenter og gutar i lag og leikte i lag eller?\n" +
    "52: Ja, me var no berre ti der ute, så @då leikte no §alle i lag.\n" +
    "I: Å, ja. Korleis var det hjå dykk?\n" +
    "40: Nei, <nøler> det var av og til ~jentene var med, men det var som regel berre ~gutane.\n" +
    "I: Kva dreiv de med jentene og gutane då?\n" +
    "\n" +
    "40: Nei, eg veit ikkje eg. Me kasta §snøballar, og dei, dei var blink.\n" +
    "52: <humrar>\n" +
    "I: <humrar> Hugsar det nokon spesielle episodar ifrå de gjekk i barneskulen, skuleturar de var på eller\n" +
    "noko anna som skjedde?\n" +
    "52: @Frå #barneskulen @då hugsar eg (kun) sjuandeklasse og §alle @~slåstkampane @då.\n" +
    "I: Ja.\n" +
    "52: Det er det eg hugsar @frå sjuande.\n" +
    "I: Ja.\n" +
    "52: Så før det så hugsar eg eigentleg ikkje så mykje.\n" +
    "I: <nøler> Nede i Trudvang eller ute i Norane, når nokon hadde bursdag i klassen, hadde de noko\n" +
    "spesielt ritual då, noko de gjorde?\n" +
    "52: Me song og så, og så hadde han, har han krona og så @då song me og så fortsette me @etterpå,\n" +
    "@på skulearbeid.\n" +
    "I: Mm.\n" +
    "40: Første par @¤åra så var det slik at me hadde @då berre med ei sånn kake slik den som hadde\n" +
    "gebursdag @då, feira me §alle. Og ein gong [trur eg] @då var det nokon som sette fyr på duken, slik\n" +
    "papirduk.\n" +
    "I: Ja, men de gjorde ikkje noko anna altså?\n" +
    "40: Berre, berre song og slikt @då …\n" +
    "I: Javel.\n" +
    "40: gjorde ikkje noko sånn særleg.\n" +
    "I: Er de av den typen som er tidleg oppe om morgonen og har god tid?\n" +
    "40: Nei, ikkje akkurat. @Då @når eg har fri @då er det litt tidleg opp og så av og *sykla. Og @når eg\n" +
    "skal @på skulen, @då søv eg så lenge som mogeleg.\n" +
    "52: Eg blir vekt til ho er halv @åtte kvar dag uansett @når det er @på skulen. Og @når eg har fri,\n" +
    "@då pleier eg å *liggja så lenge som mogeleg og *sova og *slappa av.\n" +
    "I: Kva er seigast og moroast på skulen @då?\n" +
    "52: Det er nok å @stå opp.\n" +
    "I: Ja.\n" +
    "52: Og så *koma seg av garde, det er det seigaste, tykkjer no eg @då.\n" +
    "40: Det er vel det så, @stå opp tidleg. Eller @må ikkje @stå opp så tidleg @då, eg @står no opp\n" +
    "@når ho er @åtte, men det er tidleg nok.\n" +
    "I: Men når de først er komne her, kva likar de best då?\n" +
    "52: ¤Friminutta.\n" +
    "\n" +
    "I: Fordi at?\n" +
    "52: For, for @då det er moroare enn ~timane.\n" +
    "I: Kva driv de med då?\n" +
    "52: Nei, me pleier å *sitja nede i gangen og *prata, eller så er me ute og pratar.",

text_41og42 : "I: Hugsar de nokon episodar frå de var mindre?\n" +
    "41: Ja.\n" +
    "I: Kva då?\n" +
    "41: Nei, det var @når eg gjekk i #barnehagen @utpå Lomelde, @då var det sånn at §alle var redde\n" +
    "for ei sånn heks som me §kalla det som budde under #barnehagen i eit sånt gult lite hus. @Då var\n" +
    "det sånn §alle laga historier om kva ho laga i ~grytene sine inne. Og så alt mogeleg, sant, om at ho\n" +
    "@åt ungar og litt sånn forskjellig.\n" +
    "I: Dikta opp eventyr?\n" +
    "41: Ja.\n" +
    "42: <nøler> Ja, @når eg gjekk i #barnehagen @frampå Notsete frami Dalen, så pleidde eg alltid å …\n" +
    "Det var eit sånn vepsebol som hang oppi eit tre i ein krok i ei sånn grus … fotballbane no, men eg veit\n" +
    "ikkje om det var det @då. Og der hugsar eg, eg fekk med meg §alle dei som var liksom litt yngre enn\n" +
    "meg bort der. Og så var det … Eg var valdsamt rask @då, så eg kasta stein @på det slik at det datt\n" +
    "ned, og så sprang eg, slik at dei fekk ~vepsane etter seg.\n" +
    "42: Og så kom eg ned @på Trudvang skule , og så kom det no mange andre som eg aldri hadde møtt\n" +
    "før @då. Eg hadde ein, ein kompis @då som eg hadde @gått @på #barnehage med @frampå\n" +
    "Notsete.\n" +
    "I: Fekk de høyra mykje om skulen før de begynte sjølve?\n" +
    "41: Ja, me fekk *høyra om §alle dei forferdelege ~sprøytene, og om lekser og sånt. Men me gleda oss\n" +
    "mest @då til å *begynna, @sjå korleis det var der.\n" +
    "I: Mm. Når nokon i klassen hadde bursdag, gjorde de nok spesielt då?\n" +
    "41: Me song for dei @på skulen. Og så @når me gjekk i #barnehagen, så hadde me som oftast ei\n" +
    "kake. Og så fekk det #bursdagsbarnet lov å *velja om dei ville ha kake, gele eller is. Så laga me krone\n" +
    "og song bursdagssong. Så var det av og til #bursdagsbarnet kom med ein is eller noko sånt @når me\n" +
    "begynte @på skulen òg av og til, kom med ein is til kvar i klassen.\n" +
    "I: Ja, hadde de noko, noko ritual når de starta eller slutta om dagen då på skulen?\n" +
    "42: Eg trur me, me song om morgonen, og så song me til maten, og så lurer eg @på om me song\n" +
    "@når me reiste òg, men eg er ikkje sikker.\n" +
    "41: Ja, av og til song me @når me reiste òg. Me song kvar dag til maten.\n" +
    "I: Ja. Korleis set de Sogndal på kartet? <nøler> Kva fortel de til andre når de skal forklara Sogndal kvar\n" +
    "det ligg hen?\n" +
    "41: Det er ved Noregs lengste fjord. Det er ein fin liten, det er fin lita bygd med masse eple og, ja.\n" +
    "42: Fin natur, masse §fjell rundt her.\n" +
    "\n" +
    "41: Ja,\n" +
    "42: Ikkje så mykje folk her. Dei fleste kjenner kvarandre.\n" +
    "I: Kva seier du om naturen då?\n" +
    "41: <nøler> Det er noko av det flottaste med Sogndal det. Det er så fin sånn, @gå @på tur om @vår\n" +
    "og haust og sommar. Om vinteren kan du @gå @på ski og sånn, så det er ... Så er det valdsamt fine\n" +
    "§fjell du kan *velja om du vil @gå i bratt terreng, flatt terreng eller sånn midt i §mellom.\n" +
    "I: Mm.\n" +
    "41: Så det er valdsamt flott å @gå.\n" +
    "I: Er de stolte over bygda dykkar?\n" +
    "41: Ja.\n" +
    "42: Ja, ja eg har ikkje, eg har ikkje nokon planar om å *flytta @endå i §alle fall.\n" +
    "41: Nei.\n" +
    "42: Og om eg så @må *flytta, vil eg nok alltids *koma tilbake ein gong.\n" +
    "41: Ja, eg reiser kanskje ut for å @få utdanning, men eg trur eg reiser tilbake her og. Viss eg skal\n" +
    "*starta noka eiga bedrift eller noko sånt, trur eg vil *reisa hit att her.\n" +
    "I: Mm.\n" +
    "42: Ja, eg kjem til å *busetja meg her så lenge som det er @råd, for å *seia det slik.\n" +
    "I: Mm.\n" +
    "………………………………………….\n" +
    "42: For tre @år sidan så reiste me @på besøk nedi Danmark. #Syskenbarnet mitt og ¤foreldra hennar\n" +
    "og så ¤syskena hennar @då, dei var @på ferie nedi Danmark, for dei hadde leigt seg ei hytte i to\n" +
    "veker, trur eg.\n" +
    "I: Mm.\n" +
    "42: Så skulle me ned og så *besøkja dei @då, så @då køyrde me @ifrå Sogndal og ned der @på tolv\n" +
    "timar.\n" +
    "I: Å, ja.\n" +
    "42: Så det var ein lang biltur. Stoppa ikkje så fælt mange plassar heller så. Men det gjekk no greitt.\n" +
    "Me overlevde no.\n" +
    "I: Mm. Korleis trur de det vil vera å vera ungdom her om, ja lat oss seia om tjue år då?\n" +
    "41: Æss, @då kjem … Eg veit ikkje om det, trur ikkje det kjem til å bli så valdsamt mykje forskjellig.\n" +
    "@Då kjem <stammar>. Eg synest denne ¤bygda kjem til å, trur denne kjem til å <stammar>, trur\n" +
    "denne ¤bygda kjem til å *utvikla seg mykje meir sånn teknisk og sett og, sant. For no har det vore\n" +
    "masse utvikling her dei siste ¤åra. Med masse nye butikkar og sånt og det elektriske og sånt.\n" +
    "42: Nei, eg veit ikkje. Me kan vel i grunnen ikkje *tenkja seg korleis det kan bli om tjue @år. Anten så\n" +
    "@går det den eine eller så @går det den andre vegen.\n" +
    "\n" +
    "I: Mm.\n" +
    "42: Funne opp det som @går an å *finna opp, eller så finn ein opp masse nytt. Kanskje det vil bli ein\n" +
    "liten by til slutt.\n" +
    "41: Ja.\n" +
    "42: Kven veit?\n" +
    "I: Mm.\n" +
    "41: Så kjem det til å bli mykje meir sånn datamaskinbruk og sånn til skulearbeidet.",

text_42pog54p: "42: Nei, elles så er det jo vorte mykje skiturisme. Skal me §kalla det det?\n" +
    "54: Ja.\n" +
    "42: Det er jo mange som kjem. Du har fjellsportfestivalen. Du har mange …\n" +
    "54: Du har ~fjellsportgreiene, ja.\n" +
    "42: Ja, du har nok @fått …\n" +
    "54: Og det, det dreg saman mykje folk.\n" +
    "42: Ja, du har nok @fått inn det òg som ein del av Sogndal @på same @måte som Lerum og\n" +
    "Sogndal §fotball.\n" +
    "54: Eg høyrde @på, @på P4 i dag, @då nemnde han der reporteren to eller tre plassar, og\n" +
    "@då sa han det … deriblant var Sogndal. Og han rekna med det at det var mange som @då\n" +
    "hadde, hadde teke seg fri i dag og var #gjerne allereie komne i ~løypene. @Då er jo Sogndal\n" +
    "i, ikkje så lite meir sånn sett @då @når dei snakkar om det @på radioen at det er ein fin plass\n" +
    "å @gå @på ski.\n" +
    "I: Ja, korleis var det å høyra det då, for deg?\n" +
    "54: Nei, det er jo klart at du tykkjer det er litt, eller litt stas å *høyra det @på radioen. Sogndal\n" +
    "har jo (bemerka) seg i mange @måtar med både fotballgreier, sant, og litt med dette her\n" +
    "~idolgreiene og syngjedamer og greier så.\n" +
    "I: Kva vil du trekkja fram då som er liksom Sogndal for deg?\n" +
    "42: Viss eg tenkjer sånn Sogndal, så er det jo i utgangspunktet fotballaget.\n" +
    "54: Ja, men dei har jo hatt tippeligalag, eller … i nokså mange @år @då.\n" +
    "42: Tippe, einaste ¤tippeligabygda …\n" +
    "54: Ja, ja.\n" +
    "42: … i Noreg.\n" +
    "54: Men det som blir litt for oss som bur midt oppi alt dette her heile ¤tida @då, me tek det\n" +
    "som ein sjølvfølgje. Dette her er heilt greitt.\n" +
    "I: Mm.\n" +
    "54: Men eg har tenkt @på det mange gonger @når du køyrer bak desse her ~bubilane @då så\n" +
    "…\n" +
    "42: <humrar>\n" +
    "54: … køyrer @då for det første i femti kilometer i @åttisona, og @då køyrer dei litt @på\n" +
    "andre sida og ser @på §alle plassar. Me ser ikkje det som dei ser. Så det er ein fin plass sånn.\n" +
    "Og me har valdsamt fin natur her, mykje fin natur og mange fine plassar å @gå @på tur i\n" +
    "§fjellet og …\n" +
    "I: Mm.\n" +
    "42: Ja, det var jo noko av det eg <nøler> @då eg skulle *flytta fram att her @då, ¤nærheita til\n" +
    "§fjellet.\n" +
    "54: Ja, du er jo i §fjellet.\n" +
    "42: Og … Ja.\n" +
    "I: De er ikkje så glade i snøen, seier de, men det er meir snø her enn nede i Fjøra.\n" +
    "42: Eg har ikkje noko problem med snøen. Eg tykkjer at det er ikkje vinter viss det ikkje er\n" +
    "snø.\n" +
    "\n" +
    "54: Nei, det er ikkje det.\n" +
    "42: Men han kunne godt ha kome, så kunne han @fått lege i fred.\n" +
    "54: Ja.\n" +
    "42: Og ikkje sånn at han …\n" +
    "54: Mm.\n" +
    "42: … smelta vekk, og så skal du §fylla @på att tretti centi, og så @må du til att å *brøyta det\n" +
    "og så …\n" +
    "I: Mm. Dalen er no lang, sant, men er det kontakt heile vegen med sogndøler, eller er det\n" +
    "liksom grend for grend?\n" +
    "42: Det er #gjerne delt litt etter, kva skal eg *seia, ¤grendelaga. Det skjer vel ikkje så mykje\n" +
    "lenger innafor ¤grendelaga som det gjorde tidlegare, men det er no oppe og @går.\n" +
    "54: Ofte, så er det oppdelt slik at dei med sauer og slik, så har dei #gjerne dei som er i ei\n" +
    "grend, dei har #gjerne felles i §fjellet òg, sant. Og dei som er bønder, dei har jo meir med\n" +
    "kvarandre å *gjera.\n" +
    "42: Ja, eg treffer no ~bøndene mykje @då.\n" +
    "54: Ja, du treffer ~bøndene mykje. Ja, ja. Det var det …\n" +
    "42: Men det er no meir i jobbsamanheng.\n" +
    "54: Ja @då, ja @då. Det har nok med ~grendene å *gjera, …\n" +
    "42: Ja.\n" +
    "54: … som du seier.\n" +
    "42: Eg trur det ligg att litt sånn i forhold til korleis det var delt inn med, ja, grendeskular og,\n" +
    "og no er no alt nede i sentrum, men.\n" +
    "54: Du veit det var … Ja, men det var no skule @på Notsete, og det var @på Gjesthaugen, og\n" +
    "det var @på Torstad.\n" +
    "42: Gjesthaugen.\n" +
    "I: Ja, så hadde de vel butikk òg, så alt dette her er jo vekke då? Det er vel att berre ein\n" +
    "barnehage no?\n" +
    "54: Ja, det er #barnehagen @på Notsete, ja.\n" +
    "42: No er det (kun) #barnehage nede @på Notsete.\n" +
    "54: Der var det skule før.\n" +
    "I: Mm.\n" +
    "54: Far min gjekk i #barneskulen der.\n" +
    "I: Ja.\n" +
    "54: Eller noko av … første tre, to-tre@¤ åra, trur eg.\n" +
    "42: <nøler>\n" +
    "I: Ja, men har Sogndal endra seg mykje frå de sjølve gjekk i ungdomsskulen då, eller?\n" +
    "42: Eg har ein følelse av at det er vorte større @på ein måte, både ~studentane og elles òg.\n" +
    "Det er jo vore ein folkevekst …\n" +
    "54: Ja, ja. Det er jo større vorte. Det er no greitt det. <humrar>\n" +
    "42: Eg …\n" +
    "54: Det, det er litt dårleg eksempel @då. @Når eg gjekk @på ungdomsskulen, så var me\n" +
    "#gjerne halvparten, eller borti to tredjedelar som var borti gamle tribunebygget og røykte i\n" +
    "\n" +
    "¤friminutta. Det ser ikkje eg nokon av desse der som er ute og røyker meir. Så det er jo\n" +
    "positivt det, men det <nøler> litt slik var det meir før, trur eg.\n" +
    "42: Eg trur det største forskjellen kanskje sidan ungdomsskulen er … Det er mykje tilflyttarar.\n" +
    "I: Mistar du litt oversikta då over kven som bur her, for å seia det slik?\n" +
    "42: Ja.\n" +
    "54: Ja.\n" +
    "42: Det er det eg òg tenkte med det med mykje tilflyttarar. Før så visste du @på ein @måte\n" +
    "kven §alle var.",

text_43og55: "I: Hugsar de nokon episodar ifrå de var mindre, før de begynte i skulen?\n" +
    "55: Hugsar masse eg, men …\n" +
    "43: Ja.\n" +
    "55: Kjem an @på kva det skal *handla om. <humrar>\n" +
    "43: <kremtar>\n" +
    "I: Ja, kva du gjorde på før du begynte på skulen?\n" +
    "55: Gjekk i #barnehagen. Nei, [berre] leikte og tulla @på.\n" +
    "43: <humrar> Nei, eg gjorde no det same eg òg @då, for å *seia det slik, hit @på Stedje. Eg\n" +
    "har vore heilt @ifrå Stedje @då, @då dreiv me @på og kasta stein @på ~jentene og sånn.\n" +
    "<humrar> Det var litt lite å *gjera @på, så @då.\n" +
    "44: Kasta stein @på ~jentene. <humrar>\n" +
    "43: Ja. Nei, eg hugsar eg pleidde *vera mykje med pappaen i styrkerommet @då, så gadd\n" +
    "ikkje eg å @gå i #barnehagen. Eg ville heller *vera med å *bera vekter med han @då i …\n" +
    "I: Kva dreiv de med når de var heime då, når de leikte heime?\n" +
    "55: Spelte §fotball for harde livet, kasta stein.\n" +
    "I: Hadde de noko sånn fast ritual når de startar timane om morgonen, de gjorde kvar dag?\n" +
    "55: Me hadde sånn morgonstund eller kva dei §kalla det. Der §alle ~elevane sette seg i ein\n" +
    "ring @på golvet, og så sat dei og song og noko sånt styr. Og det var i førsteklassen @då så.\n" +
    "I: Hadde de noko sånt?\n" +
    "43: Ja, ja, me hadde vel i slutten av dagen. @Då stod me i ring og hoppa rundt og takk for i\n" +
    "dag eller noko slikt altså. Eg trur ikkje me hadde noko i (begynnelsen) akkurat. Det var vel\n" +
    "berre i #barnehagen me hadde sånn.\n" +
    "I: Men når nokon i klassen hadde bursdag då, gjorde de noko spesielt då?\n" +
    "55: @Då hoppa dei og song @endå meir.\n" +
    "43: Det var kanskje at me hadde med ei kake @då eller noko sånt, eller song gebursdagssong\n" +
    "og. Syng gebursdagssong, det gjer me vel @endå, tenkjer eg [@når nokon har gebursdag.]\n" +
    "55: Dessverre.\n" +
    "I: Leikte jenter og gutar i lag når de var nede i Trudvang?\n" +
    "43: Ja, av og til så hadde me vel sånn politi og tjuv, eller så trur eg det var meir og meir\n" +
    "splitta, tenkjer eg.\n" +
    "I: Hugsar du kva de dreiv med i friminutta?\n" +
    "43: Ja me stod no og prata.\n" +
    "55: Hoppa.\n" +
    "43: Eg pleidde no å *springa rundt @innpå der me ikkje hadde lov å *springa, @innpå\n" +
    "¤kontora og gøymde oss §innimellom ~trappene og sånt. Og det største kicket det var å ikkje\n" +
    "bli teken av ~lærarane @då. Det var det me håpte @på heile ¤tida.\n" +
    "I: Kva kunne skje då?\n" +
    "43: Nei, det. Me fekk jo ulikt med kjeft @då. <humrar> Men eitt @år @då slutta det heilt\n" +
    "@då. @Då gadd ikkje me meir, for @då @låg me så tynt an at @då torde me ikkje meir. Me\n" +
    "dreiv og sprang opp med musikkskulen og §alle plassar. Var me tekne av vaktmeisteren ein\n" +
    "gong for at me hadde sprunge @nedpå sløyden og …\n" +
    "\n" +
    "I: Hugsar du nokon sånne episodar [som du var]?\n" +
    "55: Ja, me pleidde å *springa der me ikkje hadde lov me òg. Me hadde skulen nett attmed\n" +
    "sjøen @då, så me sprang nedfor sjøen berre. <humrar> Nedmed @båthamn og kasta stein\n" +
    "@på @båtane gjorde me. Og det var ikkje noko lurt. Så spelte me jo §fotball @då i kvart\n" +
    "friminutt, var heilt galne, men så plutseleg slutta det òg. Så @då vart det jo berre kjedeleg, og\n" +
    "berre stod me der.\n" +
    "I: Er de av den typen som er tidleg oppe om morgonen og har god tid?\n" +
    "55: Nei.\n" +
    "43: Nja.\n" +
    "55: Søv lengst mogeleg om morgonen.\n" +
    "43: <kremtar>\n" +
    "I: Kva tid må du opp?\n" +
    "55: Eg bør opp klokka @åtte. Bussen @går halv ni, men pleier ikkje *koma meg opp før ho er\n" +
    "ti over eller, for eg har dårleg tid om morgonen.\n" +
    "43: Nei, eg har same som han berre at eg er vel inni stova komen til ho er @åtte og så … Men\n" +
    "eg bur no nett her oppe, så eg pleier kanskje å *sitja @på ned eller @gå ned eller noko sånt,\n" +
    "så eg har vel ikkje så dårleg tid. Av og til så er eg litt meir tid enn … Har eg altfor lite tid\n" +
    "@då, så berre tek eg §sykkelen. @Då er eg nede med ein gong så.\n" +
    "I: Kva driv de med når de har fri no då? Kva gjer de helst då?\n" +
    "55: Spelar §fotball.\n" +
    "……………………………………….\n" +
    "I: Har de vore på nokon fisketurar sånn her i Sogndal?\n" +
    "55: Fisketurar. Ja, eg har jo nokon @båtar så.\n" +
    "I: Å ja.\n" +
    "55: Er jo @på fjorden ganske mykje @då om sommaren. Fiskar vel ikkje så frykteleg mykje.\n" +
    "Det er vel heller berre *køyra. <humrar>\n" +
    "I: Mm. Viss du fiskar, kva fiskar du med då?\n" +
    "55: Fiskar med stong, sluk eller sånn, set no #garn mykje @då.\n" +
    "I: Har du gjort det?\n" +
    "43: Ja, eg var no mykje og fiska med ¤besteforeldra mine før @når eg var litt mindre @då så\n" +
    "... Men før så var eg no med dei og fiska ein del. Eg veit ikkje om eg fekk så mykje fisk men.\n" +
    "I: Kva du fiska med då?\n" +
    "43: Det var sånn #garn og snor og sånn. Nei, ikkje #garn, tenkjer eg, men det var sånn som du\n" +
    "heiv ned i vatnet og så surra du og drog av og til noko. <humrar> Det var sjeldan eg fekk fisk\n" +
    "@då. Trur dei fekk ein ein gong, men eg hugsar ikkje att kva slags fisk det var.\n" +
    "55: Ja.\n" +
    "…………………………………………………\n" +
    "I: Det er store planar når det gjeld skulane her i Sogndal òg. Veit de kva det skal bli? Veit de\n" +
    "noko om det?\n" +
    "55: Ny ungdomsskule her (i hvert fall) veit no ikkje meir enn det.\n" +
    "43: Utruleg stor ny,\n" +
    "55: Mm.\n" +
    "43: … der §alle ungdomsskuleelevar og Trudvang-elevar skal *koma i lag og.\n" +
    "\n" +
    "I: Ja.\n" +
    "43: Skikkeleg framtids … Frisk pust var det vel prosjektet heitte eller noko sånn.\n" +
    "I: Kva tid skal dei starta med det då?\n" +
    "43: Når ~ungane @våre skal til i skulen. <latter>\n" +
    "55: <latter>\n" +
    "43: Var det vel dei sa. @Då skulle han *vera ferdig.\n" +
    "I: Kvar den skal, kvar den skal vera hen den då?\n" +
    "43: Den skal *vera @oppå @Kvåle her. Dei har så mykje fotballbaner her at eg veit ikkje om\n" +
    "dei @får plass til han. Det er vel rett over her kanskje. Dei har vel ikkje heilt planlagt dette\n" +
    "der kvar han skal @stå hen.\n" +
    "……………………………………………..\n" +
    "I: Korleis trur de foreldra dykkar vil at de skal snakka?\n" +
    "55: Dei vil vel at skal *snakka sogning.\n" +
    "43: Ja, mest sogning.\n" +
    "55: Begge ¤foreldra mine er no fødde her, så @då snakkar no dei sogning òg så.\n" +
    "43: Ja.\n" +
    "I: Mm.\n" +
    "43: Heile ¤slekta mi er vel sogndøl.\n" +
    "55: Mm.",

text_44og65: "I: <nøler> Hugsar de nokon episodar frå de var mindre?\n" +
    "44: Ja, eg, @når eg var ein fem, seks @år, så var eg borte @hjå kompisen min. Så hadde me ikkje\n" +
    "nokon ting å *gjera @på, så me tenkte me skulle *kasta noko sånn stein @då @på kvarandre. Så me\n" +
    "fann kvar sin sånn pappkasse som me skal ha og *verna oss med, så samla me saman masse stein\n" +
    "@då, og så *begynna å *kasta @då, berre sånn @småstein. Og så vart han kompisen min lei av å\n" +
    "*kasta småstein @då, så han fann ein stor murstein, og så kjem han hit og så smør han han midt i\n" +
    "hovudet @på meg. Og så @står me først og ler @då, så kjem, kjenner eg at blodet begynner å\n" +
    "*renna nedover hovudet @på meg, så @då, @då begynner eg sånn og hyler, for å, for å *seia det\n" +
    "slik. Så kjem mor hans ut @då, og ho var der no, vart no ikkje heilt blid. Men eg laut inn altså @då sy\n" +
    "nokon sting oppi hovudet. Men det gjekk no bra.\n" +
    "65: Dette kan ikkje eg *hugsa, men eg var veldig, veldig liten så dreiv @på og sprang rundt i stova. Og\n" +
    "så, så hadde mamma sånn der kaffibesøk @då av tante og onkel. Og så skulle eg hit og så ha kaffi eg\n" +
    "òg, for eg skulle *vera veldig vaksen. Så sprang eg hit og så reiv eg i duken, så fekk eg sånn der\n" +
    "kaffikjel over heile meg. @Då @måtte oppi sånn der kaldt vatn badekar opplegg.\n" +
    "I: Det var mykje dramatikk.\n" +
    "65: Ja.\n" +
    "44: Ja.\n" +
    "I: Dette var før de begynte i skulen, ikkje sant?\n" +
    "44: Ja.\n" +
    "65: Ja.\n" +
    "I: Kva trefte de nokon jamaldringar før de begynte i skulen, altså andre plassar enn heime?\n" +
    "65: Ja, ja, gjekk i #barnehagen @då.\n" +
    "……………………………………………………………\n" +
    "I: Kva dreiv de med når i friminutta når de begynte i skulen?\n" +
    "65: Sparka §fotball.\n" +
    "44: Ja, for det meste. @§Slåball og alt sånn, forskjellige leikar.\n" +
    "I: Hugsar de nokon, nokon episodar ifrå de gjekk der nede?\n" +
    "65: Ja. Det var … Me hadde @§slåball, og så var det ein i klassen som skulle @slå @då. Og så var det\n" +
    "ei som stod for langt, ho stod ikkje nærme, for langt bak han, sånn at han skulle @slå, så bomma han\n" +
    "@på §ballen, så fekk ho §kølla midt i trynet. <humrar> @Då, det var ikkje godt.\n" +
    "I: Kjem du på noko de dreiv med?\n" +
    "44: Nei, me var no @på epleslang @då @hjå naboen, og så det gjekk no bra heilt til me … Det var ein\n" +
    "gong me skulle @gå hit, så @då hadde me vore der mange gonger, så var ikkje så redde lenger. Gjekk\n" +
    "me hit, og så plukka me nokre eple, og så høyrde me ein som stod og ropte etter oss. Han stod og\n" +
    "vatna ¤epla, og så @då dukka me fort @då, og så gøymde me oss bakom nokre tre. Han @står og ser\n" +
    "\n" +
    "etter oss, og så begynner han å *vatna vidare, og så me berre @går hit att, <humrar> men etter det\n" +
    "så trur ikkje eg me var hit der meir.\n" +
    "I: <nøler> De, gjekk de i same klassen de?\n" +
    "65: Nei.\n" +
    "44: Nei, eg var på ~Norane, eg.\n" +
    "I: Og du gjekk?\n" +
    "65: Ned @på Trudvang.\n" +
    "I: Ja.\n" +
    "………………………………………………………..\n" +
    "I: Ja. Er det nokon typiske trekk ved naturen her de vil trekkja fram?\n" +
    "44: §Fjell.\n" +
    "65: Ja, dal, dal. <humrar>\n" +
    "44: §Fjell. Masse skog.\n" +
    "I: Ja.,\n" +
    "44: Masse fine sånne turområde som du kan @gå i så.\n" +
    "……………………………………………………\n" +
    "I: Har de vore på nokon klasseturar etter at de kom opp her då som de hugsar noko frå?\n" +
    "44: Me var no til Runde i @åttande. Og så var me til Alværa sånn konfirmasjonsleir der.\n" +
    "I: Mm.\n" +
    "44: Det var no passeleg artig.\n" +
    "I: Ja, kan du fortelja litt om kva som skjedde der?\n" +
    "44: Ja, @på Alværa @då hadde me sånn, sånn stunder som me samlast, skulle me *svara @på\n" +
    "@spørsmål eller noko sånt, *tenna lys og eit eller anna sånt, be for nokon og alt sånn greier.\n" +
    "65: Fekk *vera oppe så lenge me ville, berre me ikkje heldt nokon andre vakne og kom oss opp att til\n" +
    "@åtte neste morgon.\n" +
    "44: Det var vanskeleg.\n" +
    "65: Det var ofte sånn at du ikkje gadd å *sova, så du berre var oppe heile ¤natta.\n" +
    "44: Ja, me hoppa … Eller det rommet eg var @på, me hoppa jo ut glaset. Og så <humrar> stakk me\n" +
    "opp @på ~trampolinane og hoppa me der midt @på ¤natta, det var i tre, fire ¤tida eller noko. Og så\n" +
    "kjem presten ut. Han skulle @sjå etter nokon. Og så ser han, så spring me langt, langt oppi, og så\n" +
    "gløymer me att ~skorne @våre der, og så spring me berre @på ~sokkane oppover. Men me laut ned\n" +
    "att, eller @når me ikkje ser att presten, så ville me ned att. Og så @då sprang me no ned att @då, så\n" +
    "henta me ~skorne på ~trampolinane. Så sprang me ned att. Og så opna me glaset, og så nokre kom\n" +
    "seg inn før presten kom springande rundt hjørnet med nokre han hadde funne oppi skogen der. Så\n" +
    "det var ikkje så mykje vits i. <humrar>\n" +
    "\n" +
    "65: <humrar>\n" +
    "I: Hadde de nokon sånne?\n" +
    "65: Ja, me for no uti vindauget og sprang rundt heile huset. Og så skulle me *sova på trampolinen,\n" +
    "men det gjekk ikkje.\n" +
    "44: Me hadde noko sånn jenter @innpå rommet, og så presten kom, han kom no inn av og til viss han\n" +
    "høyrde noko lyd. Kjem han inn, og så prøver me å *gøyma dei @då. Så fann han som regel dei. Så\n" +
    "@då @måtte dei inn att @på rommet. <nøler> Så berre gjekk dei ut att glaset og inn att glaset\n" +
    "@vårt, så sånn dreiv me @på heile ¤natta så.",

text_44pog55p: "I: Kva tid møttest de første gongen?\n" +
    "44: Det var vel i #barnehage eller rett etter #barnehagen.\n" +
    "55: Gjekk du i #barnehage du? <latter>\n" +
    "44: Ja, eg var no på @Ylvisåker, eg.\n" +
    "I: Men når de kom i skulen då, kva hugsar de derifrå?\n" +
    "44: Jau, me hengde no mykje i lag, @ifrå me begynte @på skulen.\n" +
    "I: Mm.\n" +
    "44: Me var no ikkje … me hadde no ein liten klasse. Me var ikkje meir enn fem stykk, fire\n" +
    "gutar og ei jente, (i hvert fall) i første …\n" +
    "55: Første @¤åra.\n" +
    "44: … første fem, seks @¤åra. Du hengde no eigentleg i lag med §alle. Me var no ikkje så\n" +
    "mange, så var no nesten nøydd til det.\n" +
    "I: Ja, kva gjorde de på då?\n" +
    "55: Som nører flest, me kasta stein.\n" +
    "44: Ja.\n" +
    "I: Å, ja.\n" +
    "55: Kasta ting.\n" +
    "44: Nei, me var no mykje ute.\n" +
    "55: Ja.\n" +
    "44: Sykla og sprang og herja og klatra og gjorde ting me sikkert ikkje hadde lov til. <humrar>\n" +
    "55: Spelte mykje §fotball.\n" +
    "44: Ja, det gjorde me no mykje. Ja, ja var ikkje mykje me var inne.\n" +
    "55: Nei.\n" +
    "44: I §alle fall.\n" +
    "55: Var ikkje så mykje data @då …\n" +
    "I: Nei.\n" +
    "55: … og tv-spel\n" +
    "I: Nei.\n" +
    "44: Nei.\n" +
    "55: Me var vel helst ute.\n" +
    "I: Mm. Kva, kva dreiv de med, meiner du?\n" +
    "55: Nei, det var vel mykje det same. Me for no og sprang rundt i ~skogane og bygde ting,\n" +
    "spelte §fotball.\n" +
    "44: Ser slik som før, så var det no ungdomsklubbar og, og litt forskjellig slikt.\n" +
    "55: Ja.\n" +
    "44: Juniorklubbar, miniklubbar. Det var no liksom for §alle aldrar @då oppover. Men det er\n" +
    "det no ikkje noko av lenger.\n" +
    "I: Å, nei. Heilt slutt på det altså?\n" +
    "44: Ja, hei, det er vel lite grann, veit eg, men det er no (hvert fall) ikkje @på same leis som det\n" +
    "var.\n" +
    "55: Nei.\n" +
    "\n" +
    "44: Me var no #gjerne ein tjue, tretti stykk @på desse ~ungdomsklubbane som var før, og\n" +
    "@då var det no ein gong i, ein gong i veka. Men no er det vel ein …\n" +
    "55: Ein gong i @månaden, #gjerne.\n" +
    "44: … ein gong i @månaden, #gjerne, @då er dei vel ein fem, seks stykk.\n" +
    "55: @Då @går du der av plikt sikkert.\n" +
    "44: Ja.\n" +
    "I: De har eit ungdomshus her oppe.\n" +
    "55: Mm.\n" +
    "I: Er det i bruk, eller?\n" +
    "55: Ja.\n" +
    "44: Ja, men det er no til alt mogeleg.\n" +
    "I: Ja.\n" +
    "44: Det er no til både §bryllaup og ja, bingo og alt mogeleg slags §tilstellingar. Og det er, det\n" +
    "er til ungdomsklubb eller slikt òg. Det einaste som er @endå, veit eg, det er vel 4H, trur eg.\n" +
    "Men det begynner vel å *dabba litt det òg, trur eg.\n" +
    "I: Ja, så dei som veks opp her i dag, dei blir ikkje så godt kjende med kvarandre heller dei då?\n" +
    "Dei blir på ein måte med ein gong …\n" +
    "44: Nei, nei, dette er no den ¤dataverda, så dei blir no sitjande mykje heime.\n" +
    "55: Sit heime @på sosiale media.\n" +
    "44: Ja.\n" +
    "I: Å, ja.\n" +
    "44: Det ser eg no …\n" +
    "55: Det hadde ikkje me før, så @då samlast me heller ein eller annan plass. Men no sit dei vel\n" +
    "heime og (chattar), sikkert. Elles så er det no inne i Sogndal …\n" +
    "44: Ja.\n" +
    "55: … har eg inntrykk av i §alle fall.\n" +
    "44: Ja, men det trur eg.\n" +
    "55: Me har vel ikkje dei ¤tilboda her ute lenger heller som var før.\n" +
    "I: Tidlegare til Sogndal for å seia det sånn.\n" +
    "55: @Då døyr ~bygdene ut @då. <humrar>\n" +
    "44: Ja. Det er vel enkelt og greitt.\n" +
    "55: Ja.\n" +
    "44: Så er det no litt §fotball og slik enno, men eg trur det har vore litt dauare no @då, men det\n" +
    "@går vel litt i …\n" +
    "55: Det @går vel litt opp og ned, litt.\n" +
    "44: … bølgjer det òg\n" +
    "44: Ja.\n" +
    "55: Ja.\n" +
    "…………………………………………….\n" +
    "I: Her er no eit fint bilete då, eller <uforståeleg>\n" +
    "44: ¤Brua?\n" +
    "I: Ja.\n" +
    "44: Ja, det var @på tide.\n" +
    "\n" +
    "55: Det var @på tide, ja.\n" +
    "I: Har det noko å bety då?\n" +
    "44: Ja, det har no …\n" +
    "55: Nei.\n" +
    "44: Ja, ja.\n" +
    "55: Ikkje for oss.\n" +
    "44: Det er no seigt for dei som @står @oppå @når det dett ned dette gamle dritet.\n" +
    "55: <humrar>\n" +
    "I: <latter> Skal vel ikkje vera oppå når dei riv det?\n" +
    "55: Det har vel litt å *seia for ~loftesnesingane, sikkert, ~baringane.\n" +
    "44: Det har no (i hvert fall) ein del med trafikksikkerheit å *gjera …\n" +
    "55: Ja.\n" +
    "44: … både for @gåande og syklande og … Det er no med livet som innsats uansett kva du\n" +
    "…\n" +
    "55: Mm.\n" +
    "44: Viss du @står no @på Hagalandet og ser det fer tre vogntung @oppå, …\n" +
    "55: <humrar>\n" +
    "44: … så er det meir som ei hengebru. Kjem det to store, så er det trongt for ein liten\n" +
    "@gåande, ja.\n" +
    "55: Mm.\n" +
    "44: Ei med ei #barnevogn eller eitkvart, @då er det jo spennande, det er no heilt sikkert. Men\n" +
    "det er no ikkje det einaste smale partiet rundt om her, så.\n" +
    "…………………………………….\n" +
    "44: Jau, men det er klart det, med så det kjem jo ein del tilflyttarar her til òg @då, (i hvert fall)\n" +
    "no @når det er såpass mykje studentar slik sant som #gjerne @då òg buset seg her, så @då,\n" +
    "blir til …\n" +
    "55: Du har lærarar i, @på skulen og #barnehage som snakkar @bokmål.\n" +
    "44: Ja, ja.\n" +
    "55: Eller fint.\n" +
    "I: Mm.\n" +
    "55: Det merkar eg no @på mine eigen ungar òg det, at dei §hallar over til døling <humrar>\n" +
    "eller eit eller anna, <latter>\n" +
    "44: Ja.\n" +
    "55: … eit eller anna. Så skal no ikkje så mykje til. Ungar er vel lett @påverkelege, så.\n" +
    "44: @Endå så trur eg at det er bra, forhaldsvis bra slik som i ~utkantane enno @då. Eg trur at\n" +
    "dei helde meir @på @språket enn kva dei som bur nærmare sentrum.\n" +
    "I: Mm. For du nokre kommentarar av og til @på @måten de pratar @på? Nokon som\n" +
    "kommenterer det?\n" +
    "44: Ja, pratar no (sjølvfølgeleg) grautete. Det er no av og til du @får ein kommentar @på.\n" +
    "55: Ja.\n" +
    "44: Det er no enkelte som … , det er (sjølvfølgeleg) enkelte som meiner at ein, at dei meiner\n" +
    "¤dialekta er skikkeleg bra og … Ho er jo spesiell, sant? Så ein bør jo *halda @på ho. Det er\n" +
    "no mange som seier.\n" +
    "\n" +
    "55: Nei, det er no som han seier at det er no grautete, er ikkje alle som skjønar kva me seier.\n" +
    "Skal no ikkje så fæle langt vekk heller, før det, folk ikkje skjønar alt. Anna enn det, så har vel\n" +
    "ikkje eg @fått nokon kommentarar @på det.",

text_45og46: "I: Hugsar de nokon episodar frå de var mindre?\n" +
    "45: Ja, oppi ¤trappa @på Trudvang, hivde §snøballar gjennom <stammar> lysrøyret ned i\n" +
    "hovudet @på lærarar, @låg @oppå mørkeloftet. Me gjekk heilt opp, over ¤pedagogkontora\n" +
    "og så tok me ut nokon sånne lamper, og så berre hivde me snø. Gjorde de noko sånt, eller?\n" +
    "46: Nei, eg hugsar eg datt ned ¤trappa. To trapper trur eg eg datt ned.\n" +
    "45: [Kva for nokon då]?\n" +
    "46: Ei borte for gymmen, ei sånn betong og så ei nede for klasserommet.\n" +
    "45: §Ille.\n" +
    "46: Ja.\n" +
    "45: Begge to?\n" +
    "46: Ja, var ikkje heilt godt.\n" +
    "I: Fekk de høyra mykje om skulen før de begynte sjølve?\n" +
    "45: Nja, eg har ei tante som er lærar, så eg visste no sånn cirka kva det var. Men eg @såg i\n" +
    "~skulebøkene @når eg var mindre, så.\n" +
    "46: Nei, eg trur ikkje det. Eller me var no hite @på #barnehagen, så @då var me hite og var\n" +
    "og så prata med lærarar.\n" +
    "45: Sånn førskulelæring.\n" +
    "46: Ja. @Då var me hite og var og prata med ~lærarane og slik. Det var no det eg høyrde, trur\n" +
    "eg.\n" +
    "I: Kva gjorde de på i friminutta?\n" +
    "45: ¤Friminutta spelte me §fotball, §@slåball, basket.\n" +
    "46: Hæ, me fór, ja me huska, hugsar eg. Og så fór me og gjekk.\n" +
    "45: Gjekk?\n" +
    "46: Ja, me berre gjekk. Og så spelte me §@slåball.\n" +
    "45: Har ikkje forandra dykk så mykje de @då. <humrar>\n" +
    "46: <humrar>\n" +
    "45: Berre @gå og @gå og @gå. Ja, og i første @året @på #barneskulen så hadde me politi og\n" +
    "tjuv, @på oppsida. Hadde de òg det?\n" +
    "46: Nei, men eg hugsar første skuledagen.\n" +
    "45: Sant, sant at me @måtte oppi … Fengselet var av §alle plassar oppi den der luftingsen\n" +
    "som stod @på oppsida. Berre klatra opp der.\n" +
    "46: Ja, ja. Men eg hugsar første skuledagen @då datt ¤bjørka ned.\n" +
    "45: Ja, det var ikkje nok moro.\n" +
    "46: Det var ikkje langt @ifrå. Det var ikkje langt @ifrå at det gjekk gale @då @når …\n" +
    "45: Nei.\n" +
    "………………………………………\n" +
    "I: Er det noko som er spesielt for kva jenter driv med og kva gutar driv med?\n" +
    "45: Jenter når dei er mindre, så @går dei @på drillen, og @då @går ~gutane @på speidaren.\n" +
    "Kan henda ~jentene òg driv med, men det er no eit sånt skilje.\n" +
    "46: No, eg veit ikkje … No er det vel ikkje så stor forskjell på jenter og gut.\n" +
    "45: Nei, no @går dei @på disko, dansar, festar.\n" +
    "\n" +
    "46: Men #barneskulen @då var det no slik at ~jentene dei fór og hoppa …\n" +
    "45: Paradis.\n" +
    "46: Ja, og så sånn strikk. Så var det no ~gutane dei spelte no §fotball.\n" +
    "45: Så kanskje dei hoppa tau.\n" +
    "46: Ja. [Det hende no.]\n" +
    "45: Men @då @måtte ~jentene *tvinga oss først.\n" +
    "I: Men kva gjer de helst når de har fri då?\n" +
    "45: [Veit ikkje.]\n" +
    "46: Nei, om sommaren @då er eg mykje @på sjøen og spenner §fotball. Om vinteren blir det\n" +
    "mykje *sitja inne og så *gjera lekser.\n" +
    "45: Det er ikkje så mykje å *gjera @på.\n" +
    "46: Data, det er vel det det @går i.\n" +
    "45: Ja, så om vinteren for mitt vedkomande så @må eg ut og så *vera med i skogen.\n" +
    "46: Eg har no snømoking @då om vinteren.\n" +
    "I: Mm.\n" +
    "46: Det er no noko du gjer til dagen viss, viss det er snø.\n" +
    "45: Ok.\n" +
    "46: Kvar dag.\n" +
    "……………………………………………\n" +
    "I: Var det noko radioprogram de kunne høyra på når de var mindre, eller som var populære?\n" +
    "46: Eg høyrde @på #Barnetimen av og til.\n" +
    "I: Ja.\n" +
    "46: Eller det var no sånn tilfeldig, kom an @på viss eg var @hjå bestemor @då, @då høyrde\n" +
    "eg som regel @på det, så. Og heime høyrde ikkje eg så mykje @på det at.\n" +
    "45: Eg høyrde av og til @på «Lørdagsbarnetimen». Det er sånn liksom annleis enn\n" +
    "#barnetimen. Eg synest den var morare. Den var mykje spennande, så liksom og så @på\n" +
    "Radioteateret @når eg no er blitt litt eldre, så er eg begynt å *høyra litt på det. Det er\n" +
    "spennande, han Dickie Dick Dickens.\n" +
    "………………………………\n" +
    "I: Er det noko med naturen som gjer det spesielt her?\n" +
    "45: Fine turterreng.\n" +
    "46: Ja.\n" +
    "45: Fint å @gå @på tur.\n" +
    "46: Mykje §fjell. Du kan alltid @gå ein ny …\n" +
    "45: Tur.\n" +
    "46: Eller viss du @går @på tur, så har du, kan du nesten alltid ha ein ny plass å @gå @når du\n" +
    "… Sjeldan du @går …\n" +
    "45: @På same plass.\n" +
    "46: Nei.\n" +
    "45: Fleire gonger.\n" +
    "46: Ja.\n" +
    "I: Mm. Kva du som bur i Dalen, kva set du mest pris på med naturen?\n" +
    "\n" +
    "45: Det er no §¤fjella, turar, opp @på §stølen. Skal *setja i gang, *setja i stand att selet @vårt\n" +
    "no så om det blir. Det er ikkje sikkert, men håpar no @på det.\n" +
    "I: Ja.\n" +
    "45: @Då er det no meir sånn ¤beliggenheita. Betre.\n" +
    "I: Er det nokon fiskemoglegheiter for eksempel?\n" +
    "45: Ja. Framme i ¤elva, så er det no fine. Framunder Vassenden så, frami Osen @då som me\n" +
    "§kallar det. Så er det no fint. Og så er det i §Høljarvollen.\n" +
    "I: Korleis fiskereiskapar kan ein bruka då?\n" +
    "45: Makk og stong. Det er det som er best, trur eg. Så er det no …\n" +
    "46: Nei, her nede, nede så er det no fjorden, *liggja @båt. Det er no … Det gjer eg no òg om\n" +
    "~somrane for så vidt. Somme tider så fiskar … Det er no ikkje så mykje fisk du @får, men er\n" +
    "det med fiskestong eller harpe.\n" +
    "I: Brukar ikkje nokon andre reiskapar du då?\n" +
    "46: <nøler> #Garn brukar no litt. Altså er med bestfar min. Han brukar #garn.",

text_46pog61p: "46: ~Studentane som eg opplever her @då, dei kjem … §alle har (i hvert fall) éin sykkel eller\n" +
    "to syklar og (i hvert fall) eitt ski-par … ikkje §alle. Men, men dei har (i hvert fall) mykje …\n" +
    "har kort veg til å *reisa fram @på §fjellet og *køyra seg ein skitur før dei skal ha førelesing,\n" +
    "*sykla seg ein tur.\n" +
    "I: Men kva er det de set pris på med denne her plassen her då?\n" +
    "61: Det er at det er kort avstand til naturen og dei (~opplevelsane) som eg set pris @på,\n" +
    "familien min … at me ein kan @gå ein tur ein ettermiddag utan at du treng eigentleg å\n" +
    "*planleggja fleire veker @på førehand.\n" +
    "I: Mm.\n" +
    "……………………………………..\n" +
    "61: Ja, me var til Runde, men det var konfirmasjonsleir. Eg veit ikkje om du var med?\n" +
    "46: Jau, Runde var eg med.\n" +
    "I: Ja. Kan de ikkje seia litt om det då?\n" +
    "46: At me var @på @båttur og slikt med noko, var ikkje noko.\n" +
    "61: Jau, var ikkje du ein av dei som vart sjøsjuk òg?\n" +
    "46: <humrar> Det, heilt sikkert. Det hugsar eg ikkje, faktisk. Men det …\n" +
    "61: <humrar> Eg trur eigentleg §alle @låg.\n" +
    "46: … trur, er no ikkje usannsynleg med meg (i hvert fall).\n" +
    "61: Ja, det var eigentleg ut mot havet det, der ute.\n" +
    "46: Det var ut mot havet.\n" +
    "61: Ja.\n" +
    "46: Men eg veit at det var eit val å *vera @på ein @båttur òg, og det var (i hvert fall) ikkje eg\n" +
    "@på.\n" +
    "61: Og så var det …\n" +
    "46: Men det var #gjerne @etterpå.\n" +
    "61: Nei. Men me hadde valet §mellom @båttur og fjelltur.\n" +
    "46: Ja.\n" +
    "I: Å, ja. Men, viss de var på båttur, var det noko fisking og slikt då, eller?\n" +
    "46: Nei, ikkje som eg hugsar.\n" +
    "61: Nei, ein rundtur rundt ¤øya for å @sjå @på ~fuglane, trur eg me eigentleg skulle.\n" +
    "46: Lundefugl.\n" +
    "I: Mm.\n" +
    "61: Ja.\n" +
    "I: Men her i fjorden då, er det noko fisking her?\n" +
    "46: Eg dreiv med det før, ja.\n" +
    "I: Ja, kva …?\n" +
    "46: Det var ikkje så mykje fisk.\n" +
    "I: Det var ikkje så mykje fisk? Nei?\n" +
    "46: Nei.\n" +
    "I: Kva fiska du med?\n" +
    "46: Med stong og pilk, stort sett.\n" +
    "\n" +
    "I: Ja.\n" +
    "61: Eg har nett @fått levering med mykje fisk @ifrå Vik.\n" +
    "I: Ja.\n" +
    "61: Ja. Så det er ein del no i @år faktisk i forhold til kva det har vore.\n" +
    "I: Veit du kva fiskeutstyr dei brukar?\n" +
    "61: Nei, det er sikkert #garn.\n" +
    "I: Ja.\n" +
    "61: Det er vel mest effektivt.\n" +
    "……………………………………..\n" +
    "46: Nye bru.\n" +
    "I: Kva synest du om denne der då?\n" +
    "46: Rasande fin. @På tide.\n" +
    "61: Ho passar godt inn.\n" +
    "46: Mm.\n" +
    "I: Ja, så ho er fin å sjå på med andre ord?\n" +
    "61: Ja, det tykkjer i §alle fall eg.\n" +
    "46: Fin å @sjå @på, og så blir det litt betre for @gåande og syklande og … Så var det vel ein\n" +
    "ingeniør som no driv … er borte i prosjektet der som har uttalt det at han ikkje køyrer over\n" +
    "¤brua viss han ser at det kjem ein lastebil @på <humrar> imot seg.\n" +
    "I: Nei.\n" +
    "46: Så det seier vel noko om standen som den gamle ¤brua er i.\n" +
    "61: Kjenner godt at det gyngar.\n" +
    "46: Ikkje godt å *koma over med verken §sykkel eller #barnevogn, for å *seia det slik, eller\n" +
    "@gåande @på vinteren.\n" +
    "………………………………………….\n" +
    "I: <nøler> Kva, kva trur de vil skje med, med sogndalsdialekten då i framtida?\n" +
    "46: Den blir nok meir og meir utvatna, tippar eg.\n" +
    "I: Kva meiner du med det?\n" +
    "46: Nei, me, desse her typiske ¤trekka trur eg forsvinn. Men eg kjenner no det @når eg pratar\n" +
    "med andre, eller folk @ifrå andre plassar at ein somme tider @må *leggja om for å, for å\n" +
    "*føla seg @forstått, faktisk.\n" +
    "I: Mm.\n" +
    "46: Og det er vel …\n" +
    "I: Kva er det dei ikkje skjønar då? Kva er det som er så vanskeleg?\n" +
    "46: Ja, sytten, atten og nitten er no #gjerne ikkje det enklaste alltid og noko meir, eg veit\n" +
    "ikkje.\n" +
    "61: Desse ao-~endingane er vel ikkje alltid like greie heller.\n" +
    "46: Nei, eg fekk vel beskjed … Me var til Oslo ein gong, og @då fekk eg spørsmål om me var\n" +
    "@ifrå Island så.\n" +
    "I: Ja, og då sa du?\n" +
    "46: Nei, @då sa eg at eg var det. <humrar> Nei, @då sa eg at eg var @frå Sogndal. @Då var\n" +
    "dei vel litt overraska ja. Det er nokre @år sidan.\n" +
    "I: Men er det viktig for deg å markera at du er ifrå Sogndal?\n" +
    "\n" +
    "46: Ja.\n" +
    "I: Kvifor er det viktig?\n" +
    "46: Nei, det er, har … No @får me oppslag om at Sogndal er best @på omdømme. Me @får,\n" +
    "det er gode, gode … Det er ein god plass, tenkjer eg. Det er ein fin plass å *vera @ifrå.\n" +
    "I: Mm.\n" +
    "46: Men det er no den plassen ein er oppvaksen. Så er det no der eg alltid har vore, og @då\n" +
    "har du no sjølvsagt ein (tilhøyrigheit) der, tenkjer eg.\n" +
    "I: For deg då?\n" +
    "61: Eg òg har jo budd her heile livet, men (muligens) det har noko med at ¤foreldra mine\n" +
    "ikkje er @herifrå. Så har eg ikkje det same, ja, kva skal me §kalla det? ¤Tilknytinga som\n" +
    "#gjerne du har.\n" +
    "46: Mm. Det kan nok *henda.\n" +
    "61: Det er ikkje så viktig for meg i §alle fall sjølv om eg merkar at det kjem jo med @¤åra det\n" +
    "òg. Ein føler jo etter kvart at ein er meir og meir tilknytt.\n" +
    "I: Ja.\n" +
    "61: Sjølvsagt.\n" +
    "I: Så røtene er her liksom?\n" +
    "61: Ja, det begynner no å *festa seg røter. <latter>\n" +
    "46: <latter>\n" +
    "I: <latter> Ja.\n" +
    "46: Jau, det er viktig.\n" +
    "I: Akkurat.\n" +
    "46: Eg har røter @på §alle sider langt baketter i tid.\n" +
    "61: Ja, det har ikkje eg, veit du.\n" +
    "46: Sikkert det som gjer det @då.\n" +
    "61: Ja.",

text_49og61: "I: Trefte de nokon jamaldringar før de begynte i skulen då?\n" +
    "61: Eg trefte jo ~naboane. Dei var jo @på alder med meg. Nokon var eitt @år yngre og nokon var eitt\n" +
    "@år eldre. Så var me ute og sykla og leikte i ~sandkassane.\n" +
    "49: Ja, hadde liksom ikkje nokon nabo @på min alder, så eg var mykje i lag med ¤søstera mi. Og så eg\n" +
    "hadde eigentleg ikkje nokon @på min alder før eg begynte i #barnehagen @når eg var fem.\n" +
    "I: Trefte du nokon andre utanom heime?\n" +
    "61: Ja, eg trefte @når eg var sånn parken.\n" +
    "I: Å, ja.\n" +
    "61: @Då trefte eg nokon, og så begynte eg i #barnehagen @når eg var fire eller noko sånn.\n" +
    "49: Trudde vel at det var litt sånn vakse og litt slik #gjerne å *begynna @på skulen.\n" +
    "61: Nei, eg høyrde ikkje så mykje om det, for dei eg var med var jo yngre enn meg og like gamle, så\n" +
    "fekk ikkje *høyra så masse.\n" +
    "I: Så var veldig spennande når de starta sjølve då?\n" +
    "61: Ja. <humrar>\n" +
    "49: <humrar> Ja.\n" +
    "I: <nøler> Kva gjorde de på i friminutta?\n" +
    "61: Me pleidde å *gøyma oss inni ~gangane, og så sprang me opp @på loftet @på Trudvang. Og der\n" +
    "gøymde me oss for ~lærarane så. Me sat der og var nervøse kvar gong dei kom opp ¤trappa.\n" +
    "49: Nei, me hadde sånn vanlege ting i ¤friminutta. Politi og tjuv, og så var det ikkje lov å *vera inni\n" +
    "~gangane. Så var litt inni ~gangane, og så vart jaga ut og. Gutar, nei. <humrar> Dei var vel med dei\n" +
    "òg, litt.\n" +
    "61: Dei spente som oftast §fotball …\n" +
    "49: Ja.\n" +
    "61: … i ¤friminutta. Det var liksom dei som regjerte @på fotballbana. Me hadde ikkje noko der å\n" +
    "*gjera.\n" +
    "……………………………………………………………\n" +
    "I: Viss de skal karakterisera rommet dykkar, kva vil de seia? Korleis ser det ut?\n" +
    "49: Lite, vil eg *seia. Overfylt av ting.\n" +
    "I: Kva, kva er det der?\n" +
    "49: Det er det vanlege. Det er seng og @klesskåp og §skatoll og §hyller.\n" +
    "\n" +
    "I: Er det heilt nake?\n" +
    "49: Ja, <humrar> heng eigentleg ikkje så mykje @på ~veggane.\n" +
    "61: Eg har overfylt rom med masse §hyller og plakatar, bilete @frå turar me har vore @på og. Og så\n" +
    "er det lys i §alle §hyllene.\n" +
    "I: Hugsar de nokon sånne turar de har vore på?\n" +
    "49: Mm, ja, var jo i Avdalen.\n" +
    "61: Og Osloturen.\n" +
    "49: Mm.\n" +
    "I: Ja, kan de fortelja litt om dei, ein av dei turane?\n" +
    "49: <nøler> Turen til Avdalen, det var jo ganske så mykje @gåing. <humrar>\n" +
    "61: Veldig bratt der.\n" +
    "49: Mm.\n" +
    "61: Så @då me endeleg kom opp, så samlast §alle ~jentene i fjøsen der, og så sat me der, og så fekk\n" +
    "me besøk av ~gutane og så gjekk me @på besøk til dei mens ~lærarane sat inni hovudhuset.\n" +
    "49: Så vakna eg veldig tidleg om morgonen, sånn ¤sekstida.\n" +
    "61: Me sov vel ikkje i det heile teke.\n" +
    "49: Nei, <humrar> trur det var mange som ikkje sov i heile teke. Så dreiv og prøvde å *vekkja\n" +
    "~lærarane klokka seks, sju om morgonen, men det greidde ikkje dei. Det var sånn §bjølle inni huset\n" +
    "@då, sant, som dreiv og ringde @på. Men det hjelpte ikkje.\n" +
    "I: Kva elles var det de gjorde på den turen?\n" +
    "61: Me skulle til Vettisfossen for å @sjå på den, og så skulle me *samla planter til (naturfagen) og\n" +
    "sikkert berre ha det sosialt. Og så var me @på Årdal verk samtidig. Skulle me *læra om\n" +
    "kjemiprosessar eit eller anna. Eg hugsar ikkje kva det handla om.\n" +
    "………………………………………………………..\n" +
    "I: Ja, er det noko med naturen som gjer det spesielt å bu her eller?\n" +
    "49: Det er jo fjord og §fjell <humrar>\n" +
    "I: Mm.\n" +
    "49: … og sånn. Typisk norsk.\n" +
    "I: Ja, kva meiner du om det?\n" +
    "61: Nei, eg veit ikkje om eg meiner noko spesielt.\n" +
    "49: <humrar>\n" +
    "61: Det er er jo mogelegheit for å @gå @på §fjellet @på om sommaren og vinteren og … Så kan du\n" +
    "*vera @utpå med @båt og …\n" +
    "……………………………………………..\n" +
    "\n" +
    "I: Kven sine meiningar betyr mest for dykk? Kven høyrer de mest på trur de sjølve?\n" +
    "49: ¤Foreldra.\n" +
    "61: ~Venene sine meiningar tel ganske masse.\n" +
    "61: Det spørst kva det er for noko @då.\n" +
    "I: Mm.\n" +
    "49: Det kan jo, kan *vera vener òg @då, enkelte gonger, og ¤foreldra enkelte gonger.\n" +
    "I: Mm.",

text_50og62: "I: Hugsar de nokon episodar ifrå de gjekk i barneskulen?\n" +
    "50: Eg hugsar no noko. Kva eg hugsar @då? Eg hugsar at me leikte politi og tjuv med\n" +
    "~gutane, ~Jentene mot gutar, så gutar skulle ta ~jentene, og så var jentelus og sånne ting. Og\n" +
    "så hugsar eg me klatra opp i ¤trea og gøymde oss der. Og ein gong så §alle ~gutane fanga\n" +
    "meg fordi ... Eg veit ikkje kvifor, men eg klarte alltid å *koma meg unna. Så heldt dei fast, så\n" +
    "heldt liksom ~armane mine og ~føtene mine ut. Og så heldt liksom ein og ein der og to og så\n" +
    "ein i kvar fot, og så bar meg ut og heiv meg i snøen. Men eg klarte å *slita meg laus, for eg\n" +
    "beit ein, trur eg.\n" +
    "62: <humrar> Eg hugsar at @frå me gjekk i femteklasse, så fekk me lov til å @gå i vegen. Me\n" +
    "slapp å *vera @på skulen sitt område i ¤friminutta. Og det var stort @då.\n" +
    "I: Kva, kva gjorde de på i friminutta?\n" +
    "62: Me leikte jo politi og tjuv og sånn. Og så var me ein del med #barnehagen.\n" +
    "I: Å?\n" +
    "62: Dei @småe ~ungane. Gjekk og snakka saman og … Det var stort sett det me dreiv med.\n" +
    "I: Trefte de nokon som var på dykkar alder før de begynte i skulen?\n" +
    "50: Ja.\n" +
    "62: Ja, #barnehagen.\n" +
    "50. Heime og i #barnehagen.\n" +
    "I: Kjende de kvarandre godt frå før?\n" +
    "50: Nei, me møttest i @åttande klassen.\n" +
    "62: <latter>\n" +
    "50: Eller, eigentleg i sjuande @då. Me hadde sånn inviterte ~Norane til skulen @vår. Og så\n" +
    "blei me inviterte ut i ~Norane.\n" +
    "I: På bussen, skjedde det noko der? Sat de og prata, eller kva gjorde de på?\n" +
    "62: Ikkje noko som eg hugsar.\n" +
    "50: §Kalla litt. Nei, det var jo, me sat bakarst, og det var sånn skikkeleg stor status å @stå\n" +
    "bakarst i bussen @då.\n" +
    "I: Ja.\n" +
    "62: <humrar>\n" +
    "50: Fordi at det var av og til sånn lasterom baki der du kan *leggja ski og sånn. Der stod me\n" +
    "bak og @såg ut, og så dreiv me og banna og …\n" +
    "62: <humrar>\n" +
    "50: … lærte av dei eldre ~ungane. <humrar>\n" +
    "…………………………………………………..\n" +
    "I: Kva hugsar du frå då? Kva var du med på?\n" +
    "50: Eg var vel … Me var ikkje med @på så masse, eigentleg. Det var … Me var liksom ein\n" +
    "gjeng som var heime, og så gjorde me ~leksene og så var det rett ut @etterpå, ut og leika i\n" +
    "gata. Me var sånn veldig mange @på min alder rundt der som eg bur. Så @då, så ein gong så\n" +
    "om sommaren så leikte me at me var i drillen. Me hadde sånne pinnar med sånn folie @på\n" +
    "~kantane. Så gjekk me rundt og drilla etter sånn korpsmusikk. Og så spelte me §fotball og\n" +
    "sånn turnering oppe @på ballbana der som eg bur, eller @på #Kjørnes @då. Så var det sånn\n" +
    "\n" +
    "turnering kvar sommar. Så var me med der. Så vann me alltid me ~jentene @då. Så hadde me\n" +
    "… Me var alltid dei som kom med sånn snåle ting, hadde @på oss hattar eller slips eller sånne\n" +
    "ting. Det var litt kult. <humrar>\n" +
    "I: Kjem du på noko som du vil fortelja?\n" +
    "62: Og så spelte eg fiolin og #althorn.\n" +
    "I: Ja.\n" +
    "62: <humrar> Var med i korps.\n" +
    "50: Har du spelt #althorn?\n" +
    "62: <humrar> Ja, eitt @år.\n" +
    "50: Eg har spelt baryton.\n" +
    "62: I korps.\n" +
    "50: I eitt @år, men eg spelte ikkje i korps.\n" +
    "62: Eg spelte i korps eitt @år.\n" +
    "50: Å.\n" +
    "62: Til Larvik @på tur, Farrisfestivalen. Det var morosamt det.\n" +
    "I: Ja, kva som var morosamt? Kva hugsar du?\n" +
    "62: Eg hugsar me @låg @på ein skule. Mange i eitt klasserom. Så gjekk me rundt og spelte i\n" +
    "~gatene i Larvik.\n" +
    "…………………………………….\n" +
    "50: Det hadde vore langt å @gå, fem kilometer, for eg bur @på #Kjørnes. Og det er\n" +
    "byggjefelt utfor her, men med bussen så tok det fem minutt. Ganske fort.\n" +
    "I: <nøler> Hadde du, du hadde ikkje så kort skuleveg du heller då?\n" +
    "62: Nei, er no ikkje veldig lang @då, med fem kilometer eller noko sånt.\n" +
    "…………………………………………\n" +
    "I: Var det noko radioprogram de følgde med på når de var, var småe som var interessant?\n" +
    "62: Trur ikkje eg har høyrt @på radio noko særleg.\n" +
    "I: Musikk då?\n" +
    "62: Det er jo dei siste @¤åra @då, @når eg blei eldre, @på Alvin og …\n" +
    "……………………………………\n" +
    "I: Vil de bli oppfatta som å vera sogndøler?\n" +
    "50: Stolt over ¤bygda si. Det må ein *vera. Ein kjem trass alt @herifrå. Det er fint @utpå\n" +
    "bondeland … Det er betre å bu her enn å bu i ein by.\n" +
    "62: Trur eg òg.\n" +
    "50: Eigentleg, ja. Betre miljø.\n" +
    "I: Kva, kva du er stolt over, her?\n" +
    "50: Stolt over §alle §¤fjella @våre,\n" +
    "62: <latter>\n" +
    "50: … som me kan @gå opp @på og … Opp i Dalen. <humrar> @På ski. Opp @på\n" +
    "§Anestølen og *kosa oss der oppe med kaffi og kaker. <humrar> Nei, det er no fin grend. Eg\n" +
    "er no bra stolt over fjorden. Den er veldig flott fjord.\n" +
    "62: Ja.\n" +
    "I: Ja, kva vil du dra fram då? Kva er typisk for naturen her?\n" +
    "62: §Fjell, fjord.\n" +
    "\n" +
    "I: Kva skulle de ønskja var annleis i Sogndal?\n" +
    "62: <humrar> Betre tilbod for oss ungdomen.\n" +
    "I: Kva tilbod?\n" +
    "62: Ein plass der me kan @gå og berre *vera liksom utan at me @må *gjera noko spesielt.\n" +
    "50: Utan at det @må *kosta oss noko.\n" +
    "62: Ja.\n" +
    "50: Det er svært … Det er jo Solhov @då, men der er det liksom ingen som gidd å @gå. Og\n" +
    "så driv dei og pussar opp der no, i staden for at me eigentleg kunne ha @fått ¤studentkroa\n" +
    "som er liksom midt i sentrum.",

text_51og60: "I: Hugsar de nokon episodar frå de var mindre?\n" +
    "51: @Då me gjekk @nedpå Trudvang så pleidde me ofte å *spela §fotball @då om\n" +
    "morgonen. Så var det om og *gjera å *koma først ned for @då var det sånn krangel om første-\n" +
    "og andrebana så ... @Då sykla me fort ned, så var det om og *gjera å *koma først hit til bana.\n" +
    "I: Trefte de nokon jamaldringar før de starta i skulen?\n" +
    "51: Eg har no @gått @på skulen med besteveninna mi @då. Dei har eg @gått med i\n" +
    "#barnehagen heilt sidan eg var liten. Dei @går eg @på skulen med @endå, tre stykke.\n" +
    "I: Ja, fekk dek høyra mykje om skulen før de begynte sjølve då?\n" +
    "60: Nei.\n" +
    "51: Eg fekk *høyra at det var så strenge elevar, nei strenge lærarar der og at … Men miljøet\n" +
    "var eigentleg ganske bra. <uforståeleg>\n" +
    "60: Eg fekk *høyra at det var så morosamt å @gå @på skule liksom slik. <humrar>\n" +
    "I: Kan de fortelja om nokon skuleturar de var på eller noko slikt?\n" +
    "51: Eg veit ikkje om me var så mange, @på mange skuleturar. Det trur eg ikkje me var.\n" +
    "60: Me var til Bergen.\n" +
    "51: Ikkje me i §alle fall.\n" +
    "60: Me var til Bergen.\n" +
    "51: Ja, Bergen var me til vel i sjuande, nei sjette klassen eller sjuande klassen. Tok me\n" +
    "@båten til Bergen, så var me der resten av dagen. Tok me heim att @når klokka var sånn\n" +
    "fem. Handla me heile dagen [då].\n" +
    "60: Å, ja.\n" +
    "51: @Då me tok Fløyen.\n" +
    "60: Me kunne *velja §mellom sånn Fløyen og Akvariet. Elles kunne me @få @gå og *handla\n" +
    "heile ¤tida.\n" +
    "51: Mm.\n" +
    "…………………………………….\n" +
    "I: Var det noko de opplevde som urettferdig? Nokon episodar de kan hugsa som var\n" +
    "urettferdige?\n" +
    "50: Ja, hugsar alltid eg fekk ¤skulda i klassen viss andre, andre prata, og så var det eg som\n" +
    "fekk ¤skulda. @Måtte ikkje *springa i ~gangane og … Pleidde de å *reisa opp i, opp i desse\n" +
    "der ~gangane der ~musikkgreiene? Der hite?\n" +
    "60: Ja. [Sat der] oppe me.\n" +
    "51: Ja, så gjekk me og sprang der. Det @får me alltid kjeft for, hugsar eg.\n" +
    "I: Kva gjorde de då?\n" +
    "60: Me gjorde no eigentleg ikkje @på så mykje. Me sat no berre ute, i ¤friminutta liksom. Me\n" +
    "gjekk rundt og rundt skulen.\n" +
    "51: @Såg @på ~yrkisane som kom nedover og russen.\n" +
    "60: Å, ja.\n" +
    "51: Det var moro @når russen kom nedover.\n" +
    "60: Midttimen.\n" +
    "51: Mm.\n" +
    "\n" +
    "……………………………………..\n" +
    "I: Når nokon i klassen hadde bursdag, gjorde de noko spesielt då?\n" +
    "51: Me song vel kanskje i klassen @vår, trur eg.\n" +
    "60: Ja, me song sånn song.\n" +
    "51: Og så var det, ja. Det var berre synging.\n" +
    "I: <nøler> Var det noko spesielt radioprogram de kunne høyra på når de var mindre?\n" +
    "60: Nei, høyrde ikkje @på radio.\n" +
    "51: #Barnetimen. <humrar>\n" +
    "60: Nei, men eg høyrde ikkje @på radio så mykje.\n" +
    "51: Nei, eg gjorde vel eigentleg ikkje det @då\n" +
    "I: Lærte de noko framandspråk på skulen då?\n" +
    "60: Engelsk.\n" +
    "51: Engelsk, begynte me vel med i andre- eller tredjeklassen, trur eg.\n" +
    "I: Er de av den typen som er tidleg oppe om morgonen og har god tid?\n" +
    "60: Nei.\n" +
    "51: Nei.\n" +
    "60: Seint opp og dårleg tid.\n" +
    "51: Ja, av og til faktisk, men viss du legg deg tidleg om kvelden, så klarer du eigentleg å @stå\n" +
    "opp tids nok og *vera i godt humør [i alle fall.]\n" +
    "I: Korleis er ein vanleg sånn, vanleg dag for deg?\n" +
    "60: Ja, sånn viss eg @må @på skulen, så @står eg opp @når ho er sånn ti over @åtte,\n" +
    "<humrar> Så @går eg @på badet og fiksar meg, og så @går eg.\n" +
    "I: Er det noko hærverk i Sogndal?\n" +
    "51: Hærverk?\n" +
    "I: Slåssing og …?\n" +
    "51: Ja, det er det masse av @på ~laurdagane.\n" +
    "60: Folk er §fulle.\n" +
    "51: Ja. @Då klikkar dei for ingenting, veit du. Det er så lett.\n" +
    "I: Kva tilbod er det for ungdomane her i …?\n" +
    "60: Ingenting.\n" +
    "51: Jau du har jo Meieriet @då for tiandeklassen. I Solhov.\n" +
    "60: Eg veit ikkje eg eigentleg, men før så var det noko sånn #barnedisko @då, noko sånn.\n" +
    "51: Ja.\n" +
    "60: Nedpå #barneskulen. Så det verkar litt sånn; barnsleg eller sånn …\n" +
    "51: Mm. Det er jo mest for @åttande @då. Skogly.\n" +
    "60: Ja, men @då @må du *reisa heilt @på Kaupanger.\n" +
    "51: Ja.\n" +
    "60: Det er veldig kjedeleg.\n" +
    "51: @Då trur eg ikkje det er noko anna.\n" +
    "I: Brukar de kulturhuset ofte?\n" +
    "60: Einaste @når me er @på kino.\n" +
    "51: Ja, er berre det.\n" +
    "I: Er det noko de er stolte over her i Sogndal, då? Kva de liksom vil seia …?\n" +
    "\n" +
    "51: ¤§Fjella, dei er eg stolt over.\n" +
    "60: Fine §fjell.\n" +
    "51: Mm. Det er det.\n" +
    "I: Kva synest de når de høyrer ein sogning på radio eller fjernsyn då?\n" +
    "51: Det høyrest merkeleg ut.\n" +
    "60: Ja. Det høyrest heilt løye ut.\n" +
    "51: Mm.\n" +
    "I: Kvifor det?\n" +
    "60: Nei, for ... Nei, eg veit ikkje eg. Du er ikkje vant til å *snakka slik på TV og slik.\n" +
    "51: Nei.\n" +
    "60: Snakkar liksom @bokmål og slik @då.\n" +
    "51: Folk pleier faktisk å *leggja om ¤dialekta si @når dei kjem @på TV og radioen. Det har\n" +
    "eg lagt merke til i §alle fall.\n" +
    "60: Det er jo nokon @frå Leikanger som er sånn vêrdamer og slik, sant?\n" +
    "I: Ja.\n" +
    "60: Dei har jo lagt om.\n" +
    "51: Ja, sånn som «Hotel Cæsar».\n" +
    "60: Ja.\n" +
    "51: Sekretæren til Hildring.\n" +
    "60: Hildring.\n" +
    "51: Ho kjem jo @frå Fardal, eller i §alle fall @frå Sogndal, trur eg.\n" +
    "60: Mm.\n" +
    "51: Sånn modellgreier, og så hadde ho … høyrest ut som ho snakkar blanding §mellom eit\n" +
    "eller anna anna og … Hø prøver å *snakka @bokmål, men ho klarer ikkje det.",

text_51pog59p: "I: Sogndal har jo vakse valdsamt sidan de gjekk på ungdomsskulen.\n" +
    "59: Mm.\n" +
    "I: Er no, er ikkje det over sju tusen innbyggjarar her no? Har no forandra seg litt.\n" +
    "59: Ja, det har det.\n" +
    "51: Mm. Forandra seg veldig masse. Jau, du @får tomt her @på #Kjørnes.\n" +
    "59: Men @då har du ikkje @råd til å *byggja. <humrar>\n" +
    "51: Nei, sant. Men så @får du vel òg … Jau, du kan @få framme i Dalen @då, men det er jo,\n" +
    "det er jo avstand @då.\n" +
    "I: Men det er no, no lagar dei jo sykkelveg og … så det er jo lettare å koma seg bort her.\n" +
    "59: Ja, eg er jo vaksen opp @på #Kjørnes òg, så for min del så synest eg det var heilt greitt.\n" +
    "51: Litt utanfor.\n" +
    "59: Litt utanfor.\n" +
    "51: Så @går det buss og så, ja. Det er ikkje så langt heller no til #Kjørnes.\n" +
    "59: Nei.\n" +
    "51: For liksom #Kjørnes er liksom no nesten det nye sentrum òg sant. @Når det kjem så\n" +
    "masse hus som det gjer her òg.\n" +
    "I: Mm.\n" +
    "59: Det eg synest er kjekt her, er at det er eit nybyggjarfelt, sant. Så §alle i rekka @vår @på\n" +
    "@åtte er jo i same situasjon.\n" +
    "51: Mm.\n" +
    "59: §Alle er @på same altså, ja … ~Ungane er @på same alder og …\n" +
    "I: Mm.\n" +
    "59: Det er ikkje … Eg seier eg er @frå Sogndal. Eg seier ikkje at eg er @frå #Kjørnes.\n" +
    "51: <humrar> Er @frå #Kjørnes, ja.\n" +
    "59: Nei.\n" +
    "I: Nei. <humrar>\n" +
    "51: Kvar er det hen liksom?\n" +
    "59: Sånn som dei som er @frå Kaupanger seier at dei er @frå Kaupanger, sant …\n" +
    "51: Ja.\n" +
    "59: … sjølv om det er i Sogndal kommune.\n" +
    "I: Ja.\n" +
    "51: Mm.\n" +
    "59: Men eg seier at eg er @frå Sogndal, eg er ikkje @frå #Kjørnes.\n" +
    "51: Nei.\n" +
    "59: Men eg veit ikkje …\n" +
    "51: Folk hadde berre vore forvirra, lurt @på kvar det var #Kjørnes liksom. <humrar>\n" +
    "59: Men eg veit ikkje kva dei, dei som bur i Sogndal kva dei §kallar oss. <latter> Det kan jo\n" +
    "*henda dei <uforståeleg>\n" +
    "I: Ja.\n" +
    "51: Dei som bur @på #Kjørnes, ja.\n" +
    "59: Mm.\n" +
    "\n" +
    "………………………………\n" +
    "59: Eg er litt skeptisk til at dei har flytta sjette og sjuande klasse opp @på @Kvåle, fordi at\n" +
    "det blir … Det der skiftet §mellom å @gå @ifrå #barneskulen og opp @på ungdomsskulen …\n" +
    "Eg hugsar at det var veldig sånn …\n" +
    "51: Mm.\n" +
    "59: … stor skifte. Det var veldig spesielt.\n" +
    "51: Mm.\n" +
    "59: Så eg berre tenkjer at ~ungane blir store litt …\n" +
    "51: fort.\n" +
    "59: … tidleg. Eller liksom at dei skal …\n" +
    "I: Mm.\n" +
    "51: Mm. At det @går litt fort?\n" +
    "59: Ja.\n" +
    "51: Mm.\n" +
    "59: At dei plutseleg der me var @når me begynte @på ungdomsskulen at dei er der kanskje\n" +
    "§allereie i sjette klasse. Sjølv om …\n" +
    "51: Men dei er jo mykje… Har ikkje du merka? <uforståeleg> Altså eg ser jo berre @på dei\n" +
    "som er tretten @år gamle no.\n" +
    "59: Mm.\n" +
    "51: Som har mobil, altså før det òg.\n" +
    "59: Mm.\n" +
    "51: @Når eg var tretten @år, eg hadde ikkje mobil. Eg hadde jo ikkje … Altså, altså\n" +
    "~prioriteringane er heilt annleis no enn det dei var før, sant?\n" +
    "59: Ja.\n" +
    "51: Og at det er forventning at du skal *klara deg mykje tidlegare no enn det du, altså … Det\n" +
    "merkar jo eg. Det ser eg jo berre …\n" +
    "…………………………..\n" +
    "59: Men det er jo ein sving unna.\n" +
    "51: Det er jo ikkje for langt unna sentrum.\n" +
    "59: Ein sving unna det dei §kallar solsida, sant?\n" +
    "51: Mm. Og solsida er jo sentrum i Sogndal no.\n" +
    "59: Ja, det er jo det dei seier.\n" +
    "51: Det er jo ikkje …\n" +
    "59: Men Bjelde det er Dalen.\n" +
    "51: Notsete. …\n" +
    "59: Ja.\n" +
    "51: @Då snakkar me heller Dalen.\n" +
    "59: Ja, men eg tenkjer …\n" +
    "51: Nei, veit du.\n" +
    "59: Og oppi der er det så mange @småbarnsfamiliar òg.\n" +
    "51: Mm.\n" +
    "59: Så dei burde jo absolutt ha takka ja til …\n" +
    "51: Ja.\n" +
    "\n" +
    "59: … både utbygging, for dei har lyst å *skilja ut tomter, og så *byggja ein #barnehage.\n" +
    "I: Mm.\n" +
    "59: Og det er jo kjempe … Eg synest det er trist eg at dei bygde desse ~høgblokkene, for eg\n" +
    "tenkte at liksom …\n" +
    "I: Men tenkte …\n" +
    "59: … for bygde, bygde …\n" +
    "51: Der kunne det ha vore ein #barnehage.\n" +
    "I: Hen meiner du no?\n" +
    "51: Ulvahaugen du tenkjer @på?\n" +
    "59: Ja, eg tenkjer både Ulvahaugen og …\n" +
    "51: Der kunne jo i staden for og bygt …\n" +
    "59: … nede på Coop Extra der òg.\n" +
    "59: Eg synest det er trist at …\n" +
    "51: Ja.\n" +
    "59: … det er kome høgblokker i Sogndal,\n" +
    "51: Ja, sant ja. Ja.\n" +
    "59: I ¤bygda.\n" +
    "I: Mm.\n" +
    "59: Og no skal dei jo *koma med det @på\n" +
    "51: @På Lerumskaien òg, ja.\n" +
    "59: Lerumkaien òg. Ja.\n" +
    "51: Ja, der kunne ha vore i Ulvahaugen.\n" +
    "59: Mm.\n" +
    "51: I staden for og bygt den siste ¤blokka, så kunne dei hatt #barnehage der.\n" +
    "59: Ja, mm.\n" +
    "…………………………………………….\n" +
    "51: ¤Brua.\n" +
    "59: Å, det er så kjekt at dei er komne i gang.\n" +
    "I: Ja, kva synest de om den @då?\n" +
    "51: Det er jo flott.\n" +
    "59: Eg synest den er fin eg.\n" +
    "51: Ja, eg synest ho er flott eg òg.\n" +
    "59: Ja.\n" +
    "51: Ja, du kjem ut @ifrå tunellen og du berre ser ¤brua.\n" +
    "59: Ja.\n" +
    "51: Ho er skikkeleg flott altså.\n" +
    "59: Er det.\n" +
    "51: Ja, det må eg *seia. Eg ser jo at hin, er jo, det er nett så vidt eg torer å *køyra over ho.\n" +
    "<humrar>\n" +
    "59: Mm. Han er §falleferdig, altså.\n" +
    "51: Ja, han er det altså.\n" +
    "59: Ja.\n" +
    "51: Rett og slett.\n" +
    "\n" +
    "59: Men eg har jo òg §trilla til Sogndal nokre gonger no og <humrar> …\n" +
    "51: Har du §trilla over den ¤brua der?\n" +
    "59: Eg har §trilla over den ¤brua.\n" +
    "51: Det hadde eg aldri gjort.\n" +
    "59: Det var ikkje noko kjekt.\n" +
    "51: Nei.\n" +
    "59: For ¤vogna mi er ganske brei òg.\n" +
    "I: Ja.\n" +
    "59: Så det fortauet @på ¤brua, den (funkar) ikkje.\n" +
    "51: Men du klarer jo knapt å @gå der.\n" +
    "59: Ja.\n" +
    "51: Viss du skal ha ein §sykkel @då …\n" +
    "59: Ja.\n" +
    "51: … over der, så @må du nesten ha §sykkelen uti vegen.\n" +
    "…………………………………..\n" +
    "I: Kva du set pris på med …med bortsett frå familien då, med å bu her?\n" +
    "51: Eg har jo mange av gamle ~vennene mine som fortsatt bur her, som er komne tilbake òg\n" +
    "etter dei har liksom vore vekke. Så, eg set pris @på det, og det at liksom at ~ungane, og dei\n" +
    "@går i #barnehage her … Og det er, det er berre litt sånn spesielt, fordi at du @får liksom, du\n" +
    "blir liksom knytt, knytt mykje meir til dei som … ja #~barnehagane og ~skulane og alt, sant,\n" +
    "fordi … Men det er jo mykje som har forandra seg @på Trudvang sidan …\n" +
    "I: Ja.\n" +
    "59: Mm.\n" +
    "51: … me gjekk der, for å *seia det sånn @då.\n" +
    "I: Ja, kva er annleis no?\n" +
    "51: Nei, altså <sukkar> … Men dei er mykje meir flinkare til å @sjå ~ungane no, synest eg,\n" +
    "enn det dei var før.\n" +
    "59: Følte sånn i klassen @vår, så hadde me veldig innprenta at §alle skal *vera med §alle.\n" +
    "51: Mm.",

text_52pog53p: "I: Så de var ikkje, har ikkje gått på førskule eller noko sånt i hop?\n" +
    "52: Nei, eg gjekk …\n" +
    "53: Jau, det gjorde me vel.\n" +
    "52: Eg gjekk i #barnehagen @på @Ylvisåker.\n" +
    "53: Ja, du og den der førskule …\n" +
    "52: Og så hadde me vel @~seksåringane før skulen.\n" +
    "I: Var du i noko sånt?\n" +
    "53: Gjekk i Lomelde #barnehage. Gjorde du?\n" +
    "52: Nei, eg gjekk i @Ylvisåker.\n" +
    "53: Ja, ja eg gjekk @på Lomelde.\n" +
    "52: Og så gjekk eg @på gamle #barneparken bak Domus.\n" +
    "53: Ja, for me hadde eigen #barnehage ute @på Lomelde nesten fram til eg begynte @på\n" +
    "skulen. Eg trur #gjerne eg var eitt @år @innpå @Ylvisåker.\n" +
    "52: Så @når me var komne inn her, så vart det jo litt, #gjerne litt meir struktur @då, for me\n" +
    "@måtte … me var no nesten tretti stykke i kvar klasse. Så var det å *finna likesinna folk @då\n" +
    "i ~klassane liksom @då. Det gjekk no eigentleg ganske kjapt.\n" +
    "I: Ja, drukna de blant dei andre, eller heldt de i hop då òg.\n" +
    "53: Nei, me var med andre folk @då, trur eg.\n" +
    "52: Me var vel med andre @då …\n" +
    "53: Mm.\n" +
    "52: … og så vel #gjerne nokre ettermiddagar og helgar og sånn …\n" +
    "53: Ja.\n" +
    "52: … me var ute og spelte §fotball …\n" +
    "53: Spelte §fotball.\n" +
    "52: … @på @Ylvisåker.\n" +
    "I: Ja, så når de skulle på fritidsaktivitetar, så fór de inn til Sogndal då òg, eller?\n" +
    "52: Me hadde vel ungdomsklubben i ~Norane?\n" +
    "53: Ungdomsklubben, ja. Den hadde me ei stund.\n" +
    "52: Kvar, kvar fredag eller noko.\n" +
    "53: Mm. Men elles var det her inne det skjedde.\n" +
    "I: Så då var det til og køyra dykk då, eller?\n" +
    "53: Ja, eller buss.\n" +
    "52: Eller buss.\n" +
    "53: Mm., tok buss.\n" +
    "52: @Når den ein gong gjekk, eller berre bli att etter skulen og slikt @då. For eg spelte jo\n" +
    "§fotball for Sogndal. Mens eg gjekk @på ungdomsskulen og slikt så, @då reiste jo eg berre\n" +
    "opp til besta. Så @åt eg middag og slikt der, og så reiste eg @på trening @etterpå eller kamp\n" +
    "eller det, det som @måtte *vera. I staden for å ta skulebussen heim att og så snu i ¤døra og så\n" +
    "*reisa heim, inn att liksom.\n" +
    "I: Mm. Korleis var det med deg? Var du på noko?\n" +
    "\n" +
    "53: Eg begynte @på §fotball, men eg slutta ganske fort. <nøler> Eg hadde eigentleg ikkje\n" +
    "nokon fritidsaktivitetar her inne i Sogndal slik sett. Eg hadde venner @då, sjølvsagt, men\n" +
    "@då vart eg køyrd eller tok buss så.\n" +
    "I: I dag er det vel veldig mykje de kan gjera på, desse her som veks opp?\n" +
    "52: Ja, altså, det er jo masse fritidsaktivitetar. Eg ser no sånn @på vinteren @då, så er det jo\n" +
    "ski, skikarusell oppi §Hollekve. Har dei idrettsskule, og så har dei §handball, §fotball,\n" +
    "klatring, så det er jo … Og så har dei Barnas Turlag. Dei er flinke å *reisa ut i skogen og\n" +
    "§¤fjella og sånt @året rundt eigentleg.\n" +
    "53: Eg trur det er fleire og fleire som reiser inn her med ~ungane sine. Eg trur … det er ikkje\n" +
    "så mykje fotballag att uti ~Norane.\n" +
    "I: Å, dei har ikkje eige lag lenger?\n" +
    "52: Nei, ikkje, ikkje … Dei har vel #gjerne dei §aller minste aldersbestemte.\n" +
    "53: <nøler> Dei hadde det før i (hvert fall). Men no er det fleire og fleire som reiser inn her\n" +
    "med ~ungane. Eg veit ikkje. Sikkert enklare.\n" +
    "……………………………..\n" +
    "I: Det er ikkje så lett å få einebustad i sentrum lenger. Dei byggjer ikkje så mykje av det.\n" +
    "52: Nei, @då @må du …\n" +
    "53: Det trur eg ikkje er lov faktisk å *byggja …\n" +
    "52: … ut @på #Kjørnes, så @får du vel næraste einebustad der du kan *byggja.\n" +
    "53: I sentrum er det ikkje lov å *byggja einebustad lenger. @Då @må du utom.\n" +
    "I: Så viss Sogndal skal veksa endå meir, kvar kan dei reisa hen då for å seia det sånn?\n" +
    "52: Nei, det blir vel utover …\n" +
    "53: Oppover mot Dalen eller bort, bort mot #Kjørnes @då.\n" +
    "52: Ja, eller utover mot ~Norane.\n" +
    "53: Ja, ~Norane.\n" +
    "I: Ja.\n" +
    "52: No blir det jo …\n" +
    "53: Og det blir litt bra.\n" +
    "52: … @kommunesamanslåing @då, så @då blir det vel noko veksing i §alle retningar.\n" +
    "…………………………………\n" +
    "53: Ja, eg ser den nye ¤brua her.\n" +
    "I: Ja, kva synest du om den?\n" +
    "53: Nei, eg synest det er ei fin bru, eg.\n" +
    "52: Det er @på høg tid òg …\n" +
    "53: @På høg tid er det òg, ja. Så …\n" +
    "52: … at dei gjer noko. For livsfarleg å @gå over denne gamle ¤brua @når at det kjem store\n" +
    "køyretøy der, så ristar jo heile ¤brua.\n" +
    "53: Så er det no ein fin boge @på nye ¤brua.\n" +
    "I: Mm. Valdsamt smalt fortau på den gamle (i hvert fall).\n" +
    "53: Ja. Det kan vel knapt §kallast eit fortau, så det.\n" +
    "…………………………………\n" +
    "I: Ja, dei hadde kamp i går, sant?\n" +
    "53: Ja.\n" +
    "\n" +
    "52: Ja.\n" +
    "I: Kven var det dei spelte mot @då?\n" +
    "52: Hødd.\n" +
    "53: Tapte to ein mot Hødd.\n" +
    "I: Gjorde dei det?\n" +
    "52: Ja, viss dei slepper til fjorten- @femtenåringar så, så …\n" +
    "53: Ja.\n" +
    "52: … er det vel berre nett for og så gje dei litt (matching).\n" +
    "53: Mm.\n" +
    "52: @Då er ikkje resultatet så forferdeleg nøye.\n" +
    "53: Skal ikkje *lesa så mykje ut av ¤resultata i treningskampar.\n" +
    "I: Dei skulle vel prøva ut litt, ja, men er de og ser på kampar? Er de opptekne av det?\n" +
    "52: Veit ikkje …\n" +
    "53: Førre sesong var eg @på ein god del heimekampar.\n" +
    "52: Ja.\n" +
    "53: Ikkje @på hausten, men eg var @på @våren og sommaren, så var eg @på nesten §alle.\n" +
    "I: Men er det viktig at me har eit lag som er så gode at dei er oppe i eliten, eller? Har det noko\n" +
    "å seia for oss?\n" +
    "52: Det er jo …\n" +
    "53: For ¤profileringa av ¤bygda så er det no fint i §alle fall.\n" +
    "52: Ja, så er det bra for ~småklubbane òg.",

text_53og63: "I: Kva gjorde de på i friminutta når de var komne i skulen?\n" +
    "63: Sykla @på sånne trehjulssyklar og klatra i tre, tok eple @frå ¤trea.\n" +
    "I: Hugsar du nokon spesielle episodar, sånne korte?\n" +
    "63: <nøler> Ja, eg og ein kamerat dreiv og balanserte @oppå eit sånt gjerde, og så hadde me\n" +
    "sånne pinnar med sånne tornar @på, og så dreiv og fekta @då så.\n" +
    "53: Me spelte §fotball, og kvar veke så hadde me ein klassekamp.\n" +
    "I: Var jenter og gutar i lag, eller korleis var det?\n" +
    "53: Ja.\n" +
    "63: Ja, @då var det jente- og gutelag. I §alle fall [hjå oss.]\n" +
    "53: Mm.\n" +
    "I: Men elles i friminutta då, var de i lag då òg?\n" +
    "63: Ja.\n" +
    "I: Var ikkje noko dei dreiv med og de dreiv med?\n" +
    "63: Jau, det var jo det @då men.\n" +
    "I: Kva då?\n" +
    "63: Dei hoppa jo ofte strikk og sånn. Kanskje dei spelte §ball @på vegg.\n" +
    "53: Mm. Jau, hoppa paradis, men nokre blei ofte med @på §fotball, ~elevane.\n" +
    "I: Hadde de noko sånn, noko spesielt de gjorde når nokon i klassen hadde bursdag?\n" +
    "63: Ja, @då skulle dei @stå @oppå pulten, og så skulle me *syngja for dei. Heile klassen\n" +
    "skulle *syngja for den som hadde bursdag.\n" +
    "I: Å, ja.\n" +
    "53: Ja, han stod @oppå bordet. Song me til han.\n" +
    "I: De stod rundt de då?\n" +
    "63: Nja.\n" +
    "53: <nøler>\n" +
    "63: Me berre sat @på pulten sånn og song, ja.\n" +
    "I: Når de starta skulen om morgonen då. Hadde de nokon spesielle ritual, noko de gjorde fast\n" +
    "då?\n" +
    "63: Ja, me spelte ofte §ball @på vegg før det ringde inn @då.\n" +
    "I: Men når de slutta om dagen då?\n" +
    "63: <nøler> Nei, det var ikkje noko særleg. Eg venta av og til @på ¤søstera mi @når ho var\n" +
    "ferdig halv tre og sånn, og @då spelte eg §fotball og, ja.\n" +
    "I: Men i klasserommet, var det noko avslutning liksom på dagen?\n" +
    "63: Siste dagen i @året, ja @då var det avslutning.\n" +
    "……………………………………………………….\n" +
    "I: Kva, når de var, når de var mindre, var det noko radioprogram som var, som var populært\n" +
    "då?\n" +
    "53: #Barnetimen, @såg eg òg, eller høyrde eg av og til @på.\n" +
    "I: Å, ja. Kva du, med deg?\n" +
    "63: Nei, eg høyrde nesten ikkje @på radio.\n" +
    "I: Fjernsyn då?\n" +
    "\n" +
    "63: Berre #barne-tv.\n" +
    "53: Mm. #barne-tv.\n" +
    "…………………………………\n" +
    "I: Korleis, korleis var det å ha eldre søsken då?\n" +
    "63: Ho eldre ¤søstera mi, ho dreiv alltid og <stammar> leikte familie med meg og\n" +
    "§¤tvillingsøstera mi, og så var jo ho liksom ¤mora @då, og så var eg hunden og så …\n" +
    "I: <humrar>\n" +
    "53: <humrar>\n" +
    "63: … var ho ¤dottera og så, ja.\n" +
    "I: Korleis var det då?\n" +
    "63: Nei, eg syntest det var ok. Eg berre gjekk rundt og bjeffa.\n" +
    "53: Ja, ¤søstera mi, ho leikte eg med Lego med. Men eg kom ofte i, eg krangla ofte med bror\n" +
    "min. Ja.\n" +
    "I: Kvifor det?\n" +
    "53: Berre han erta meg. Han berre begynte å *yta meg heile ¤tida.\n" +
    "……………………………………………\n" +
    "I: Korleis vil de beskriva rommet dykkar heime? Korleis ser det ut?\n" +
    "63: Ja.\n" +
    "53: Det er rotete.\n" +
    "I: Viss du skal seia til nokon som ikkje har vore der kva om er der.\n" +
    "53: Masse plakatar og kvitt rom. To glas, …\n" +
    "I: Mm.\n" +
    "53: … ei seng, eit skrivebord, …\n" +
    "I: Ja.\n" +
    "53: … eit @skåp og ein TV.\n" +
    "I: Korleis ser ditt ut?\n" +
    "63: Nja. Eg har eit stort kvitt rom med ei sånn høg seng. Og så er det ope under. Og så har eg\n" +
    "eit skrivebord, eit @skåp, ein kommode og sofa, @blåe lister i taket og @på golvet.\n" +
    "I: Har du noko rundt omkring, eller er det heilt nake?\n" +
    "63: Jo, eg har nokon sånne, sånne bilar som @står til §utstilling. Og så …\n" +
    "……………………………………………\n" +
    "I: Har de vore på nokon, nokon skuleturar seinare då, som de hugsar noko frå? Har ikkje de\n" +
    "nett vore på Runde? Kan de fortelja litt om det?\n" +
    "63: Det var liksom ein gutefløy og ein jentefløy. Og så skulle me *fylla ~termosane kvar dag.\n" +
    "Dei blei fylt og så …\n" +
    "I: Ja.\n" +
    "63: Så var me og @såg @på masse sånn fuglar og sånn.\n" +
    "I: Kva vil du seia, fortelja frå turen? Noko du hugsar?\n" +
    "53: Brannalarmen.\n" +
    "I: Å, ja.\n" +
    "63: Mm.\n" +
    "53: <humrar>\n" +
    "I: Ja.\n" +
    "\n" +
    "53: Ja, natt til torsdag, trur eg det var, @då gjekk brannalarmen tre gonger. Og så @på klokka\n" +
    "seks @då stod me opp og begynte å *rydda.\n" +
    "I: Ja.\n" +
    "53: @Då @når brannalarmen hadde @gått. Og så var me @på @båttur og fjelltur og …\n" +
    "………………………………..\n" +
    "I: Det er store planar for når det gjeld skulane her.\n" +
    "63: Ja.\n" +
    "I: Veit de kva, noko om kva som skal bli?\n" +
    "63: Ja, det skal bli ein skule her med #barneskule og … Nei, eg veit ikkje heilt kva det skal\n" +
    "bli. Ungdomsskule i §alle fall og så …\n" +
    "I: Kvar det skal vera hen då?\n" +
    "63: Her. Skal *bruka desse ¤bygga og så skal dei *byggja @på meir.\n" +
    "I: Å, ja.\n" +
    "63: Sånne hus som stikk ut sånn og så, ja.\n" +
    "53: Ja.\n" +
    "I: Mm.",

text_54og64: "I: Hugsar de nokon episodar frå de var mindre, før de begynte i skulen?\n" +
    "64: Ja, @når eg var tre @år så skulle eg @på do seint @på ¤natta, så gjekk eg ut @på\n" +
    "kjøkenet og pissa inni @skåpet. <latter>\n" +
    "54: Svevnegreier det @då.\n" +
    "64: Eller mamma greidde no å *stoppa meg nett før eg begynte @då.\n" +
    "I: Trefte de nokon jamaldringar før de begynte i skulen då?\n" +
    "54: Ein lenger frami Dalen @då, han, han vart eg venn med @då i første eller dette\n" +
    "@~seksårsgreiene.\n" +
    "I: Mm.\n" +
    "54: Han har eg vore venn med sidan, så no har me vore venner i ti @år.\n" +
    "I: Men var de nokon andre plassar enn heime før de begynte?\n" +
    "54: @På skulen?\n" +
    "I: Ja?\n" +
    "54: @På slik #barnehage eller styr?\n" +
    "I: Ja?\n" +
    "64: Ja, eg var no i ¤fritidsordninga, trur eg.\n" +
    "54: Ja, bak Domus, eller der var slik ein park, veit du.\n" +
    "I: Å, ja. Kva dreiv de med når de var der då?\n" +
    "54: Nei, det var no å *leika og var no ute og så leikte med bilar og slikt styr, alt mogeleg\n" +
    "§mellom §himmel og jord.\n" +
    "I: Når nokon i klassen hadde bursdag, gjorde de noko spesielt då?\n" +
    "64: Ja, me song for dei [skulle eg til å *seia].\n" +
    "54: Ja, me song for dei. Det gjorde me for §alle.\n" +
    "I: Kan de fortelja litt om kva de gjorde i friminutta og slik?\n" +
    "64: Eg spelte no §fotball og leikte, ja.\n" +
    "54: Ja, §fotball det var no ikkje mi største interesse.\n" +
    "64: Nei.\n" +
    "54: Drap nok interessa for §fotball. Var no med litt av og til, eller så var det no til å @gå og\n" +
    "*trakka rundt der.\n" +
    "I: Kva hugsar de frå skulevegen då?\n" +
    "64: Bussen.\n" +
    "I: Kva hende der?\n" +
    "54: Hugsar alltid dei @på #Kjørnes dei hadde bussen som gjekk i Dalen @etterpå, så dei\n" +
    "@frå #Kjørnes dei hadde alltid rasert bussen slik at han var krasa bossbøtter og slik heile\n" +
    "bussen. Så fekk @då dei i Dalen ¤skulda for dette der. Så me fekk berre slike bussar som nett\n" +
    "så vidt hang i saman.\n" +
    "I: Kva likte de å driva med når de hadde fri då?\n" +
    "64: Sykla, @gå @på §rulleskøyter. Eg har gått heilt sidan eg gjekk i sjetteklassen eller noko\n" +
    "sånn, @på §rulleskøyter.\n" +
    "54: Ja, eg òg sykla mykje før [kan du seia], og så spente me no litt §fotball @då. Så me etter\n" +
    "dei hadde slege @på ~bøane @då, så spente me §fotball @på ~bøane.\n" +
    "\n" +
    "I: Om vinteren då?\n" +
    "54: @Då var det masse kjelkesigling. Var masse bratte bøar her, så det gjekk no bra fort\n" +
    "nedover der.\n" +
    "I: Har de vore på nokon lange bilturar nokon sinne med familie,\n" +
    "64: Ja.\n" +
    "I: eller med venner?\n" +
    "64: Me køyrde @frå, kva var det? @Frå Sogndal til Tyskland @på ein dag. Me kom, me\n" +
    "reiste liksom, eller det var nesten ein dag @då, brukte litt meir enn det. Me køyrde @herifrå\n" +
    "sånn seint @på kvelden eller noko sånt, klokka §elleve, eller noko sånt. Så var me framme i\n" +
    "Tyskland klokka halv tolv @på dagen, to dagar @etterpå.\n" +
    "I: Og då var det ingen pause?\n" +
    "64: Jo, me hadde nokon @fåe pausar. @Måtte sova, veit du. Elles hadde ikkje me kome\n" +
    "levande fram.\n" +
    "I: Men med moped då, har du vore på nokon turar der?\n" +
    "54: Har no nokon sånne stølsvegar @då, kan du *seia. Kjem no #gjerne, ja, har no #gjerne ei\n" +
    "mil å *køyra @på kanskje?\n" +
    "I: Hugsar du nokon gonger du har vore der?\n" +
    "54: Ja, hugsar ein gong eg køyrde @på isen. @Då slo eg meg.\n" +
    "I: Ja.\n" +
    "54: Det var ilt. Øydela masse @på mopeden og … Men eg skada ikkje meg sjølv @då, men\n" +
    "eg slo meg kraftig.\n" +
    "I: Men sånn som frami Dalavatnet har du drive noko fiske der då kanskje?\n" +
    "54: Har fiska der.\n" +
    "I: Ja. Kva fiskereiskapar har du brukt?\n" +
    "54: Nei, me har brukt #garn, og så har me fiskestong.\n" +
    "I: Ja.\n" +
    "54: Oter har eg fiska med.\n" +
    "I: Har du fått noko då?\n" +
    "54: Ja, me får alltid noko, men det er ikkje det, noko å *skryta av. Men me har @fått ein del.\n" +
    "I: Har du vore på noko fiske?\n" +
    "64: Ja, @når pappaen hadde @båt. <humrar>\n" +
    "I: Ja.\n" +
    "64: Så hadde dei no ganske flaks @då. Det var ein sånn, cirka så stor fisk eller noko sånt,\n" +
    "følgde etter @båten heile ¤tida.\n" +
    "I: Å, ja.\n" +
    "64: Fekk eg heldigvis tak i han. Så liksom, det er ein sånn <stammar> stenger, sant? Sånn\n" +
    "teleskopgreier, fiskestong. Heiv eg ut, gjekk liksom snøret, gjekk ned under @båten. Fekk eg\n" +
    "napp, så trudde eg det var fisken, så var han hitom propellen @på @båten. Så rauk, knakk\n" +
    "heile ¤fiskestonga midt @på.\n" +
    "…………………………………………….\n" +
    "I: Kva set de mest pris på med å bu her i Sogndal? Kva vil du framheva som positivt?\n" +
    "54: Ja, og så er ikkje det, er ikkje det så masse hus, og det er ikkje så …\n" +
    "64: Det er no ein del borte @på #Kjørnes der.\n" +
    "\n" +
    "54: Ja, ja, men det er ikkje så tett at …\n" +
    "64: Nei.\n" +
    "54: … du ikkje ser.\n" +
    "I: Korleis trur de det vil vera å vera ungdom her i Sogndal, sei om tjue år då?\n" +
    "64: Eg veddar på desse der ~ungane som kjem liksom om tjue @år eller noko sånn, dei kjem\n" +
    "sikkert til å *vera veldig bortskjemde og sånt @då. Folk tener jo grovt med pengar for ¤tida,\n" +
    "så kjem jo berre til å @få alt dei vil liksom.\n" +
    "I: Kva vil endra seg mest, trur de her?\n" +
    "64: Oppførselen skulle eg til å *seia. Folket.",

text_56og66: "I: Hugsar de nokon spesielle episodar frå de var mindre?\n" +
    "66: Ja, det er jo sånn forskjellige #barnehagehistorier og sånt @då, @frå spikar i foten til\n" +
    "§skallebank og *detta av huska og sånn forskjellige ting. Eg flytta hit til Sogndal @når eg var\n" +
    "fire @år, og før det så budde eg på Austlandet @då. @Då prata, eg prata jo @bokmål @når\n" +
    "eg kom hit, men så har eg lagt om etter kvart.\n" +
    "56: Nei, eg begynte i #barnehagen @når eg var fire, fire eller fem @år.\n" +
    "I: Ja, fekk de høyra noko av dei som var eldre?\n" +
    "66: Nokon historier fekk me jo *høyra, om liksom ~lærarane der og sånn forskjellig.\n" +
    "56: Ja, eg vart vel ikkje fortalt så mykje akkurat før, men. Ein kamerat av meg begynte @på\n" +
    "ungdomsskulen. Han var ein god del eldre. Sa han at han angra @på at han ikkje gjorde meir\n" +
    "jævelskap @når han gjekk @på #barneskulen, fordi at @når han kom @på ungdomsskulen,\n" +
    "så var det ikkje hjelp i. Så @då @måtte eg berre (*benytta) dei fem @¤åra eg hadde att @på\n" +
    "#barneskulen, *gjera så mykje jævelskap eg kunne, fordi @då dei kunne ikkje *straffa [meg]\n" +
    "@på nokon som helst @måte.\n" +
    "I: Hugsar de nokon spesielle episodar frå barneskulen?\n" +
    "66: Ja, eller eg og … Det var dei siste @¤åra eg han og nokon andre me hadde ein sånn klubb\n" +
    "kan me vel *seia eigentleg @då. <humrar> Der me fann @på litt sånn forskjellige ting @då.\n" +
    "Me dreiv og … Ja, me gjekk vel der me ikkje skulle @gå. Me gjorde ting me ikkje skulle\n" +
    "*gjera, så til slutt så blei me oppdaga @då. Me @måtte til rektor ein liten tur.\n" +
    "I: Kva gjorde de på i friminutta?\n" +
    "56: Anten var du med på §ball @på vegg, eller sparka litt §fotball eller så hang du @på vegg\n" +
    "eller gjerde eller noko sånt. @Når hausten kom, @då så … Det var epletre nede i nedste delen\n" +
    "av skulen der, så @då @åt me kart.\n" +
    "66: Eg var med og spelte §fotball av og til, men kvar klasse hadde vel ein dag i veka der dei\n" +
    "hadde den store bana. Det var liksom topp. Det var, @då var litt kjekt å *spela §fotball, men\n" +
    "elles så var liksom å @stå å *prata med dei andre ¤folka i klassen.\n" +
    "I: Mm. Kva dreiv de med på fritida?\n" +
    "56: Eg har alltid spelt masse data. Så fekk eg gitar til @tiårsdagen. Så begynte eg å *spela litt\n" +
    "gitar. Elles så har eg no gjort, leikt leiker som §alle ~ungane.\n" +
    "I: Mm.\n" +
    "56: Gøymsel i byggefeltet og slik.\n" +
    "66: Ja, eg òg hugsar @når me fekk den første datamaskinen. Det var stort, heime. Og @då\n" +
    "gjekk det mykje tid til det, men elles så var det jo … Me budde i ein stor eplehage @då. Der\n" +
    "var òg masse sånn andre ungar som budde. Så @då om hausten var det særleg fint, for @då\n" +
    "var det fullt av eple på ¤trea, og me kunne @gå rundt i hagen og *plukka og *eta og …. Så\n" +
    "var det ei stor fotballbane like ved sida av òg, så me kunne *spela litt §fotball av og til viss me\n" +
    "ville.\n" +
    "………………………………….\n" +
    "I: Kva skjer i, i sentrum då?\n" +
    "66: Det er jo del no folk i tiande klassen @då, så oppover @på vidaregåande som @går der\n" +
    "om ~kveldane @då.\n" +
    "\n" +
    "I: Er det noko de meiner ungdomane i Sogndal har bruk for?\n" +
    "66: Det er jo mange som ergrar seg over at det ikkje er nokon plassar me kan @gå @då om i\n" +
    "~helgane om ~kveldane og sånn, sidan det er @attenårsgrense @på dei fleste ~plassane. Ein\n" +
    "kanskje ein plass der me kunne @gå liksom utan å ha noko, berre *vera der liksom, *finna\n" +
    "@på ting sjølv. Ikkje sånn som puben eller sånn som meieriet, men kanskje ein plass der det\n" +
    "kunne *vera konsertar. Det kunne *vera litt forskjellig show kanskje.\n" +
    "56: Dei har jo for eksempel prøvd seg med skaterampe @då utan at det tok heilt av. Men viss\n" +
    "dei hadde bygd eit område med det og hatt litt servering og kanskje hatt, eller ein plass med\n" +
    "ein scene i §alle fall, så trur eg det og @fått inn band @frå andre kommunar. Og det slit dei\n" +
    "med. Det prøver dei @på Solhov, men klarer ikkje. Men hatt eitt eller anna sånt som fungerer\n" +
    "og @fått inn musikk andre plassar @frå.\n" +
    "I: Kva som er gale med Solhov då?\n" +
    "56: Eg veit ikkje heilt. Det har vel @fått eit stempel @då så. Det er jo liksom … Det er for,\n" +
    "det er @~småungane som reiser der sant. Det er sjette, femte, sjette og sjuande klasse som er\n" +
    "der @på ~fredagane på diskoteket. Elles er det berre ein øvingsplass for band.\n" +
    "………………………………………….\n" +
    "I: Kva, er det noko som er typisk for jenter og gutar?\n" +
    "66: Bleika @håret for eksempel hos ~jentene. Det er mange som gjer det. Det, det er … Eg\n" +
    "veit ikkje om dei synest det er pent, men det er vel … Det, eg ser mange som gjer det no. Eg\n" +
    "veit ikkje kven dei prøver å *likna @på, men det er mange som gjer det.\n" +
    "56: Så desse #buffaloskorne.\n" +
    "………………………………………………\n" +
    "I: Kva tykkjer de er best med å bu i Sogndal?\n" +
    "66: Det er ikkje så stort miljø, så det blir vel §alle kjenner §alle liksom. Det blir ganske tett.\n" +
    "Dei fleste ¤miljøa her er jo ganske bra i forhold til mange andre plassar liksom, ~storbyane\n" +
    "der det blir mykje vald og mykje @slåssing og sånn. Så her, her er det ikkje så mykje av det.",

text_57og67: "I: Hugsar de nokon episodar frå de var mindre? Før de starta i skulen?\n" +
    "67: Eg gjekk i #barnehagen @oppå Stedje. Der var sånn leikehus. Bak @på baksida av dette\n" +
    "leikehuset, der var nokon som eg gjekk i #barnehagen med @då. Dei hadde tagga til heile,\n" +
    "heile baksida der som … Og #barnehagetanta ho hadde ein gitar, og det syntest me var så\n" +
    "spennande @då og så. Ein gong så prøvde eg den gitaren @då, rauk sjølvsagt ~strengane.\n" +
    "Dreiv og så fingra på den gitaren, så «joing». Det var ikkje noko særleg.\n" +
    "I: Trefte du nokon jamaldringar før du begynte i skulen?\n" +
    "57: Ja, det gjorde eg nok. Men ein del som eg @går i klasse med no, dei kjende eg godt @då.\n" +
    "I: Kvar du trefte dei hen då?\n" +
    "57: Nei, det var i #barnehagen, og så gjekk, vart, gjekk me jo i klasse @då.\n" +
    "I: Fekk de høyra mykje om skulen før de begynte sjølve?\n" +
    "67: Høyrde no litt <uforståeleg>.\n" +
    "57: Høyrde at det var mykje lekser og slikt arbeid.\n" +
    "I: Ja. Kven det var som fortalde dette her?\n" +
    "57: <nøler> Andre som gjekk @på skulen.\n" +
    "67: Eldre folk og sånt ja.\n" +
    "I: Ja.\n" +
    "57: Ja.\n" +
    "67: Trur eg, etter kva eg hugsar.\n" +
    "I: Korleis stemde det som de hadde fått vita då?\n" +
    "67: Nei.\n" +
    "57: Eg trur ikkje me tenkte @på det så mykje til å *begynna med, men etter kvart så stemde\n" +
    "det nok.\n" +
    "67: Ja.\n" +
    "I: Mm. Kva gjorde de på i friminutta og sånn då?\n" +
    "57: Spelte §fotball.\n" +
    "67: Ja, mykje det gjekk @på @på #barneskulen, ja. No @står no berre og heng ute her sånn.\n" +
    "57: Ja, no er ikkje det noko sånn.\n" +
    "67: Nei.\n" +
    "57: Det var nesten §fotball i §alle fri …\n" +
    "67: Ja, dreiv og valde lag @då, så var det alltid nokon som stod att til slutt. Det er no ikkje så\n" +
    "kjekt.\n" +
    "I: Kva er moroast og seigast på skulen no då?\n" +
    "67: ¤Friminutta er moroast. <humrar>\n" +
    "I: Kvifor det?\n" +
    "67: Nei, altså er det ikkje sånn ~timane det er sånn, det er liksom @må du liksom\n" +
    "*konsentrera heile ¤tida.\n" +
    "57: Ja.\n" +
    "67: Og så er det …\n" +
    "57: Prøvar og slikt …\n" +
    "67: Ja.\n" +
    "\n" +
    "57: Det kan *vera litt seigt. Ein del prøvar.\n" +
    "I: Kvifor det?\n" +
    "57: Nei, for det er vanskeleg ofte. Og det blir mykje å *øva @på ofte og mykje lesing og.\n" +
    "I: Korleis er det ein førebur seg då når ein skal ha prøve?\n" +
    "57: @Då må ein no *lesa godt @på førehand.\n" +
    "67: Ja. Nei, det er vel ikkje anna å *gjera enn å *lesa og *lesa og *lesa.\n" +
    "I: Kva er moroast då?\n" +
    "57: Er det fag du tenkjer @på eller elles?\n" +
    "I: Ja, det måtte vera opp til deg det.\n" +
    "57: Nei, altså fagmessig, så er kanskje matematikk og samfunnsfag dei beste ¤faga.\n" +
    "67: Ja.\n" +
    "57: @Språk er litt verre, for det kan *vera litt vanskeleg, @når det gjeld grammatikk og ein\n" +
    "del sånne ting.\n" +
    "67: Det spørst jo kva du meistrar det @då. <uforståeleg>\n" +
    "57: Ja.\n" +
    "…………………………………………\n" +
    "I: Er det nokon som driv med noko fiske? Er de opptekne av det?\n" +
    "57: Nei, det er ikkje eg oppteken av.\n" +
    "67: Eg fiska ein del i fjor sommar og rundt @oppå §fjellet her.\n" +
    "I: Mm.\n" +
    "67: Det var, det var så tidleg om sommaren at ¤vatna var frosne, så eg fekk ikkje så mykje\n" +
    "fisk.\n" +
    "57: Nei, eg har no vore @på fisketur til Kalvåg @då ein del @år no så. Men elles så fiskar\n" +
    "ikkje eg så mykje her @då.\n" +
    "I: Nei.\n" +
    "57: Og så Færøy har eg vore òg.\n" +
    "I: Å ja.\n" +
    "57: Fekk ein god del fisk, så, ja.\n" +
    "I: Kva slags reiskapar bruker du då?\n" +
    "57: Nei, det er vel mest fiskestong <nøler>, som me bruker. Ja. Det er ganske kjekt, for @då\n" +
    "@får du fisk veldig ofte @når du hiver uti.\n" +
    "67: Var det utmed havet?\n" +
    "57: Ja, langt ute.\n" +
    "67: Ja, me var ein tur uti Ytre Sogn òg me.\n" +
    "57: Ja.\n" +
    "67: I fjor.\n" +
    "57: Det var, eg veit ikkje me var i Færøy, eg veit ikkje kvar de var hen.\n" +
    "67: Me, eg hugsar ikkje kva det heitte. Det, det er i Ytre Sogn der.\n" +
    "57: Ja.\n" +
    "67: Køyrer ut mot Holmedal og ut gjennom der. @Då fekk me ein del fisk. Budde på ei hytte.\n" +
    "Det var ufatteleg @rått. Kvar gong du kasta uti omtrent,\n" +
    "57: Ja.\n" +
    "67: så kunne du dra opp att. Med ein gong så fekk du fisk.\n" +
    "\n" +
    "57: Same for oss.\n" +
    "I: Mm.\n" +
    "57: Du @får ein god del fisk.\n" +
    "I: Er det ikkje noko fiske frami Dalavatnet og i sjøen her då?\n" +
    "67: Eg var med pappaen ein gong og ein han jobba med og trekte #garn frami der.\n" +
    "I: Mm.\n" +
    "67: Det er bra med fisk der frami òg.\n" +
    "57: Eg har vore ein til to gonger og fiska der oppe. Eg, eg trur ikkje me fekk så mykje.\n" +
    "67: Du må setja #garn viss du skal @få, trur eg.\n" +
    "57: Ja, jau me, ja det stemmer det, me var oppe og tok #garn ein gong.\n" +
    "67: Ja.\n" +
    "57: @Då fekk me ein god del, hugsar eg. Trur fekk inn over tjue.\n" +
    "…………………………………………………………………….\n" +
    "I: Har de vore på nokon … Er de på nokon ferietur om sommaren med bil eller noko de\n" +
    "hugsar frå det?\n" +
    "57: Ja, me pleier no å *reisa anten til Sverige eller Danmark. Men i @år så veit eg ikkje om\n" +
    "det blir noko. Eg veit ikkje heilt om eg hugsar att noko. Me har jo vore i Legoland, men det er\n" +
    "lenge sidan.\n" +
    "I: Mm.\n" +
    "57: @Då var eg sju, @åtte @år, trur eg.\n" +
    "……………………………………………………………………\n" +
    "I: Er de noko opptekne av miljøvern og slike spørsmål?\n" +
    "57: Har eigentleg aldri tenkt @på det spesielt.\n" +
    "I: Nei. Finst det nokon sånne miljøvenlege framkomstmiddel som …?\n" +
    "67: @Går no an å ta §sykkelen @då sjølvsagt. Nei, eg veit ikkje.\n" +
    "57: Nei, altså eg, <humrar> eg veit ikkje.\n" +
    "I: Er det noko de bruker sjølve ofte?\n" +
    "67: Eg syklar ein del @ifrå #Kjørnes og til Fjøra. Og i §alle fall om sommaren @då.\n" +
    "57: Eg brukar @sykkel om sommaren viss eg brukar han. Eg pleier å @gå mest i staden for å\n" +
    "*sykla.\n" +
    "I: Mm.\n" +
    "67: Du bur no så\n" +
    "57: Ja.\n" +
    "67: lokalt til òg.\n" +
    "57: Ja, så eg brukar ikkje §sykkelen så mykje. Det kan no *henda eg syklar @på ein tur til\n" +
    "~Vikane eller til ~Saurane. Det spørst jo.\n" +
    "I: Mm.",

text_59: "I: Hugsar de nokon episodar frå de var mindre, før de begynte i skulen?\n" +
    "59: Gjekk i #barnehagen @då. Nei, eg gjekk i #barnehage @på Helgheim.\n" +
    "I: Å ja.\n" +
    "59: Der borte @på folkehøgskulen. Eg og <stammar> besteveninna mi @då, me var dei\n" +
    "einaste som var like gamle som var jenter, liksom. Og @når det regna ute, @då pleidde me å\n" +
    "ta med oss teppe ut i hagen i #barnehagen. Så pleidde me å *liggja under der og *syngja\n" +
    "songar @då, @når det pøsregna ute, var ganske morosamt.\n" +
    "I: Fekk de høyra mykje om skulen før de begynte sjølve, eller?\n" +
    "59: Eg hadde ein bror som var to @år eldre enn meg. Så eg @såg at han sat og gjorde lekser\n" +
    "og sånne ting. Så det var liksom at han laga nokre @småe @oppgåver til meg og sånne ting\n" +
    "som eg fekk lov å *gjera. Eg lærte litt å *plussa saman ein og ein og sånne ting,\n" +
    "I: Mm.\n" +
    "59: før eg begynte.\n" +
    "I: Var det noko radioprogram som var spesielt interessant når de var småe?\n" +
    "59: Eg høyrde jo @på #barnetimen av og til @når, eller berre @når det kom liksom. Det var\n" +
    "ikkje sånn at eg @måtte *høyra @på det @på ein @måte, men @når eg høyrde at det kom,\n" +
    "@då sat eg no og høyrde @på det.\n" +
    "I: Mm. Hugsar de nokon spesielle episodar ifrå barneskulen?\n" +
    "59: Ja, eg hugsar første @året eg begynte @på skulen @då, eller det var i\n" +
    "@#seksårsbarnehagen.\n" +
    "I: Ja.\n" +
    "59: Der pleidde eg og ho besteveninna mi pleidde å @gå ut. Og @når ingen @såg oss, så tok\n" +
    "me av oss #skorne og sprang rundt @på sokkelesten over alt @på heile skulen. Og så @når\n" +
    "me kom heim att, så var me heilt svarte under ~sokkane @då. Så lurte ¤foreldra @våre svært\n" +
    "@på kva det var me hadde gjort @då, men det sa me aldri. <humrar> Det var veldig\n" +
    "morosamt.\n" +
    "I: Når nokon i klassen hadde bursdag, gjorde de noko spesielt då?\n" +
    "59: Me song.\n" +
    "I: Ja.\n" +
    "59: Ja, eg var med ei veninne @på skulen hennar, og @då var det sånn om morgonen at @når\n" +
    "læraren kom inn, @då måtte §alle saman @stå fint ved sida av pulten. Så @måtte han *seia\n" +
    "«sit ned», og @då kunne dei *setja seg ned [då.]\n" +
    "I: Akkurat.\n" +
    "59: Så var ganske sånn strengt i forhold til det me hadde, synest eg.\n" +
    "……………………………………\n" +
    "59: Ja, så same kvelden inni der @då skulle me *eta graut @då, var sånn mandel i grauten.\n" +
    "Og @då sat eg og @åt @då. Så @såg det ut som det var nokon som hadde @fått §mandelen.\n" +
    "Så sa eg til mamma: «Nei, no vil eg ikkje ha meir». Ho begynte å *rota litt rundt i grauten min\n" +
    "@då og så plutseleg så fann ho §mandelen, sant så: «Å, nett ta og et denne skeia», sant, ho sa\n" +
    "til meg @då.\n" +
    "I: <humrar>\n" +
    "\n" +
    "59: Så @då fekk eg mandelen @då.\n" +
    "I: Ja.\n" +
    "59: Så fekk eg ein stor marsipan.\n" +
    "…………………………………..\n" +
    "I: Kva hugsar de frå skulevegen og sånn då? Kva gjorde de der?\n" +
    "59: Ja, eg tek buss @då. Før så var det sånn at me @måtte @gå heilt, eller eg bur på #Kjørnes\n" +
    "@då. Det er ganske langt oppi ~bakkane. Eg @måtte jo @gå heilt ned til riksvegen for å ta\n" +
    "bussen. Men no så har bussen begynt å *køyra oppover ~bakkane @då.\n" +
    "I: Å ja.\n" +
    "59: Og @då, me har ein buss som berre @går til #barneskulen. Så har me to bussar som @går\n" +
    "både #barneskulen og ungdomsskulen @då. Det er ganske greitt at me har @fått skifta no\n" +
    "liksom.\n" +
    "…………………………………………………….\n" +
    "I: Korleis vil de beskriva rommet dykkar heime? Korleis ser det ut?\n" +
    "59: Stort. Det er veldig stort.\n" +
    "I: Ja.\n" +
    "59: Eg har veldig, eg har halve øvste etasje i huset @vårt. Eg deler øvste etasje med bror min\n" +
    "@då. Så @då han eine, @på eine sida, så har eg rom @på andre sida.\n" +
    "I: Ja.\n" +
    "59: Så er det eit lite kott §imellom, eller ein bu §imellom.\n" +
    "I: Når du kjem inn der, korleis er det liksom møblert då?\n" +
    "59: Ja, ¤senga mi den @står heilt innerst i rommet under eit sånn @skråtak @då. Og han er\n" +
    "@blå. No er sånn §gamal seng som §onkelen min hadde tenkt å *brenna @på eit @bål. Men\n" +
    "så fann mamma han og syntest han var så fin. Så ho redda han @då, siste liten. Og så <nøler>\n" +
    "har eg eit raudt @skåp som @står rett eller bakom ¤senga nesten, eller ved sida av ¤senga.\n" +
    "………………..\n" +
    "I: Har de vore på nokon lange bilturar med, med nokon nokosinne, med familien eller med\n" +
    "venner eller, som de hugsar?\n" +
    "59: For nokre somrar sidan så dreiv me og køyrde gjennom tolv, tretten, fjorten fylke. @Då\n" +
    "dreiv eg og talde. @Då hadde me nettopp hatt @på skulen om §alle ¤fylka, så @då kunne eg\n" +
    "¤namna på §alle ¤fylka så.",

text_68og69: "I: Når det er for eksempel jul, er det nokon tradisjonar då?\n" +
    "69: Midt @på dagen, så kjem julenissen. <latter>\n" +
    "I: Ja, vel.\n" +
    "68: Midt @på dagen?\n" +
    "69: Ja, og så @får me ein pakke, og så om kvelden @då @får me resten liksom. Og så et me\n" +
    "pinnekjøt og ribbe og dessert.\n" +
    "68 Eg @får ein pakke om morgonen, og så @på kvelden @då kjem julenissen og gir oss\n" +
    "pakke. Og så opnar me resten litt seinare. Eg trur det er etter mat.\n" +
    "69: Ja, det gjer me òg.\n" +
    "……………………………………\n" +
    "I: Her har de òg eit bilete av kinoen. Har de nokon filmar de har sett?\n" +
    "69: Ja, var «Burning 2» for to veker sidan. Eg var ikkje @på premieren.\n" +
    "I: Nei.\n" +
    "69: Eg var seinare.\n" +
    "68: Og eg var i @går @då, så.\n" +
    "I: Kva tykte de om han då?\n" +
    "68: Var bra.\n" +
    "69: Han var betre enn første liksom.\n" +
    "68: Kvifor det?\n" +
    "69: Fordi det var meir action i denne her.\n" +
    "I: Ja, kva skjedde då?\n" +
    "68: Nei, det, det var sånn ulovleg kappløp @då, og så …\n" +
    "69: Så var det ungen til [han] @på ein @måte som starta alt som skulle *vera med å *køyra,\n" +
    "ho og kjærasten og så …\n" +
    "68: Så ville ikkje ¤mora og faren det @då. Så dei prøvde å *stoppa ho. Og så dreiv no dei\n" +
    "@på ein @måte og jaga ho @då. Begynte kjærasten og ho og begynte å *krangla litt. Og så\n" +
    "gjekk ho inn i andre bilen, og så begynte ho @på ein @måte å *kjefta på faren @då fordi han\n" +
    "ikkje var den same.\n" +
    "69: Fordi han hadde vore i fengsel.\n" +
    "68: I to @år.\n" +
    "69: Ja, det gode laget vann @på ein @måte.\n" +
    "68: Mm.\n" +
    "69: Det var to lag som …\n" +
    "I: Ja.\n" +
    "69: … konkurrerte mot kvarandre.\n" +
    "68: Ja, og så var det jo mange @då som krasja og @måtte *hoppa inni ein annan bil og ... Det\n" +
    "var òg mange som styrta inn i garasjeportar eller tak …\n" +
    "69: Ja, det var det.\n" +
    "68: … og snø.\n" +
    "I: Å, ja.\n" +
    "\n" +
    "68: Det @står liksom i (begynnelsen) av filmen at ¤stunta er utførte av sånne profesjonelle.\n" +
    "Og dei har no gjort dette her vel eit par gonger før, eller mange gonger.\n" +
    "I: Mm.\n" +
    "68: Så dei veit no korleis ein skal *gjera det.\n" +
    "I: Kva det var som var mest spennande?\n" +
    "68: Eg synest dette der med, @då dei skulle *køyra forbi denne der @båten liksom.\n" +
    "69: Ja, det var ein @båt som var akkurat @på veg ut sånn der is, og så kom dei akkurat forbi\n" +
    "før det knakk.\n" +
    "68: Men det var berre nokon bilar før politiet kom bak dei @då.\n" +
    "69: Så nokon @måtte *hoppa over …\n" +
    "68: … på ein @måte med ~bilane så.\n" +
    "………………………………………\n" +
    "I: <nøler> Når de gjekk på Trudvang, for der gjekk de før, sant?\n" +
    "69: Ikkje eg.\n" +
    "I: Du gjekk ikkje der?\n" +
    "69: Nei.\n" +
    "I: Nei, kvar gjekk du då?\n" +
    "69: @På ~Norane.\n" +
    "I: Ja, så det var her, her på Kvåle de blei kjende då?\n" +
    "69: Ja, litt meir.\n" +
    "I: Ja.\n" +
    "69: Me kjende kvarandre før òg, men me blei meir kjende no.\n" +
    "I: Kva noko de har gjort i lag som de hugsar frå de var …?\n" +
    "69: Me spelte kamp mot dei. Det hugsar eg.\n" +
    "68: <humrar>\n" +
    "69: Me tapte sånn tjue éin. <humrar>\n" +
    "68: <humrar>\n" +
    "69: Men ikkje noko meir, trur eg.\n" +
    "I: Ja, kva som var fordelen med å koma her, tykte du?\n" +
    "69: Det er fleire å *vera med, og @då @når du blei så §gamal, så begynte du å @få fleire\n" +
    "vener @frå Sogndal.\n" +
    "I: No då, når de bur der ute, så må de vel få skyss for å koma inn her?\n" +
    "69: Det tek ti minutt å *køyra ut, cirka.\n" +
    "I: Men har de nokon aktivitetar der ute og eller?\n" +
    "69: Dei som @går i første til fjerde klasse,\n" +
    "I: Ja.\n" +
    "68: <kremtar>\n" +
    "69: har §fotball der ute @då, men.\n" +
    "I: Kva er det kjekkaste de gjer på? Kva er det de likar best å gjera når de skal få velja?\n" +
    "68: Å *vera med vener liksom.\n" +
    "69: Ja, *vera med [hand] vener eller *spela §handball eller @sjå film.\n" +
    "68: Mm.\n" +
    "I: Er det mest sitjing i ro eller?\n" +
    "\n" +
    "69: Nei, eg prøver å *vera\n" +
    "68: Aktiv.\n" +
    "69: aktiv. <humrar> Eg har trening sånn tre gonger i veka, så har eg kamp om ~helgane.\n" +
    "I: Det må du seia litt om korleis de trenar og sånn og …\n" +
    "68: Ja, det er … Nokon øvingar er kjekke og, men dei er slitsame eigentleg §alle saman.\n" +
    "I: Ja, korleis er det å veksa opp i Sogndal i dag, viss de skal seia noko til folk som ikkje bur\n" +
    "her då? Kva vil de seia om, om Sogndal då?\n" +
    "68: Nei, det er flott å bu her.\n" +
    "I: Kva de set pris på?\n" +
    "69: Det er ikkje altfor mange folk her.\n" +
    "68: Det, det veks liksom, men ein oppdagar ikkje så veldig mykje.\n" +
    "69: Og så er det … Ja, det kunne vore litt meir, meir ting og gjort her @då men.\n" +
    "I: Ordføraren dykkar sa litt tidlegare i haust at Sogndal er kjend for fotball, saft og puddersnø.\n" +
    "Korleis vil de karakterisera bygda?\n" +
    "69: Det er masse §fjell her.\n" +
    "68: Mm.\n" +
    "69: Og viss du likar å @gå i §fjellet, så er det no bra å bu i <latter> Sogndal.\n" +
    "68: Det, det er no plassen liksom.\n" +
    "69: Og så er det masse laussnø om vinteren, ja, og ja §fotballen og …\n" +
    "68: ¤Safta og syltetøyet.\n" +
    "69: … ¤safta og syltetøyet. Brusen òg eller.\n" +
    "68: Mm.\n" +
    "69: Julebrus.\n" +
    "I: Kva meiner de er den viktigaste identiteten til, til Sogndal?\n" +
    "68: Ein eller fleire ting?\n" +
    "I: Ja, viss du kjem på fleire ting så.\n" +
    "68: Saft eller Lerum @då og §fotball.\n" +
    "69: Ja, det er eigentleg det.\n" +
    "68: Mm.\n" +
    "69: Og §fjell.\n" +
    "68: Mm.",

text_70og71: "I: Kjenner de kvarandre frå før?\n" +
    "70: <nøler> Litt, ikkje så veldig.\n" +
    "71: Litt, me gjekk i same #barnehage.\n" +
    "I: Ja, hugsar de noko frå barnehagen då?\n" +
    "71: Me fekk alltid rundstykke til lunsj.\n" +
    "70: Ja, hugsar no litt av det, men det er lenge sidan no @då, så det er ikkje så lett å *hugsa.\n" +
    "71: Me song liksom alltid for, for maten, sånne songar som ingen hugsar lenger.\n" +
    "I: Men hugsar de første skuledagen?\n" +
    "70: Nei, eg hugsar at me skulle fram til rektor og sånt, og han skulle *introdusera oss. Så var\n" +
    "¤foreldra med første skuledagen. Det var det.\n" +
    "71: Ja, eg hugsar at ¤foreldra mine liksom stod rett attmed der eg sat, og så sat eg heilt bakarst\n" +
    "i klasserommet ved vindauge.\n" +
    "I: Ja, de måtte vel øva dykk på å ha opp handa og alt slikt, ikkje sant?\n" +
    "71: Første dagen så gjorde sånn §alle saman det, men no er det nesten ingen som gjer det.\n" +
    "<humrar> Så det er liksom …\n" +
    "I: Kva leikte de med då?\n" +
    "70: Det var no det §fotball og sånne leikar og sånt det gjekk i.\n" +
    "71: Me var alltid sånn eplehagen med sånne syklar.\n" +
    "I: Hadde de syklar på skulen?\n" +
    "70: Ja.\n" +
    "71: Ja, me hadde sånn bu med mange syklar. Det var to- og tre- og firehjulssyklar der. Så det\n" +
    "var liksom …\n" +
    "I: Seier du det ja?\n" +
    "70: Det var alltid konkurranse om kven som skulle få §sparkesykkelen. <humrar>\n" +
    "71: Ja, det hugsar eg. ~Jentene dreiv @på og slengte seg, og ~gutane spelte §fotball. Så var\n" +
    "det nokon gutar som berre sprang rundt inni ~buskene og sånne ting.\n" +
    "I: Kva gjorde du?\n" +
    "70: Det var no å *slenga seg og *vera i eplehagen slik som ho seier.\n" +
    "I: Men har de vore på nokon skuleturar nokon sånn spesielle ting?\n" +
    "71: Ja, me reiser jo liksom @på sånn\n" +
    "70: Me var …\n" +
    "71: fjelltur kvart @år, trur eg.\n" +
    "70: Me var til Fjærland og så overnatta der @på campingplassen.\n" +
    "I: Ja, kan de fortelja litt om den turen?\n" +
    "70: Me var @på campingplassen, og så hadde, var me fire til fem elevar @på kvar hytte.\n" +
    "71: Og så midt @på ¤natta så kom liksom ein lærar og banka @på ¤døra. Og så @låg eg og\n" +
    "veninna mi me @låg oppe @på hemsen. Og det var sånn stigeliknande greier ned, mens dei\n" +
    "som @då @låg @på rommet, som var kanskje sånn to meter @ifrå ¤døra, ikkje klarte å @gå\n" +
    "ut og *opna ¤døra. Så me @måtte @gå ned, *opna ¤døra, men det gadd jo ikkje me. Så @då\n" +
    "begynte læraren å *banka @på glaset òg, men me visste ikkje at det var læraren. Så det var jo\n" +
    "ganske skummelt for oss.\n" +
    "\n" +
    "I: <humrar>\n" +
    "71: <humrar> Men ja, me fekk jo opna ¤døra til slutt @då.\n" +
    "I: Men kva dei ville då?\n" +
    "71: Dei ville berre *seia sånn «god natt».\n" +
    "I: Å, ja. <latter>\n" +
    "71: Så det. <humrar>\n" +
    "I: Hugsar du noko frå den turen?\n" +
    "70: Ja, me kunne *melda oss @på kva me ville. Det var å *vera på museet og @gå fjelltur\n" +
    "eller *vera @på gardsbesøk. Eg valde å @gå @på gardsbesøk. <nøler> @Då køyrde me …\n" +
    "Me køyrde til garden i hengjar @på traktor, og så var me og @såg på litt forskjellige dyr, og\n" +
    "så mata me ~sauene og litt sånt.\n" +
    "…………………………………………..\n" +
    "71: Har de òg hatt sånn MOT-besøk? <humrar>\n" +
    "70: Ja, det hadde me.\n" +
    "I: Ja, kva går det ut på, MOT?\n" +
    "71: Tja, me sit i ein ring og berre snakkar om masse (random stuff).\n" +
    "70: @Får besøk og slikt av ~leiarane i MOT her i Sogndal, kva no det er. <humrar>\n" +
    "I: Ja.\n" +
    "70: Og så pratar me no om at du @må *tora å *seia nei og …\n" +
    "71: *Tora å @gå din eigen veg og masse sånne ting.\n" +
    "70: Ja, pratar om alt mogeleg som du eigentleg lurer @på. Me veit liksom kva det @går ut\n" +
    "@på @då, så det er liksom … Me har høyrt det før, men samtidig så er det liksom dei … Er\n" +
    "det ein ting du snakkar om, så snakkar dei sånn fullt ut om det og at dei … Det er ikkje berre\n" +
    "sånn i to sekund og så er dei ferdige med det liksom.\n" +
    "I: Men får de prøva ut noko då?\n" +
    "71: Me får liksom *gjera sånne … Eg veit ikkje kva eg skal *§kalla det, men me @får lov til å\n" +
    "*gjera ting (i hvert fall) som @stå @på stolar og … Sånn gruppepress som me har prøvd.\n" +
    "…………………………………….\n" +
    "I: Men viss de skal snakka til folk som ikkje er herifrå, så skal de seia noko om der de kjem\n" +
    "ifrå, kva vil de trekkja fram då?\n" +
    "70: Du det er ja det er liksom §¤fjella og sånt her @då. Det er mange som kjem her og sånn til\n" +
    "å *gjera forskjellige ting.\n" +
    "71: Men det er veldig fine §fjell og sånn her @då.\n" +
    "70: Ja, det er fin plass.\n" +
    "I: Det kjem jo veldig mange folk @ifrå andre plassar og @går @på skule her @då, altså\n" +
    "studentar og …\n" +
    "71: Ja.\n" +
    "70: Ja.\n" +
    "I: … tilflytting og sånt. Kva trur de dei set pris på her?\n" +
    "70: Høgskulen og sånn.\n" +
    "71: At høgskulen er så bra som han er.\n" +
    "I: Ja.\n" +
    "\n" +
    "71: At @då dei faktisk har masse å *gjera her. Dei kan jo *stikka @på fine fjellturar. Eg\n" +
    "meiner studentar har folkehøgskulen her og sånne ting. Er jo sånn at dei kjem hit for å kanskje\n" +
    "@gå @på sånn naturlinje eller noko sånn.\n" +
    "I: Mm.\n" +
    "71: Og @då er det jo veldig fint at me har dei §¤fjella og ~fjordane og ~plassane som me har\n" +
    "her @då.\n" +
    "I: Mm.",

text_72og73: "I: Korleis var det når de gjekk der nede?\n" +
    "72: Alt, alt var så gamalt der nede i forhold til her oppe.\n" +
    "73: Ja.\n" +
    "72: Her er det ein ny del og ein §gamal del, men der nede var jo alt berre nesten murvegger.\n" +
    "73: Ja, det var liksom alt gamalt.\n" +
    "I: Ja, for at eg òg har gått på den skulen der nede, og då me kom i sjetteklasse, då var den der raude\n" +
    "delen bak der, då var, då vart den heilt ny.\n" +
    "72: Jammen det som me §kallar oppsida, det bygget @på oppsida.\n" +
    "73: Å, ja, det.\n" +
    "I: Men kva, kva gjorde de på der nede då? Friminutt og slik?\n" +
    "72: Nei, for det meste …\n" +
    "73: Spelte §fotball.\n" +
    "72: @Då var det mest å *spela §fotball, for @då ...\n" +
    "73: Ja.\n" +
    "I: Ja, var det blanding av jenter og gutar, eller var det kvar for seg?\n" +
    "73: <nøler> A mot B, eller\n" +
    "72: Ja.\n" +
    "73: Ja, noko sånt.\n" +
    "72: Dei som kom, dei spelte §fotball. Elles så dei som ikkje ville *spela §fotball, dei stod ute liksom\n" +
    "berre og @såg @på. Det var …\n" +
    "73: Ja, elles var dei borte og eller oppe der.\n" +
    "I: Mm.\n" +
    "73: Ja, veit det søren kva eg skal *§kalla det.\n" +
    "I: Og så har de gått to år her oppe på Kvåle skule òg.\n" +
    "73: Ja.\n" +
    "72: Ja.\n" +
    "I: Ja, korleis var det å koma opp her då?\n" +
    "72: Nei, litt løye første dagen i sjette klasse, for @då …\n" +
    "73: Eigentleg ikkje noko forskjell, men eg gjekk nesten ned til Trudvang første dagen.\n" +
    "I: Sakna du der nede då?\n" +
    "73: Nei\n" +
    "………………………………………………………………………………..\n" +
    "I: Har de fått kjeft for noko?\n" +
    "72: Skal me @sjå. Mamma og pappaen blei litt sinte @når eg heldt @på å *tenna @på hunden @vår.\n" +
    "Det var noko sånn trykkegreier som du trykkjer @på, så kjem det flamme.\n" +
    "I: Ja, sånn lighter?\n" +
    "72: Ja, så bad mamma meg om å *fjerna noko ugras, tenkte eg at ja, ja. Så @såg eg den @låg @på\n" +
    "¤trappa, skjønar du. Så tenkte eg i staden for å bli driten @på nevar og *plukka det, kva å *tenna\n" +
    "@på det? Kjem denne hunden @då hit og så snusar han og så berre smett han fint under, så eg held\n" +
    "\n" +
    "@på å *tenna @på i nakken @på han. Det veit eg ikkje om mamma og pappaen veit eigentleg, men\n" +
    "det er ikkje så nøye.\n" +
    "73: Kva då?\n" +
    "72: Eg heldt @på å *drepa nabokatten.\n" +
    "73: Nei.\n" +
    "I: He, he.\n" +
    "72: Jau. Han kom inn ¤døra, og så slo eg til han med ein kost. Jammen det var sommar, og så @måtte\n" +
    "me *lufta ¤døra, eller *opna ¤døra slik at det kom luft inn. Kom det ein katt som vil du *lokka inn\n" +
    "over oss sikkert, så han hoppa @på altanen, så han hoppa ut.\n" +
    "I: Har ikkje foreldra vore sinte på deg nokon gonger.\n" +
    "73: Jo, det har dei ja. Mm.\n" +
    "72: He, he, det hugsar eg @på Play Station, he, he.\n" +
    "73: Å, ja. Eg klikka @på mamma.\n" +
    "72: Kva du gjorde @då eigentleg?\n" +
    "73: Ho ville ikkje *hjelpa meg med ~leksene.\n" +
    "………………………………………………………………….\n" +
    "72: Og så @då dette ~regiongreiene med om at, om at dei skal @slå i saman dei tre ¤vestlandsfylka\n" +
    "og bli ein region.\n" +
    "I: Ja, har du tenkt på det?\n" +
    "72: Ja, @då blir, @då blir Sogndal så lite viss det blir ein heil region.\n" +
    "73: Ja.\n" +
    "I: Mm.\n" +
    "73: Eg vil ikkje det.\n" +
    "I: Ordføraren dykkar han sa litt tidlegare i haust at Sogndal var kjend for fotball, saft og puddersnø. Så\n" +
    "korleis vil de karakterisera Sogndal?\n" +
    "73: Sogndal saft.\n" +
    "72: Sogndal saft, §fjell.\n" +
    "73: Sogndal saft, §fotball.\n" +
    "72: §Fjell og fjord og §fotball.\n" +
    "73: Sogndal saft og\n" +
    "72: §Fjell, fjord og §fotball.\n" +
    "73: Nei.\n" +
    "72: Jau.\n" +
    "73: Nei, Sogndal saft og §fotball og §fjell og fjord, nei og fjord og §fjell.\n" +
    "\n" +
    "I: Den rekkefølgja der?\n" +
    "………………………………………….\n" +
    "I: Skal spela mot Start siste kampen, og det er vel eit lag som ligg langt nede?\n" +
    "73: Ja, dei burde dei *vinna mot.\n" +
    "72: Dei har rykt ned. Dei har rykt ned.\n" +
    "73: Ja.\n" +
    "72: Spesielt etter dei tapte i @går.\n" +
    "73: Kven dei tapte mot?\n" +
    "72: Viking.\n" +
    "73: Ja.\n" +
    "I: Ja, Start tapte i går, og Sogndal tapte òg i går.\n" +
    "73: Ja, dei tapte tre ein.\n" +
    "72: Dei leia ein null til syttiandre minutt\n" +
    "73: Ja.\n" +
    "72: eller noko.\n" +
    "73: Me høyrde det @på radioen.\n" +
    "I: Mm.\n" +
    "73: Me sat jo i bil i heile @går.\n" +
    "72: Herre Jesus.\n" +
    "73: Eller eg har jo sete i bil i heile ¤helga for å *seia det.\n" +
    "I: Kvifor det?\n" +
    "73: @På laurdag så var eg til Nordfjordeid.\n" +
    "I: Mm.\n" +
    "73: Men ferja der var stengt, så me @måtte *køyra via Stryn. Det var no to timar ekstra. Så det var\n" +
    "fire timar berre til Nordfjordeid, så tilbake.\n" +
    "I: Så de er mykje ute og reiser då når de er i helgane?\n" +
    "72: Ja.\n" +
    "73: Mm.\n" +
    "I: På turnering eller kampar og sånt.\n" +
    "72: Og så er det no til ¤helga att. @Då skal me same vegen. @Då skal me til Florø.\n" +
    "73: Peng.\n" +
    "I: Korleis trur de det ser ut her i Sogndal\n" +
    "73: Heilt, heilt …\n" +
    "I: om tjue år?\n" +
    "\n" +
    "72: Ja.\n" +
    "73: annleis. Kanskje ein ny #barneskule.\n" +
    "72: Ja, #gjerne ein #barnehage.\n" +
    "73: Ny #barne, ny #barneskule.\n" +
    "72: #Barnehage eller #barneskule.\n" +
    "73: Eg trur det kjem til å *koma sånn ny #barneskule.\n" +
    "72: Kanskje dei kjem med noko nytt at dei @går berre nokre @år i #barnehagen, og så har dei sånn\n" +
    "førskule, ikkje er #barnehage, men ikkje er skule heller liksom at dei, at det er sånn (forberedelse) til\n" +
    "skulen.\n" +
    "I: Trur de det vil vera noko grøntområde igjen nedi Fjøra?\n" +
    "72: Ikkje mykje.\n" +
    "73: Parken.\n" +
    "72: Hagen til besta og besten #gjerne.\n" +
    "I: Er de mykje på kulturhuset då?\n" +
    "73: Eg er ikkje.\n" +
    "72: Eg, eg ser ein film §innimellom.\n" +
    "I: Her ventar dei på gjest nummer ein million på kinoen.\n" +
    "73: Det blir meg.\n" +
    "I: Det kan bli deg, ja. Kva trur du du får i premie?\n" +
    "73: Uff, @då burde eg @få ein million.",

text_74og75: "I: Men hugsar de no før de begynte på skulen då? Når de var endå mindre?\n" +
    "75: Å, ja. Ja, me gjekk i #barnehagen.\n" +
    "74: Gjorde me? Ja, det gjorde me #gjerne.\n" +
    "I: Ja vel.\n" +
    "74: Ja.\n" +
    "I: Men har de vore på nokon skuleturar?\n" +
    "74: Me har vore til Fjærland.\n" +
    "I: Kan de fortelja om det?\n" +
    "75: Ja, der hadde me sånne forskjellige aktivitetar me skulle *vera med @på. Det eine var\n" +
    "kraft …\n" +
    "74: Kraft- <nøler> stasjon eller noko sånn greier.\n" +
    "75: Ja, kraftstasjon.\n" +
    "74: Og eine skulle du @på ein gard.\n" +
    "75: Og ein skulle du *fiska.\n" +
    "74: Ja.\n" +
    "75: Og det enda med at dei som var @på kraftstasjonen, dei hadde §alle ¤tinga bortsett @frå\n" +
    "fisking. Me var først @på kraftstasjonen. Så skulle me @gå opp der vatnet kjem, og så var me\n" +
    "innom ein gard, og det einaste me mangla, var fiske.\n" +
    "I: Ja, mm.\n" +
    "74: Sat i traktoren.\n" +
    "I: Men hadde de det kjekt saman då på …\n" +
    "75: Ja, ja.\n" +
    "I: … kvelden eller?\n" +
    "75: Ja, det hadde me.\n" +
    "I: Kva gjorde de då?\n" +
    "75: Me spelte §fotball og … ja i ballbingen der, og så spelte me ....\n" +
    "74: @Åt godteri gjorde me òg, så.\n" +
    "75: … §volleyball.\n" +
    "I: Ja.\n" +
    "……………………………………..\n" +
    "74: Nei, eg var der faktisk, @på @såg @på desse ~bilane.\n" +
    "I: Du såg den filmen?\n" +
    "74: Ja.\n" +
    "I: Ja. Var du der på premieren?\n" +
    "74: Jepp.\n" +
    "I: Mm. Det er litt spesielt då at dei har ei bilutstilling når dei skal visa film. Såg du den filmen\n" +
    "òg?\n" +
    "75: Ja.\n" +
    "I: Ja. Kan de ikkje fortelja litt om den filmen då? Det er jo ein oppfølgjar av ein som har gått\n" +
    "tidlegare. Har du sett …?\n" +
    "75: Ja, han er etterfølgjar av den «Burning 1», som han sa.\n" +
    "\n" +
    "I: Mm.\n" +
    "75: Denne her toaren den føregjekk @på snøen og …\n" +
    "74: Den skal vera litt sånn der vinteraktig sånn. @Då har §alle blitt litt eldre, og han som\n" +
    "køyrde i «Burning», altså einaren, han skal liksom, han skulle ikkje *køyra meir. Og så endar\n" +
    "det opp med at han begynner å *køyra etter kvart.\n" +
    "I: Mm.\n" +
    "75: Ja.\n" +
    "74: For at han skal *henta …\n" +
    "75: Ungen sin.\n" +
    "74: … ungen sin, ja.\n" +
    "I: Å, ja. Ja, kva hadde skjedd sidan einaren eller frå første filmen då? Kva … ?\n" +
    "75: Hadde blitt mykje eldre, og han, han Roy som han heiter, han kom ut or fengselet.\n" +
    "74: Ja.\n" +
    "I: Ja, kva synest de då når de samanliknar dei to filmane?\n" +
    "75: Eg likte toaren betre eg, fordi han føregjekk @på snøen og køyrde no opp eit skitrekk òg.\n" +
    "74: Ja, men det var, det var ikkje … Einaren @såg litt betre ut.\n" +
    "75: Ja.\n" +
    "74: Altså det var meir realistisk at det ville ha skjedd.\n" +
    "I: Ja vel. Ja, var det litt meir sånn <nøler> filmtriks på denne her andre då?\n" +
    "75: Ja.\n" +
    "74: Ja.\n" +
    "75: Det var det.\n" +
    "74: Det var liksom at dei skulle … Dei sladda framfor ein @båt, det var sånn att, og så berre\n" +
    "kjem dei køyrande nett framom, og sistemann greidde ikkje det. Og så berre køyrer han bånn\n" +
    "gass mot vatnet, og så berre bremsar han akkurat, og så dett denne ¤isblokka over, sant. Det er\n" +
    "jo null realistisk.\n" +
    "I: Ja. Eg såg første filmen, og slutten på den filmen var jo ein som for utfor for Nordkapp der\n" +
    "…\n" +
    "75: Ja.\n" +
    "I: … i lausa lufta.\n" +
    "74: Ja.\n" +
    "I: Så vakna han, vakna han i lufta.\n" +
    "74: Ja, ja, det var …\n" +
    "75: Det han opna i §mellom ¤seta der @då opna han, så drog han opp ein spake, @då kom det\n" +
    "vengjer på bilen.\n" +
    "74: <humrar>\n" +
    "I: Ja, vel.\n" +
    "74: Det kom i toaren @då så @då.\n" +
    "I: Å, ja.\n" +
    "75: Ja, det kom i toaren.\n" +
    "74: Og @då overlevde jo han.\n" +
    "I: Ja.\n" +
    "\n" +
    "74: Og @då var det jo dei som sende han utfor @då, dei som trudde han var død. Dei, det var\n" +
    "jo bestekompisen hans. Så @då handla det litt om det òg. Så @då.\n" +
    "I: Mm. Var det difor han kom i fengsel då eller?\n" +
    "74: Nei, det var for …\n" +
    "75: Nei, det var fordi dei driv og …\n" +
    "74: … køyrde race.\n" +
    "75: … køyrer, køyrer etter ~vegane.\n" +
    "74: Ja.\n" +
    "I: Så han hadde ikkje lov å gjera det fleire gonger?\n" +
    "75: Nei. Men det enda vel med … Det gjorde han vel att i toaren?\n" +
    "74: Ja.\n" +
    "I: Ja, men korleis enda den då?\n" +
    "75: Nei, han same fyren han vart no @påkøyrd av ein buss.\n" +
    "74: Ja, altså. Det var han som blei, køyrde utfor det stupet, han som dei trudde var død.\n" +
    "Akkurat i det dei var ferdige sant, @då var han i §rullestol og sånt.\n" +
    "75: Og så sette §rullestolheisen seg fast. Og så …\n" +
    "74: Sånn at det stod ut av bilen sånn, sant. Bilen var der, og så sat han der ute, fordi at han\n" +
    "ikkje fekk *koma inn att i bilen med §~rullegreiene,\n" +
    "I: Å.\n" +
    "74: for det hengde seg opp.\n" +
    "75: Og så kom det ei §gamal dame, og så flaug han framover med §rullestolen. Så kom det\n" +
    "ein …\n" +
    "74: Fordi at bilen @bråbremsa sant og rett framme så stoppar han der. Så kjem det ein trailer\n" +
    "og noko rett i han.\n" +
    "75: <humrar>\n" +
    "74: Og så sluttar han. <humrar>\n" +
    "I: [Uff].",

text_76og77: "I: Kan de fortelja nokon ting som de hugsar frå «gamledagar»?\n" +
    "76: Eg hugsar veldig godt at først så gjekk eg i Kyrkjebakken #barnehage, og men så\n" +
    "@etterpå bytte eg til Stedje #barnehage.\n" +
    "77: Eg gjekk @på #Kjørnes #barnehage. Eg skifta ikkje eller noko, men og eg gjekk med to\n" +
    "kompisar som eg @går i klasse med no. Me var ofte @på tur nedmed vatnet og @båt og alt\n" +
    "mogeleg. Det var [veldig] kjekt. Det var [no] kanskje fordel med å bu berre @på #Kjørnes,\n" +
    "fordi det var vatn nedved #barnehagen. Me var av og til @på @båttur og sånt…\n" +
    "I: Ja.\n" +
    "77: … og fiska. Me fiska med fiskestong av og til. Det var ikkje §alle som fekk, men me bytte\n" +
    "@på.\n" +
    "I: Hugsar de nokon ting som skjedde når de kom på skulen?\n" +
    "77: Eg har jo mange venner som har @gått @på forskjellige #barnehagar @då. Så no @når\n" +
    "me kom i @åttande, så har me bytt klasse.\n" +
    "I: Ja.\n" +
    "77: Så @då har eg kome i klasse med dei og @gått i klasse med dei og vore med dei i @åtte\n" +
    "@år no.\n" +
    "I: Ja, kva leikte de med i barneskulen, friminutt og sånne ting?\n" +
    "77: Nei, me spelte no veldig mykje §fotball. Det hugsar eg. Det var jo eigentleg det, nesten.\n" +
    "76: Eg hugsar at eg spelte ikkje så mykje §fotball @då. Eg berre følte at §fotball var ikkje\n" +
    "heilt mi greie.\n" +
    "I: Hugsar de første skuledagen? Det er ofte ein litt spesiell dag.\n" +
    "77: Ja. Det var jo @då eg møtte dei, så eg ville jo *prøva å *hugsa §alle ¤namna og *prøva å\n" +
    "@få dei så ~venene som passa meg. @Når eg kom litt utover i andre og tredje klasse, @då\n" +
    "fann eg dei som passa meg. Så @då har eg vore med dei i dei siste @¤åra no.\n" +
    "76: Ja, første skuledag den hugsar eg eigentleg ganske bra. Eg hugsar at me skulle @gå fram\n" +
    "til rektor, og han skulle *ønska meg velkomen til skulen og alt sånt. Inni klasserommet så\n" +
    "presenterte ~lærarane seg, og @etterpå så vidt eg hugsar, så gjekk me ut og leikte katt og mus.\n" +
    "I: Kva driv de med då, eller kva gjorde de på?\n" +
    "77: På ¤fritida?\n" +
    "I: Ja.\n" +
    "77: Eller på skulen eller kva?\n" +
    "I: Ja.\n" +
    "77: Nei, me spelte jo eigentleg, møttest jo på fotballtrening og fot… Det var jo §fotball. Det\n" +
    "var greia liksom. Så me spelte veldig mykje §fotball, og kanskje av og til leikte me litt\n" +
    "gøymsel eller noko @på skulen eller tikken eller ball-sisten.\n" +
    "I: Mm.\n" +
    "77: Med heile klassen @då av og til for å *prøva å @få med §alle. Så me ville jo *prøva å\n" +
    "*skapa ein vennegjeng @då.\n" +
    "……………………………………..\n" +
    "I: Har de vore på nokon skuleturar då som de hugsar?\n" +
    "\n" +
    "76: Ja, Fjærland hugsar eg veldig godt. @Då gjekk me og @såg rundt i bokbyen. Og så sov,\n" +
    "fekk me *sova @på @våre eigne hytter.\n" +
    "I: Kan du fortelja noko?\n" +
    "76: Eg delte rom saman med to andre som @går i klassen min. Me tre fekk det beste rommet\n" +
    "av §alle i heile hytta @vår. Me fekk den største hytta der det var massevis av rom, og §alle\n" +
    "¤romma var like bortsett @frå @våre. Så §alle blei ganske misunnelege @på rommet @vårt\n" +
    "@då. Me hadde sofa og bord og ein dobbelseng.\n" +
    "77: Me fekk ei hytte, den var … hadde jo ikkje lov å ha med meg IPad eller telefon eller noko.\n" +
    "Så eg hugsar at me @måtte @låsa ~dørene og sant, fordi at ingen skulle *storma huset @vårt\n" +
    "fordi at me hadde @fått ein tv der. Så me sat og @såg på tv om kvelden, og ~lærarane kom\n" +
    "inn og @såg på tv med oss. Og så var det aktivitetar dagen @etterpå med fisking og fjelltur\n" +
    "og for dei som ville det. @Då skulle *melda deg @på ein @då, så eg melde meg @på fisking.\n" +
    "76: Eg var på kraftverket, eg var og @såg @på kraftverket. Gaiden skulle *forklara oss, men\n" +
    "me høyrde jo ingenting.\n" +
    "…………………………………………\n" +
    "I: Er det noko de er flaue over med heimstaden?\n" +
    "76: Det var berre at eg fekk *høyra i #barnehagen at det skulle *koma ei ny bru, og så har dei\n" +
    "begynt @på den ¤brua no.\n" +
    "I: [Ja].\n" +
    "77: Skulle eigentleg vore ferdig dette @året her.\n" +
    "76: Ordføraren forklarte seg @på det. Han sa at viss dei hadde venta no, så kunne dei ha\n" +
    "@fått ein flott leikepark ved ¤brua liksom, med fjordstien i tillegg. Så har dei tenkt å *venta\n" +
    "litt sånn at det kunne bli betre.\n" +
    "I: Og så er det som du seier fjordsti [her]. Kva har de noko forhold til den eller?\n" +
    "77: Har @gått der to gonger kanskje. Men det er jo kjekt for tursitar kanskje. Men det er jo\n" +
    "ein fin tur, ikkje lang heller. Det er berre *rusla …\n" +
    "76: Laget mitt pleier å *jogga, har joggetur der. Eg blir jo ofte med @på desse ~joggeturane.\n" +
    "Me joggar jo ved fjordstien. Eg synest det er morosamt at me joggar, og så er det vatn rett\n" +
    "under oss nesten.\n" +
    "……………………………………………………\n" +
    "I: Det er òg mykje snakk om samanslåing av kommunar. Har de høyrt noko om det?\n" +
    "77: Det har eg høyrt, men eg har ikkje høyrt noko sånn om kva, @når og kvar.\n" +
    "76: Eg var faktisk med @på møte om det i kulturhuset, og dei snakka om sånn\n" +
    "@kommunesamanslåing. Og så fekk eg *vita at Sogndal var [den] einaste ¤bygda som hadde\n" +
    "svara ja, men resten hadde svara nei.",

text_78og79: "I: Ja, kjenner de kvarandre frå før, eller?\n" +
    "79: Me gjekk, me begynte i klasse i @år @når det var klasseskifte.\n" +
    "78: Ja.\n" +
    "I: Kan de fortelja noko om før de begynte på skulen for eksempel?\n" +
    "79: @Når me leikte i #barnehagen?\n" +
    "I: Ja.\n" +
    "79: Eg pleidde å *huska.\n" +
    "78: Ja, eg huska litt eg òg, og så sprang me att og fram. Eg hugsar veldig lite.\n" +
    "I: Men når de begynte på skulen då?\n" +
    "78: Eg kom … Eg kjende to stykke som eg gjekk i #barnehage med @frå før av.\n" +
    "I: Men kva gjorde de for eksempel i musikktimane i Trudvang? Kva gjorde de på då?\n" +
    "78: Me song.\n" +
    "79: Me spelte blokkfløyte.\n" +
    "I: Ja.\n" +
    "78: Ja, og så song me med blokkfløyte.\n" +
    "79: Og så hadde me gitar.\n" +
    "I: Gjorde de det same?\n" +
    "79: Ja.\n" +
    "78: Ja, det var sånn trinnvis.\n" +
    "I: Hadde de av og til noko sånn avslutningsfeiringar eller noko sånn oppsetjingar då?\n" +
    "79: Ja, me hadde nokon. Det var nokon som spelte gitar @på ¤avslutninga. Ja, §alle var med på eit\n" +
    "show eller noko.\n" +
    "78: Song i kor og …\n" +
    "……………………………………………………….\n" +
    "I: <nøler> Har de nokon ting de må gjera når de er heime? Har de nokon oppgåver som de blir sette\n" +
    "til?\n" +
    "79: Æh, *rydda rommet og *dekka @på eller noko.\n" +
    "78: Det er litt forskjellig, men eg må i §alle fall *rydda rommet.\n" +
    "I: Korleis ser rommet ditt ut? Korleis vil du beskriva det? Korleis ser det ut liksom viss du kjem inn\n" +
    "døra? Kva ser du då?\n" +
    "79: Rotete.\n" +
    "\n" +
    "78: <latter>\n" +
    "I: Ja.\n" +
    "79: ¤Senga, det er det du ser først. Og så ser du pulten og glaset. Det er det første du fangar blikket\n" +
    "@på.\n" +
    "78: <kremtar>\n" +
    "I: Ja, korleis ser ditt ut?\n" +
    "78: Æh, eg ser først @skåpet, og så ser eg glaset. Så ser eg ¤senga.\n" +
    "………………………………………………..\n" +
    "I: Men korleis vil de beskriva ein perfekt skuledag i dag då?\n" +
    "78: <nøler>\n" +
    "I: Korleis ser den ut?\n" +
    "78: Ikkje *begynna så tidleg.\n" +
    "79: Ja, *begynna klokka ni, og så har me noko morosamt dei første ~timane. Ikkje *begynna direkte i\n" +
    "~bøkene og *begynna å *skriva med ein gong.\n" +
    "78: *Begynna med noko gøy, enkelt. Kunst og handverk for eksempel.\n" +
    "79: Sånn som tysdag. Det er den beste dagen.\n" +
    "78: <humrar>\n" +
    "I: Ja, fortel om tysdagen.\n" +
    "79: @Då begynner me med tre timar kunst og handverk og så … Ja, @då har me jobb med det §alle\n" +
    "saman.\n" +
    "78: Det er to\n" +
    "79: Halve, ja halve gruppa har @måling, og den andre gruppa har pjone. Og så @få, er det friminutt.\n" +
    "@Då @går me ut, og så … Me spelte §fotball i dag. Og så begynner me @på det me arbeidde med\n" +
    "igjen. Og så @når me er ferdige, så skal me @gå tur @på tysdagar. Og i dag gjekk me ned ved …\n" +
    "78: Vatnet. ¤Brua.\n" +
    "79: Ja, me gjekk, me gjekk over ¤brua nede ved høgskulen, opp att. Så hadde me mat, så ringde det\n" +
    "inn.\n" +
    "78: Og så er me her.\n" +
    "79: Og så er me her. Så pleier eg å @gå på trening. Det ja … Eg har trening nesten kvar dag etter\n" +
    "skulen.\n" +
    "78: Det har eg òg.\n" +
    "79: Og så pleier, gjer eg lekser, og så slappar eg berre av.\n" +
    "…………………………………………………………\n" +
    "78: Me har MOT-dag @på skulen.\n" +
    "\n" +
    "I: De har MOT ja.\n" +
    "78: MOT og glede.\n" +
    "I: Kan du fortelja noko om MOT, for det hadde ikkje me når eg gjekk. Det er nytt.\n" +
    "78: <nøler> Me har dei to første eller dei to siste ~timane. Så er det nokon som framfører talent, og\n" +
    "så er du @måte med klassen den dagen.\n" +
    "I: Ja, kva går det ut på? Kva er vitsen med det MOT?\n" +
    "78: Å *visa glede til andre.\n" +
    "79: At, at §alle skal *tora å ha mot til å *visa kva dei kan.\n" +
    "……………………………………………………………………………………..\n" +
    "I: Eg har høyrt at i dag er det ein sånn mobilfri dag på skulen.\n" +
    "78: Ja.\n" +
    "I: Kva, kva tenkjer de om det?\n" +
    "78: <latter>\n" +
    "79: Nei, det er jo litt trist. Me @får ikkje brukt telefonen. For flesteparten av ungdomar i dag er @på\n" +
    "telefonen.\n" +
    "I: Ja.\n" +
    "78: §Alle er det.\n" +
    "79: Ja.\n" +
    "I: Kva gjer de i dag då når de ikkje har moglegheita?\n" +
    "79: Uff, me spelte §fotball i eine friminuttet, og så sat me inne og prata.\n" +
    "…………………………………………………………..\n" +
    "79: @Når eg tenkjer §fotball @då tenkjer eg på ~Fosshaugane.\n" +
    "78: Stadion eller hallen.\n" +
    "79: At det er gratis å *trena i hallen, for det er det ikkje så mange andre plassar i Noreg det er.\n" +
    "I: Nei.\n" +
    "78: Me har @Kvåle @då.\n" +
    "79: Og at du kan *trena heile @året.",

text_80og81: "I: De har sikkert eit rom heime. Korleis vil de beskriva det viss du står ved inngangen til\n" +
    "rommet, korleis, kva er der inne og?\n" +
    "81: Viss det er ¤døra?\n" +
    "I: Ja.\n" +
    "81: Viss ¤døra @då @står inntil veggen der, så er ¤senga mi her, der eg sit og så har eg nokre\n" +
    "knaggar framfor ¤senga @på ein vegg, og så har eg ein kommode med, som eg kan *setja ting\n" +
    "i. Og så er det ein sånn §hylle @på ein @måte som @står @på bakken, og som har fleire\n" +
    "§hyller i seg. Og så er det @då ¤senga mi igjen.\n" +
    "I: Ja, korleis er ditt?\n" +
    "80: Nei, eg har enkelt rom eg. ¤Døra @står @på ein @måte der som ho @står her, og så er\n" +
    "¤senga bakom ¤døra. Og så har eg ein kommode der musikkspelaren @står, og så har eg ein\n" +
    "pult og så eit glas over pulten. Og så har eg @klesskåp framfor ¤døra, og så er det ¤døra.\n" +
    "I: Kvar søv du hen og?\n" +
    "80: ¤Senga. [Jo], ¤senga @står bak ¤døra.\n" +
    "I: Å, ja.\n" +
    "80: Ja, når du opnar ¤døra, sant,\n" +
    "81: Å, ja, å ja.\n" +
    "80: så @står ho bakom ¤døra.\n" +
    "………………………………………………….\n" +
    "81: Vis MOT veit eg kva er. <humrar>\n" +
    "I: Ja, det har de her på skulen.\n" +
    "81: Ja.\n" +
    "80: Ja.\n" +
    "I: Kva går det ut på?\n" +
    "81: Det er at du skal *tora å *seia nei til ting og *tora å *seia din eigen meining mot folk\n" +
    "@då.\n" +
    "80: *Vita korleis du skal *vera mot andre og dei andre ¤folka, og ikkje *vera slem og sånt,\n" +
    "@slå.\n" +
    "81: Ja.\n" +
    "I: Mm.\n" +
    "81: Og *vita at det liksom kanskje sjølv om det ikkje ser sånn ut, så kan det *henda at folk\n" +
    "faktisk har, har ein tung bakgrunn enn det me kanskje er vant til @då, andre folk.\n" +
    "80: Eller @då legg dei opp eit program me skal *gjera. Og så @då seier dei no skal me ha eit\n" +
    "skodespel og at nokon skal @gå ut på gangen, skal de *sitja der, og så skal nokon *koma inn,\n" +
    "og så @får dei spørsmål om å @få øl, vin og røyk og snus og masse sånt.\n" +
    "I: Ja.\n" +
    "80: Og så skal dei *prøva å *seia nei mot eit gruppepress.\n" +
    "81: Og så er det jo alltid nye ting @då, men det er alltid @på det same,\n" +
    "80: <hostar>\n" +
    "81: alltid innom, innafor …\n" +
    "80: … same tema.\n" +
    "\n" +
    "81: Ja, at det liksom har alltid same meining med alt @då.\n" +
    "I: Mm.\n" +
    "81: Men dei lærer oss det @på ein morosam @måte, så me hugsar det @på ein @måte, i\n" +
    "staden for å berre *sitja der og *høyra @på kva dei snakkar.\n" +
    "I: Mm.\n" +
    "81: Så det er @på ein @måte ein kreativ @måte å *gjera det @på.\n" +
    "I: Så de må inn i dei forskjellige rollane då?\n" +
    "80: Ja.\n" +
    "81: Ja.\n" +
    "I: Mm.\n" +
    "80: Men det er sånn friviljug opp med ¤handa dei som vil *vera med …\n" +
    "I: Ja.\n" +
    "80: … @på skodespel og sånn.\n" +
    "81: Du @får jo *velja sjølv og sånn.\n" +
    "I: Men trur de det har nokon effekt å jobba sånn?\n" +
    "80: Ja.\n" +
    "I: Mm.\n" +
    "80: Veldig masse.\n" +
    "……………………………………………………………………….\n" +
    "I: For mykje tull på Facebook. Er det noko de brukar?\n" +
    "80: Ja.\n" +
    "81: Eg brukar Facebook ja, men eg legg ikkje ut noko.\n" +
    "I: Kva brukar de det til?\n" +
    "80: Snakkar med vener …\n" +
    "81: Ja, så har me sånn app som fører [med] mot Facebook. Så er det sånn snakkeapp som det\n" +
    "@går an å *snakka saman med. Og så er det sånn poke @då, så du kan *poka ~venene dine.\n" +
    "80: <humrar> Tikken @på ein @måte.\n" +
    "81: Ja, liksom.\n" +
    "I: Ja.\n" +
    "81: Liksom no pokar eg ho.\n" +
    "I: Ja.\n" +
    "80: Og så pokar eg tilbake.\n" +
    "81: Så pokar ho tilbake. Og så er det sånn at du kan *lesa om ¤saka @på Facebook, ting som\n" +
    "interesserer deg og sånne ting, @sjå kva andre ~venene dine likar og kommenterer. Og ja …\n" +
    "80: @Sjå kva ~venene dine gjer @på ¤fritida for eksempel viss dei legg ut noko.\n" +
    "81: Ganske enkelt [i alle fall] viss du spør om noko @på Facebook, så @får liksom §alle\n" +
    "~venene dine sin [mailar], og @då kan dei *spørja om, nei *svara og sånne ting.\n" +
    "…………………………………………….\n" +
    "I: <nøler> Kulturhuset i Sogndal, det er litt i vinden. Har de brukt det ein del, eller?\n" +
    "81: Ja.\n" +
    "80: Kino.\n" +
    "81: @Går ofte @på kino, teater, det er liksom sånne ting @då.\n" +
    "………………………………………………..\n" +
    "\n" +
    "80: ¤Saftbygda.\n" +
    "81: Ja. Det å *kunna *§kalla, liksom *seia eg kjem @frå ¤saftbygda,\n" +
    "80: Det er liksom …\n" +
    "81: … det kan du ikkje *seia viss du bur i Leikanger …\n" +
    "80: Aurland.\n" +
    "81: … liksom eller Aurland eller Balestrand liksom.\n" +
    "I: Men Sogndal då, det er jo ein plass som har vakse mykje dei siste åra og …\n" +
    "80: Ja.\n" +
    "I: … forandra seg mykje.\n" +
    "80: Sogndal §fotball.\n" +
    "I: Ja, kva synest du om det?\n" +
    "80: Det er no bra at dei greidde å *koma seg opp @ifrå OBOS-ligaen @då. Så spelar dei bra\n" +
    "§fotball.\n" +
    "81: Mm, dei tapte jo no @då.\n" +
    "80: Ja.\n" +
    "I: Men er det viktig for dykk at me …\n" +
    "80: Ja.\n" +
    "I: … har eit lag som er i eliteserien?\n" +
    "80: Det er veldig viktig.\n" +
    "81: <uforståeleg> litt @då.\n" +
    "………………………………………………………..\n" +
    "I: Kva trur de har forandra seg i Sogndal då om tjue år? Det skjer jo ganske mykje no.\n" +
    "81: Storleiken.\n" +
    "80: §Fotballen.\n" +
    "I: Det er jo ein frukthage her oppe på Rutlin som dei har snakka ein del om …\n" +
    "80: Ja, skal det bli #barnehage.\n" +
    "81: Det har ikkje eg høyrt.\n" +
    "80: <latter>\n" +
    "I: Trur de det kjem til å bli noko sånt?\n" +
    "80: Håpar no ikkje det. @Får håpa at eplehagen kan *vera der, for det er ganske gode eple.\n" +
    "I: Ja, viss det er ei fruktbygd, så må det vel vera …\n" +
    "80: Ja.\n" +
    "I: … kanskje frukt ein plass?\n" +
    "81: Eg håpar at han blir der, men eg håpar at dei finn eit anna stad som det @går an å *setja\n" +
    "ein #barnehage.\n" +
    "I: Mm.",

text_82og83: "I: Men hugsar de frå de var mindre, frå de før de begynte på skulen?\n" +
    "82: @Då me gjekk i #barnehagen og sånt?\n" +
    "I: Ja.\n" +
    "82: @Då eg gjekk i #barnehagen så pleidde eg no å eigentleg mest å *leika med gutar og sånt,\n" +
    "eigentleg.\n" +
    "I: Kva fann du på då?\n" +
    "82: Klatra, bygde ting som ofte dei andre ~jentene ikkje pleidde å *gjera.\n" +
    "I: Hugsar du noko frå før de begynte i skulen?\n" +
    "83: Ja, eg var (i hvert fall) meste med ~gutane og sånt no, så var det nokon jenter som var\n" +
    "med oss. Og så dreiv me @på og så leikte rundt i #barnehagen.\n" +
    "I: Første skuledagen då? Er det ein dag de hugsar noko frå?\n" +
    "83: Eg hugsar [i hvert fall] @frå starten av dagen @då me var, stod nede @på plassen @på\n" +
    "Trudvang, og så @når dei ropte opp namnet @på §alle ~førsteklassingane.\n" +
    "I: Mm. Hadde de noka sånn samlingsstund eller noko sånt i barneskulen?\n" +
    "83: Me hadde slike benkar me sat @på, så var det sånn framme [med] tavla.\n" +
    "I: Ja.\n" +
    "83: Så sat me der litt i §alle fall.\n" +
    "82: Mm.\n" +
    "I: Kva gjorde de då då?\n" +
    "83: Nei, ein gong i veka så skulle me ta med ein ting som begynte @på ein bokstav, så skal\n" +
    "§alle ha med ein sånn ting.\n" +
    "82: Ja.\n" +
    "83: Så er det alltid sånn at @når du kom til første bokstaven i namnet, så var det, tok du alltid\n" +
    "med deg sjølv.\n" +
    "82: Mm.\n" +
    "I: Hugsar du noko?\n" +
    "82: Ja, det og så @då hadde ofte så hadde me hems. Dei fleste ¤klasseromma hadde hems. Og\n" +
    "der pleidde skikkeleg mange å *vera der å *lesa og *eta og liksom *snakka saman og sånt.\n" +
    "I: Men hadde de nokon sånne musikktimar?\n" +
    "83: Me hadde vel framføring ein gong §innimellom\n" +
    "82: Ja.\n" +
    "83: som ¤foreldra kunne *koma å @sjå @på.\n" +
    "82: Til jul og @påsken og …\n" +
    "83: Ja, men så var det noko sånn §innimellom nokre skuletimar.\n" +
    "82: Var det?\n" +
    "83: Så hadde me øvd nokre songar, så kom ¤foreldra viss dei hadde lyst.\n" +
    "82: Trur ikkje me hadde det.\n" +
    "83: Det veit [eg] ikkje. Trur (hvert fall) me hadde det. Me song, song, me hadde sånn song\n" +
    "@bakpå vekeplanen me skulle *syngja kvar dag før første time og så før fekk lov til å @gå.\n" +
    "…………………………………………………………………….\n" +
    "I: <nøler> Viss de skal beskriva rommet dykkar, sei de kjem inn her då, korleis ser det ut?\n" +
    "\n" +
    "82: Den første veggen @når man ser, @når man kjem inn, den fremste der, så er det\n" +
    "vindauge, og der har eg ingenting. Og så @på den siste veggen, så har eg berre @skåpet mitt,\n" +
    "nei bordet mitt eller pulten.\n" +
    "I: Kva har du bilete av då?\n" +
    "82: <nøler> Eg har bilete av tante og ~onklane mine @då me var @små, og så har eg ein\n" +
    "billett @frå Disneyland, trur det var den første turen eg reiste @på, eller som eg hugsar (i\n" +
    "hvert fall). Ein bilete av ¤bestemora mi og meg @på kafé.\n" +
    "I: Korleis ser ditt rom ut då?\n" +
    "83: @Når du kjem inn, så til høgre med ein gong, så har eg eit skrivebord der eg sit og gjer\n" +
    "lekser @på. Og så @på eine veggen, så har eg sånn der (whiteboard) som eg kan *skriva,\n" +
    "skriv @på. Viss eg skal ta notatar og sånn, så pleier eg å *skriva på den. Og så attmed der\n" +
    "igjen, midten, nesten sånn midt i rommet, så er ¤senga mi, og så <uforståeleg> sånn plakatar\n" +
    "av heile Sogn og ~Fjordane. Og så er det <nøler> ei §hylle under der igjen.\n" +
    "………………………………………………………………………\n" +
    "I: Men viss de skal fortelja om Sogndal til folk som ikkje er herifrå, kva vil de dra fram då?\n" +
    "Kva vil de seia er typisk for [Sogndal]?\n" +
    "83: Verdas einaste tippeligabygd.\n" +
    "82: <nøler> Det kjem jo mange turistar her i sommaren for å @sjå på fjorden og ¤§fjella og\n" +
    "sånt og ~isbreane, og så tek dei ofte bilete av alt saman. <humrar>\n" +
    "83: Ja, alt @ifrå\n" +
    "82: Eple\n" +
    "83: epletre til ~bossdunkane.\n" +
    "82: Ja.\n" +
    "I: Kva vil du seia er typisk? Kva vil du trekkja fram? Kva trur du dei kjem for å oppleva her?\n" +
    "83: Naturen, ¤§fjella og ~fjordane og sånt.\n" +
    "I: De skriv, i norsken, så skriv de to skriftspråk,\n" +
    "82: Ja.\n" +
    "I: nynorsk og bokmål. Kva <uforståeleg>\n" +
    "83: Me skriv ikkje så masse @bokmål eigentleg no.\n" +
    "82: Nei.\n" +
    "I: Men at de lærer (i hvert fall) begge.\n" +
    "83: Ja.\n" +
    "I: Kva synest de om det?\n" +
    "82: Eg synest eigentleg at det burde no vore litt meir om @bokmål og litt, kanskje litt mindre\n" +
    "om nynorsk.\n" +
    "83: Men i dagleglivet @vårt så blir jo me, blir det jo masse @bokmål som blir brukt @på tv\n" +
    "82: Mm.\n" +
    "83: og @på, @på sosiale media og sånt òg og sånt.\n" +
    "I: Men kva skriv de når de skriv på sosiale media då?\n" +
    "82: Eg skriv blanda.\n" +
    "I: Du blandar?\n" +
    "82: Mm.\n" +
    "83: Eg skriv sånn som eg pratar.\n" +
    "\n" +
    "82: Ja.\n" +
    "I: Du skriv dialekt liksom?\n" +
    "83: Ja.\n" +
    "82: Mm.\n" +
    "83: Eg synest det er enklast å *skriva dialekt. Og så kjem det jo an @på kven eg skriv, for eg\n" +
    "skriv jo ikkje kanskje så veldig masse dialekt til ¤besteforeldra mine.\n" +
    "I: Nei.\n" +
    "82: Nei.\n" +
    "83: Der skriv eg kanskje meir skikkeleg.",

text_84og85: "I: Ja, kva hugsar du då?\n" +
    "85: Ja, me, me hadde alltid sånne raude og @blå stolar @på, i #barnehagen. Og så, eg hugsar ikkje\n" +
    "kven som var først, men begge to var fast innstilte @på å ha akkurat den stolen, for det var einaste\n" +
    "[dei] var att. Ja, så me dreiv og krangla om den i sikkert tre minutt før ein vaksen kom og så tok han\n" +
    "@ifrå oss og sette, sette seg @på han sjølv.\n" +
    "84: Altså du @må ha den raude stolen.\n" +
    "85: Ja.\n" +
    "84: Viss du har den @blå stolen, @då var du ukul liksom.\n" +
    "85: Ja.\n" +
    "I: Ja vel, så det var sånn idé som de hadde fått?\n" +
    "85: Ja.\n" +
    "84: Ja. <humrar>\n" +
    "85: Og så hugsar du sofaen? Me alltid skulle *sitja i kroken?\n" +
    "84: Ja, det var veldig kjekt @då @når …\n" +
    "85: Ja.\n" +
    "84: @Når me hadde samlingsstund, så var det veldig stas å *sitja i kroken i, av sofaen.\n" +
    "I: Kva andre ting gjorde de i samlingsstundene då?\n" +
    "85: Altså me song songar.\n" +
    "……………………………………………………………………………..\n" +
    "I: Ute då når de i friminutta og sånn? Kva gjorde de på?\n" +
    "84: I første til fjerde klasse så var eg eigentleg ikkje veldig mykje med ~jentene. Eg var veldig mykje\n" +
    "med sånn ~gutane og leikte i sandkassen og leikte (captain America) og sånne ting. <humrar>\n" +
    "…………………………………………………………………….\n" +
    "I: Kan de beskriva rommet dykkar?\n" +
    "84: Golvet er heilt dekt av klede. Og så har eg ei seng @på høgre sida @når du kjem inn. Og så har eg\n" +
    "ein skrivepult @på venstre sida. Og så har eg ein sånn, sånn stong med kleshengarar @på til, ja til\n" +
    "høgre @då liksom framfor ¤senga. Og så har eg eit lite @skåp der òg. Så har eg nattbordet mitt\n" +
    "attmed ¤senga, og så har eg ~gardinene mine, og så, ja så har eg ein §yogaball som eg sit @på @når\n" +
    "eg sminkar meg. <humrar>\n" +
    "I: Ja, men heilt nake rundt deg då?\n" +
    "85: ~Veggane.\n" +
    "\n" +
    "84: ~Veggane? Ja, nei eg har ein plakat med … Me hadde eit sånn prosjekt i fjor som heitte «Ung\n" +
    "2016». Det var sånn danseprosjekt som me hadde her @på skulen @då. Det var kjekt. @Då hadde\n" +
    "me ein plakat, og den heng @på veggen. Elles er det ganske tomt eigentleg. <humrar>\n" +
    "I: Mm. Korleis ser ditt ut då?\n" +
    "85: Eg skal *byta rom @då. Det, eg skal liksom @få halve, det bitte vesle halve kjøkenet @vårt. Men\n" +
    "(i hvert fall) eg har rydda det no, for eg skal ha besøk. Eg vaskar som oftast ikkje klede, men @då dei\n" +
    "@fåe ~gongene i @månaden eg ryddar, @då … Altså eg har liksom ¤senga mi liksom inntil høgre, og\n" +
    "så over der så har eg liksom ein knagg og ein spegel. Og så har eg ei §hylle som pappaen laga. Jo og\n" +
    "så har eg ein vegg @då, for eg @går @på taekwondo og 4H, så eg har sånn §alle taekwondo-\n" +
    "¤sertifikata mine hengt opp og så §alle 4H …\n" +
    "84: Plakatar [sånn].\n" +
    "85: Nei, ikkje plakatar dei der, dei ¤arka.\n" +
    "84: Ja, du veit sånn. Eg §kallar det berre plakatar eg. <humrar>\n" +
    "85: Det me @får godkjent. Og eit bilete av ¤søskenbarna mine.\n" +
    "…………………………………………………………………….\n" +
    "I: <nøler> Har de nokon heime som de er ueinige eller einige med?\n" +
    "85: ~Brørne mine,\n" +
    "84: Sånn …\n" +
    "85: pappaen, mamma, §alle.\n" +
    "I: Ja.\n" +
    "85: Så har me ein sånn skikkeleg §gamal moped sant, så @frå oldefar min. Og så farfar min [berre],\n" +
    "sant «Ja, viss ikkje den verkar, sant, så @må me jo, @må jo *kjøpa ny ein, sant?» Så me køyrde\n" +
    "liksom ein omveg @på sånn to timar for å *kjøpa ein moped i §tilfelle den gamle, @åtti @år gamle\n" +
    "mopeden ikkje funka.\n" +
    "84: <humrar>\n" +
    "85: Berre for å ha noko å *leika med [då].\n" +
    "……………………………………………………………………\n" +
    "I: Kva betyr det for dykk her at me har eit lag som er i eliteserien og sånn då?\n" +
    "85: Eg tykkjer det er tøft. Viss eg er @på kampar … Eg likar å liksom *vera @på kampar sjølv om eg er\n" +
    "der minst eller maks sånn ein gong i @året. Bitte vesle ¤bygda så med sju tusen personar er betre\n" +
    "enn liksom storbyar.\n" +
    "I: Mm. Kva seier du?\n" +
    "84: Viss (man) er i Sogndal og ikkje spelar §fotball, så blir (man) ikkje lagt merke til. Det er liksom ho\n" +
    "jenta som ikkje spelar §fotball liksom. Viss du ikkje spelar §fotball i Sogndal, så er du liksom dum.\n" +
    "<uforståeleg>\n" +
    "85: Heile familien min og altså ¤søskenbarna mine og liksom heile ¤slekta mi har spelt §fotball, så eg\n" +
    "…\n" +
    "\n" +
    "84: Eg har ingenting imot §fotball, men det er sånn eg hadde det veldig før, for det var liksom sånn\n" +
    "«Å, fotball er så kjedeleg og eller får ball i fjeset.» Og var skikkeleg redd §ballen og hata det. Men no\n" +
    "er eg berre sånn viss me skal ha det i fysakktimen, greitt. Men ikkje set meg, <humrar> ikkje lat oss\n" +
    "*spela mot dei som er skikkeleg gode liksom, for det er ikkje gøy.\n" +
    "…………………………………………………………………………….\n" +
    "85: Og så dei kan *laga, *laga liksom #barnehagar §alle andre plassar, men dei kan liksom ikkje [flytt]\n" +
    "berre *flytta ein eplehage @derifrå og liksom annan plass.\n" +
    "84: Kvifor kan dei ikkje liksom berre ha sånn ein gigantisk #barnehage sånn ti etasjar, berre sånn\n" +
    "ekstremt stor #barnehage. <latter>\n" +
    "85: Altså eg, eg likar mykje betre @småe #barnehagar.\n" +
    "84: Ja, ja.\n" +
    "85: Eg synest Notsete #barnehage, for du kjenner §alle.\n" +
    "84: Det er koseleg. Sånn i Dalen kan det *vera sånn liten koseleg #barnehage, men i Fjøra så er det\n" +
    "liksom sånn fire, fem, @åtte #barnehagar så @då. <humrar>\n" +
    "………………………………………………………………………………….\n" +
    "85: Me snakka om det i fjor. Me hadde sånn der bygd,\n" +
    "84: Prosjekt,\n" +
    "85: sånn ¤bygda mi.\n" +
    "85: Og så me skulle …\n" +
    "84: Der me hadde fleire grupper, og så fekk §alle forskjellige @oppgåver.\n" +
    "85: Ja.",

text_86og87: "87: Me har jo @gått @på #barneskulen i lag.\n" +
    "I: Men hugsar de noko før de begynte i skulen?\n" +
    "86: #Barnehagen?\n" +
    "I: Ja.\n" +
    "86: Ja.\n" +
    "87: @Då, @då,\n" +
    "86: Eg ... Nei,\n" +
    "87: gjekk me vel ikkje i same klasse?\n" +
    "86: eg gjekk @på Stedje.\n" +
    "87: Eg gjekk @på Rones.\n" +
    "I: Men hugsar de kva de dreiv med der då, kva de gjorde på?\n" +
    "86: Eg var no litt galen eg, @då. Sprang rundt og.\n" +
    "I: Mm.\n" +
    "87: Eg var alltid sånn at eg, eg trudde eg var ganske mykje tøffare enn §alle dei andre. Så eg, eg\n" +
    "bestemte litt over andre og kva me skulle *leika og sånn.\n" +
    "I: Men første skuledagen då, er det noko de hugsar frå den?\n" +
    "87: @På #barneskulen?\n" +
    "I: Ja.\n" +
    "87: Ja, det var spennande å *koma opp @på skulen. Det gledde eg meg to @år\n" +
    "til før eg begynte @på skulen. Det var første skuledagen. Den skulle bli spesiell. Skulesekk og\n" +
    "@gå til skulen og lærarar og …\n" +
    "I: For det er ikkje så langt ifrå skulen, så?\n" +
    "87: Nei.\n" +
    "86: Nei, ikkje #barneskulen.\n" +
    "87: Det var ein akkurat grei tur å @gå. Og så fekk me ha det kjekt. Og så hadde dei av og til\n" +
    "slike timar òg, @då. Me sat oss ned @på graset. Og så fortalde dei litt og.\n" +
    "86: Spente me litt §fotball, [og].\n" +
    "87: Mm.\n" +
    "I: Ja. Var jenter og gutar i lag i friminutta, eller? [Ja].\n" +
    "86: Ja, nja, kanskje ikkje så mykje @då.\n" +
    "I: Nei.\n" +
    "87: Det var meir gut og jente, trur eg,\n" +
    "86: Ja, ja [var det].\n" +
    "87: (i hvert fall) i klassen @vår.\n" +
    "I: Og det som òg er litt spesielt her, er at de har hatt nokre sånne fysakktimar heile tida, har\n" +
    "ikkje de det?\n" +
    "87: Jau.\n" +
    "86: Jo, det har me.\n" +
    "I: Korleis var det i Trudvang?\n" +
    "87: Det har blitt veldig masse fokusert @på @då, at @på Trudvang så var det veldig viktig med\n" +
    "fysisk aktivitet og helse §mellom ~timane, @då. Slik at @då friskar hjernen opp. Og du\n" +
    "@får, det er litt lettare å *læra. @Då har me sånne aktivitetar for eksempel at me spring\n" +
    "opp ein bakke, hentar\n" +
    "86: Ja.\n" +
    "87: eit @spørsmål, ned ein bakke, @må *finna det ut, opp ein bakke, leverer svaret, tek nytt\n" +
    "@spørsmål og så vidare.\n" +
    "I: Ja.\n" +
    "86: Det var jo laga sånn film av @på NRK,\n" +
    "87: Mm.\n" +
    "86: som var på tv-en, fordi #barneskulen i Trudvang\n" +
    "87: <kremtar>\n" +
    "86: og ungdomsskulen var liksom ein av dei mest, dei ~skulane som hadde lagt inn mest fysisk\n" +
    "aktivitet i ~timane, @då.\n" +
    "I: Mm.\n" +
    "86: Der me lærte og var i fysisk aktivitet samtidig.\n" +
    "I: Ja.\n" +
    "86: Så det var jo veldig bra.\n" +
    "I: Mm. Korleis opplevdest det å vera med på noko sånt, då?\n" +
    "87: Det var kjempekjekt.\n" +
    "86: Eg syntest det var, ja, eg syntest det var kjekt. Stas @når det var sånn kameramann og tv\n" +
    "og.\n" +
    "87: Å, det var koseleg!\n" +
    "86: Ja.\n" +
    "I: Ja, <latter> men de har det no òg?\n" +
    "86: Ja.\n" +
    "87: Ja, me har jo fysisk aktivitet. Men kanskje ikkje så mykje, @då.\n" +
    "86: Men det er ikkje, det er ikkje så mykje læring, liksom.\n" +
    "87: Nei.\n" +
    "I: Nei.\n" +
    "86: No er det mest sånn *sitja inne i ~timane. Og det er ikkje så kjekt.\n" +
    "87: Nei, det er meir fokus @på ¤faga, no @når me skal opp til eksamen om eit @år og sånn.\n" +
    "I: Korleis då, [trur du]?\n" +
    "86: Ja, det har jo vore sånn innsamling av mobilar no.\n" +
    "I: Ja, kva, då? Kva synest de om det?\n" +
    "87: Nei, eg synest eigentleg ikkje noko om det. Eg synest at viss me vil, så skal me @få lov til å\n" +
    "*sitja i ¤friminutta på ~telefonane. Det er liksom, @då @får me litt sånn pause @frå ¤faga,\n" +
    "@då. At me @får lov til å *spela litt og *slappa av litt @på telefonen. Viss me vil det. Og\n" +
    "så det er jo ikkje, sjølv om me har lov til å *bruka telefonen, så betyr jo ikkje det at §alle\n" +
    "sit, det er jo mange folk som sit og pratar og har telefon, for eksempel.\n" +
    "86: Me, me er jo, etter timen, så er me litt slitne og vil ut. Og så @då er me jo kanskje ein gjeng\n" +
    "i lag og sit og kanskje spelar eitt spel, som §alle har, @då.\n" +
    "I: Mm. Men kva gjer de då?\n" +
    "86: @Går me rundt og, i ~gangane.\n" +
    "…………………………………………………….\n" +
    "I: Men viss de skal fortelja om plassen de kjem ifrå, til nokon utanfrå, kva seier de er typisk\n" +
    "då?\n" +
    "Der du kjem ifrå? Kva seier du [då]?\n" +
    "86: Det er veldig mykje §fotball og sport. Og det er mykje saft og brus.\n" +
    "87: Mange\n" +
    "86: Ja.\n" +
    "87: som @står @på ski.\n" +
    "I: Ja.\n" +
    "87: Det er valdsamt mange som kjem @frå utlandet og alt mogleg, som kjem til [Sogn], Sogn og\n" +
    "~Fjordane og @står @på ski rundt om her, for det er så masse fine §fjell og masse god snø\n" +
    "og\n" +
    "I: Mm.\n" +
    "87: fine forhold.\n" +
    "I: Ja.\n" +
    "86: Og mange som @går på folkeskulen og høgskulen reiser jo hit nesten mest fordi all naturen\n" +
    "og §alle ¤§fjella og\n" +
    "87: Ja.\n" +
    "86: toppturar og alt mogleg, som er heilt fantastisk her, ski og.\n" +
    "87: Det er veldig ny høgskule, kjempebra bygd høgskule som er nede,\n" +
    "I: Mm.\n" +
    "87: rett under skulen @vår her.\n" +
    "86: Og det er jo, @når det er mykje snakk om Sogndal eller §fotball på tv, så er det jo ofte\n" +
    "Sogndal ¤saftbygda @då [som], eller som me er veldig kjende for.\n" +
    "I: Mm.\n" +
    "86: Ja.\n" +
    "87: Verdas einaste\n" +
    "86: Ja.\n" +
    "87: tippeligabygd.\n" +
    "86: Me har jo Lerum, som er over heile Noreg og.",

text_88og89: "89: Og så hadde me sånn ~gutane skulle ta ~jentene, og ~jentene skulle ta ~gutane og sånn.\n" +
    "88: Mm.\n" +
    "89: Og så har, var det ei sånn, slags trehytte @på ein @måte som me ofte var og klatra @på ein\n" +
    "@måte oppi. Og så var det sånt tre der og med sånne plankar som var spikra opp sant. Så klatra me\n" +
    "opp der, og kom oss ikkje ned igjen. <humrar>\n" +
    "88: <latter>\n" +
    "I: Nei. Ja, måtte det koma nokon og henta dykk då, eller?\n" +
    "89: Ja. Ein gong, men som oftast så sa dei vaksne berre «Ja, du får prøva å koma deg ned att.»\n" +
    "I: <humrar>\n" +
    "88: <humrar> Og så hugsar eg at det var ein sånn @båt der\n" +
    "89: Ja.\n" +
    "88: … som me alltid var @oppå, og du kunne berre *vera to om gongen. Så @då sat me alltid @oppå\n" +
    "der, og <uklart> og folk prøvde å *koma opp, så var det berre sånn «Nei, får ikkje lov.» <latter>\n" +
    "89: <latter> Var det ein @båt? Var ikkje det ein bil?\n" +
    "88: Jo, jo bil.\n" +
    "89: Jo.\n" +
    "88: Ja.\n" +
    "89: Med eit sånn tak @på ein bil.\n" +
    "88: Mm.\n" +
    "89: Og så var det det at for eksempel @når me hadde sånn ~gutane skulle ta ~jentene og sånn, så\n" +
    "sprang me alltid opp der, for @då kunne ingen ta oss.\n" +
    "88: Og så hugsar eg at det siste @året, så kunne (man) *klatra opp @på ein sånn …\n" +
    "89: Ja, @på den store plassen.\n" +
    "88: Mm.\n" +
    "89: Der som berre dei største kunne *vera.\n" +
    "I: Ja, korleis var den plassen då?\n" +
    "89: Det var ein sånn kanskje ein meter høg mur, så det @på ein @måte ein slags bakke over og litt\n" +
    "tre og sånn. Det var @på ein @måte oppe ved gjerdet. Der @på ein @måte toppen av #barnehagen.\n" +
    "88: Mm. Så hugsar eg det var veldig kult å *vera der oppe.\n" +
    "89: Ja, viss, viss du var der, så var du ein av dei kule liksom.\n" +
    "88: Mm.\n" +
    "I: Kva gjorde de der oppe då?\n" +
    "\n" +
    "89: Eigentleg ingenting.\n" +
    "88: Nei.\n" +
    "89: Det var berre å *vera der oppe og berre …\n" +
    "88: *Sjå\n" +
    "89: … @stå der og @sjå på §alle andre som ville *vera der, men ikkje fekk lov.\n" +
    "88: <humrar>\n" +
    "……………………………………………………………………….\n" +
    "I: Hadde de noko samlingsstund i barnehagen?\n" +
    "89: Ja.\n" +
    "88: Ja.\n" +
    "I: Ja. Korleis var det? Kva gjorde de då?\n" +
    "88: Mm.\n" +
    "89: Sånn me song og fortalde kva me hadde gjort i ¤helga og …\n" +
    "88: Så hugsar eg at me hadde sånn sovetime. <humrar>\n" +
    "89: Ja.\n" +
    "I: Å, ja.\n" +
    "88: Me høyrde @på musikk.\n" +
    "89: Og så den her, så dei vaksne las den ¤boka, veit du. Eg veit ikkje kva den heiter, men det var sånn\n" +
    "personar som stort, rundt ansikt med sånne @småe @hårtustar.\n" +
    "88: Ja. <humrar>\n" +
    "…………………………………………………………………………..\n" +
    "I: Har de vore på nokon skuleturar noko som de hugsar?\n" +
    "89: <nøler> @På Trudvang så var me på Astruptunet.\n" +
    "88: Ja, men eg hugsar det med Astruptunet at dei hadde noko sånn … Hugsar du dei der ¤tinga som\n" +
    "(man), som ein ikkje kunne *spisa? Men som @såg ut som … Nei, nei, rabarbra. <nøler>\n" +
    "89: Å, ja.\n" +
    "88: Det var sånne som @såg ut som rabarbra, men (man) kunne ikkje (*spisa) dei. Og så hugsar eg at\n" +
    "~dørene var sånn skikkeleg @låge.\n" +
    "89: Ja.\n" +
    "88: Og så sa dei at det var fordi om det kom nokon som ikkje skulle inn, så kom dei til å *stanga seg i\n" +
    "¤døra. <latter>\n" +
    "I: Mm.\n" +
    "\n" +
    "89: Og så var det noko sånn at @då kunne dei @stå, @stå og berre *opna ¤døra og @sjå kven det\n" +
    "var for og så <nøler> *finna ut om dei ville *opna, eller om dei ville *seia at dei skulle @gå og sånn.\n" +
    "…………………………………………………………………………….\n" +
    "I: Korleis vil de beskriva Sogndal til folk som kjem frå ein annan plass? Kva vil de seia? Kva vil de dra\n" +
    "fram og seia er typisk for … ?\n" +
    "88: Høge §fjell.\n" +
    "89: Ja, Sogndal er @på ein @måte blitt litt kjend @på grunn av §fotballen og Lerum og …\n" +
    "88: Det er jo ein del av ¤bygda.\n" +
    "I: Kva er du stolt over her i Sogndal då?\n" +
    "88: Eg veit ikkje, kanskje litt sånn naturen eigentleg. Og …\n" +
    "I: Kva med naturen då?\n" +
    "88: <nøler> Det er jo sånn høge §fjell og fin utsikt.\n" +
    "I: Mm.\n" +
    "88: Og så er det jo veldig fint @når (man) … Om kvelden @når (man) køyrer heim igjen, så ser man\n" +
    "§alle dei ¤lysa som er der @frå …\n" +
    "89: Mm.\n" +
    "88: @Når man køyrer inn @frå #Kjørnes, og så ser (man) @på Sogndal.\n" +
    "89: Ja.\n" +
    "………………………………………………………………..\n" +
    "I: Er de noko på ski eller?\n" +
    "88: I ¤fritida så pleier eg jo, om vinteren så pleier ¤foreldra mine å dra meg med til Hafslo @på ski, og\n" +
    "der … Eg likar meg best der sidan liksom eg ikkje er så glad i å *køyra rett nedover @på slalåm. Så eg\n" +
    "kan ta ei sånn koffertløype som er mykje meir slakk og avslappande. <humrar>\n" +
    "I: Mm. Så det er mest å stå på ski det?\n" +
    "88: Ja.\n" +
    "I: Du går ikkje så mykje?\n" +
    "88: Eller jo pleier … Mamma pleier å … Eller mamma og #søskenbarnet mitt pleier å @få meg med.\n" +
    "Så, eg er ikkje så veldig glad i det, men …\n" +
    "I: Er ikkje så glad i å … ?\n" +
    "88: Å @stå @på ski eller *køyra slalåm eller noko sånt.\n" +
    "I: Er det noko de saknar her?\n" +
    "89: <nøler> Eigentleg ikkje.",

text_90og91: "91: Eg begynte i #barnehage ganske seint.\n" +
    "I: Kva gjorde du på då i, i barnehagen?\n" +
    "91: I #barnehagen? <nøler> Eg hugsar ikkje så mykje. Eg hugsar at eg var veldig glad i å *teikna sånn\n" +
    "med Signe heile ¤tida. Og så @når me var ute, så var eg veldig glad i å <nøler> *huska.\n" +
    "90: Eg gjekk i Notsete #barnehage. Og i #barnehagen så var eg mykje med gutar. Og eg var med meir\n" +
    "gutar enn jenter @då. Og eg likte valdsamt godt å *køyra syklar uti desse ~greiene der.\n" +
    "I: Hadde de noko sånn samlingsstund og slik?\n" +
    "91: Ja, eg trur me hadde det.\n" +
    "I: Kva gjorde de då?\n" +
    "91: Det einaste eg hugsar det var vel at @når kvar einaste gong @når nokon hadde bursdag, så dreiv\n" +
    "dei, så heldt dei oss etter ~armane og ~føtene liksom, så dreiv dei liksom sånn halvveges kasta oss\n" +
    "opp i ¤lufta sånn så mange gonger som me fylte @år.\n" +
    "I: Ja. Så kom de opp her på ungdomsskulen, og kva blei annleis då, eller?\n" +
    "91: Mm.\n" +
    "90: Me gjekk @frå eldst til yngst.\n" +
    "91: Ja, det var ganske sånn stor overgang. Og så synest eg ¤friminutta på #barneskulen var mykje\n" +
    "kjekkare enn dei som er her oppe.\n" +
    "I: Ja, kvifor det?\n" +
    "90: Det var jo meir leiker @nedpå Trudvang enn her oppe @då.\n" +
    "I: Ja.\n" +
    "91: Ja. Her er det liksom dei ¤tinga som er, som kjekt å *driva @på med, det var det der hjulet som\n" +
    "dreiv og snurra rundt, og så var det jo §fotball.\n" +
    "90: Mm.\n" +
    "……………………………………………………………………………….\n" +
    "I: Korleis ville de karakterisera Sogndal viss de skulle seia kva som er typisk?\n" +
    "91: Ja, ¤§fjella og ja å @gå @på ski. Eller det er veldig mange som @går @på ski, slalåm.\n" +
    "I: Mm.\n" +
    "90: Ja, naturen det med puddersnø og sånt. Det er jo mykje natur. <nøler> ¤§Fjella og så fjorden og\n" +
    "så snøen framme i §Hollekve, for §Hollekve begynner å bli valdsamt bra og stort anlegg no.\n" +
    "91: Mm.\n" +
    "I: Ja, det forandrar seg jo veldig mykje der framme …\n" +
    "90: Mm.\n" +
    "I: … i Hollekve. Kva trur de vil skje vidare der framme då?\n" +
    "\n" +
    "90: Eg har høyrt rykte om at det skal *byggja ny #barneheis oppe i Rindabotn @då. Så eg trur det\n" +
    "kjem til å bli fleire folk som kjøper seg hytte der framme òg.\n" +
    "…………………………………………………………………………….\n" +
    "I: Men kan du fortelja om, om ein dag du er på riding då for eksempel?\n" +
    "90: <nøler> Ja. Først så hentar eg hesten @då og kostar den og sånn og så … Eller eg må *sjekka om\n" +
    "ho har skadar og sånn først @då. Og så salar du @på, og så no @når det er vinter @då, så bruker me\n" +
    "refleksdekken og hovudlykt sånn at folk skal @sjå oss, sånn at me skal *klara å @sjå kvar me rir og\n" +
    "sånn. Og så berre rir eg langs vegen der eg vil. Så spring eg litt §innimellom og trenar @på å *stoppa\n" +
    "og sånt og flyttar @på forskjellige partar @på hesten og …\n" +
    "……………………………………………………………….\n" +
    "I: Kulturhuset …\n" +
    "91: Å, ja.\n" +
    "I: Er det noko de brukar til noko?\n" +
    "91: <nøler>\n" +
    "90: Kino.\n" +
    "91: Ja, det er jo ein del kino, og så er det som eg sa med <nøler> danseoppvisning @då. Det er i\n" +
    "kulturhuset.\n" +
    "I: Kan du fortelja om ein sånn oppvisning de har vore med på?\n" +
    "91: Det er som regel så er det to §førestellingar. Sånn ein klokka tre eller fire eller noko sånt og så ein\n" +
    "klokka sju. Sånn at @når me ventar @på å ha noko å *gjera, så er me berre @på senteret og så\n" +
    "liksom kjøper godteri eller @går rundt og … Ja, det er vel veldig kjekt. Og så, så er det to dansar me,\n" +
    "me liksom skal *vera med @på i kvar §førestelling. Det er den som @vår gruppe har, og så er det\n" +
    "fellesdans @på slutten av §førestillinga. Men så ja, så er det omtrent ein halv time §mellom @vår\n" +
    "dans, sånn at det er ganske kort tid §imellom dei to.\n" +
    "I: Har du nokon opptreden med hest eller?\n" +
    "90: Eg har vore med @på … Eller i august @då så var eg med @på NM i brukhestriding.\n" +
    "I: Å, ja.\n" +
    "90: <nøler> Det er hinderløype @då. <nøler> For eksempel du skal *trava gjennom kjegler utan å\n" +
    "*velta dei. Så skal du kanskje *hoppa over eit hinder eller to. Og så skal du @gå av hesten og så opp\n" +
    "@på ei rampe. Og så skal du *leia hesten rundt mens du @går opp @på rampa, og så @gå @på igjen\n" +
    "@då. Så skal du *rygga inn ein plass og så ikkje *trakka @på ~strekane og så @gå ut igjen. Ja masse\n" +
    "sånn forskjellig.\n" +
    "…………………………………………………………………..\n" +
    "I: Er de, ting de legg merke til som, som forandrar seg liksom sagt over kort tid no?\n" +
    "90: Den der ¤Loftesnesbrua.\n" +
    "I: Ja.\n" +
    "90: Den blir jo bygd. Og så fjordstien.\n" +
    "I: Ja.",

text_92og93: "93: <nøler> Me har vel kjent kvarandre sidan @åttande #gjerne?\n" +
    "92: Ja.\n" +
    "I: Mm, sidan åttande ja.\n" +
    "92: Det har me.\n" +
    "I: Men før de begynte på skulen då? <nøler> Kva gjorde de på då?\n" +
    "93: Eg var no i #barnehagen, og så var eg @hjå dagmamma før.\n" +
    "I: Ja. Kan du fortelja noko om det? Hugsar du noko frå det?\n" +
    "93: <nøler> <humrar> Eg likte alltid å *klatra, og så mammaa og pappaen fann meg i ein stige @på\n" +
    "fleire meter opp i ¤lufta mens dei hadde nett sloppe meg av syne liksom så.\n" +
    "I: <humrar>\n" +
    "93: Nei, eg elskar å *klatra og *leika med sniglar.\n" +
    "I: Ja.\n" +
    "92: Ja, eg òg var i #barnehage, men eg var ikkje her @då. Og så likte eg å *sykla sånn skikkeleg mykje.\n" +
    "Og så begynte jo eg i førsteklasse her @på ~Norane. I ¤friminutta så var det sånn kjedeleg å berre\n" +
    "*sitja å @sjå @på og @snakka om kattar og sånn, så eg berre nei eg blir med @på §fotball.\n" +
    "I: Mm.\n" +
    "……………………………………………………………..\n" +
    "93: De gjekk no i fem timar de.\n" +
    "92: Ja, me skulle *koma ned att til sånn klokka sju. Me kom ned sånn halv @åtte.\n" +
    "93: De gjekk i ¤bekmørka oppe @på Nuken.\n" +
    "92: Me skulle eigentleg opp til Nuken.\n" +
    "I: Ja.\n" +
    "92: Og @når me begynte å @gå først. Me tok buss opp til @Elvagjengåsen, og så … Me skulle\n" +
    "eigentleg opp til Hølsete. Men det vart endra, så me gjekk opp til @Elvagjengåsen. Og det vart jo\n" +
    "heilt dogga, og du @såg ikkje nesten ingenting. Og så gjekk me, og så gjekk me opp ein sti, og der\n" +
    "fann me fram at du kunne ikkje @gå over ¤elva der. Så @då @måtte me @gå ned att og så @gå over\n" +
    "¤elva @på anna vis, @måte som me eigentleg sa først, men ja. Og så kom me lenger og lenger opp,\n" +
    "og så @når det vart sånn litt mørkare og @når me tok @på oss hovudlykter, så ja, gjekk det litt gale.\n" +
    "Sjølv om me liksom brukte kompass og kart, så gjekk me sånn feil, så me gjekk altfor langt opp enn\n" +
    "der Nuken skulle *vera og så gjekk me ned att, og så i sånn halv, nei sånn litt over sju, så var me oppe\n" +
    "@på Nuken. Men me hadde jo ikkje tid til å *laga @bål eller *eta eller noko sånn, så @då gjekk me\n" +
    "opp, og så gjekk me ned att.\n" +
    "I: Var det ekkelt då?\n" +
    "92: Nei. Det var ikkje. Det var litt kaldt, fordi du gjekk liksom i snø\n" +
    "\n" +
    "93: Snø.\n" +
    "92: og regn og vind @på same tid og alt saman så.\n" +
    "I: Å, ja.\n" +
    "93: Altså.\n" +
    "92: Det var eigentleg valdsamt kjekt.\n" +
    "93: Å @gå der oppe i skodde det er noko av det verste du kan *gjera, altså @på, @på Nuken det.\n" +
    "92: I (hvert fall) i bekmørket.\n" +
    "93: Ja. Mørkeret og skodde og myr og snø. Nei, veit du kva. <humrar>\n" +
    "I: Men de har no nyleg vore på ein skuletur, har ikkje de det?\n" +
    "93: Jau.\n" +
    "92: Jau.\n" +
    "I: Det var på tvers av klassar?\n" +
    "93: Mm.\n" +
    "92: Ja.\n" +
    "I: Kan de fortelja om den?\n" +
    "93: Jau, eg for min del synest det var ein ganske kjekk tur og ein ganske lærerik tur. Og eg føler liksom\n" +
    "det at klassemiljøet som me hadde i klassen før, altså det har blitt så mykje betre no etter den turen.\n" +
    "I: Mm.\n" +
    "93: @På for eksempel Auschwitz sant og @på det jødemonumentet og alt mogeleg sånt, sant du. Det\n" +
    "@går jo inn @på deg. Og eg trur folk liksom har teke til seg det at me er nøydd til å ta vare på\n" +
    "kvarandre liksom for at det skal, det skal @gå rundt. Nei, det har liksom berre vorte så mykje betre\n" +
    "enn det det var før.\n" +
    "I: Mm.\n" +
    "92: Ja, det same med oss òg. Me var liksom … Me har blitt meir sånn §alle samla. Altså me var §alle\n" +
    "samla før, men no har me blitt meir liksom. Og sånn som ho seier, det var masse følelsar og masse\n" +
    "inntrykk @på Auschwitz òg.\n" +
    "93: Det var ganske tungt. Eg, eg er ikkje den personen som grin. Eg @får ikkje … Det @går ikkje\n" +
    "liksom. Og liksom §alle andre rundt meg dei stod og snufsa og grein og alt sånn, men sant eg stod der\n" +
    "berre, ja. Altså for all del det var tungt, og det var, det var seige greier liksom, men eg @får liksom\n" +
    "ikkje ut ~tårene @då, for å *seia det sånn. Men den verste @på Auschwitz det var vel faktisk å @sjå\n" +
    "#barnekleda og @håret og …\n" +
    "92: Eg òg vart sånn skikkeleg, fekk sånn skikkeleg inntrykk @når du @såg bilete av folk korleis dei\n" +
    "@såg ut etter seksten @månader, etter dei hadde liksom begynt og @fått behandling etter krigen.\n" +
    "Og @då var det… Altså eg fekk, eg klarte … Eg er ikkje sånn grineperson eigentleg eg heller. Så @når\n" +
    "eg var der inne, så eg fekk liksom ikkje… Men @når eg kom ut og liksom realiserte at jau det der har\n" +
    "faktisk skjedd, og eg har faktisk vore der nede, @då kom det sånn …\n" +
    "\n" +
    "I: Mm.\n" +
    "93: Nei, det var no …\n" +
    "92: Jau me var jo nede i ~Saltgruvene.\n" +
    "93: ~Saltgruvene var me òg. Det var det.",

text_94og95: "I: Hadde de noko musikk og slikt når de var i barnehagen?\n" +
    "94: Ja, me hadde samlingsstunder der me høyrde mykje på musikk. Eg veit ikkje om me song så\n" +
    "mykje. Me høyrde no ein del, og me lærde ein del slike #barnesongar. Og me song no for maten heile\n" +
    "tid. Eg veit ikkje kva de gjorde.\n" +
    "95: Eh, me samla oss eller eg trur det var starten av kvar dag. Så samla me oss som oftast i ein ring.\n" +
    "Så song me sånne songar som «Lille Petter Edderkopp» og dei der. Og så kvar gong før me hadde\n" +
    "lunsj, så song me «Den som mettar liten fugl».\n" +
    "I: Men når de begynte i skulen då, hugsar de for eksempel første skuledag? Det er jo ein litt spesiell\n" +
    "dag.\n" +
    "94: Ja, eg gjekk no bort med mamma, trur eg det var og så stod me no i ein svær ring. Og så ropte,\n" +
    "rektor trur eg ropte opp ¤namna på dei i ~klassane, trur eg. Og så gjekk me bort. Det var litt\n" +
    "skummelt.\n" +
    "95: Hugsar det var sånn tredje eller fjerde klasse. @Då kom dei der #skorne med hjul under.\n" +
    "94: Ja. <humrar>\n" +
    "95: @Då brukte §alle dei. Og så var det så mange som slo seg. Dei datt bakover og slo hovudet.\n" +
    "94: Mm.\n" +
    "95: Og så sa skulen at det der var ikkje lov og, og så slutta mesteparten med det.\n" +
    "94: <humrar>\n" +
    "95: For det var ein sånn periode der §alle saman berre flaut forbi.\n" +
    "94: Ja. <humrar>\n" +
    "95: Det var ingen som gjekk.\n" +
    "94: Ingen gjekk, ja. Eg veit ikkje. Eg fekk sko eg òg. Til slutt.\n" +
    "95: Eg …\n" +
    "94: Eg trur det var etter §alle andre hadde hatt det så.\n" +
    "95: Eg fekk ikkje lov. Hugsar eg at eg ikkje fekk lov å *kjøpa telefon før eg var ti år §gamal heller. Var\n" +
    "så sur på mamma, for at … Og ein i klassen min som hadde telefon i første klasse eller noko.\n" +
    "……………………………………………….\n" +
    "94: Me ~ungdomane som bur her, me er meir innstilte @på §fotball heile tid. Me er ikkje så innstilte\n" +
    "@på den kulturdelen av Sogndal faktisk.\n" +
    "…………………………………………………………….\n" +
    "95: MOT, ja.\n" +
    "\n" +
    "94: MOT. Det er ein organisasjon som … Og dei, @står for at ein skal *seia nei, at ein skal *takka nei\n" +
    "til alkohol @når det kjem opp og … At ein ja, ikkje skal *begynna med noko rusmisbruk, og at ein skal\n" +
    "ta vare @på §alle og … At ein skal trivast @då.\n" +
    "I: Ja, det har de inn i skulen, sant?\n" +
    "94: Ja, i @går hadde me …\n" +
    "95: MOT-time.\n" +
    "94: MOT-time. Så …\n" +
    "I: Ja. Kva går det ut på ein sånn time?\n" +
    "95: Me lærer jo å ta vare @på kvarandre og …\n" +
    "94: Mm.\n" +
    "95: … *samarbeida …\n" +
    "94: Ja.\n" +
    "95: … med forskjellige øvingar.\n" +
    "94: Mykje samarbeid og liksom (øvelsar) du ser … Me hadde ein (øvelse) der du skulle *lena deg @på\n" +
    "den hin.\n" +
    "95: Du\n" +
    "94: Slik at …\n" +
    "95: Du sat @på ein @måte i ein firkant, og så la du deg @oppå stolar, og så vart dei ~stolane tekne\n" +
    "vekk sånn at du berre klarte å *halda seg oppe med ~føtene og ¤hjelpa av han, han eller ho hi som\n" +
    "var bak deg.\n" +
    "94: Så @då …\n" +
    "95: Så det er jo å *læra å *stola @på kvarandre.\n" +
    "I: Mm.\n" +
    "94: Og ha tillit til dei hine, liksom at de greier det i lag, men viss den eine gjev seg, så vil jo ikkje det\n" +
    "@gå. @Då dett §alle.\n" +
    "I: Nei.\n" +
    "95: Og så @får me jo nyttige lærdom om korleis me skal *behandla folk rundt oss. At #gjerne ikkje\n" +
    "§alle har det så lett sjølv om det ser ut som om dei har det ganske bra.\n" +
    "I: Mm.\n" +
    "95: Du @må liksom @sjå litt lenger enn berre den personen.\n" +
    "……………………………………………………………………………….\n" +
    "I: Ja, kva er det med naturen her då som er eventuelt spesielt?\n" +
    "94: Nei, det er no §fjell og fjord.\n" +
    "95: Det er ikkje så mykje øydelagt natur her liksom.\n" +
    "\n" +
    "94: Nei.\n" +
    "95: Natur. Me har liksom Fjøra, og så har me naturen som er rundt Fjøra og så ~fjordane eller\n" +
    "fjorden. Og så fine §fjell. Det er masse turplassar. Du kan, du kan liksom @gå uavbrote viss du vil\n" +
    "liksom *reisa ein plass der du vil ha litt sånn stillheit eller ikkje så mykje @bråk, så kan du liksom\n" +
    "berre ta deg ein tur i nærmaste §fjell liksom. Ja, og no @når me driv og lagar fjordstien for eksempel.\n" +
    "No @når me skal *byggja han utover mot ¤brua, så blir det liksom nye @måtar å @sjå fjorden @på.\n" +
    "Du kan liksom @gå … Det blir jo, det er jo kjempefint å @sjå @på, og så ser du §¤fjella attmed\n" +
    "fjorden og berre …\n" +
    "94: Ja.\n" +
    "I: Mm.\n" +
    "95: … ser kor fint det er her eigentleg.\n" +
    "94: Det å @sjå utover fjorden og berre ser liksom vatnet berre ligg slik stilt og @blått, og så ser du\n" +
    "§¤fjella opp @på ~sidene, og det er valdsamt, valdsamt flott å berre @sjå @på det.\n" +
    "I: Mm, mm, mm. Er det noko de er stolte over, bortsett frå det de har sagt no, med, med Sogndal?\n" +
    "94: Nei, me har, me har [nok] eit bra samhald at ¤folki som bur her er valdsamt … tek imot folk bra,\n" +
    "og at … Eller eg, eg føler det at me er ein §snill kommune liksom at, tek vare @på dei som er her.\n" +
    "95: Ja, samhaldet @vårt eller …\n" +
    "94: Ja.\n" +
    "95: Eg føler at Sogndal er ein plass du ikkje bør kvi deg til å *koma til liksom … Du kjem og så @får du,\n" +
    "@får, blir du kjend med nokon ganske fort.",

text_96og97: "96: Gjekk ikkje me i #barnehage i lag?\n" +
    "97: Å, eg gjekk i Stedje.\n" +
    "96: Nei, eg gjekk i Kyrkjebakken.\n" +
    "I: Men kva gjorde de då før de begynte på skulen?\n" +
    "96: Eg gjekk i #barnehagen, me leika òg … Me lærte songar <latter>\n" +
    "I: Hadde de noko sånn samlingsstund?\n" +
    "96: Ja, me hadde eller, (hvert fall) min #barnehage hadde alltid, samlast alltid i ein sånn ring @på\n" +
    "(begynnelsen) eller midten av dagen.\n" +
    "97: Nei, eg hugsar ikkje stort, for eg trur eg hoppa over to @år i #barnehagen. Eg gjekk jo berre eitt\n" +
    "@år i #barnehage.\n" +
    "……………………………………………………………………\n" +
    "I: Rommet dykkar, korleis vil de beskriva det?\n" +
    "96: Eg har ei dobbeltseng @då som eg fyller opp. (uforståeleg) Så har eg ein pult attmed ¤senga og\n" +
    "eit sånn klesstativ med litt klede, og eit teppe @på golvet, og nokre bilete og ein spegel.\n" +
    "I: Korleis ser ditt ut då?\n" +
    "97: (I hvert fall) eg har ei stor seng som dekker ein fjerdedel nesten. Eg har ein pult og ein kommode,\n" +
    "og elles er det ganske nake, kvite vegger som lyser fint opp.\n" +
    "………………………………………………………………….\n" +
    "I: Mm. Kjem de på nokon episodar frå de gjekk i Trudvang?\n" +
    "96: Ja, <nøler> i femteklasse så sprang me alltid, eller. Det var jo, me @måtte jo, det var veldig\n" +
    "strengt @på Trudvang @då, så me @måtte alltid ut i liksom ¤friminutta. Men det var sånn der masse\n" +
    "sånn gangar under, eller under der me hadde klasserom, sånn i §kjellaren, så me var alltid liksom\n" +
    "nede i ~gangane og leikte tikken og gøymde oss liksom der, og @når ~lærarane kom, så gøymde me\n" +
    "oss i sånne @skåp. Ja, me, eller me blei av og til, me blei av og til liksom ferska @då, men @då me\n" +
    "heldt fortsatt @på, for gøymde oss alltid berre @når ~lærarane kom.\n" +
    "I: Kva straff fekk de då?\n" +
    "96: Nei, me fekk kjeft, men me fekk aldri, me hadde jo ikkje (anmerkningar).\n" +
    "I: Kva hugsar du frå tidlegare?\n" +
    "97: Eg hugsar ikkje så mykje, men eg hugsar det at me hadde desse ~benkane heilt attmed tavla, og\n" +
    "så hadde me noko av ¤undervisninga der.\n" +
    "I: Ja.\n" +
    "\n" +
    "97: Det var ikkje mykje skriving @då, meir snakking for eksempel å *læra å* lesa, *uttala bokstavar\n" +
    "og så vidare.\n" +
    "I: Men hadde de noko musikkundervisning og sånn?\n" +
    "96: Me song alltid, song alltid sånne gamle norske songar og så dansa me liksom.\n" +
    "I: Musikken no då?\n" +
    "96: No …\n" +
    "97: No har me eit gitarprosjekt …\n" +
    "96: Ja.\n" +
    "97: … der §alle skal *spela kvar sin song i lag med ein partner @då.\n" +
    "…………………………………………………………\n" +
    "I: Er det noko som, som gjev dykk andre tankar etter de har vore der?\n" +
    "97: Ja, det var ganske tøft. Det var ikkje noko fint å @sjå @på akkurat. Men eg hadde forventa\n" +
    "@endå litt meir … Me var der @når det var litt sol @då, <nøler> sol og fint vêr, så det var ikkje\n" +
    "akkurat den <stammar> eller følelsen å *vera der. Men det var framleis veldig tøft å @sjå @på for\n" +
    "eksempel @hår og sko og alt det.\n" +
    "I: Ja.\n" +
    "96: Me var jo inni dei ~brakkene dei sov i (i hvert fall) i Birkeneau <nøler>, og det var liksom det å\n" +
    "*tenkja @på at det var jo sjølvsagt like, @på dei ~dagane så var det jo like varmt, kanskje varmare\n" +
    "òg, der dei @måtte eller me gjekk jo berre der @på ein @måtte og @såg, men dei @måtte jo *jobba\n" +
    "heile ~dagane og fekk, fekk ikkje vatn og eller mat. Det var veldig sterkt å *vera der @då.\n" +
    "I: Mm. Men har du høyrt andre som har vore der før då?\n" +
    "97: Ja, §alle eldre enn oss har vore med.\n" +
    "I: Kva har dei sagt då?\n" +
    "97: Nei, dei også seier at det er ein kjekk tur, litt mykje buss, men ganske kjekt.\n" +
    "I: Mm. Var det andre plassar de var som har sett eit spor i dykk på ein måte?\n" +
    "96: Stasifengselet. Der @såg me, eller eg syntest det var gøyare @då i stasifengselet enn i Auswitz\n" +
    "fordi du, det var liksom (man) fekk @sjå §alle ~fengselscellene. Og liksom, det var liksom fortsatt\n" +
    "kloremerke @på ~veggane og sånn.\n" +
    "…………………………………………………………………..\n" +
    "I: <nøler> Det er jo andre ting som er typisk for Sogndal òg. Viss de treffer nokon utanfor Sogndal og\n" +
    "skal beskriva plassen de kjem frå, kva vil de seia då?\n" +
    "97: Høge §fjell, fjord.\n" +
    "I: Ja.\n" +
    "97: Det er jo masse turistar @ifrå Asia og slikt her @då. Dei blir jo ganske sjokka @når dei ser §fjell\n" +
    "og fjord og snø spesielt.\n" +
    "\n" +
    "I: Kva vil du dra fram?\n" +
    "96: Ja, det er masse §fjell og fjord, snø. Det er jo, eller eg @såg @på sånn serie at det er … Det var\n" +
    "sånne amerikanarar som liksom kom @på besøk til Sogn og ~Fjordane, og dei sa liksom sånn ja me\n" +
    "@må ta eller heime i USA liksom så @måtte me ha betalt for og kome inn.\n" +
    "97: Men @då viss me ikkje har eple @då for eksempel.\n" +
    "I: Ja.\n" +
    "97: Så blir det litt dumt å *§kalla seg ¤saftbygda. <humrar>",

text_98og99: "I: Men kvar, kvar var de hen før de begynte I skulen?\n" +
    "98: #Barnehagen. Kva me gjorde I #barnehagen @då? Leikte.\n" +
    "I: Kvar, kvar var du hen?\n" +
    "99: Nei, eg var i #barnehagen eg òg,\n" +
    "I: Ja, kva gjorde de på der?\n" +
    "99: Ute og leika i sandkasse og huska og …\n" +
    "98: Klatrestativ.\n" +
    "99: Kosa oss og …\n" +
    "…………………………………………………….\n" +
    "I: Men har ikkje de vore til utlandet no nyleg faktisk med klassen?\n" +
    "99: Jo, me har vore til Polen og Tyskland for å @sjå @på, @sjå @på korleis det var under andre\n" +
    "verdskrig, og @då var me i Auschwitz og @såg korleis ~jødane hadde det og kor stort det var. Så fekk\n" +
    "me *læra mykje om historia @då …\n" +
    "I: Mm.\n" +
    "99: … korleis det var der, men det var ganske vanskeleg å @forstå at alt dette her hadde skjedd @då\n" +
    "@når me var der, for det var … Det var så mykje å ta inn og så høge tal at …\n" +
    "98: Ja.\n" +
    "99: … det var berre heilt uverkeleg.\n" +
    "98: Det var litt mange hus, bygningar der ~jødane budde og. Så var det i Auschwitz 2 der var det\n" +
    "mykje, mykje større, men det var tre hundre bygningar til saman. Mesteparten var brent ned. Det var\n" +
    "sju hundre jødar i ein sånn bygning.\n" +
    "I: Kva tankar gjer de dykk no etter at de har vore der då?\n" +
    "98: Nei, det @må no, det kan ikkje ha vore så kjekt og vore der, vore der @då det faktisk skjedde. Det\n" +
    "er vel eigentleg einaste, einaste ~tankane eg liksom har gjort klart.\n" +
    "99: Det er berre heilt uverkeleg, så det er vanskeleg å *tenkja @på det eigentleg @då. Det blei berre\n" +
    "så uverkeleg etter den turen.\n" +
    "I: Mm. Men tvilar de på at det har skjedd då eller?\n" +
    "99: Nei. Eg trur.\n" +
    "98: Nei, det. Eg trur (i hvert fall) det har skjedd men.\n" +
    "99: Men det @såg … @Når du var der nede, @då var det vanskeleg å @forstå liksom, men @når det\n" +
    "er så mykje historier, og det er bevis etter det, og du har sett det sjølv, @då trur du jo @på det, men.\n" +
    "Det er litt vanskeleg å *vita at det har skjedd akkurat der og der liksom.\n" +
    "I: Mm.\n" +
    "98: Eg trur det har skjedd, men det er vanskeleg å §*førestilla seg korleis det var, sant.\n" +
    "I: Ja.\n" +
    "98: Sjølv om det, sjølv om du trur det har skjedd liksom. Vanskeleg å §*førestilla seg heilt kor, kor\n" +
    "gale det var.\n" +
    "\n" +
    "………………………………………………………………………\n" +
    "I: Har de sett nokon film i det siste då?\n" +
    "99: Ja.\n" +
    "98: Ja, det var no kinodagen @på laurdag.\n" +
    "I: Så då såg du film?\n" +
    "98: @Såg éin film.\n" +
    "99: Nei, ikkje den dagen, men eg var litt før, og @då var deg @på den der «Burning 2».\n" +
    "98: Ja, den @såg eg òg. Litt artig.\n" +
    "I: Mm. Og så kva hende meir då?\n" +
    "99: Nei, @på slutten @då @når dei var komne fram til der dei skal @då, @då kjem jo politiet. Og så\n" +
    "dei har jo ein slik taxivan @då, slik med sånn heis @på. Og den fekk ikkje dei opp, så han <nøler> sat\n" +
    "jo i §rullestolen ute der fordi han ville ikkje *visa til dei andre at han kunne @gå og slik. Så han\n" +
    "@måtte jo berre *(faka) at han var lam @då òg. Så han sat i §rullestolen der ute, og @då @måtte dei\n" +
    "*køyra vekk @ifrå politiet.\n" +
    "……………………………………………………………\n" +
    "I: Men viss de skal fortelja om Sogndal til andre og beskriva denne her plassen her. Kva vil de seia då?\n" +
    "Kva vil de trekkja fram?\n" +
    "98: Det viktigaste er vel kanskje, eller liksom naturen og.\n" +
    "99: §Fjellet og ~fjordane og …\n" +
    "98: §Fjell og fjordar. Fine, fint @på ski her spesielt. Fint å @gå turar her, ikkje så langt @frå nokon\n" +
    "plassar, men samtidig så er det valdsamt roleg her, liksom midt i blinken. Valdsamt annleis no for,\n" +
    "enn for nokre @år sidan <nøler> med §alle ~hyttene og to nye trekk.\n" +
    "I: Dei driv no på andre sida av fjorden òg nesten sagt borte på Loftesnes og …\n" +
    "98: Ja, dei byggjer ny bru, ja.\n" +
    "99: Det er vel for den gamle den er jo begynt å bli §gamal, og så er ho litt lita og …\n" +
    "98: Du føler deg ikkje heilt trygg @når du @går over der, spesielt sykling @då. @Då har du ein\n" +
    "§sykkel òg som tek plass.\n" +
    "99: For gangfeltet er …\n" +
    "98: Gangfeltet er så brei.\n" +
    "99: … under ein meter, og @når det kjem ein lastebil over der, @då kjenner du liksom at heile ¤brua\n" +
    "ristar.\n" +
    "98: Folk vel å ikkje, ikkje *reisa på sykletur til utover #Kjørnes der @på grunn av det. Og det er jo litt\n" +
    "dumt @då at den ¤brua skal *stoppa det, pluss at ho er jo over femti @år §gamal <stammar>, trur\n" +
    "eg. Det er @på tide med ei ny ei før det @går gale med ho.\n" +
    "I: Så har de fått noko nedmed fjorden òg som er ganske nytt.\n" +
    "99: Fjordstien.\n" +
    "I: Ja.\n" +
    "99: Ja, det er jo for det er jo mykje turistar @då som likar som kjem her @frå andre land og andre\n" +
    "plassar for å @sjå @på ¤fjelli og ~fjordane @då, så @då. Det er jo litt fint det at du kan @gå rundt\n" +
    "heile fjorden eller ¤bukta. Med @då kan du @gå heilt nedst og @sjå korleis det er @då.\n" +
    "\n" +
    "I: Mm.\n" +
    "98 Ja, det er ein grei, grei sti å ha i ¤lengda, trur eg.\n" +
    "I: Mm.",

text_100og101: "100: Her oppe så har det vel vorte meir vennegjengar, vil eg *seia, men òg etter at me begynte med\n" +
    "MOT @då, i sjuande, trur eg det var, så har jo det vorte mindre og mindre òg. Men det er jo fortsatt\n" +
    "det med vennegjengar, for @på #barneskulen, så var liksom §alle med §alle og.\n" +
    "101: Eg er stolt over korleis liksom samfunnet @vårt er @då. Liksom at me har … Me har sentrum, og\n" +
    "så har me Dalen, og så har me liksom … Det er så, det er så tett liksom. §Alle kjenner §alle, og viss du\n" +
    "tek andre plassar, så er det jo ikkje akkurat sånn.\n" +
    "I: Ja, kva fritidsaktivitetar driv de med?\n" +
    "101: Me driv med §fotball begge to.\n" +
    "100: Ei typisk treningsøkt, det er vel at me @får litt informasjon om … Me @får alltid informasjon om\n" +
    "kva me skal ha @på heile ¤treninga, liksom. Så begynner me å *varma opp, og så har me som oftast\n" +
    "ei slags (pasningsøvelse), pasning i spel og @få bevegelse etter at ein har @slått §ballen eller noko\n" +
    "sånn. Og så har me ei slags formasjonsgreie kanskje? Det blir litt feil å *§kalla det formasjon òg. Og så\n" +
    "har me spel, som oftast @på @småe baner.\n" +
    "101: Nei, @oppgåva me er vel å @få §ballen, og *halda @på han eller @gå og *leggja innlegg eller\n" +
    "*avslutta.\n" +
    "………………………………………………………\n" +
    "101: Me var no @på ein tur for nokre veker sidan, @månader sidan. @Då me var til Polen, besøkte\n" +
    "sånn konsentrasjonsleir og besøkte Berlin og …\n" +
    "100: Og så tok me Kiel-ferja, og @frå Kiel, så køyrde me §elleve timar. Eg veit ikkje heilt kvar det var\n" +
    "til eigentleg.\n" +
    "101: Det var lang tur. Og så etter me hadde stoppa ein tre kvarter @då, så køyrde me vidare, og @då\n" +
    "var det så heitt. @Då hadde bussen @stått i solsteiken, og nesten som du var heilt steikt §alle saman.\n" +
    "Så var me @på besøk i ~saltgruvene, trur eg. Det, det var utruleg kjekt @då. Me gjekk jo kjempelangt\n" +
    "ned i dei ~saltgruvene, og så @etterpå fekk me *vita at det var fire prosent av ~saltgruvene.\n" +
    "I: Fekk de sjå inne på konsentrasjonsleiren kva som var frå den tida?\n" +
    "100: <nøler> Me fekk @sjå ~brakkene der dei levde, eller der dei var @då @når dei ikkje jobba. Og så\n" +
    "fekk me @sjå, ja dei ~sovebrakkene dei var jo heilt … Det var … Eg trur det var litt større enn mi seng,\n" +
    "kanskje to gonger tre meter eller noko sånn. Og der var det fem eller seks folk som skulle oppi.\n" +
    "101: På Auschwitz 1, så var det sånne, eller dei gamle ~jobbebrakkene, så var det sånne … Dei hadde\n" +
    "masse sånn sko, og det var @hår som @låg som sånn innafor sånn bilete, som me @såg. Det var heilt\n" +
    "sjukt.\n" +
    "…………………………………………………..\n" +
    "\n" +
    "2\n" +
    "I: Men viss de skal beskriva heimstaden dykkar for nokon som ikkje er herifrå, kva vil de trekkja fram\n" +
    "då? Kva er det de seier om plassen?\n" +
    "100: <nøler> Om plassen?\n" +
    "I: Ja.\n" +
    "100: Det er utruleg fint. <nøler> @På ~vintrane så er det jo ganske bra skiforhold oppi §Hollekve. Og\n" +
    "@nedpå kaien så er det vorte sånn der, kva det heiter att? Sånn …\n" +
    "101: Fjordsti?\n" +
    "100: Ja, fjordsti og så det der SUP.\n" +
    "101: SUP, ja.\n" +
    "100: Det er jo ganske fint òg @då å @sjå liksom Sogndal @frå vatnet og så, ja.\n" +
    "101: Mm.\n" +
    "100: Det @går an å *gjera så mykje her liksom … Så mange fine turar å @gå @på og ... Det er liksom\n" +
    "så breitt kva du kan *gjera her, fordi me har liksom ski, §fotball. Du kan @gå @på så mykje turar. Du\n" +
    "kan liksom *gjera det §aller, §aller meste her.\n" +
    "I: Og så kjem det mange studentar her og vil gå på skule her.\n" +
    "101: Mm.\n" +
    "I: Og kva er det dei set pris på, trur de?\n" +
    "101: Nei, dei set nok pris @på korleis denne ¤bygda er @då. Me er ikkje så, så kvasse eller kva eg skal\n" +
    "*seia. Men me er liksom så folkeleg @då.\n" +
    "100: Ja, eg, eg i hvert fall synest det er valdsamt flott at me har, har ein så, eller har tre fine kinosalar\n" +
    "her @då, og at me har liksom dei ~moglegheitene òg til å @gå @på film.\n" +
    "I: Har de sett nokon no i det siste?\n" +
    "100: Eg @såg ein skrekkfilm for to veker sidan eller noko sånn. <humrar>\n" +
    "I: Å, ja.\n" +
    "101: Men eg skal i alle fall @sjå film i ¤helga. @Då er det sånn …\n" +
    "100: Store kinodagen.\n" +
    "101: Store kinodagen, ja.",

};

export default texts;