const variables = {
    infinitiv_a : "a",
    infinitiv_e : "e",
    infinitiv_anna : "Anna_a_e",
    ao : "ao",
    å :"å",
    anna_ao_å : "Anna_ao_å",
    bundanForm_i : "i",
    bundanForm_a : "a",
    bundanForm_anna : "Anna_i_a",
    adnedn : "adn/edn",
    aneene : "ane/ene",
    anna_adnedn_aneene : "Anna_adnedn_aneene",
    dl : "dl",
    ll : "ll",
    anna_dl_ll : "Anna_dl_ll",
    dn : "dn",
    rn : "rn",
    anna_dn_rn : "Anna_dn_rn",
};

export default variables;