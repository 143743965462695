import Texts from './informersText';

const informers =
    [
        {
            id: "01",
            place: "Dalen",
            gender: "Kvinne",
            age: "Ung 26 - 40",
            birth: "1941 - 1970",
            date_of_recording: "1996",
            education: "Høgskule",
            occupation: "Tertiær",
            parents_background: "Begge frå Sogndal",
            audio: "inf_01og02.mp3",
            text: Texts.text_1og2,
            panel: "Trend"
        },
        {
            id: "01p",
            place: "Dalen",
            gender: "Kvinne",
            age: "Middelaldra 41 - 60",
            birth: "1941 - 1970",
            date_of_recording: "2017",
            education: "Høgskule",
            occupation: "Tertiær",
            parents_background: "Begge frå Sogndal",
            audio: "inf_01pog02p.mp3",
            text: Texts.text_1pog2p,
            panel: "Panel"
        },
        {
            id: "02",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ung 26 - 40",
            birth: "1941 - 1970",
            date_of_recording: "1996",
            education: "Vidaregåande",
            occupation: "Tertiær",
            parents_background: "Begge frå Sogndal",
            audio: "inf_01og02.mp3",
            text: Texts.text_1og2,
            panel: "Trend"
        },
        {
            id: "02p",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Middelaldra 41 - 60",
            birth: "1941 - 1970",
            date_of_recording: "2017",
            education: "Vidaregåande",
            occupation: "Tertiær",
            parents_background: "Begge frå Sogndal",
            audio: "inf_01pog02p.mp3",
            text: Texts.text_1pog2p,
            panel: "Panel"
        },
        {
            id: "03",
            place: "Dalen",
            gender: "Mann",
            age: "Eldre 61 -",
            birth: "1911 - 1940",
            date_of_recording: "1996",
            education: "Yrkesfag",
            occupation: "Primær",
            parents_background: "Begge frå Sogndal",
            audio: "inf_03og04.mp3",
            text: Texts.text_3og4,
            panel: "Trend"
        },
        {
            id: "03p",
            place: "Dalen",
            gender: "Mann",
            age: "Eldre 61 -",
            birth: "1911 - 1940",
            date_of_recording: "2017",
            education: "Yrkesfag",
            occupation: "Primær",
            parents_background: "Begge frå Sogndal",
            audio: "inf_03pog30p.mp3",
            text: Texts.text_3pog30p,
            panel: "Panel"
        },
        {
            id: "04",
            place: "Dalen",
            gender: "Mann",
            age: "Eldre 61 -",
            birth: "1911 - 1940",
            date_of_recording: "1996",
            education: "Grunnskule",
            occupation: "Primær",
            parents_background: "Begge frå Sogndal",
            audio: "inf_03og04.mp3",
            text: Texts.text_3og4,
            panel: "Trend"
        },
        {
            id: "05",
            place: "Dalen",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "1996",
            education: "Vidaregåande",
            occupation: "Elev",
            parents_background: "Far frå Sogndal",
            audio: "inf_05og06.mp3",
            text: Texts.text_5og6,
            panel: "Trend"
        },
        {
            id: "05p",
            place: "Dalen",
            gender: "Kvinne",
            age: "Ung 26 - 40",
            birth: "1971 - 2000",
            date_of_recording: "2017",
            education: "Høgskule",
            occupation: "Tertiær",
            parents_background: "Far frå Sogndal",
            audio: "inf_05pog06p.mp3",
            text: Texts.text_5pog6p,
            panel: "Panel"
        },
        {
            id: "06",
            place: "Dalen",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "1996",
            education: "Vidaregåande",
            occupation: "Elev",
            parents_background: "Begge frå Sogndal",
            audio: "inf_05og06.mp3",
            text: Texts.text_5og6,
            panel: "Trend"
        },
        {
            id: "06p",
            place: "Dalen",
            gender: "Kvinne",
            age: "Ung 26 - 40",
            birth: "1971 - 2000",
            date_of_recording: "2017",
            education: "Høgskule",
            occupation: "Tertiær",
            parents_background: "Begge frå Sogndal",
            audio: "inf_05pog06p.mp3",
            text: Texts.text_5pog6p,
            panel: "Panel"
        },
        {
            id: "07",
            place: "Dalen",
            gender: "Kvinne",
            age: "Eldre 61 -",
            birth: "1911 - 1940",
            date_of_recording: "1996",
            education: "Grunnskule",
            occupation: "Primær",
            parents_background: "Begge frå Sogndal",
            audio: "inf_07og08.mp3",
            text: Texts.text_7og8,
            panel: "Trend"
        },
        {
            id: "08",
            place: "Dalen",
            gender: "Mann",
            age: "Eldre 61 -",
            birth: "1911 - 1940",
            date_of_recording: "1996",
            education: "Grunnskule",
            occupation: "Primær",
            parents_background: "Begge frå Sogndal",
            audio: "inf_07og08.mp3",
            text: Texts.text_7og8,
            panel: "Trend"
        },

        {
            id: "09",
            place: "Dalen",
            gender: "Mann",
            age: "Middelaldra 41 - 60",
            birth: "1941 - 1970",
            date_of_recording: "1996",
            education: "Vidaregåande",
            occupation: "Tertiær",
            parents_background: "Begge frå Sogndal",
            audio: "inf_09og10.mp3",
            text: Texts.text_9og10,
            panel: "Trend"
        },
        {
            id: "10",
            place: "Dalen",
            gender: "Mann",
            age: "Eldre 61 -",
            birth: "1911 - 1940",
            date_of_recording: "1996",
            education: "Høgskule",
            occupation: "Tertiær",
            parents_background: "Far frå Sogndal",
            audio: "inf_09og10.mp3",
            text: Texts.text_9og10,
            panel: "Trend"
        },
        {
            id: "11",
            place: "Dalen",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "1996",
            education: "Vidaregåande",
            occupation: "Elev",
            parents_background: "Begge frå Sogndal",
            audio: "inf_11og12.mp3",
            text: Texts.text_11og12,
            panel: "Trend"
        },
        {
            id: "11p",
            place: "Dalen",
            gender: "Kvinne",
            age: "Middelaldra 41 - 60",
            birth: "1971 - 2000",
            date_of_recording: "2017",
            education: "Vidaregåande",
            occupation: "Tertiær",
            parents_background: "Begge frå Sogndal",
            audio: "inf_11pog12p.mp3",
            text: Texts.text_11pog12p,
            panel: "Panel"
        },
        {
            id: "12",
            place: "Dalen",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "1996",
            education: "Vidaregåande",
            occupation: "Tertiær",
            parents_background: "Far frå Sogndal",
            audio: "inf_11og12.mp3",
            text: Texts.text_11og12,
            panel: "Trend"
        },
        {
            id: "12p",
            place: "Dalen",
            gender: "Kvinne",
            age: "Middelaldra 41 - 60",
            birth: "1971 - 2000",
            date_of_recording: "2017",
            education: "Vidaregåande",
            occupation: "Tertiær",
            parents_background: "Far frå Sogndal",
            audio: "inf_11pog12p.mp3",
            text: Texts.text_11pog12p,
            panel: "Panel"
        },
        {
            id: "13",
            place: "Dalen",
            gender: "Mann",
            age: "Eldre 61 -",
            birth: "1911 - 1940",
            date_of_recording: "1996",
            education: "Grunnskule",
            occupation: "Primær",
            parents_background: "Begge frå Sogndal",
            audio: "inf_13og14.mp3",
            text: Texts.text_13og14,
            panel: "Trend"
        },
        {
            id: "14",
            place: "Dalen",
            gender: "Mann",
            age: "Middelaldra 41 - 60",
            birth: "1941 - 1970",
            date_of_recording: "1996",
            education: "Høgskule",
            occupation: "Primær",
            parents_background: "Begge frå Sogndal",
            audio: "inf_13og14.mp3",
            text: Texts.text_13og14,
            panel: "Trend"
        },
        {
            id: "14p",
            place: "Dalen",
            gender: "Mann",
            age: "Eldre 61 -",
            birth: "1941 - 1970",
            date_of_recording: "2017",
            education: "Høgskule",
            occupation: "Primær",
            parents_background: "Begge frå Sogndal",
            audio: "inf_14pog29p.mp3",
            text: Texts.text_14pog29p,
            panel: "Panel"
        },
        {
            id: "15",
            place: "Dalen",
            gender: "Mann",
            age: "Ung 26 - 40",
            birth: "1941 - 1970",
            date_of_recording: "1996",
            education: "Vidaregåande",
            occupation: "Tertiær",
            parents_background: "Far frå Sogndal",
            audio: "inf_15og16.mp3",
            text: Texts.text_15og16,
            panel: "Trend"
        },
        {
            id: "15p",
            place: "Dalen",
            gender: "Mann",
            age: "Middelaldra 41 - 60",
            birth: "1941 - 1970",
            date_of_recording: "2017",
            education: "Vidaregåande",
            occupation: "Tertiær",
            parents_background: "Far frå Sogndal",
            audio: "inf_15pog16p.mp3",
            text: Texts.text_15pog16p,
            panel: "Panel"
        },
        {
            id: "16",
            place: "Dalen",
            gender: "Mann",
            age: "Middelaldra 41 - 60",
            birth: "1941 - 1970",
            date_of_recording: "1996",
            education: "Vidaregåande",
            occupation: "Tertiær",
            parents_background: "Mor frå Sogndal",
            audio: "inf_15og16.mp3",
            text: Texts.text_15og16,
            panel: "Trend"
        },
        {
            id: "16p",
            place: "Dalen",
            gender: "Mann",
            age: "Eldre 61 -",
            birth: "1941 - 1970",
            date_of_recording: "2017",
            education: "Vidaregåande",
            occupation: "Tertiær",
            parents_background: "Mor frå Sogndal",
            audio: "inf_15pog16p.mp3",
            text: Texts.text_15pog16p,
            panel: "Panel"
        },
        {
            id: "17",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Eldre 61 -",
            birth: "1911 - 1940",
            date_of_recording: "1996",
            education: "Grunnskule",
            occupation: "Primær",
            parents_background: "Begge frå Sogndal",
            audio: "inf_17og18.mp3",
            text: Texts.text_17og18,
            panel: "Trend"
        },
        {
            id: "18",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Eldre 61 -",
            birth: "1911 - 1940",
            date_of_recording: "1996",
            education: "Grunnskule",
            occupation: "Primær",
            parents_background: "Mor frå Sogndal",
            audio: "inf_17og18.mp3",
            text: Texts.text_17og18,
            panel: "Trend"
        },
        {
            id: "19",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "1996",
            education: "Grunnskule",
            occupation: "Tertiær",
            parents_background: "Far frå Sogndal",
            audio: "inf_19og20.mp3",
            text: Texts.text_19og20,
            panel: "Trend"
        },
        {
            id: "19p",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Middelaldra 41 - 60",
            birth: "1971 - 2000",
            date_of_recording: "2017",
            education: "Vidaregåande",
            occupation: "Tertiær",
            parents_background: "Far frå Sogndal",
            audio: "inf_19pog60p.mp3",
            text: Texts.text_19pog60p,
            panel: "Panel"
        },
        {
            id: "20",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "1996",
            education: "Yrkesfag",
            occupation: "Elev",
            parents_background: "Far frå Sogndal",
            audio: "inf_19og20.mp3",
            text: Texts.text_19og20,
            panel: "Trend"
        },
        {
            id: "21",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Middelaldra 41 - 60",
            birth: "1941 - 1970",
            date_of_recording: "1996",
            education: "Vidaregåande",
            occupation: "Tertiær",
            parents_background: "Far frå Sogndal",
            audio: "inf_21og22.mp3",
            text: Texts.text_21og22,
            panel: "Trend"
        },
        {
            id: "21p",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Eldre 61 -",
            birth: "1941 - 1970",
            date_of_recording: "2017",
            education: "Vidaregåande",
            occupation: "Tertiær",
            parents_background: "Far frå Sogndal",
            audio: "inf_21pog26p.mp3",
            text: Texts.text_21pog26p,
            panel: "Panel"
        },
        {
            id: "22",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Eldre 61 -",
            birth: "1911 - 1940",
            date_of_recording: "1996",
            education: "Vidaregåande",
            occupation: "Tertiær",
            parents_background: "Far frå Sogndal",
            audio: "inf_23og24.mp3",
            text: Texts.text_23og24,
            panel: "Trend"
        },
        {
            id: "23",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ung 26 - 40",
            birth: "1941 - 1970",
            date_of_recording: "1996",
            education: "Høgskule",
            occupation: "Tertiær",
            parents_background: "Begge frå Sogndal",
            audio: "inf_23og24.mp3",
            text: Texts.text_23og24,
            panel: "Trend"
        },
        {
            id: "23p",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Middelaldra 41 - 60",
            birth: "1941 - 1970",
            date_of_recording: "2017",
            education: "Høgskule",
            occupation: "Tertiær",
            parents_background: "Begge frå Sogndal",
            audio: "inf_23pog24p.mp3",
            text: Texts.text_23pog24p,
            panel: "Panel"
        },
        {
            id: "24",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ung 26 - 40",
            birth: "1941 - 1970",
            date_of_recording: "1996",
            education: "Høgskule",
            occupation: "Tertiær",
            parents_background: "Far frå Sogndal",
            audio: "inf_23og24.mp3",
            text: Texts.text_23og24,
            panel: "Trend"
        },
        {
            id: "24p",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Middelaldra 41 - 60",
            birth: "1941 - 1970",
            date_of_recording: "2017",
            education: "Høgskule",
            occupation: "Tertiær",
            parents_background: "Far frå Sogndal",
            audio: "inf_23pog24p.mp3",
            text: Texts.text_23pog24p,
            panel: "Panel"
        },
        {
            id: "25",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ung 26 - 40",
            birth: "1941 - 1970",
            date_of_recording: "1996",
            education: "Vidaregåande",
            occupation: "Tertiær",
            parents_background: "Begge frå Sogndal",
            audio: "inf_25og26.mp3",
            text: Texts.text_25og26,
            panel: "Trend"
        },
        {
            id: "26",
            place: "Fjøra",
            gender: "Mann",
            age: "Ung 26 - 40",
            birth: "1941 - 1970",
            date_of_recording: "1996",
            education: "Vidaregåande",
            occupation: "Tertiær",
            parents_background: "Far frå Sogndal",
            audio: "inf_25og26.mp3",
            text: Texts.text_25og26,
            panel: "Trend"
        },
        {
            id: "26p",
            place: "Fjøra",
            gender: "Mann",
            age: "Middelaldra 41 - 60",
            birth: "1941 - 1970",
            date_of_recording: "2017",
            education: "Vidaregåande",
            occupation: "Tertiær",
            parents_background: "Far frå Sogndal",
            audio: "inf_21pog26p.mp3",
            text: Texts.text_21pog26p,
            panel: "Panel"
        },
        {
            id: "27",
            place: "Fjøra",
            gender: "Mann",
            age: "Middelaldra 41 - 60",
            birth: "1911 - 1940",
            date_of_recording: "1996",
            education: "Høgskule",
            occupation: "Tertiær",
            parents_background: "Begge frå Sogndal",
            audio: "inf_27og28.mp3",
            text: Texts.text_27og28,
            panel: "Trend"
        },
        {
            id: "27p",
            place: "Fjøra",
            gender: "Mann",
            age: "Eldre 61 -",
            birth: "1911 - 1940",
            date_of_recording: "2017",
            education: "Høgskule",
            occupation: "Tertiær",
            parents_background: "Begge frå Sogndal",
            audio: "inf_27pog28p.mp3",
            text: Texts.text_27pog28p,
            panel: "Panel"
        },
        {
            id: "28",
            place: "Dalen",
            gender: "Mann",
            age: "Middelaldra 41 - 60",
            birth: "1911 - 1940",
            date_of_recording: "1996",
            education: "Grunnskule",
            occupation: "Tertiær",
            parents_background: "Far frå Sogndal",
            audio: "inf_27og28.mp3",
            text: Texts.text_27og28,
            panel: "Trend"
        },
        {
            id: "28p",
            place: "Dalen",
            gender: "Mann",
            age: "Eldre 61 -",
            birth: "1911 - 1940",
            date_of_recording: "2017",
            education: "Grunnskule",
            occupation: "Tertiær",
            parents_background: "Far frå Sogndal",
            audio: "inf_27pog28p.mp3",
            text: Texts.text_27pog28p,
            panel: "Panel"
        },
        {
            id: "29",
            place: "Fjøra",
            gender: "Mann",
            age: "Middelaldra 41 - 60",
            birth: "1941 - 1970",
            date_of_recording: "1996",
            education: "Vidaregåande",
            occupation: "Tertiær",
            parents_background: "Mor frå Sogndal",
            audio: "inf_29og30.mp3",
            text: Texts.text_29og30,
            panel: "Trend"
        },
        {
            id: "29p",
            place: "Fjøra",
            gender: "Mann",
            age: "Eldre 61 -",
            birth: "1941 - 1970",
            date_of_recording: "2017",
            education: "Vidaregåande",
            occupation: "Tertiær",
            parents_background: "Mor frå Sogndal",
            audio: "inf_14pog29p.mp3",
            text: Texts.text_14pog29p,
            panel: "Panel"
        },
        {
            id: "30",
            place: "Fjøra",
            gender: "Mann",
            age: "Eldre 61 - ",
            birth: "1911 - 1940",
            date_of_recording: "1996",
            education: "Yrkesfag",
            occupation: "Tertiær",
            parents_background: "Begge frå Sogndal",
            audio: "inf_29og30.mp3",
            text: Texts.text_29og30,
            panel: "Trend"
        },
        {
            id: "30p",
            place: "Fjøra",
            gender: "Mann",
            age: "Eldre 61 - ",
            birth: "1911 - 1940",
            date_of_recording: "2017",
            education: "Yrkesfag",
            occupation: "Tertiær",
            parents_background: "Begge frå Sogndal",
            audio: "inf_03pog30p.mp3",
            text: Texts.text_3pog30p,
            panel: "Panel"
        },
        {
            id: "31",
            place: "Fjøra",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "1996",
            education: "Yrkesfag",
            occupation: "Elev",
            parents_background: "Begge frå Sogndal",
            audio: "inf_31og32.mp3",
            text: Texts.text_31og32,
            panel: "Trend"
        },
        {
            id: "31p",
            place: "Fjøra",
            gender: "Mann",
            age: "Middelaldra 41 - 60",
            birth: "1971 - 2000",
            date_of_recording: "2017",
            education: "Yrkesfag",
            occupation: "Tertiær",
            parents_background: "Begge frå Sogndal",
            audio: "inf_31pog32p.mp3",
            text: Texts.text_31pog32p,
            panel: "Panel"
        },
        {
            id: "32",
            place: "Fjøra",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "1996",
            education: "Vidaregåande",
            occupation: "Elev",
            parents_background: "Far frå Sogndal",
            audio: "inf_31og32.mp3",
            text: Texts.text_31og32,
            panel: "Trend"
        },
        {
            id: "32p",
            place: "Fjøra",
            gender: "Mann",
            age: "Middelaldra 41 - 60",
            birth: "1971 - 2000",
            date_of_recording: "2017",
            education: "Vidaregåande",
            occupation: "Tertiær",
            parents_background: "Far frå Sogndal",
            audio: "inf_31pog32p.mp3",
            text: Texts.text_31pog32p,
            panel: "Panel"
        },
        {
            id: "33",
            place: "Dalen",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "1996",
            education: "Vidaregåande",
            occupation: "Elev",
            parents_background: "Far frå Sogndal",
            audio: "inf_33og34.mp3",
            text: Texts.text_33og34,
            panel: "Trend"
        },
        {
            id: "34",
            place: "Dalen",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "1996",
            education: "Yrkesfag",
            occupation: "Elev",
            parents_background: "Begge frå Sogndal",
            audio: "inf_33og34.mp3",
            text: Texts.text_33og34,
            panel: "Trend"
        },
        {
            id: "35",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Begge frå Sogndal",
            audio: "inf_35og58.mp3",
            text: Texts.text_35og58,
            panel: "Trend"
        },
        {
            id: "36",
            place: "Norane",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Begge frå Sogndal",
            audio: "inf_36og47.mp3",
            text: Texts.text_36og47,
            panel: "Trend"
        },
        {
            id: "36p",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ung 26 - 40",
            birth: "1971 - 2000",
            date_of_recording: "2017",
            education: "Vidaregåande",
            occupation: "Tertiær",
            parents_background: "Begge frå Sogndal",
            audio: "inf_36pog48p.mp3",
            text: Texts.text_36pog48p,
            panel: "Panel"
        },
        {
            id: "37",
            place: "Norane",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Begge frå Sogndal",
            audio: "inf_37og38.mp3",
            text: Texts.text_37og38,
            panel: "Trend"
        },
        {
            id: "38",
            place: "Norane",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Begge frå Sogndal",
            audio: "inf_37og38.mp3",
            text: Texts.text_37og38,
            panel: "Trend"
        },
        {
            id: "39",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Begge frå Sogndal",
            audio: "inf_39og48.mp3",
            text: Texts.text_39og48,
            panel: "Trend"
        },
        {
            id: "40",
            place: "Fjøra",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Begge frå Sogndal",
            audio: "inf_40og52.mp3",
            text: Texts.text_40og52,
            panel: "Trend"
        },
        {
            id: "41",
            place: "Dalen",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Begge frå Sogndal",
            audio: "inf_41og42.mp3",
            text: Texts.text_41og42,
            panel: "Trend"
        },
        {
            id: "42",
            place: "Dalen",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Begge frå Sogndal",
            audio: "inf_41og42.mp3",
            text: Texts.text_41og42,
            panel: "Trend"
        },
        {
            id: "42p",
            place: "Dalen",
            gender: "Mann",
            age: "Ung 26 - 40",
            birth: "1971 - 2000",
            date_of_recording: "2017",
            education: "Vidaregåande",
            occupation: "Tertiær",
            parents_background: "Begge frå Sogndal",
            audio: "inf_42pog54p.mp3",
            text: Texts.text_42pog54p,
            panel: "Panel"
        },
        {
            id: "43",
            place: "Fjøra",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Begge frå Sogndal",
            audio: "inf_43og55.mp3",
            text: Texts.text_43og55,
            panel: "Trend"
        },
        {
            id: "44",
            place: "Norane",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Begge frå Sogndal",
            audio: "inf_44og65.mp3",
            text: Texts.text_44og65,
            panel: "Trend"
        },
        {
            id: "44p",
            place: "Norane",
            gender: "Mann",
            age: "Ung 26 - 40",
            birth: "1971 - 2000",
            date_of_recording: "2017",
            education: "Yrkesfag",
            occupation: "Tertiær",
            parents_background: "Begge frå Sogndal",
            audio: "inf_44pog55p.mp3",
            text: Texts.text_44pog55p,
            panel: "Panel"
        },
        {
            id: "45",
            place: "Dalen",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Begge frå Sogndal",
            audio: "inf_45og46.mp3",
            text: Texts.text_45og46,
            panel: "Trend"
        },
        {
            id: "46",
            place: "Fjøra",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Begge frå Sogndal",
            audio: "inf_45og46.mp3",
            text: Texts.text_45og46,
            panel: "Trend"
        },
        {
            id: "46p",
            place: "Fjøra",
            gender: "Mann",
            age: "Ung 26 - 40",
            birth: "1971 - 2000",
            date_of_recording: "2017",
            education: "Yrkesfag",
            occupation: "Tertiær",
            parents_background: "Begge frå Sogndal",
            audio: "inf_46pog61p.mp3",
            text: Texts.text_46pog61p,
            panel: "Panel"
        },
        {
            id: "47",
            place: "Norane",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Mor frå Sogndal",
            audio: "inf_36og47.mp3",
            text: Texts.text_36og47,
            panel: "Trend"
        },
        {
            id: "48",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Far frå Sogndal",
            audio: "inf_39og48.mp3",
            text: Texts.text_39og48,
            panel: "Trend"
        },
        {
            id: "48p",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ung 26 - 40",
            birth: "1971 - 2000",
            date_of_recording: "2017",
            education: "Høgskule",
            occupation: "Tertiær",
            parents_background: "Far frå Sogndal",
            audio: "inf_36pog48p.mp3",
            text: Texts.text_36pog48p,
            panel: "Panel"
        },
        {
            id: "49",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Mor frå Sogndal",
            audio: "inf_49og61.mp3",
            text: Texts.text_49og61,
            panel: "Trend"
        },
        {
            id: "50",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Far frå Sogndal",
            audio: "inf_50og62.mp3",
            text: Texts.text_50og62,
            panel: "Trend"
        },
        {
            id: "51",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Far frå Sogndal",
            audio: "inf_51og60.mp3",
            text: Texts.text_51og60,
            panel: "Trend"
        },
        {
            id: "51p",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ung 26 - 40",
            birth: "1971 - 2000",
            date_of_recording: "2017",
            education: "Høgskule",
            occupation: "Tertiær",
            parents_background: "Far frå Sogndal",
            audio: "inf_51pog59p.mp3",
            text: Texts.text_51pog59p,
            panel: "Panel"
        },
        {
            id: "52",
            place: "Norane",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Mor frå Sogndal",
            audio: "inf_40og52.mp3",
            text: Texts.text_40og52,
            panel: "Trend"
        },
        {
            id: "52p",
            place: "Norane",
            gender: "Mann",
            age: "Ung 26 - 40",
            birth: "1971 - 2000",
            date_of_recording: "2017",
            education: "Vidaregåande",
            occupation: "Tertiær",
            parents_background: "Mor frå Sogndal",
            audio: "inf_52pog53p.mp3",
            text: Texts.text_52pog53p,
            panel: "Panel"
        },
        {
            id: "53",
            place: "Norane",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Far frå Sogndal",
            audio: "inf_53og63.mp3",
            text: Texts.text_53og63,
            panel: "Trend"
        },
        {
            id: "53p",
            place: "Norane",
            gender: "Mann",
            age: "Ung 26 - 40",
            birth: "1971 - 2000",
            date_of_recording: "2017",
            education: "Vidaregåande",
            occupation: "Tertiær",
            parents_background: "Far frå Sogndal",
            audio: "inf_52pog53p.mp3",
            text: Texts.text_52pog53p,
            panel: "Panel"
        },
        {
            id: "54",
            place: "Dalen",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Far frå Sogndal",
            audio: "inf_54og64.mp3",
            text: Texts.text_54og64,
            panel: "Trend"
        },
        {
            id: "54p",
            place: "Dalen",
            gender: "Mann",
            age: "Ung 26 - 40",
            birth: "1971 - 2000",
            date_of_recording: "2017",
            education: "Yrkesfag",
            occupation: "Tertiær",
            parents_background: "Far frå Sogndal",
            audio: "inf_42pog54p.mp3",
            text: Texts.text_42pog54p,
            panel: "Panel"
        },
        {
            id: "55",
            place: "Norane",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Far frå Sogndal",
            audio: "inf_43og55.mp3",
            text: Texts.text_43og55,
            panel: "Trend"
        },
        {
            id: "55p",
            place: "Norane",
            gender: "Mann",
            age: "Ung 26 - 40",
            birth: "1971 - 2000",
            date_of_recording: "2017",
            education: "Yrkesfag",
            occupation: "Tertiær",
            parents_background: "Far frå Sogndal",
            audio: "inf_44pog55p.mp3",
            text: Texts.text_44pog55p,
            panel: "Panel"
        },
        {
            id: "56",
            place: "Fjøra",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Far frå Sogndal",
            audio: "inf_56og66.mp3",
            text: Texts.text_56og66,
            panel: "Trend"
        },
        {
            id: "57",
            place: "Fjøra",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Mor frå Sogndal",
            audio: "inf_57og67.mp3",
            text: Texts.text_57og67,
            panel: "Trend"
        },
        {
            id: "58",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Ingen frå Sogndal",
            audio: "inf_35og58.mp3",
            text: Texts.text_35og58,
            panel: "Trend"
        },
        {
            id: "59",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Ingen frå Sogndal",
            audio: "inf_59.mp3",
            text: Texts.text_59,
            panel: "Trend"
        },
        {
            id: "59p",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ung 26 - 40",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Høgskule",
            occupation: "Tertiær",
            parents_background: "Ingen frå Sogndal",
            audio: "inf_51pog59p.mp3",
            text: Texts.text_51pog59p,
            panel: "Panel"
        },
        {
            id: "60",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Ingen frå Sogndal",
            audio: "inf_51og60.mp3",
            text: Texts.text_51og60,
            panel: "Trend"
        },
        {
            id: "60p",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ung 26 - 40",
            birth: "1971 - 2000",
            date_of_recording: "2017",
            education: "Høgskule ",
            occupation: "Tertiær",
            parents_background: "Ingen frå Sogndal",
            audio: "inf_19pog60p.mp3",
            text: Texts.text_19pog60p,
            panel: "Panel"
        },
        {
            id: "61",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Ingen frå Sogndal",
            audio: "inf_49og61.mp3",
            text: Texts.text_49og61,
            panel: "Trend"
        },
        {
            id: "61p",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ung 26 - 40",
            birth: "1971 - 2000",
            date_of_recording: "2017",
            education: "Høgskule",
            occupation: "Tertiær",
            parents_background: "Ingen frå Sogndal",
            audio: "inf_46pog61p.mp3",
            text: Texts.text_46pog61p,
            panel: "Panel"
        },
        {
            id: "62",
            place: "Norane",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Ingen frå Sogndal",
            audio: "inf_50og62.mp3",
            text: Texts.text_50og62,
            panel: "Trend"
        },
        {
            id: "63",
            place: "Fjøra",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Ingen frå Sogndal",
            audio: "inf_53og63.mp3",
            text: Texts.text_53og63,
            panel: "Trend"
        },
        {
            id: "64",
            place: "Fjøra",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Ingen frå Sogndal",
            audio: "inf_54og64.mp3",
            text: Texts.text_54og64,
            panel: "Trend"
        },
        {
            id: "65",
            place: "Fjøra",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Ingen frå Sogndal",
            audio: "inf_44og65.mp3",
            text: Texts.text_44og65,
            panel: "Trend"
        },
        {
            id: "66",
            place: "Fjøra",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Ingen frå Sogndal",
            audio: "inf_56og66.mp3",
            text: Texts.text_56og66,
            panel: "Trend"
        },
        {
            id: "67",
            place: "Fjøra",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "1971 - 2000",
            date_of_recording: "2001",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Ingen frå Sogndal",
            audio: "inf_57og67.mp3",
            text: Texts.text_57og67,
            panel: "Trend"
        },
        {
            id: "68",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Begge frå Sogndal",
            audio: "inf_68og69.mp3",
            text: Texts.text_68og69,
            panel: "Trend"
        },
        {
            id: "69",
            place: "Norane",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Far frå Sogndal",
            audio: "inf_68og69.mp3",
            text: Texts.text_68og69,
            panel: "Trend"
        },
        {
            id: "70",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Far frå Sogndal",
            audio: "inf_70og71.mp3",
            text: Texts.text_70og71,
            panel: "Trend"
        },
        {
            id: "71",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Mor frå Sogndal",
            audio: "inf_70og71.mp3",
            text: Texts.text_70og71,
            panel: "Trend"
        },
        {
            id: "72",
            place: "Fjøra",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Mor frå Sogndal",
            audio: "inf_72og73.mp3",
            text: Texts.text_72og73,
            panel: "Trend"
        },
        {
            id: "73",
            place: "Fjøra",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Ingen frå Sogndal",
            audio: "inf_72og73.mp3",
            text: Texts.text_72og73,
            panel: "Trend"
        },
        {
            id: "74",
            place: "Fjøra",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Begge frå Sogndal",
            audio: "inf_74og75.mp3",
            text: Texts.text_74og75,
            panel: "Trend"
        },
        {
            id: "75",
            place: "Fjøra",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Far frå Sogndal",
            audio: "inf_74og75.mp3",
            text: Texts.text_74og75,
            panel: "Trend"
        },
        {
            id: "76",
            place: "Fjøra",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Ingen frå Sogndal",
            audio: "inf_76og77.mp3",
            text: Texts.text_76og77,
            panel: "Trend"
        },
        {
            id: "77",
            place: "Fjøra",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Ingen frå Sogndal",
            audio: "inf_76og77.mp3",
            text: Texts.text_76og77,
            panel: "Trend"
        },
        {
            id: "78",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Begge frå Sogndal",
            audio: "inf_78og79.mp3",
            text: Texts.text_78og79,
            panel: "Trend"
        },
        {
            id: "79",
            place: "Fjøra",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Begge frå Sogndal",
            audio: "inf_78og79.mp3",
            text: Texts.text_78og79,
            panel: "Trend"
        },
        {
            id: "80",
            place: "Dalen",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Far frå Sogndal",
            audio: "inf_80og81.mp3",
            text: Texts.text_80og81,
            panel: "Trend"
        },
        {
            id: "81",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Ingen frå Sogndal",
            audio: "inf_80og81.mp3",
            text: Texts.text_80og81,
            panel: "Trend"
        },
        {
            id: "82",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Ingen frå Sogndal",
            audio: "inf_82og83.mp3",
            text: Texts.text_82og83,
            panel: "Trend"
        },
        {
            id: "83",
            place: "Fjøra",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Ingen frå Sogndal",
            audio: "inf_82og83.mp3",
            text: Texts.text_82og83,
            panel: "Trend"
        },
        {
            id: "84",
            place: "Dalen",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Ingen frå Sogndal",
            audio: "inf_84og85.mp3",
            text: Texts.text_84og85,
            panel: "Trend"
        },
        {
            id: "85",
            place: "Dalen",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Begge frå Sogndal",
            audio: "inf_84og85.mp3",
            text: Texts.text_84og85,
            panel: "Trend"
        },
        {
            id: "86",
            place: "Fjøra",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Mor frå Sogndal",
            audio: "inf_86og87.mp3",
            text: Texts.text_86og87,
            panel: "Trend"
        },
        {
            id: "87",
            place: "Fjøra",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Ingen frå Sogndal",
            audio: "inf_86og87.mp3",
            text: Texts.text_86og87,
            panel: "Trend"
        },
        {
            id: "88",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Mor frå Sogndal",
            audio: "inf_88og89.mp3",
            text: Texts.text_88og89,
            panel: "Trend"
        },
        {
            id: "89",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Begge frå Sogndal",
            audio: "inf_88og89.mp3",
            text: Texts.text_88og89,
            panel: "Trend"
        },
        {
            id: "90",
            place: "Dalen",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Begge frå Sogndal",
            audio: "inf_90og91.mp3",
            text: Texts.text_90og91,
            panel: "Trend"
        },
        {
            id: "91",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Ingen frå Sogndal",
            audio: "inf_90og91.mp3",
            text: Texts.text_90og91,
            panel: "Trend"
        },
        {
            id: "92",
            place: "Norane",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Ingen frå Sogndal",
            audio: "inf_92og93.mp3",
            text: Texts.text_92og93,
            panel: "Trend"
        },
        {
            id: "93",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Begge frå Sogndal",
            audio: "inf_92og93.mp3",
            text: Texts.text_92og93,
            panel: "Trend"
        },
        {
            id: "94",
            place: "Fjøra",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Far frå Sogndal",
            audio: "inf_94og95.mp3",
            text: Texts.text_94og95,
            panel: "Trend"
        },
        {
            id: "95",
            place: "Fjøra",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Begge frå Sogndal",
            audio: "inf_94og95.mp3",
            text: Texts.text_94og95,
            panel: "Trend"
        },
        {
            id: "96",
            place: "Fjøra",
            gender: "Kvinne",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Ingen frå Sogndal",
            audio: "inf_96og97.mp3",
            text: Texts.text_96og97,
            panel: "Trend"
        },
        {
            id: "97",
            place: "Fjøra",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Begge frå Sogndal",
            audio: "inf_96og97.mp3",
            text: Texts.text_96og97,
            panel: "Trend"
        },
        {
            id: "98",
            place: "Fjøra",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Begge frå Sogndal",
            audio: "inf_98og99.mp3",
            text: Texts.text_98og99,
            panel: "Trend"
        },
        {
            id: "99",
            place: "Fjøra",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Begge frå Sogndal",
            audio: "inf_98og99.mp3",
            text: Texts.text_98og99,
            panel: "Trend"
        },
        {
            id: "100",
            place: "Fjøra",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Ingen frå Sogndal",
            audio: "inf_100og101.mp3",
            text: Texts.text_100og101,
            panel: "Trend"
        },
        {
            id: "101",
            place: "Fjøra",
            gender: "Mann",
            age: "Ungdom 13 - 25",
            birth: "2001 -",
            date_of_recording: "2016",
            education: "Grunnskule",
            occupation: "Elev",
            parents_background: "Far frå Sogndal",
            audio: "inf_100og101.mp3",
            text: Texts.text_100og101,
            panel: "Trend"
        },
    ];

export default informers;