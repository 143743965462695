const symbols = {
    infinitiv_a : "*",
    infinitiv_e : "‘",
    ao : "@",
    å :"{",
    bundanForm_i : "¤",
    bundanForm_a : "%",
    adnedn : "~",
    aneene : "¨",
    dl : "§",
    ll : "+",
    dn : "#",
    rn : "}",

    infinitiv_anna : "*a",
    ao_anna : "@a",
    bundanForm_anna : "¤a",
    adnedn_anna : "~a",
    dl_anna : "§a",
    dn_anna : "#a"
};

export default symbols;